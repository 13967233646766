import { Box, Link, Typography } from "@mui/material";
import env from "env";

export default function AuthFooter() {
  return (
    <Box sx={{ marginTop: 3, textAlign: "center" }}>
      <Typography variant="body5" color="darkGrey.30">
        Lunit SCOPE IO runs on <strong>Desktop Chrome browser</strong>. Powered
        by Lunit Inc.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: (theme) => theme.spacing(2),
          "& > *": {
            margin: (theme) => theme.spacing(0, 4),
          },
        }}
      >
        <Typography variant="body5" color="darkGrey.30">
          Ver. {env.version}
        </Typography>
        {/* Need to change  */}
        <Link href={env.ifuLink}>Instructions for use</Link>
      </Box>
    </Box>
  );
}
