/**
 * WSIAnalysisResult Reducer
 */
import { createReducer, StateType, ActionType } from "typesafe-actions";
import { combineReducers } from "redux";
import produce from "immer";
import { FetchStatus, AnalysisStatus } from "dux/utils/commonEnums";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import {
  WSIAnalysisResultState,
  WSIMetaInfoMgmtState,
  WSIListState,
  WSIAnalysisResultViewerState,
  WSIReportState,
  AnalyzedSlidesState,
} from "./model";
import {
  GET_WSI_LIST_TYPES,
  getWSIList,
  GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES,
  getWSIAnalysisResultSummaryById,
  GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES,
  getWSIAnalysisResultErrorMsgById,
  COMPLETE_WSI_FILE_UPLOADING_TYPES,
  completeWSIFileUploading,
  UPDATE_WSI_META_INFO_TYPES,
  updateWSIMetaInfo,
  UPDATE_CANCER_NAME_TYPES,
  updateCancerName,
  SET_NEED_TO_REFRESH_WSI_LIST,
  setNeedToRefreshWSIList,
  GENERATE_REPORTS,
  generateReports,
  DONE_GENERATING_REPORT,
  UPDATE_REPORT_STATE,
  updateReportState,
  GET_ANALYZED_SLIDE_LIST_TYPES,
  getAnalyzedSlides,
  RESET_ANALYZED_SLIDES,
  REQUEST_ANALYSIS_TYPES,
  requestAnalysis,
  DELETE_SLIDES_TYPES,
  RESET_DELETE_STATUS,
  CANCEL_REPORT_GENERATION,
  cancelReportGeneration,
  CLOSE_REPORT_PROGRESS,
} from "./actions";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import forEach from "lodash/forEach";
import { DEFAULT_PAGE_SIZE } from "utils/pagination";

const wsiMetaInfoMgmtState: WSIMetaInfoMgmtState = {
  currentMethod: FetchMethod.Post,
  error: "",
  fetchStatus: FetchStatus.Idle,
};

const wsiMetaInfoMgmtReducer = createReducer<WSIMetaInfoMgmtState>(
  wsiMetaInfoMgmtState,
  {
    [UPDATE_WSI_META_INFO_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Put;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [UPDATE_WSI_META_INFO_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [UPDATE_WSI_META_INFO_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof updateWSIMetaInfo.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [UPDATE_CANCER_NAME_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [UPDATE_CANCER_NAME_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [UPDATE_CANCER_NAME_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof updateCancerName.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
  }
);

export const wsiListInitialState: WSIListState = {
  fetchStatus: FetchStatus.Idle,
  deleteStatus: FetchStatus.Idle,
  analysisError: "",
  list: [],
  pagination: {
    first: true,
    last: true,
    size: DEFAULT_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0,
  },
  needToRefresh: false,
  resetSearch: false,
};

const WSIAnalysisResultReducer = createReducer<WSIListState>(
  wsiListInitialState,
  {
    [GET_WSI_LIST_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
        draft.needToRefresh = false;
        draft.resetSearch = false;
      }),
    [GET_WSI_LIST_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
    [GET_WSI_LIST_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getWSIList.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.list = payload.contents;
        draft.pagination = {
          ...state.pagination,
          first: payload.first,
          last: payload.last,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        };
      }),
    [REQUEST_ANALYSIS_TYPES.REQUEST]: (
      state,
      action: ActionType<typeof requestAnalysis.request>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((item) => {
          if (!payload.wsiIds.includes(item.id)) {
            return item;
          }
          return {
            ...item,
            status: AnalysisStatus.Requested,
          };
        });
      }),
    [REQUEST_ANALYSIS_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof requestAnalysis.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((item) => {
          if (!payload.wsiIds.includes(item.id)) {
            return item;
          }
          return {
            ...item,
            status: AnalysisStatus.Failed,
          };
        });
      }),
    [GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getWSIAnalysisResultSummaryById.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((item) => {
          if (item.id !== payload.wsiId) {
            return item;
          }
          return {
            ...item,
            analysisId: payload.analysisId,
            status: payload.status,
            statusUpdatedTime: payload.statusUpdatedTime,
            summary: payload.summary,
            comment: payload.comment,
          };
        });
      }),
    [GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getWSIAnalysisResultErrorMsgById.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((item) => {
          if (item.id !== payload.wsiId) {
            return item;
          }
          return {
            ...item,
            analysisId: payload.analysisId,
            status: payload.status,
            statusUpdatedTime: payload.statusUpdatedTime,
            errorMsg: payload.errorMsg,
            comment: payload.comment,
          };
        });
      }),
    // [GET_WSI_ANALYSIS_RESULT_REPORT_TYPES.REQUEST]: (
    //   state,
    //   action: ActionType<typeof getWSIAnalysisResultReportById.request>
    // ) =>
    //   produce(state, (draft) => {
    //     const { payload } = action;
    //     draft.list = state.list.map((item) => {
    //       if (item.id !== payload) {
    //         return item;
    //       }
    //       return {
    //         ...item,
    //         isReportDownloading: true,
    //       };
    //     });
    //   }),
    // [GET_WSI_ANALYSIS_RESULT_REPORT_TYPES.SUCCESS]: (
    //   state,
    //   action: ActionType<typeof getWSIAnalysisResultReportById.success>
    // ) =>
    //   produce(state, (draft) => {
    //     const { payload } = action;
    //     draft.list = state.list.map((item) => {
    //       if (item.analysisId !== payload.analysisResult.analysisId) {
    //         return item;
    //       }
    //       return {
    //         ...item,
    //         isReportDownloading: false,
    //       };
    //     });
    //   }),
    // [GET_WSI_ANALYSIS_RESULT_REPORT_TYPES.FAILURE]: (
    //   state,
    //   action: ActionType<typeof getWSIAnalysisResultReportById.failure>
    // ) =>
    //   produce(state, (draft) => {
    //     const { payload } = action;
    //     draft.list = state.list.map((item) => {
    //       if (item.analysisId !== payload) {
    //         return item;
    //       }
    //       return {
    //         ...item,
    //         isReportDownloading: false,
    //       };
    //     });
    //   }),
    [COMPLETE_WSI_FILE_UPLOADING_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof completeWSIFileUploading.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        if (state.pagination.first) {
          draft.list = [payload, ...state.list];
        }
        const isInBoundary =
          state.pagination.totalElements % state.pagination.size;
        if (state.pagination.first && state.pagination.last && !isInBoundary) {
          draft.pagination.last = false;
        }
        draft.pagination.totalElements = state.pagination.totalElements + 1;
        draft.pagination.totalPages = isInBoundary
          ? state.pagination.totalPages
          : state.pagination.totalPages + 1;
      }),
    [UPDATE_WSI_META_INFO_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof updateWSIMetaInfo.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((wsi) => {
          if (wsi.id !== payload.id) {
            return wsi;
          }
          return {
            ...wsi,
            tags: payload.tags,
          };
        });
      }),
    [UPDATE_CANCER_NAME_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof updateCancerName.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.list = state.list.map((wsi) => {
          const targetWSIItem = payload.find((item) => item.id === wsi.id);
          if (!targetWSIItem) {
            return wsi;
          }
          return {
            ...wsi,
          };
        });
      }),
    [SET_NEED_TO_REFRESH_WSI_LIST]: (
      state,
      action: ActionType<typeof setNeedToRefreshWSIList>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.needToRefresh = payload.needToRefresh;
        draft.resetSearch = payload.resetSearch || false;
      }),
    [DELETE_SLIDES_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.deleteStatus = FetchStatus.Pending;
      }),
    [DELETE_SLIDES_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.deleteStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_SLIDES_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.deleteStatus = FetchStatus.Rejected;
      }),
    [RESET_DELETE_STATUS]: (state) =>
      produce(state, (draft) => {
        draft.deleteStatus = FetchStatus.Idle;
      }),
  }
);

export const viewerInitialState: WSIAnalysisResultViewerState = {
  analysisId: "",
  summary: [],
  controlPanel: [],
  biomarkerResultFilePath: "",
  histologyResultFilePath: "",
  rawDataResultFilePath: "",
  inferenceResultLoaded: false,
  inferenceResultFilePath: "",
  fetchStatus: FetchStatus.Idle,
};

const viewerReducer = createReducer<WSIAnalysisResultViewerState>(
  viewerInitialState,
  {
    // [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.REQUEST]: (state) =>
    //   produce(state, (draft) => {
    //     draft.inferenceResultLoaded = false;
    //     draft.fetchStatus = FetchStatus.Pending;
    //   }),
    // [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.SUCCESS]: (
    //   state,
    //   action: ActionType<typeof getAnalysisSourceDetailsById.success>
    // ) =>
    //   produce(state, (draft) => {
    //     const { payload } = action;
    //     draft.analysisId = payload.analysisId;
    //     draft.inferenceResultFilePath = payload.inferenceResultFilePath;
    //     draft.summary = payload.summary;
    //     draft.comment = payload.comment;
    //     draft.controlPanel = payload.controlPanel.map(
    //       ({ id, title, items }) => ({
    //         id,
    //         title,
    //         items: items.map((item) => ({ ...item, active: false })),
    //       })
    //     );
    //     draft.fetchStatus = FetchStatus.Fulfilled;
    //   }),
    // [RESET_ANALYSIS_RESULT]: (_) => viewerInitialState,
    // [UPDATE_IMAGE_MASK_LOADING_STATUS]: (
    //   state,
    //   action: ActionType<typeof updateImageMaskLoadingStatus>
    // ) =>
    //   produce(state, (draft) => {
    //     draft.inferenceResultLoaded = action.payload;
    //   }),
    // [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.FAILURE]: (state) =>
    //   produce(state, (draft) => {
    //     draft.fetchStatus = FetchStatus.Rejected;
    //   }),
  }
);

const reportInitialState: WSIReportState = {
  progressClosed: false,
  wsiIds: [],
  states: {},
};

export const reportReducer = createReducer<WSIReportState>(reportInitialState, {
  [GENERATE_REPORTS]: (state, action: ActionType<typeof generateReports>) =>
    produce(state, (draft) => {
      // filter wsiIds so that report state holds
      // only one loading state for each wsiId
      const wsiItems = filter(
        action.payload,
        (value) => findIndex(draft.wsiIds, (id) => id === value.id) < 0
      );
      forEach(wsiItems, (value) => {
        draft.wsiIds.push(value.id);
        draft.states[value.id] = {
          wsiId: value.id,
          name: value.name,
          loading: false,
          done: false,
          error: null,
          createTime: value.createTime,
        };
      });
    }),
  [UPDATE_REPORT_STATE]: (
    state,
    action: ActionType<typeof updateReportState>
  ) =>
    produce(state, (draft) => {
      const { wsiId, loading, done, error } = action.payload;
      if (draft.states[wsiId]) {
        draft.states[wsiId].loading = loading;
        draft.states[wsiId].done = done;
        draft.states[wsiId].error = error;
      }
    }),
  [CANCEL_REPORT_GENERATION]: (
    state,
    action: ActionType<typeof cancelReportGeneration>
  ) =>
    produce(state, (draft) => {
      draft.wsiIds = filter(
        state.wsiIds,
        (wsiId) => wsiId !== action.payload.wsiId
      );
      delete draft.states[action.payload.wsiId];
    }),
  [CLOSE_REPORT_PROGRESS]: (state) =>
    produce(state, (draft) => {
      draft.progressClosed = true;
    }),
  [DONE_GENERATING_REPORT]: (_) => reportInitialState,
});
export const analyzedSlidesInitialState: AnalyzedSlidesState = {
  fetchStatus: FetchStatus.Idle,
  list: [],
};

const analyzedSlidesReducer = createReducer<AnalyzedSlidesState>(
  analyzedSlidesInitialState,
  {
    [RESET_ANALYZED_SLIDES]: (state) => analyzedSlidesInitialState,
    [GET_ANALYZED_SLIDE_LIST_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_ANALYZED_SLIDE_LIST_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
    [GET_ANALYZED_SLIDE_LIST_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getAnalyzedSlides.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.list = payload;
      }),
  }
);

const wsiAnalysisResultReducer = combineReducers({
  wsiList: WSIAnalysisResultReducer,
  wsiMetaInfoMgmt: wsiMetaInfoMgmtReducer,
  viewer: viewerReducer,
  report: reportReducer,
  analyzedSlides: analyzedSlidesReducer,
});

export const initialState: WSIAnalysisResultState = {
  wsiList: wsiListInitialState,
  wsiMetaInfoMgmt: wsiMetaInfoMgmtState,
  viewer: viewerInitialState,
  report: reportInitialState,
  analyzedSlides: analyzedSlidesInitialState,
};

export type WSIAnalysisResultReducerState = StateType<
  typeof wsiAnalysisResultReducer
>;

export default wsiAnalysisResultReducer;
