import PageTemplate from "components/common/PageTemplate";
import SlideList from "components/SlideList/SlideList";
import withAuthGuard from "hoc/withAuthGuard";
import { useParams } from "react-router-dom";
export interface ISlideURLParams {
  projectId?: string;
}

const Slides = () => {
  const { projectId } = useParams<ISlideURLParams>();
  return (
    <PageTemplate>
      <SlideList
        projectId={projectId && Number.parseInt(projectId)}
        key={projectId ? projectId : "slide-page"}
      />
    </PageTemplate>
  );
};

export default withAuthGuard(Slides);
