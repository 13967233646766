import {
  Typography,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import FormTemplate, { CommonFormProps, FormMode } from "./Template";
import { formatUserRole, APIUserInput, UserRole } from "dux/user/model";
import { getEmailExists } from "dux/user/api";

interface UserFormProps extends CommonFormProps {
  mode: FormMode;
  defaultValues?: APIUserInput | null;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "440px",
}));

const FormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

function UserForm({
  onCancel,
  onSubmit,
  open,
  mode,
  defaultValues,
  loading,
}: UserFormProps) {
  const { register, handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { errors, isValidating } = formState;
  return (
    <FormTemplate
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={`${mode} User`}
      loading={loading}
      isValidating={isValidating}
    >
      <Container>
        <FormRow>
          <InputLabel htmlFor="title" required>
            <Typography variant="body3" color="darkGrey.5">
              Name
            </Typography>
          </InputLabel>
          <TextField
            helperText={errors.name && errors.name.message}
            error={!!errors.name}
            placeholder="Your name"
            inputRef={register({
              required: "Please fill out this field.",
              maxLength: {
                value: 200,
                message: "Please type less than 200 characters",
              },
            })}
            defaultValue={defaultValues && defaultValues.name}
            variant="outlined"
            name="name"
            required
            autoFocus
          />
        </FormRow>
        <FormRow>
          <InputLabel htmlFor="title" required>
            <Typography variant="body3" color="darkGrey.5">
              Email
            </Typography>
          </InputLabel>
          <TextField
            required
            name="email"
            type="email"
            variant="outlined"
            placeholder="lunit@lunit.io"
            defaultValue={defaultValues && defaultValues.email}
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            disabled={mode === FormMode.Edit}
            inputRef={register({
              required: "Please fill out this field.",
              maxLength: {
                value: 300,
                message: "Please type less than 300 characters",
              },
              validate: mode === FormMode.Add && {
                emailExists: async (value) => {
                  const emailExists = await getEmailExists(value);
                  return !emailExists || "The email is already registered.";
                },
              },
            })}
          />
        </FormRow>
        <FormRow>
          <Controller
            name="roleId"
            control={control}
            defaultValue={
              (defaultValues && defaultValues.roleName) || UserRole.Analyzer
            }
            render={({ onChange, value }) => (
              <>
                <InputLabel htmlFor="title" required>
                  <Typography variant="body3" color="darkGrey.5">
                    Role
                  </Typography>
                </InputLabel>
                <Select
                  labelId="user-role-select-label"
                  disabled={value === UserRole.Admin}
                  sx={{ width: "100%" }}
                  id="user-role-select"
                  inputRef={register}
                  value={value}
                  onChange={onChange}
                  name="roleId"
                  required
                >
                  {Object.keys(UserRole).map(
                    (key) =>
                      (key !== "Admin" ||
                        (!!defaultValues &&
                          defaultValues.roleName === UserRole.Admin)) && (
                        <MenuItem key={key} value={UserRole[key]}>
                          {formatUserRole(UserRole[key])}
                        </MenuItem>
                      )
                  )}
                </Select>
              </>
            )}
          />
        </FormRow>
        <FormRow>
          <InputLabel htmlFor="title">
            <Typography variant="body3" color="darkGrey.5">
              Telephone
            </Typography>
          </InputLabel>
          <TextField
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber && errors.phoneNumber.message}
            placeholder="Phone number"
            inputRef={register({
              pattern: {
                value: new RegExp(
                  "^\\+?(\\d{1,3}-)?\\d{2,4}-\\d{3,4}(-\\d{4})?$"
                ),
                message:
                  "Phone numbers should be in the format of ###-####-#### or ###-####.",
              },
            })}
            defaultValue={defaultValues && defaultValues.phoneNumber}
            variant="outlined"
            name="phoneNumber"
          />
        </FormRow>
      </Container>
    </FormTemplate>
  );
}

export default UserForm;
