import { ProjectUUID } from "dux/projects/model";
import { FetchStatus } from "dux/utils/commonEnums";
import { FileWithPath } from "react-dropzone";
import { Task } from "redux-saga";

export type WSIId = string;
export type PresignedURL = string;
export type FileName = string;

export interface XHRState {
  isUploading: boolean;
  uploadFailed: boolean;
  loaded: number;
  total: number;
  error?: string;
}

export enum WSIFileUploadError {
  NoMatchingMRXS = "No matching .mrxs file for selected folder of .dat files.",
  NoDAT = "Please upload corresponding directory along with .mrxs file for uploading MIRAX slide.",
  MissingSlideDat = "Your .mrxs file is missing one or more data files.",
  InvalidFile = "Invalid/Unsupported file",
  InvalidFilename = "Not acceptable file name detected. Please make sure the file name does not contain following character: ':'",
  UploadCancelled = "Upload cancelled.",
  UploadFailedNetwork = "Failed to upload. Unexpected network failure.",
  UploadFailedServer = "Failed to upload. Unexpected server error.",
  FileLimitReached = "Failed to upload. Slide storage limit exceeded.",
  CompleteAPICallFailed = "Upload complete API call failed.",
}

export enum WSIFileUploadStage {
  INIT = "INIT",
  GetPresignedURL = "GET_PRESIGNED_URL",
  UploadFileToS3 = "FILE_UPLOAD_TO_S3",
  CompleteUploadAPI = "COMPLETE_UPLOAD_API",
  ISyntaxConverting = "ISYNTAX_CONVERTING",
  DONE = "DONE",
}

export interface CompleteUploadingPayload {
  wsiId: WSIId;
}

export interface CancelUploadPayload {
  wsiId: WSIId;
  projectId: ProjectUUID;
  customerId: string;
}

// TODO: double check which file types are accepted
export const DEFAULT_ACCEPTABLE_FILE_TYPES = [
  { type: "Aperio", formats: [".svs"] },
  { type: "MIRAX", formats: [".mrxs"] },
];

export interface AcceptableFileFormat {
  type: string;
  formats: string[];
}

export interface APIAcceptableFileFormat {
  name: string;
  ext: string;
}

export interface APISlideLimit {
  customerId: string;
  currentSlideCount: number;
  limitSlideCount: number;
}

// wrapper class so that we can cancel stored saga tasks
export class TaskRef {
  ref: Task;

  constructor(task: Task) {
    this.ref = task;
  }
}

export interface SingleWSIUploadState {
  targetFiles: FileWithPath[];
  totalSize: number;
  projectId?: ProjectUUID;
  presignedURLs: {
    [filename: string]: string;
  };
  stage: WSIFileUploadStage;
  xhrPool: XHRState[];
  targetWSIId?: WSIId;
  error?: string;
  loading: boolean;
}

export type WSIUploadState = {
  states: { [wsiKey: string]: SingleWSIUploadState };
  keyOrder: string[];
  uploadWatcherTask?: TaskRef;
  allowedFormats: AcceptableFileFormat[];
  formatsFetchStatus: FetchStatus;
  limitFetchStatus: FetchStatus;
  currentSlideCount: number;
  limitSlideCount: number;
};
