import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";
import MFAForm from "./MFAForm";
import { resetSettingsState } from "dux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsStateSelector,
  isViewerUserSelector,
} from "dux/user/selectors";
import NotificationForm from "./NotificationForm";
import { logout } from "dux/utils/actionHandlingHelper";
import { abortAllWSIUpload } from "components/WSIUpload/dux/actions";
import env from "env";

export const settingsOptions = [
  {
    key: "Profile",
    title: "Profile",
  },
  {
    key: "Password",
    title: "Password",
  },
  {
    key: "Security",
    title: "Multi-Factor Authentication (MFA)",
  },
  {
    key: "Notification",
    title: "Notification",
  },
];

interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
  defaultSelectedMenu?: number;
}

const SettingsContents = styled("div")(({ theme }) => ({
  display: "flex",
  borderTop: `1px solid ${theme.palette.darkGrey[80]}`,
  height: "100%",
}));

const SettingsList = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body5,
  color: theme.palette.darkGrey[30],
  margin: 0,
  padding: theme.spacing(1, 0),
  "&:hover": {
    background: "transparent",
  },
  "&.Mui-selected:hover": {
    background: "transparent",
    color: "#7292FD",
  },
  "&.Mui-selected": {
    color: "#7292FD",
  },
  "& a": {
    color: theme.palette.darkGrey[30],
  },
}));

export default function SettingsDialog({
  open,
  onClose,
  defaultSelectedMenu = 0,
}: SettingsDialogProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [selectedMenu, setSelectedMenu] = useState(0);
  const settingsState = useSelector(getSettingsStateSelector);
  const isViewer = useSelector(isViewerUserSelector);
  const filteredSettingsOptions = useMemo(
    () =>
      !isViewer
        ? settingsOptions
        : settingsOptions.filter(({ key }) => key !== "Notification"),
    [isViewer]
  );

  useEffect(() => {
    setSelectedMenu(defaultSelectedMenu);
  }, [defaultSelectedMenu]);

  useEffect(() => {
    if (!open) {
      dispatch(resetSettingsState());
    }
  }, [dispatch, open]);

  const handleMenuItemClick = (index) => {
    setSelectedMenu(index);
  };

  const renderSettingsContent = useCallback(
    (key: string) => {
      switch (key) {
        case "Profile":
          return <ProfileForm onCancel={onClose} />;
        case "Password":
          return <PasswordForm onCancel={onClose} />;
        case "Security":
          return <MFAForm loading={settingsState.loading} />;
        case "Notification":
          return <NotificationForm onCancel={onClose} />;
        default:
          return null;
      }
    },
    [settingsState, onClose]
  );

  return (
    <Dialog
      aria-labelledby="profile-settings-title"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
      }}
      maxWidth="lg"
    >
      <div
        style={{
          width: 700,
          height: 600,
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
        }}
      >
        <DialogTitle
          id="profile-settings-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle3">Settings</Typography>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              sx={{
                padding: 0.5,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.darkGrey[80],
                },
              }}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <SettingsContents sx={{ overflowX: "scroll" }}>
          <MenuList
            sx={{
              width: 200,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRight: `1px solid ${theme.palette.darkGrey[80]}`,
              padding: (theme) => theme.spacing(3, 4),
              "&:focus": {
                outline: "none",
              },
            }}
          >
            {filteredSettingsOptions.map((option, index) => (
              <SettingsList
                key={option.key}
                selected={index === selectedMenu}
                onClick={(event) => handleMenuItemClick(index)}
              >
                {option.key}
              </SettingsList>
            ))}
            <Divider
              sx={{
                margin: (theme) => theme.spacing(1, 0),
              }}
            />
            <SettingsList>
              <Link href={env.ifuLink} target="_blank">
                Instructions for use
              </Link>
            </SettingsList>
            <Divider
              sx={{
                margin: (theme) => theme.spacing(1, 0),
              }}
            />
            <SettingsList
              onClick={() => {
                dispatch(abortAllWSIUpload());
                dispatch(logout());
              }}
            >
              Log Out
            </SettingsList>
          </MenuList>
          {renderSettingsContent(settingsOptions[selectedMenu].key)}
        </SettingsContents>
      </div>
    </Dialog>
  );
}
