import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { DialogContext } from "components/Dialog/context";
import { getAnyWSIUploading } from "components/WSIUpload/dux/selectors";
import Typography from "@mui/material/Typography";
import { logout } from "dux/utils/actionHandlingHelper";
import { abortAllWSIUpload } from "components/WSIUpload/dux/actions";

const IDLE_TIMEOUT_SEC = 60 * 30;

const useIdleDetector = () => {
  const { handleDialog } = useContext(DialogContext);
  const dispatch = useDispatch();
  const wsiUploading = useSelector(getAnyWSIUploading);

  const handleOnIdle = useCallback(() => {
    // on idle
    handleDialog({
      width: 504,
      agree: "Confirm",
      content: (
        <Typography variant="body2">
          {
            "You were automatically logged out due to inactivity. Please log in again."
          }
        </Typography>
      ),
      handleAgreementCallback: () => {
        handleDialog(null);
      },
      forceAction: true,
      disableBackdropClick: true,
    });
    dispatch(abortAllWSIUpload());
    dispatch(logout());
  }, [dispatch, handleDialog]);

  const { reset, pause } = useIdleTimer({
    timeout: IDLE_TIMEOUT_SEC * 1000, // convert to milliseconds
    onIdle: handleOnIdle,
    startOnMount: true,
    debounce: 200, // Prevents multiple events firing (especially mousemove)
    events: [
      "click",
      "mousedown",
      "mousemove",
      "mouseover",
      "mousewheel",
      "DOMMouseScroll",
      "wheel",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "keydown",
    ],
    name: "logout-timer",
  });

  useEffect(() => {
    let time = null;
    const cb = () => {
      if (document.visibilityState === "visible") {
        if (time === null) {
          reset();
          return;
        }
        const diff = new Date().getTime() - time;
        time = null;

        if (diff <= IDLE_TIMEOUT_SEC * 1000) {
          reset();
          return;
        }
        handleOnIdle();
      } else if (!wsiUploading) {
        time = new Date().getTime();
        pause();
      }
    };
    document.addEventListener("visibilitychange", cb);
    return () => {
      document.removeEventListener("visibilitychange", cb);
    };
  }, [pause, reset, wsiUploading, handleOnIdle]);

  useEffect(() => {
    if (wsiUploading) {
      pause();
    } else if (document.visibilityState === "visible") {
      reset();
    }
  }, [wsiUploading, pause, reset]);
};

export default useIdleDetector;
