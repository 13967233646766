import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";

const Container = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "100px",
});

export const NoData = () => {
  const theme = useTheme();
  return (
    <Container>
      <Typography
        variant="h3"
        color={theme.palette.darkGrey[15]}
        sx={{ marginBottom: "16px" }}
      >
        There isn't any user
      </Typography>
      <Typography variant="subtitle3" color={theme.palette.darkGrey[15]}>
        Please click “Create” button on the top right to add a user
      </Typography>
    </Container>
  );
};
