import {
  EditIcon,
  MoreIcon,
  ProjectsTwoToneIcon,
  StartIcon,
  TagIcon,
} from "assets/icons";
import {
  Box,
  BoxProps,
  Card,
  Checkbox,
  IconButton,
  Popover,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import EllipsizedTypography from "components/EllipsizedTypography";
import usePopper from "hooks/usePopper";
import { SlideItem, SlideStatus, SlideUUID } from "dux/slide/model";
import {
  slideStatusColorProps,
  slideStatusLabels,
} from "components/SlideList/common/literals";
import SlideTagChip, {
  GetChipProps,
} from "components/SlideList/common/SlideTagChip";
import { Project } from "dux/projects/model";
import { useCallback, useMemo, useState } from "react";
import SlideContextMenu, {
  SlideContextMenuProps,
} from "../common/SlideContextMenu";
import { DateFormatter, DATETIME_FORMAT } from "utils/format";
import { Tag } from "dux/tags/model";
import FallbackImage from "components/SlideList/common/FallbackImage";
import {
  requestSlideInferences,
  requestSlidesAnalyze,
  resetSlideMgmtState,
  updateSlideSummary,
} from "dux/slide/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  ChipTooltip,
  IOResultCell,
  TooltipContainer,
} from "./component/Columns";
import useTagsCRUD from "components/hooks/useTagsCRUD";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";
import SlideAutocomplete from "./common/SlideAutocomplete";
import { isArray } from "lodash";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";
import { APICancerType } from "dux/cancerTypes/model";
import {
  isAnalyzerUserSelector,
  isViewerUserSelector,
} from "dux/user/selectors";
import SingleAutocomplete from "./common/SingleAutocomplete";

const ChipHeight = 22;

interface ISlideCardProps {
  slide: SlideItem;
  isChecked: boolean;
  handleEditSlide?: SlideContextMenuProps["onEdit"];
  handleDeleteSlide?: SlideContextMenuProps["onDelete"];
  handleSlideInfo?: SlideContextMenuProps["handleInfo"];
  handleFailedInfo?: (id: SlideUUID) => void;
  totalProjects: Project[];
  totalTags: Tag[];
  handleChecked?: (id: SlideItem, checked: boolean) => void;
  handleSelectedList: () => void;
  handleOpenSlideViewer: (id: SlideItem) => void;
}

const CardHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  flex: "0 0",
  maxHeight: ChipHeight,
});

const ThumbnailImage = styled(FallbackImage)`
  width: 100%;
  height: auto;
`;

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
  opacity: 0,
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: "24px",
  width: "24px",
  "&:hover": {
    color: "#7292FD",
  },
}));

const withSize = (component) =>
  styled(component)({
    width: 20,
    height: 20,
    flexShrink: 0,
  });

interface ChipContainerProps extends BoxProps {
  disabled?: boolean;
}

const EllipsizedChipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<ChipContainerProps>(({ disabled }) => ({
  height: ChipHeight,
  flex: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  cursor: disabled ? "default" : "pointer",
}));

const StyledCancerTypeTextField = styled(TextField)(({ theme }) => ({
  border: "1px solid transparent",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  marginLeft: "-12px",
  paddingLeft: "11px",
  paddingRight: "11px",
  height: "28px",
  cursor: "default",
  ".MuiInput-root.MuiInputBase-root": {
    ...theme.typography.body5,
  },
  "& input": {
    // width: "100%",
    height: "28px",
    padding: 0,
    border: "none",
  },
}));

const StartIconComponent = withSize(StartIcon);
const EditIconComponent = withSize(EditIcon);
const ProjectsIconComponent = withSize(ProjectsTwoToneIcon);
const TagIconComponent = withSize(TagIcon);

const SlideCard = ({
  slide,
  isChecked,
  totalTags,
  totalProjects,
  handleChecked,
  handleEditSlide,
  handleSlideInfo,
  handleFailedInfo,
  handleDeleteSlide,
  handleSelectedList,
  handleOpenSlideViewer,
}: ISlideCardProps) => {
  const menuPopper = usePopper();
  const projectPopper = usePopper();
  const tagPopper = usePopper();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAnalyzer = useSelector(isAnalyzerUserSelector);
  const isViewer = useSelector(isViewerUserSelector);
  const { requestCreateTag, requestDeleteTag, requestEditTag } = useTagsCRUD({
    onCreated: (tag) => {
      if (tagPopper.open) {
        dispatch(
          updateSlideSummary.request({
            wsiId: slide.wsiId,
            name: slide.name,
            tagIds: mappedTags.concat(tag).map((tag) => tag.id),
            projectIds: slide.projects,
          })
        );
      }
    },
  });
  const { requestCreateProject, requestDeleteProject, requestEditProject } =
    useProjectsCRUD({
      onCreated: (project) => {
        if (projectPopper.open) {
          dispatch(
            updateSlideSummary.request({
              wsiId: slide.wsiId,
              name: slide.name,
              tagIds: slide.tags,
              projectIds: mappedProjects
                .concat(project)
                .map((project) => project.id),
            })
          );
        }
      },
    });
  const [isCancerPopperOpen, setIsCancerPopperOpen] = useState(false);
  const {
    cancerTypes,
    requestCreateCancerType,
    requestDeleteCancerType,
    requestEditCancerType,
    cancerTypeMgmtState: { loading: cancerTypeLoading },
  } = useCancerTypeCRUD({
    onCreated: (cancerType) => {
      if (isCancerPopperOpen) {
        handleCancerTypeChange(cancerType);
      }
    },
  });
  const mappedProjects = totalProjects.filter((val) =>
    slide.projects.some((innerVal) => innerVal === val.id)
  );
  const mappedTags = totalTags.filter((val) =>
    slide.tags.some((innerVal) => innerVal === val.id)
  );

  const onCheckChanged = useCallback(
    (event) => {
      event.stopPropagation();
      handleChecked(slide, event.target.checked);
    },
    [slide, handleChecked]
  );

  const onClickSlideViewerButton = useCallback(() => {
    handleOpenSlideViewer(slide);
  }, [handleOpenSlideViewer, slide]);

  const onProjectsChange = (value: Project[]) => {
    dispatch(
      updateSlideSummary.request({
        wsiId: slide.wsiId,
        name: slide.name,
        tagIds: slide.tags,
        projectIds: value.map((project) => project.id),
      })
    );
  };

  const onTagsChange = (value: Tag[]) => {
    dispatch(
      updateSlideSummary.request({
        wsiId: slide.wsiId,
        name: slide.name,
        tagIds: value.map((tag) => tag.id),
        projectIds: slide.projects,
      })
    );
  };

  const handleCancerTypeChange = useCallback(
    (value: APICancerType) => {
      if (value) {
        handleSelectedList();
        dispatch(
          updateSlideSummary.request({
            id: slide.id,
            wsiId: slide.wsiId,
            name: slide.name,
            tagIds: slide.tags,
            projectIds: slide.projects,
            cancerType: value.name,
          })
        );
      }
    },
    [
      dispatch,
      slide.id,
      slide.wsiId,
      slide.name,
      slide.tags,
      slide.projects,
      handleSelectedList,
    ]
  );

  const selectedCancerType = useMemo(
    () =>
      cancerTypes.filter((cancerType) => cancerType.name === slide.cancerType),
    [slide.cancerType, cancerTypes]
  );

  return (
    <>
      <Card
        className={clsx(menuPopper.open ? "Mui-focused" : undefined)}
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          backgroundColor: (theme) => theme.palette.background.secondary,
          border: "1px solid transparent",
          borderRadius: 2,
          height: "284px",
          "&:hover, &.Mui-focused": {
            cursor: "pointer",
            backgroundColor: (theme) => theme.palette.background.selected,
            "& .MoreIcon": {
              opacity: 1,
            },
          },
        }}
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          const target = e.target as Element;
          if (
            !target.classList.contains("MuiBackdrop-root") &&
            !target.classList.contains("MuiInput-input") &&
            !target.classList.contains("MuiListItemText-primary") &&
            !target.classList.contains("MuiMenuItem-root")
          ) {
            onClickSlideViewerButton();
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexBasis: 0,
            overflow: "hidden",
            padding: 3,
            justifyContent: "center",
          }}
        >
          <CardHeader>
            <Checkbox
              sx={{
                padding: 0,
                marginRight: 0.5,
              }}
              checked={isChecked}
              onClick={(e) => onCheckChanged(e)}
            />
            <SlideTagChip
              onClick={(e) => {
                e.stopPropagation();
                return slide.status === SlideStatus.PreAnalFailed ||
                  slide.status === SlideStatus.AnalFailed
                  ? handleFailedInfo(slide.id)
                  : undefined;
              }}
              clickable={
                slide.status === SlideStatus.PreAnalFailed ||
                slide.status === SlideStatus.AnalFailed
              }
              label={slideStatusLabels(slide.status)}
              {...GetChipProps(slide.status, theme)}
              color={slideStatusColorProps(slide.status)}
            />
            <IconButton
              aria-label="Ellipsis Button"
              aria-expanded={menuPopper.open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                menuPopper.onOpen(e);
              }}
              sx={{
                marginLeft: "auto",
                '&:hover, &[aria-expanded*="true"]': {
                  backgroundColor: (theme) => theme.palette.darkGrey[70],
                },
                color: "#fff",
              }}
            >
              <MoreIconComponent className="MoreIcon" />
            </IconButton>
          </CardHeader>
          <Box
            sx={{
              flex: "0 1 auto",
              marginTop: 1.75,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {
              <>
                <StyledIconButton
                  sx={{
                    mr: 1.75,
                  }}
                  disabled={
                    (slide.status !== SlideStatus.Ready &&
                      slide.status !== SlideStatus.AnalFailed) ||
                    isViewer
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectedList();
                    slide.status === SlideStatus.PreAnalFailed
                      ? dispatch(requestSlideInferences.request([slide]))
                      : dispatch(
                          requestSlidesAnalyze.request({
                            sourceIds: [slide.id],
                          })
                        );
                  }}
                >
                  <StartIconComponent />
                </StyledIconButton>
                <Tooltip title="View slide" key="view-slide">
                  <EllipsizedTypography
                    sx={{
                      WebkitLineClamp: 3,
                      textAlign: "left",
                      wordBreak: "break-all",
                    }}
                    direction="column"
                    variant="body4"
                    color="darkGrey.10"
                  >
                    {slide.name}
                  </EllipsizedTypography>
                </Tooltip>
              </>
            }
          </Box>
          <Box
            sx={{
              marginTop: "auto",
            }}
          />
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              height: 32,
              alignItems: "center",
            }}
          >
            <>
              <EditIconComponent
                sx={{
                  marginRight: 2,
                }}
              />
              {isViewer ? (
                <EllipsizedTypography
                  variant="body5"
                  color={theme.palette.darkGrey[20]}
                >
                  {slide.cancerType}
                </EllipsizedTypography>
              ) : (
                <SingleAutocomplete
                  totalOptions={cancerTypes}
                  selectedOption={selectedCancerType[0]}
                  onChangeValue={handleCancerTypeChange}
                  placeholder={"Select a cancer type or create one"}
                  onCreateRequest={requestCreateCancerType}
                  onDeleteRequest={requestDeleteCancerType}
                  onEditRequest={requestEditCancerType}
                  onFocus={() => setIsCancerPopperOpen(true)}
                  onBlur={() => setIsCancerPopperOpen(false)}
                  disabled={
                    slide?.status === SlideStatus.Analyzing ||
                    slide?.status === SlideStatus.PreAnalyzing
                  }
                  readonlyItems={cancerTypes?.filter(
                    (item) => item.name === "Pan cancer"
                  )}
                  loading={cancerTypeLoading}
                  renderInput={(props) => (
                    <StyledCancerTypeTextField
                      {...props}
                      variant="standard"
                      InputProps={{
                        ...props.InputProps,
                        disableUnderline: true,
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={(e) => e.stopPropagation()}
                      fullWidth
                      sx={{
                        borderRadius: 0,
                        ...{
                          "&:hover": {
                            borderColor: (theme) =>
                              props.focused
                                ? theme.palette.scope1.main
                                : theme.palette.darkGrey[30],
                          },
                          borderColor: (theme) =>
                            props.focused ? theme.palette.scope1.main : "none",
                        },
                      }}
                    />
                  )}
                  sx={{
                    flex: 1,
                  }}
                />
              )}
            </>
          </Box>
          {mappedProjects.length > 0 && (
            <Box
              sx={{
                marginTop: 0.75,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
            >
              <>
                <ProjectsIconComponent
                  sx={{
                    marginRight: 2,
                    marginLeft: 0.25,
                    marginTop: "auto",
                  }}
                />
                <ChipTooltip
                  title={
                    <TooltipContainer>
                      <Typography variant="body5">
                        This task is also part of:
                      </Typography>
                      {mappedProjects.map((project, index) => (
                        <Typography
                          sx={{ whiteSpace: "pre-wrap" }}
                          key={`project-${index}`}
                          variant="body5"
                        >
                          {`• ${project.title}`}
                        </Typography>
                      ))}
                      <Typography
                        variant="body5"
                        color="#AFAFB1"
                        sx={{ marginTop: "4px" }}
                      >
                        Click to add or edit this
                      </Typography>
                    </TooltipContainer>
                  }
                >
                  <EllipsizedChipContainer
                    disabled={isViewer}
                    onClick={
                      !isViewer
                        ? (e) => {
                            e.stopPropagation();
                            projectPopper.onOpen(e);
                          }
                        : undefined
                    }
                  >
                    {mappedProjects.map((value) => (
                      <SlideTagChip
                        disabled={isViewer}
                        key={value.id}
                        label={value.title}
                      />
                    ))}
                  </EllipsizedChipContainer>
                </ChipTooltip>
                <Popover
                  id="project-select"
                  disableAutoFocus
                  open={projectPopper.open}
                  anchorEl={projectPopper.anchorEl}
                  onClose={() => {
                    projectPopper.onClose();
                    dispatch(resetSlideMgmtState());
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box sx={{ width: "308px" }}>
                    <SlideAutocomplete
                      totalOptions={totalProjects}
                      selectedOption={mappedProjects}
                      onChangeValue={onProjectsChange}
                      placeholder={
                        isAnalyzer
                          ? "Select a project"
                          : "Select a project or create one"
                      }
                      onCreateRequest={
                        !isAnalyzer ? requestCreateProject : undefined
                      }
                      onDeleteRequest={
                        !isAnalyzer ? requestDeleteProject : undefined
                      }
                      onEditRequest={
                        !isAnalyzer ? requestEditProject : undefined
                      }
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onClick={(e) => e.stopPropagation()}
                          onFocus={(e) => e.stopPropagation()}
                          variant="outlined"
                          placeholder={
                            isArray(props.InputProps.startAdornment) &&
                            props.InputProps.startAdornment.length > 0
                              ? ""
                              : "Search for an option"
                          }
                          sx={{
                            borderRadius: 0,
                            "& .MuiInputBase-root": {
                              padding: (theme) => theme.spacing(1, 2),
                              flexFlow: "row wrap",
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              flexBasis: "50px",
                              flexGrow: 1,
                              flexShrink: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Popover>
              </>
            </Box>
          )}
          {mappedTags.length > 0 && (
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
            >
              <>
                <TagIconComponent
                  sx={{
                    marginRight: 2,
                    marginLeft: 0.25,
                    marginTop: "auto",
                  }}
                />
                <ChipTooltip
                  title={
                    <TooltipContainer>
                      <Typography variant="body5">
                        This task is also part of:
                      </Typography>
                      {mappedTags.map((tag, index) => (
                        <Typography
                          sx={{ whiteSpace: "pre-wrap" }}
                          key={`tag-${index}`}
                          variant="body5"
                        >
                          {`• ${tag.title}`}
                        </Typography>
                      ))}
                      <Typography
                        variant="body5"
                        color="#AFAFB1"
                        sx={{ marginTop: "4px" }}
                      >
                        Click to add or edit this
                      </Typography>
                    </TooltipContainer>
                  }
                >
                  <EllipsizedChipContainer
                    disabled={isViewer}
                    onClick={
                      !isViewer
                        ? (e) => {
                            e.stopPropagation();
                            tagPopper.onOpen(e);
                          }
                        : undefined
                    }
                  >
                    {mappedTags.map((value, index) => (
                      <SlideTagChip
                        disabled={isViewer}
                        key={`tag-${index}`}
                        label={value.title}
                      />
                    ))}
                  </EllipsizedChipContainer>
                </ChipTooltip>
                <Popover
                  id="project-select"
                  disableAutoFocus
                  open={tagPopper.open}
                  anchorEl={tagPopper.anchorEl}
                  onClose={() => {
                    tagPopper.onClose();
                    dispatch(resetSlideMgmtState());
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box sx={{ width: "308px" }}>
                    <SlideAutocomplete
                      totalOptions={totalTags}
                      selectedOption={mappedTags}
                      onChangeValue={onTagsChange}
                      placeholder="Select a tag or create one"
                      onCreateRequest={requestCreateTag}
                      onDeleteRequest={requestDeleteTag}
                      onEditRequest={requestEditTag}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onClick={(e) => e.stopPropagation()}
                          onFocus={(e) => e.stopPropagation()}
                          variant="outlined"
                          placeholder={
                            isArray(props.InputProps.startAdornment) &&
                            props.InputProps.startAdornment.length > 0
                              ? ""
                              : "Search for an option"
                          }
                          sx={{
                            borderRadius: 0,
                            "& .MuiInputBase-root": {
                              padding: (theme) => theme.spacing(1, 2),
                              flexFlow: "row wrap",
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              flexBasis: "50px",
                              flexGrow: 1,
                              flexShrink: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Popover>
              </>
            </Box>
          )}
          <Box
            sx={{
              marginTop: "12px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Typography
              variant="body6"
              color="darkGrey.40"
              sx={{
                lineHeight: "24px",
              }}
            >
              <Typography
                variant="body6"
                color="darkGrey.60"
                sx={{
                  marginRight: 0.9,
                }}
              >
                Uploaded Date
              </Typography>
              {DateFormatter(slide.uploadedAt, DATETIME_FORMAT)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Typography
              variant="body6"
              color="darkGrey.40"
              sx={{
                lineHeight: "24px",
              }}
            >
              <Typography
                variant="body6"
                color="darkGrey.60"
                sx={{
                  marginRight: 0.5,
                }}
              >
                Updated Date
              </Typography>
              {DateFormatter(slide.statusUpdatedAt, DATETIME_FORMAT)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& > :not(:last-child)": {
                  marginRight: 1,
                },
              }}
            >
              <IOResultCell value={slide.ioResult} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 140,
            height: "100%",
            justifyContent: "center",
            bgcolor: "#fff",
          }}
        >
          <ThumbnailImage
            src={slide.thumbnailPath}
            fallback="/images/empty-thumbnail.png"
          />
        </Box>
        <SlideContextMenu
          anchorEl={menuPopper.anchorEl}
          open={menuPopper.open}
          onClose={menuPopper.onClose}
          handleInfo={handleSlideInfo}
          onDelete={handleDeleteSlide}
          onEdit={handleEditSlide}
          slide={slide}
        />
      </Card>
    </>
  );
};

export default SlideCard;
