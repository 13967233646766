import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "dux/user/actions";

export default function useFectchCurrentUser() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser.request(""));
  }, [dispatch]);
}
