import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import styled from "@mui/material/styles/styled";
import { MoreIcon } from "assets/icons";

import usePopper from "hooks/usePopper";
import UserContextMenu from "components/common/UserContextMenu";
import { DateFormatter, DATE_FORMAT } from "utils/format";
import {
  ExtendedUser,
  formatUserRole,
  UserMeta,
  UserRole,
  UserUUID,
} from "dux/user/model";
import EllipsizedTypography from "components/EllipsizedTypography";
import DataGridContainer from "components/common/DataGridContainer";
import { ProjectChips } from "./component/Columns";
import { Project } from "dux/projects/model";

interface IUserTableProps {
  userList: UserMeta[];
  sortModel: GridSortModel;
  selectedUsers: UserUUID[];
  totalProjects: Project[];
  handleDeleteUser: (UserID: UserUUID) => void;
  handleEditUser: (user: ExtendedUser) => void;
  handleRowSelect: (items: UserUUID[]) => void;
  handleSortModelChange: (newModel: GridSortModel) => void;
}

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
});

const UserMenuCell = React.memo(({ row, value, onEdit, onDelete }: any) => {
  const menuPopper = usePopper();
  return (
    <>
      <Typography variant="body5" sx={{ color: "#AFAFB1" }}>
        {DateFormatter(value, DATE_FORMAT)}
      </Typography>
      <IconButton
        color="primary"
        size="small"
        className="hover-shown"
        onClick={menuPopper.onOpen}
        sx={{
          position: "absolute",
          right: 4,
          '&:hover, &.Mui-focused, &[aria-expanded*="true"]': {
            backgroundColor: (theme) => theme.palette.background.selected,
            color: "#fff",
          },
        }}
      >
        <MoreIconComponent className="MoreIcon" />
      </IconButton>
      <UserContextMenu
        anchorEl={menuPopper.anchorEl}
        open={menuPopper.open}
        onClose={menuPopper.onClose}
        onDelete={onDelete}
        onEdit={(row) => onEdit(row)}
        role={row.roleName}
        user={row}
      />
    </>
  );
});

const getColumns = ({
  handleDeleteUser,
  handleEditUser,
  totalProjects,
}: Pick<
  IUserTableProps,
  "handleDeleteUser" | "handleEditUser" | "totalProjects"
>): GridColDef[] => [
  {
    field: "email",
    headerName: "Email",
    headerClassName: "header",
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      return (
        <EllipsizedTypography variant="body5">
          {params.value}
        </EllipsizedTypography>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "header",
    sortable: true,
    width: 239,
    renderCell: (params) => {
      return (
        <EllipsizedTypography sx={{ display: "block" }} variant="body5">
          {params.value}
        </EllipsizedTypography>
      );
    },
  },
  {
    field: "roleName",
    headerName: "Role",
    headerClassName: "header",
    sortable: true,
    width: 127,
    renderCell: (params) => {
      return (
        <Typography variant="body5">{formatUserRole(params.value)}</Typography>
      );
    },
  },
  {
    field: "projects",
    headerName: "Assigned Project",
    headerClassName: "header",
    sortable: false,
    width: 159,
    renderCell: (params) => {
      if (params.row.roleName !== UserRole.Viewer) {
        return <Typography variant="body5">All Projects</Typography>;
      }
      return (
        <ProjectChips
          value={params.value}
          userId={params.row.id}
          api={params.api}
          totalProjects={totalProjects}
        />
      );
    },
  },
  {
    field: "phoneNumber",
    headerName: "Telephone",
    headerClassName: "header",
    sortable: false,
    width: 135,
    renderCell: (params) => {
      return <Typography variant="body5">{params.value}</Typography>;
    },
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    headerClassName: "header",
    sortable: true,
    width: 148,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <UserMenuCell
          row={params.row}
          value={params.value}
          onDelete={handleDeleteUser}
          onEdit={handleEditUser}
        />
      );
    },
  },
];

function UserTable({
  handleDeleteUser,
  handleEditUser,
  handleRowSelect,
  handleSortModelChange,
  userList,
  sortModel,
  selectedUsers,
  totalProjects,
}: IUserTableProps) {
  const columns = useMemo(
    () => getColumns({ handleDeleteUser, handleEditUser, totalProjects }),
    [handleDeleteUser, handleEditUser, totalProjects]
  );

  return (
    <DataGridContainer>
      <DataGrid
        rows={userList}
        columns={columns}
        headerHeight={40}
        rowHeight={40}
        showColumnRightBorder={true}
        showCellRightBorder={true}
        disableColumnMenu={true}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedUsers}
        isRowSelectable={(params) => params.row.roleName !== UserRole.Admin}
        onSelectionModelChange={(selectedUsers) =>
          handleRowSelect(selectedUsers)
        }
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        hideFooter
      />
    </DataGridContainer>
  );
}

export default React.memo(UserTable);
