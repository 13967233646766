import { useMemo } from "react";
import { Project } from "dux/projects/model";
import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IconButton, Typography } from "@mui/material";
import { MoreIcon } from "assets/icons";
import ProjectContextMenu, {
  ProjectContextMenuProps,
} from "components/common/ProjectContextMenu";
import usePopper from "hooks/usePopper";
import EllipsizedTypography from "components/EllipsizedTypography";
import { useHistory } from "react-router";
import DataGridContainer from "components/common/DataGridContainer";

interface IProjectTableProps {
  projects: Project[];
  isAnalyzer: boolean;
  isViewer: boolean;
  handleEditProject?: ProjectContextMenuProps["onEdit"];
  handleDeleteProject?: ProjectContextMenuProps["onDelete"];
}

const getColumns = ({
  handleDeleteProject,
  handleEditProject,
  isAnalyzer,
  isViewer,
}: Pick<
  IProjectTableProps,
  "handleDeleteProject" | "handleEditProject" | "isAnalyzer" | "isViewer"
>): GridColDef[] => [
  {
    field: "title",
    headerName: "Title",
    headerClassName: "header",
    width: 256,
    sortable: false,
    renderCell: (params) => {
      return (
        <EllipsizedTypography
          direction="row"
          variant="body3"
          color="darkGrey.10"
        >
          {params.value}
        </EllipsizedTypography>
      );
    },
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "header",
    width: 458,
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <EllipsizedTypography
          variant="body5"
          color="darkGrey.30"
          direction="row"
        >
          {params.value}
        </EllipsizedTypography>
      );
    },
  },
  {
    field: "slideCount",
    headerName: "No. of Slides",
    headerClassName: "header",
    headerAlign: "right",
    align: "right",
    width: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography variant="body6">{params.value}</Typography>;
    },
  },
  {
    field: "fromDate",
    headerName: "From",
    headerClassName: "header",
    width: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body6" color="darkGrey.30">
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "toDate",
    headerName: "To",
    headerClassName: "header",
    width: 144,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { row, value } = params;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const menuPopper = usePopper();
      return (
        <>
          <Typography variant="body6" color="darkGrey.30">
            {value}
          </Typography>
          {!isAnalyzer && !isViewer && (
            <IconButton
              aria-label="Ellipsis Button"
              aria-expanded={menuPopper.open ? "true" : undefined}
              className="hover-shown"
              onClick={(e) => {
                e.stopPropagation();
                menuPopper.onOpen(e);
              }}
              sx={{
                position: "absolute",
                right: 4,
                color: "#fff",
                '&:hover, &.Mui-focused, &[aria-expanded*="true"]': {
                  backgroundColor: (theme) => theme.palette.background.selected,
                },
              }}
            >
              <MoreIconComponent className="MoreIcon" />
            </IconButton>
          )}
          <ProjectContextMenu
            anchorEl={menuPopper.anchorEl}
            open={menuPopper.open}
            onClose={menuPopper.onClose}
            onDelete={handleDeleteProject}
            onEdit={handleEditProject}
            project={row}
          />
        </>
      );
    },
  },
];

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
});

export default function ProjectTable({
  projects,
  isAnalyzer,
  isViewer,
  handleEditProject,
  handleDeleteProject,
}: IProjectTableProps) {
  const history = useHistory();

  const columns = useMemo(
    () =>
      getColumns({
        handleEditProject,
        handleDeleteProject,
        isAnalyzer,
        isViewer,
      }),
    [handleEditProject, handleDeleteProject, isAnalyzer, isViewer]
  );

  return (
    <DataGridContainer>
      <DataGrid
        rows={projects}
        columns={columns}
        headerHeight={40}
        rowHeight={40}
        showColumnRightBorder={true}
        showCellRightBorder={true}
        disableColumnMenu={true}
        hideFooter={true}
        onRowClick={(params) => {
          history.push(`/projects/${params.id}/slides`);
        }}
        components={{
          NoRowsOverlay: () => <></>,
        }}
      />
    </DataGridContainer>
  );
}
