import { FetchMethod, request } from "dux/utils/apiRequestHelper";
import { Paginate } from "dux/utils/commonModels";
import qs from "qs";
import {
  // ProjectListRequestParams,
  // ProjectRequestValues,
  ProjectUUID,
  APICreateProjectParams,
  APIDeleteProjectsParams,
  APIGetProjectsParams,
  APIGetProjectsPerPageParams,
  APIUpdateProjectParams,
  ProjectDtoOutput,
  APIProjectBulkMappingDeleteParams,
} from "./model";
// import { DEFAULT_PAGE_SIZE } from "../../utils/pagination";

const qsStringfyDefaultOptions = {
  encode: true,
  indices: false,
};

const queryString = (obj: any) => qs.stringify(obj, qsStringfyDefaultOptions);

export const getProjects = (payload: APIGetProjectsParams) =>
  request<ProjectDtoOutput[]>({
    method: FetchMethod.Get,
    path: `projects?${queryString(payload)}`,
  });

export const getProjectsPerPage = (payload: APIGetProjectsPerPageParams) =>
  request<Paginate<ProjectDtoOutput>>({
    method: FetchMethod.Get,
    path: `projects/pages?${queryString(payload)}`,
  });

export const createProject = (payload: APICreateProjectParams) =>
  request<ProjectDtoOutput>({
    method: FetchMethod.Post,
    path: "projects",
    payload,
  });

export const deleteProjects = (payload: APIDeleteProjectsParams) =>
  request({
    method: FetchMethod.Delete,
    path: "projects",
    payload: payload.ids,
    skipJson: true,
  });

export const updateProject = (payload: APIUpdateProjectParams) => {
  const { id, ...body } = payload;
  return request<ProjectDtoOutput>({
    method: FetchMethod.Put,
    path: `projects/${id}`,
    payload: body,
  });
};

// export const getAllProjects = () =>
//   request({
//     method: FetchMethod.Get,
//     path: "projects/me",
//   });

// export const getProjectsPerPage = (params: ProjectListRequestParams) => {
//   return request({
//     method: FetchMethod.Get,
//     path: `projects/pages?sort=createdAt,desc&size=${DEFAULT_PAGE_SIZE}&page=${
//       params.page
//     }&title=${encodeURIComponent(params.title) || ""}`,
//   });
// };

// export const createProject = (payload: ProjectRequestValues) =>
//   request({
//     method: FetchMethod.Post,
//     path: "projects",
//     payload,
//   });

// export const updateProject = (payload: ProjectRequestValues) =>
//   request({
//     method: FetchMethod.Put,
//     path: `projects/${payload.id}`,
//     payload: {
//       ...payload,
//       id: undefined,
//     },
//   });

// export const deleteProjects = (payload: ProjectUUID[]) =>
//   request({
//     method: FetchMethod.Delete,
//     path: `projects`,
//     skipJson: true,
//     payload,
//   });

export const enterProject = (payload: ProjectUUID) =>
  request({
    method: FetchMethod.Post,
    path: `projects/${payload}/enter`,
    skipJson: true,
  });

export const deleteProjectMappings = (
  payload: APIProjectBulkMappingDeleteParams
) =>
  request({
    method: FetchMethod.Delete,
    path: `projects/${payload.id}/mapping/slide`,
    payload: payload.targets,
    skipJson: true,
  });
