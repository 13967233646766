import React, {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useCallback,
  useState,
} from "react";

interface IFallbackImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallback: string;
}

export default React.memo(function FallbackImage({
  src,
  fallback,
  ...others
}: IFallbackImageProps) {
  const [imageSource, setImageSource] = useState<string>(src);
  const onError = useCallback(() => {
    setImageSource(fallback);
  }, [fallback]);
  return (
    <img
      {...others}
      src={imageSource}
      onError={onError}
      alt={others.alt ? others.alt : "picture"}
    />
  );
});
