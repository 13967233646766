import React from "react";
import ForgotPasswordForm from "components/auth/ForgotPasswordForm";
import AuthTemplate from "components/auth/AuthTemplate";

const ForgotPassword: React.FC = () => {
  return (
    <AuthTemplate>
      <ForgotPasswordForm />
    </AuthTemplate>
  );
};

export default ForgotPassword;
