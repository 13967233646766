import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Typography, Tooltip, LinearProgress } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { getSingleWSIUploadStateByWSIKeySelector } from "components/WSIUpload/dux/selectors";
import {
  completeWSIUploadingSuccess,
  retrySingleWSIUpload,
} from "components/WSIUpload/dux/actions";
import {
  WSIFileUploadError,
  WSIFileUploadStage,
} from "components/WSIUpload/dux/model";
import { ErrorIcon, CloseIcon, ReloadIcon, SuccessIcon } from "assets/icons";
import EllipsizedTypography from "components/EllipsizedTypography";
import useStompMessageWatch from "hooks/useStompMessageWatch";
import { EventType, NotificationMessage } from "hooks/useStompClient";
import reduce from "lodash/reduce";

const ProgressContainer = styled("div")({
  position: "absolute",
  bottom: 0,
  width: "100%",
});

interface ProgressIndicatorProps {
  progress: number;
  stage: WSIFileUploadStage;
}

const ProgressIndicatorForStage = ({
  stage,
  progress,
}: ProgressIndicatorProps) => {
  if (stage === WSIFileUploadStage.UploadFileToS3) {
    return (
      <ProgressContainer>
        <LinearProgress variant="determinate" value={progress} />
      </ProgressContainer>
    );
  }
  return (
    <ProgressContainer>
      <LinearProgress />
    </ProgressContainer>
  );
};

interface FileProgressProps {
  wsiKey: string;
  onCancel: () => void;
}

const RootContainer = styled("div")({
  display: "flex",
  width: 500,
  height: 64,
  alignItems: "center",
  position: "relative",
  "&:not(:last-child)": {
    boxShadow: "inset 0px -1px 0px rgba(134, 148, 177, 0.16)",
  },
});

const RightButton = styled(IconButton)({
  flexGrow: 0,
  flexShrink: 0,
  marginRight: "28px",
  height: "28px",
  width: "28px",
  padding: "4px",
  ":hover": {
    backgroundColor: (theme) => theme.palette.darkGrey[80],
  },
});

const filenameSx = {
  marginLeft: "32px",
  marginRight: "16px",
  flexGrow: 1,
  flexBasis: "82px",
  overflow: "hidden",
  textOverflow: "ellipsis",
} as const;

export const FileProgress = ({ wsiKey, onCancel }: FileProgressProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getSingleWSIUploadStateByWSIKey = useMemo(
    () => getSingleWSIUploadStateByWSIKeySelector(wsiKey),
    [wsiKey]
  );
  const uploadState = useSelector(getSingleWSIUploadStateByWSIKey, isEqual);
  const [mouseOnErrorIconButton, setMouseOnErrorIconButton] = useState(false);
  const { loading, error, stage, progress, targetFiles, targetWSIId } =
    uploadState;

  const isUploadSuccessMessage = useCallback(
    (message: NotificationMessage) => {
      const filteredEvent = message.eventData.filter(
        (event) =>
          event.type === EventType.SlideUpload && event.status === "SUCCESS"
      );
      return (
        filteredEvent.length > 0 &&
        reduce(
          filteredEvent,
          (matches, event) => matches || event.slideInfo.id === targetWSIId,
          false
        )
      );
    },
    [targetWSIId]
  );
  const newUploadSuccessMessage = useStompMessageWatch(isUploadSuccessMessage);
  useEffect(() => {
    if (
      newUploadSuccessMessage.length > 0 &&
      stage === WSIFileUploadStage.ISyntaxConverting
    ) {
      dispatch(completeWSIUploadingSuccess(wsiKey));
    }
  }, [dispatch, wsiKey, stage, newUploadSuccessMessage]);

  if (loading && !error) {
    // uploading
    return (
      <RootContainer>
        <EllipsizedTypography variant="body5" sx={filenameSx}>
          {targetFiles[0].name}
        </EllipsizedTypography>
        <ProgressIndicatorForStage progress={progress} stage={stage} />
        <Tooltip title="Cancel">
          <RightButton onClick={onCancel} size="large">
            <CloseIcon />
          </RightButton>
        </Tooltip>
      </RootContainer>
    );
  }
  if (error) {
    return (
      <RootContainer>
        <EllipsizedTypography variant="body5" sx={filenameSx}>
          {targetFiles[0].name}
        </EllipsizedTypography>
        <Typography
          color="error"
          variant="body4"
          sx={{
            marginRight: "30px",
            flexGrow: 1,
            flexBasis: "290px",
            fontWeight: "400 !important",
          }}
        >
          {error}
        </Typography>
        {error === WSIFileUploadError.UploadFailedNetwork ||
        error === WSIFileUploadError.UploadCancelled ? (
          <Tooltip title="Retry">
            <RightButton
              onMouseEnter={() => setMouseOnErrorIconButton(true)}
              onMouseLeave={() => setMouseOnErrorIconButton(false)}
              onClick={() => {
                setMouseOnErrorIconButton(false);
                // dispatch retry action for non-MRXS files
                dispatch(retrySingleWSIUpload(wsiKey));
                // TODO: figure out what to do for retrying MRXS files
              }}
              size="large"
            >
              {mouseOnErrorIconButton ? (
                <ReloadIcon style={{ color: theme.palette.darkGrey[0] }} />
              ) : (
                <ErrorIcon style={{ color: theme.palette.error.main }} />
              )}
            </RightButton>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              error === WSIFileUploadError.InvalidFilename
                ? "Change the file's name and retry by dragging and dropping the file again"
                : "Retry by dragging and dropping the file again."
            }
          >
            <span>
              <RightButton disabled size="large">
                <ErrorOutlineIcon />
              </RightButton>
            </span>
          </Tooltip>
        )}
      </RootContainer>
    );
  }
  return (
    <RootContainer>
      <EllipsizedTypography variant="body5" sx={filenameSx}>
        {targetFiles[0].name}
      </EllipsizedTypography>
      {stage === WSIFileUploadStage.ISyntaxConverting && (
        <>
          <ProgressIndicatorForStage progress={progress} stage={stage} />
          <Typography
            variant="body4"
            sx={{
              marginRight: "30px",
              flexGrow: 1,
              flexBasis: "290px",
              fontWeight: "400 !important",
            }}
          >
            Converting iSyntax file...
          </Typography>
        </>
      )}
      {stage !== WSIFileUploadStage.DONE &&
        stage !== WSIFileUploadStage.ISyntaxConverting && (
          <Tooltip title="Cancel">
            <RightButton onClick={onCancel} size="large">
              <CloseIcon />
            </RightButton>
          </Tooltip>
        )}
      {stage === WSIFileUploadStage.DONE && (
        <Tooltip title="Done">
          <RightButton size="large">
            <SuccessIcon style={{ color: "#00C9EA" }} />
          </RightButton>
        </Tooltip>
      )}
    </RootContainer>
  );
};
