import { ClickAwayListener, Menu, MenuItem } from "@mui/material";
import { MenuProps } from "@mui/material/Menu";
import { Project } from "dux/projects/model";

export interface ProjectContextMenuProps extends MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  onDelete?: (project: Project) => void;
  onEdit?: (project: Project) => void;
  project: Project;
}

const ProjectContextMenu = ({
  anchorEl,
  open,
  onClose,
  onDelete,
  onEdit,
  project,
}: ProjectContextMenuProps): ReturnType<typeof Menu> => {
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        BackdropProps={{ open: false }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ margin: (theme) => theme.spacing(0.5, 0) }}
      >
        <MenuItem
          onClick={() => {
            onEdit(project);
            onClose();
          }}
        >
          Edit Project
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(project);
            onClose();
          }}
        >
          Delete Project
        </MenuItem>
      </Menu>
    </ClickAwayListener>
  );
};

export default ProjectContextMenu;
