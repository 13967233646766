import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { WSIId } from "dux/WSIAnalysisResult/model";
import {
  getAnalysisSourceDetailsById,
  resetAnalysisState,
  resetViewerState,
  updateInferenceResultLoaded,
} from "dux/analysis/actions";
import ZoomController from "components/analysis/ZoomController";

import PageTemplate from "components/common/PageTemplate";
import SlideViewer from "components/analysis/SlideViewer";
import SlideRightPanel from "components/analysis/SlideRightPanel";
import {
  analysisResultDetailSelector,
  inferenceResultLoadedSelector,
  slideInformationSelector,
} from "dux/analysis/selectors";
import LoadingIndicator from "components/analysis/LoadingIndicator";
import useStompClient, {
  EventData,
  EventType,
  NotificationMessage,
} from "hooks/useStompClient";
import forEach from "lodash/forEach";
import { DetailsSlideInformation } from "dux/analysis/model";
import ViewerToolbar from "components/analysis/ViewerToolbar";
import useViewerToolbar from "hooks/useViewerToolbar";
import withAuthGuard from "hoc/withAuthGuard";
import { syncDrawerSlideList } from "dux/slide/actions";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "auto",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const osdContainer = {
  position: "relative",
  height: "100%",
  backgroundColor: "#fff",
  "& .navigator": {
    width: "160px !important",
    height: "160px !important",
    border: "solid 1px rgba(134, 148, 177, 0.16) !important",
    backgroundColor: "#fff !important",
    marginTop: "16px !important",
    marginRight: "16px !important",
    borderRadius: "4px",
  },
  "& .openseadragon-canvas": {
    outline: "none",
  },
  "& .displayregion": {
    border: "2px solid #5A79E3 !important",
  },
} as const;

const OuterViewerContaienr = styled("div")({
  display: "flex",
  width: "100%",
  height: "100%",
});

const InnerViewerContainer = styled("div")({
  width: "calc(100% - 240px)",
  height: "100%",
});

interface IURLParams {
  projectId: string;
  slideId: WSIId;
}

function checkNotificationMessagesForCurrentSlide({
  slideId,
  slideInformation,
  lastNotificationCount,
  notificationMessages,
}: {
  slideId: string;
  slideInformation: DetailsSlideInformation | null;
  lastNotificationCount: number;
  notificationMessages: NotificationMessage[];
}): boolean {
  const newMessages = notificationMessages.slice(
    0,
    notificationMessages.length - lastNotificationCount
  );
  let ret = false;
  forEach(newMessages, (message) => {
    forEach(message.eventData, (eventData) => {
      if (
        (eventData.type === EventType.Analysis ||
          eventData.type === EventType.AnalysisResultReview ||
          eventData.type === EventType.PreAnalysis) &&
        eventData.analysisInfo?.analysisSourceId === slideId &&
        (slideInformation.status === "ANALYZING" ||
          slideInformation.status === "PRE_ANALYZING")
      ) {
        ret = true;
      }
    });
  });
  return ret;
}

function checkNotificationMessagesForAnalysis(
  notificationMessages: NotificationMessage[],
  lastNotificationCount: number
): EventData[] {
  const newMessages = notificationMessages.slice(
    0,
    notificationMessages.length - lastNotificationCount
  );
  let ret: EventData[] = [];
  forEach(newMessages, (message) => {
    forEach(message.eventData, (eventData) => {
      if (
        eventData.type === EventType.Analysis ||
        eventData.type === EventType.AnalysisResultReview ||
        eventData.type === EventType.PreAnalysis
      ) {
        ret.push(eventData);
      }
    });
  });
  return ret;
}

export const Slide = () => {
  const dispatch = useDispatch();
  const history = useHistory(); // TODO : clean up this code when OSD renderer issue fix is done. related: issue 42
  const { toolbarState, dispatchToolbar } = useViewerToolbar();
  const { slideId } = useParams<IURLParams>();
  const { messages: notificationMessages } = useStompClient();
  const [lastNotificationCount, setLastNotificationCount] = useState(
    notificationMessages.length
  );
  const inferenceResultLoaded = useSelector(inferenceResultLoadedSelector);
  const slideInformation = useSelector(slideInformationSelector);
  const analysisResultDetail = useSelector(analysisResultDetailSelector);
  const [tmpKey, setTmpKey] = useState(0); // TODO : clean up this code when OSD renderer issue fix is done.

  useEffect(() => {
    dispatch(getAnalysisSourceDetailsById.request(slideId));
    return () => {
      dispatch(resetAnalysisState());
    };
  }, [dispatch, slideId]);

  useEffect(
    () => () => {
      dispatch(resetViewerState());
    },
    [dispatch]
  );

  useEffect(() => {
    // set image mask loading status to false on component unmount
    return () => {
      dispatch(updateInferenceResultLoaded(false));
    };
  }, [dispatch]);

  useEffect(() => {
    setTmpKey((state) => state + 1);
  }, [history]); // TODO : clean up this code when OSD renderer issue fix is done.

  useEffect(() => {
    if (
      notificationMessages.length > lastNotificationCount &&
      checkNotificationMessagesForCurrentSlide({
        slideId,
        lastNotificationCount,
        notificationMessages,
        slideInformation,
      })
    ) {
      dispatch(getAnalysisSourceDetailsById.request(slideId));
    }
    const analysisEventData = checkNotificationMessagesForAnalysis(
      notificationMessages,
      lastNotificationCount
    );
    dispatch(syncDrawerSlideList(analysisEventData));
    setLastNotificationCount(notificationMessages.length);
  }, [
    notificationMessages,
    lastNotificationCount,
    slideId,
    slideInformation,
    dispatch,
  ]);

  return (
    <PageTemplate
      toolbar={
        <ViewerToolbar
          toolbarState={toolbarState}
          dispatchToolbar={dispatchToolbar}
          slideId={slideId}
          slideInformation={slideInformation}
        />
      }
    >
      <Container sx={osdContainer}>
        <OuterViewerContaienr>
          <InnerViewerContainer>
            <SlideViewer
              key={tmpKey} // TODO : clean up this code when OSD renderer issue fix is done.
            />
            <ZoomController />
            {/* {!inferenceResultLoaded && <Loader />} */}
          </InnerViewerContainer>
        </OuterViewerContaienr>
        <SlideRightPanel
          slideId={slideId}
          slideInformation={slideInformation}
          analysisResultDetail={analysisResultDetail}
        />
      </Container>
      {!!analysisResultDetail && !inferenceResultLoaded && <LoadingIndicator />}
    </PageTemplate>
  );
};

export default withAuthGuard(Slide);
