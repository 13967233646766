declare global {
  interface UACHint {
    architecture?: string;
    bitness?: string;
    model?: string;
    platformVersion?: string;
    uaFullVersion?: string;
  }

  type HintKey = keyof UACHint;

  interface NavigatorUAData {
    brands: { brand: string; version: string }[];
    mobile: boolean;
    platform?: string;
    getHighEntropyValues: (hints: HintKey[]) => Promise<Partial<UACHint>>;
  }
  interface Navigator {
    userAgentData: NavigatorUAData;
  }
}

export default function isMac(): boolean {
  if (!navigator) return false;
  if (navigator.userAgentData) {
    return !!navigator.userAgentData.platform?.toLowerCase().includes("mac");
  }
  if (!!navigator.userAgent) {
    const ua = navigator.userAgent.toLowerCase();
    return (
      ua.includes("macintosh") ||
      ua.includes("mac os x") ||
      ua.includes("mac_powerpc")
    );
  }
  return false;
}
