import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "@mui/material";
import AuthActionButton from "./AuthActionButton";
import AuthAlert from "./AuthAlert";
import AuthDescription from "./AuthDescription";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { resetPassword, verifyValidKey } from "dux/user/api";
import { AccountProfile, ResetPasswordPathParams } from "dux/user/model";
import useQueryParams from "components/hooks/useQueryParams";

export const PASSWORD = "PASSWORD";

export default function ResetPasswordForm() {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const actionDisabled = !newPassword.trim() || !confirmNewPassword.trim();

  const { key } = useParams<ResetPasswordPathParams>();

  const { email: userEmail, name: userName } = useQueryParams<AccountProfile>();

  useEffect(() => {
    verifyValidKey({ key, purpose: PASSWORD }).catch(() => {
      history.replace("/forgot-password?expired");
    });
  }, [history, key]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSuccess(false);
      if (newPassword !== confirmNewPassword) {
        setErrorMessage("Confirm password must match the new password");
        return;
      }
      resetPassword({
        key,
        newPassword,
      })
        .then(() => {
          setSuccess(true);
          return history.replace("/login");
        })
        .catch((error) => {
          setErrorMessage(
            error.details === "Invalid value entered"
              ? "New password must contain at least 3 from uppercase, lowercase, numbers, and special characters with at least 8 characters length"
              : "Something went wrong. Please try again."
          );
        });
    },
    [newPassword, confirmNewPassword, key, history]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>Reset Password</AuthHeading>
      {!success && errorMessage !== "" && (
        <AuthAlert severity="error">{errorMessage}</AuthAlert>
      )}
      <AuthDescription>
        Dear {userName}, Please set your password for {userEmail} account for
        Lunit SCOPE products.
        <br />
        <br />
        Password should contain
        <br />
        *8-20 characters long.
        <br />
        *At least one number.
        <br />
        *At least one letter.
        <br />
        *At least one special character($@%^&amp;*!#).
      </AuthDescription>
      <AuthFormField
        id="newPassword"
        type="password"
        aria-label="New Password"
        placeholder="New password*"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
      <AuthFormField
        id="confirmNewPassword"
        type="password"
        aria-label="Confirm new password"
        placeholder="Confirm new password*"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        required
      />
      <AuthActionButton disabled={actionDisabled}>Confirm</AuthActionButton>
      <AuthNav>
        <Link href="/login">Back to log in</Link>
      </AuthNav>
    </AuthForm>
  );
}
