import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";

import styled from "@mui/material/styles/styled";

import Select from "../../common/Select";
import SelectItem from "../../common/SelectItem";
import { DATE_FORMAT } from "utils/format";

import {
  FilterDrawerParams,
  IoResultType,
  SlideDateType,
  SlideStatus,
} from "dux/slide/model";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "assets/icons";

interface IControlProps {
  drawerFilter: FilterDrawerParams;
  handleDrawerFilter: (data: FilterDrawerParams) => void;
}

const DateRangeContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "208px",
});

const TypeButton = styled(Button)(({ theme }) => ({
  display: "flex",
  height: "36px",
  width: "100%",
  borderRadius: "8px",
  marginBottom: "14px",
  justifyContent: "space-between",
  border: "1px solid" + theme.palette.darkGrey[80],
  backgroundColor: theme.palette.darkGrey[80],
  color: theme.palette.darkGrey[60],
  "&:hover": {
    borderColor: theme.palette.scope1,
  },
  "&:active": {
    borderColor: theme.palette.scope1,
  },
}));

const filterCheckBoxField = [
  "Created",
  "PreAnalyzing",
  "Ready",
  "Analyzing",
  "Failed",
  "Analyzed",
];

const filterCheckBoxLabel = [
  "Created",
  "Pre-analyzing",
  "Ready",
  "Analyzing",
  "Failed",
  "Analyzed",
];

export const StatusContorl = React.memo(
  ({ drawerFilter, handleDrawerFilter }: IControlProps) => {
    const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      let checkedArray = [...drawerFilter.selectedStatuses, event.target.name];
      if (
        drawerFilter.selectedStatuses.includes(event.target.name as SlideStatus)
      )
        checkedArray = checkedArray.filter((key) => key !== event.target.name);
      handleDrawerFilter({
        ...drawerFilter,
        selectedStatuses: checkedArray as SlideStatus[],
      });
    };

    return (
      <FormControl>
        <FormGroup>
          {filterCheckBoxField.map((r, index) => (
            <FormControlLabel
              key={r}
              control={
                <Checkbox
                  name={r}
                  checked={drawerFilter.selectedStatuses.includes(
                    r as SlideStatus
                  )}
                  onChange={handleFilterStatus}
                />
              }
              label={
                <Typography variant="body5">
                  {filterCheckBoxLabel[index]}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }
);

export const DateControl = ({
  drawerFilter,
  handleDrawerFilter,
}: IControlProps) => {
  const handleDateTypeSelect = (event: SelectChangeEvent) => {
    handleDrawerFilter({
      ...drawerFilter,
      dateType: event.target.value as SlideDateType,
    });
  };

  const handleDateRange = (value: DateRange<Date>) => {
    handleDrawerFilter({
      ...drawerFilter,
      dateValue: value,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Select
        value={drawerFilter?.dateType}
        displayEmpty
        onChange={handleDateTypeSelect}
        inputProps={{
          "aria-label": "date",
        }}
        fullWidth
        sx={{ marginBottom: "12px" }}
      >
        <SelectItem
          value={"UPLOADED_AT"}
          selected={drawerFilter?.dateType === SlideDateType.UploadedAt}
        >
          {"Uploaded Date"}
        </SelectItem>
        <SelectItem
          value={"UPDATED_AT"}
          selected={drawerFilter?.dateType === SlideDateType.UpdatedAt}
        >
          {"Updated Date"}
        </SelectItem>
      </Select>
      <DateRangePicker
        calendars={1}
        value={drawerFilter?.dateValue ?? [null, null]}
        onChange={handleDateRange}
        startText=""
        endText=""
        PopperProps={{
          placement: "bottom-start",
        }}
        inputFormat={DATE_FORMAT}
        renderInput={(startProps, endProps) => (
          <DateRangeContainer>
            <TextField
              variant="outlined"
              {...startProps}
              InputProps={{
                "aria-label": "fromDate",
              }}
              sx={{ marginBottom: "12px" }}
              fullWidth
            />
            <TextField
              variant="outlined"
              {...endProps}
              InputProps={{
                "aria-label": "toDate",
              }}
              // inputProps={{ placeholder: "mm/dd/yyyy (To)", type: "tel" }}
              sx={{ marginBottom: "14px" }}
              fullWidth
            />
          </DateRangeContainer>
        )}
      />
    </LocalizationProvider>
  );
};

export const CancerType = ({ drawerFilter, handleCancerType }) => {
  return (
    <TypeButton onClick={handleCancerType} disableRipple disableFocusRipple>
      <Typography variant="body5"> Search for an option</Typography>
    </TypeButton>
  );
};

const ClearableSelectContainer = styled("div")({
  position: "relative",
});

export const IoResultControl = ({
  drawerFilter,
  handleDrawerFilter,
}: IControlProps) => {
  const handleResultTypeSelect = (event: SelectChangeEvent) => {
    handleDrawerFilter({
      ...drawerFilter,
      ioResult: event.target.value as IoResultType,
    });
  };

  const handleClearResultType = () => {
    handleDrawerFilter({
      ...drawerFilter,
      ioResult: null,
    });
  };

  return (
    <ClearableSelectContainer>
      <Select
        value={drawerFilter?.ioResult || ""}
        displayEmpty
        renderValue={(value) => {
          if (!value) {
            return <span style={{ color: "#737377" }}>Select an option</span>;
          }
          return value;
        }}
        onChange={handleResultTypeSelect}
        inputProps={{
          "aria-label": "date",
        }}
        fullWidth
        sx={{ marginBottom: "12px" }}
      >
        <SelectItem
          value={IoResultType.Positive}
          selected={drawerFilter?.ioResult === IoResultType.Positive}
        >
          {IoResultType.Positive}
        </SelectItem>
        <SelectItem
          value={IoResultType.Negative}
          selected={drawerFilter?.ioResult === IoResultType.Negative}
        >
          {IoResultType.Negative}
        </SelectItem>
        <SelectItem
          value={IoResultType.QCFailed}
          selected={drawerFilter?.ioResult === IoResultType.QCFailed}
        >
          {IoResultType.QCFailed}
        </SelectItem>
      </Select>
      {!!drawerFilter?.ioResult && (
        <IconButton
          sx={{ position: "absolute", right: 32, top: 4, padding: 0.5 }}
          onClick={handleClearResultType}
        >
          <CloseIcon />
        </IconButton>
      )}
    </ClearableSelectContainer>
  );
};
