import React, { createContext, ReactNode } from "react";
import useDialog from "./useDialog";
import Dialog from "./Template";

export interface IDialogContent {
  handleAgreementCallback?: () => void;
  handleDisagreementCallback?: () => void;
  content: ReactNode;
  title?: ReactNode;
  disagree?: string;
  agree?: string;
  simpleDismiss?: boolean;
  useCustomActionBtns?: boolean;
  isWarning?: boolean;
  isNotice?: boolean;
  forceAction?: boolean; // If it's true, there will be only one btn to force user to do something e.g. reload the browser
  disableBackdropClick?: boolean;
  width?: number;
  height?: number;
  loading?: boolean;
  divider?: boolean;
}
interface IDialogContextProps {
  open: boolean;
  handleDialog: (content: IDialogContent) => void;
  dialogContent: IDialogContent;
}

let DialogContext: React.Context<IDialogContextProps>;
const { Provider } = (DialogContext = createContext({} as IDialogContextProps));

const DialogProvider = ({ children }) => {
  const { open, handleDialog, dialogContent } = useDialog();
  return (
    <Provider value={{ open, handleDialog, dialogContent }}>
      <>
        <Dialog />
        {children}
      </>
    </Provider>
  );
};

export { DialogContext, DialogProvider };
