/* eslint import/no-webpack-loader-syntax:0 */
// @ts-ignore
import AnalysisResultWorker from "./workers/AnalysisResultWorker";
import { MaskWorkerMessage } from "./workers/MaskWorker.worker";
import { useCallback, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import kdbush from "kdbush";
import { RenderingProperties } from "./workers/AnalysisResultWorker/loader";
import { viewOptionsSelector } from "dux/analysis/selectors";
import {
  ResultFilePaths,
  ViewOption,
  ViewOptionItem,
} from "dux/analysis/model";
import find from "lodash/find";
import forIn from "lodash/forIn";
import { HistologicalFeatureOptions } from "./SlideRightPanel/context";

export interface IndexedCoordData {
  coordinates: kdbush.KDBush;
  id: string;
  title: string;
  color: string;
}

interface IndexedGridData {
  grid: kdbush.KDBush;
  id: string;
  title: string;
  color: string;
  gridPixelSizeX: number;
  gridPixelSizeY: number;
}

declare global {
  interface Window {
    lunitScopeMaskWorker: any;
    lunitScopeAnalysisDataWorker: Worker;
  }
}

interface MaskWorkerMessageEvent extends MessageEvent {
  data: MaskWorkerMessage;
}

interface LabelIdWithColor {
  labelId: string;
  title: string;
  color: string;
}

function getGridItemsFromViewOptions(
  options?: ViewOption[]
): LabelIdWithColor[] {
  if (!options) return [];
  const gridOption = find(options, (option) =>
    option.title.includes("Immune Phenotype Map")
  );
  if (!gridOption) return [];
  return (gridOption.items as ViewOptionItem[]).map((mapItem) => ({
    labelId: mapItem.id,
    title: mapItem.title,
    color: mapItem.color,
  }));
}

function getCellItemsFromViewOptions(
  options?: ViewOption[]
): LabelIdWithColor[] {
  if (!options) return [];
  const histologicalFeatOption = find(options, (option) =>
    option.title.includes("Histological Features")
  );
  if (!histologicalFeatOption) return [];
  return (histologicalFeatOption.items as ViewOptionItem[])
    .filter((item) => item.type === "POINT")
    .map((cellItem) => ({
      labelId: cellItem.id,
      title: cellItem.title,
      color: cellItem.color,
    }));
}

function getTissueItemsFromViewOptions(
  options?: ViewOption[]
): LabelIdWithColor[] {
  if (!options) return [];
  const histologicalFeatOption = find(options, (option) =>
    option.title.includes("Histological Features")
  );
  if (!histologicalFeatOption) return [];
  return (histologicalFeatOption.items as ViewOptionItem[])
    .filter((item) => item.type === "MASK")
    .map((tissueItem) => ({
      labelId: tissueItem.id,
      title: tissueItem.title,
      color: tissueItem.color,
    }));
}

function useOffscreenVisualization({
  biomarkerResultFilePath,
  histologyResultFilePath,
  viewOptions,
}: ResultFilePaths): [
  () => Promise<any>, // prepare
  (
    ctx: CanvasRenderingContext2D,
    histologicalFeatOptions?: HistologicalFeatureOptions
  ) => void, // draw
  () => {
    indexedCellData: IndexedCoordData[];
    indexedGridData: IndexedGridData[];
    resultRenderingProperties: RenderingProperties | null;
    maskImages: { [labelId: string]: ImageBitmap };
  }, // getResultData
  boolean // canPrepareVisualization
] {
  window.lunitScopeAnalysisDataWorker =
    window.lunitScopeAnalysisDataWorker || new AnalysisResultWorker();
  const _viewOptionsSelected = useSelector(viewOptionsSelector);
  const _viewOptions = viewOptions ? viewOptions : _viewOptionsSelected;
  const gridItems = useMemo(
    () => getGridItemsFromViewOptions(_viewOptions && _viewOptions.items),
    [_viewOptions]
  );
  const cellItems = useMemo(
    () => getCellItemsFromViewOptions(_viewOptions && _viewOptions.items),
    [_viewOptions]
  );
  const tissueItems = useMemo(
    () => getTissueItemsFromViewOptions(_viewOptions && _viewOptions.items),
    [_viewOptions]
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const resultPrepared = useRef<boolean>(false);
  const masksRef = useRef<{ [labelId: string]: ImageBitmap }>(null);
  const indexedCellData = useRef<IndexedCoordData[]>([]);
  const indexedGridData = useRef<IndexedGridData[]>([]);
  const resultRenderingProperties = useRef<RenderingProperties>(null);

  useEffect(() => {
    canvasRef.current = document.createElement("canvas");
    const offscreen = canvasRef.current.transferControlToOffscreen();
    window.lunitScopeAnalysisDataWorker.postMessage(
      {
        action: "canvas",
        canvas: offscreen,
      },
      [offscreen]
    );
    return () => {
      window.lunitScopeAnalysisDataWorker.onmessage = null;
      window.lunitScopeAnalysisDataWorker.postMessage({
        action: "destroy",
      });
      canvasRef.current = null;
      if (masksRef.current) {
        forIn(masksRef.current, (bitmap) => bitmap.close());
        masksRef.current = null;
      }
    };
    // Disable the below rule not to create offscreen canvas multiple times but do only on mounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (biomarkerResultFilePath || histologyResultFilePath) {
      resultPrepared.current = false;
    }
    return () => {
      if (masksRef.current) {
        forIn(masksRef.current, (bitmap) => bitmap.close());
        masksRef.current = null;
        indexedCellData.current = [];
        indexedGridData.current = [];
        resultRenderingProperties.current = null;
      }
    };
  }, [biomarkerResultFilePath, histologyResultFilePath]);

  const canPrepareVisualization =
    !!biomarkerResultFilePath || !!histologyResultFilePath;

  const prepare = useCallback(() => {
    if (!canPrepareVisualization || resultPrepared.current) {
      return Promise.resolve(this);
    }
    return new Promise((resolve, reject) => {
      window.lunitScopeAnalysisDataWorker.onmessage = (
        evt: MaskWorkerMessageEvent
      ) => {
        if (evt.data.action === "prepared") {
          resultPrepared.current = true;
          masksRef.current = evt.data.maskImages;
          indexedCellData.current = evt.data.cellData.map((item) => ({
            coordinates: new kdbush(
              item.coordinates,
              (p) => p.x,
              (p) => p.y,
              64,
              Uint32Array
            ),
            id: item.id,
            title: item.title,
            color: item.color,
          }));
          indexedGridData.current =
            (evt.data.gridData &&
              evt.data.gridData.map((item) => ({
                grid: new kdbush(
                  item.gridResults,
                  (p) => p.minX,
                  (p) => p.minY,
                  64,
                  Uint32Array
                ),
                id: item.id,
                title: item.title,
                color: item.color,
                gridPixelSizeX: item.gridPixelSizeX,
                gridPixelSizeY: item.gridPixelSizeY,
              }))) ||
            [];
          resultRenderingProperties.current =
            evt.data.resultRenderingProperties;
          resolve(this);
        } else if (evt.data.action === "error") {
          reject(new Error(evt.data.detail));
        }
      };
      window.lunitScopeAnalysisDataWorker.onerror = (error) => reject(error);
      window.lunitScopeAnalysisDataWorker.postMessage({
        action: "load",
        biomarkerResultFilePath,
        histologyResultFilePath,
        gridItems,
        cellItems,
        tissueItems,
      });
    });
  }, [
    canPrepareVisualization,
    gridItems,
    cellItems,
    tissueItems,
    biomarkerResultFilePath,
    histologyResultFilePath,
  ]);

  const draw = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      histologicalFeatOptions: HistologicalFeatureOptions = {}
    ) => {
      if (!masksRef.current || !resultRenderingProperties.current) return;
      const { minX, minY, downscale } = resultRenderingProperties.current;
      ctx.save();
      ctx.imageSmoothingEnabled = false;
      ctx.globalAlpha = 0.3;
      forIn(masksRef.current, (bitmap, labelId) => {
        if (histologicalFeatOptions[labelId]) {
          ctx.drawImage(
            bitmap,
            minX,
            minY,
            bitmap.width * downscale,
            bitmap.height * downscale
          );
        }
      });
      ctx.restore();
      // ctx.globalAlpha = 1;
    },
    [] //[minX, minY, downsample]
  );

  const getResultData = useCallback(() => {
    return {
      indexedCellData: indexedCellData.current,
      indexedGridData: indexedGridData.current,
      resultRenderingProperties: resultRenderingProperties.current,
      maskImages: masksRef.current,
    };
  }, []);

  return [prepare, draw, getResultData, canPrepareVisualization];
}

export default useOffscreenVisualization;
