import React, { useContext } from "react";
import { createPortal } from "react-dom";
import {
  Dialog as Container,
  DialogTitle as Title,
  DialogContent as ContentWrapper,
  DialogActions as Actions,
  Button,
  Box,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import WarningIcon from "@mui/icons-material/ReportProblemOutlined";
import NoticeIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContext } from "./context";
import { isNil } from "ramda";

const Dialog = () => {
  const {
    open,
    handleDialog,
    dialogContent: {
      disableBackdropClick,
      width,
      height,
      handleDisagreementCallback,
      handleAgreementCallback,
      title,
      divider,
      content,
      disagree,
      agree,
      simpleDismiss,
      useCustomActionBtns,
      forceAction,
      isNotice,
      isWarning,
      loading,
    },
  } = useContext(DialogContext);
  const theme = useTheme();

  return (
    open &&
    createPortal(
      <Container
        open
        PaperProps={{
          sx: {
            width: `${width || 400}px`,
            height: height ? `${height}px` : "auto",
          },
        }}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleDialog(null);
          } else if (disableBackdropClick) {
            handleDialog(null);
          }
        }}
        aria-labelledby="max-width-dialog-title"
        sx={{
          padding: 10,
        }}
      >
        {isNotice && (
          <NoticeIcon
            sx={{
              color: "#7292FD",
              marginTop: 30,
              marginBottom: 5,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        {isWarning && (
          <WarningIcon
            sx={{
              color: (theme) => theme.palette.error.main,
              marginTop: 40,
              marginBottom: 5,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        {title && (
          <Title
            id="max-width-dialog-title"
            sx={{
              padding: "16px 20px 14px 20px",
              ...theme.typography.subtitle3,
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: theme.palette.darkGrey[85],
            }}
          >
            <Typography variant="subtitle3">{title}</Typography>
            {!loading ? (
              <Tooltip title="Close">
                <IconButton
                  aria-label="close"
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => handleDialog(null)}
                  disabled={loading}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress
                sx={{
                  position: "absolute",
                  right: (theme) => theme.spacing(1),
                  top: (theme) => theme.spacing(1.5),
                }}
              />
            )}
          </Title>
        )}
        {divider && (
          <Divider sx={{ backgroundColor: theme.palette.darkGrey[80] }} />
        )}
        <ContentWrapper
          sx={{
            position: "relative",
            padding: "16px 20px 0px 20px",
            backgroundColor: theme.palette.darkGrey[85],
          }}
        >
          {typeof content === "string" ? (
            <Box sx={{ ...theme.typography.body5, color: "#FFF" }}>
              {content}
            </Box>
          ) : (
            content
          )}
          {loading && (
            <CircularProgress
              sx={{
                position: "absolute",
                top: `calc(50% - 20px)`,
                right: `calc(50% - 20px)`,
              }}
            />
          )}
        </ContentWrapper>
        {!useCustomActionBtns && (
          <Actions
            sx={{
              padding: "8px 20px 20px 20px",
              backgroundColor: theme.palette.darkGrey[85],
            }}
          >
            {simpleDismiss ? (
              <Button
                variant="contained"
                autoFocus
                onClick={() => handleDialog(null)}
                color="secondary"
              >
                Close
              </Button>
            ) : (
              <>
                {!forceAction && (
                  <Button
                    color="scope1"
                    sx={{
                      ...theme.typography.button2,
                      borderRadius: 2,
                    }}
                    disabled={loading}
                    onClick={() => {
                      if (typeof handleDisagreementCallback === "function") {
                        handleDisagreementCallback();
                        handleDialog(null);
                      } else {
                        handleDialog(null);
                      }
                    }}
                  >
                    {disagree || "Cancel"}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="scope2"
                  sx={{
                    ...theme.typography.button2,
                    borderRadius: 2,
                  }}
                  disabled={loading}
                  onClick={() => {
                    if (typeof handleAgreementCallback === "function") {
                      handleAgreementCallback();
                    }
                    if (isNil(loading)) {
                      handleDialog(null);
                    }
                  }}
                >
                  {agree || "Confirm"}
                </Button>
              </>
            )}
          </Actions>
        )}
      </Container>,
      document.querySelector("#dialog-root")
    )
  );
};

export default Dialog;
