import SlideAutocomplete from "components/SlideList/common/SlideAutocomplete";
import { useCallback, useEffect, useState } from "react";
import { SlideItem, SlideStatus } from "dux/slide/model";
import { Tag } from "dux/tags/model";
import { Project } from "dux/projects/model";
import { Box, InputLabel, styled, TextField, Typography } from "@mui/material";
import FormTemplate, {
  CommonFormProps,
  FormMode,
} from "components/Forms/Template";
import { Controller, useForm } from "react-hook-form";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";
import useTagsCRUD from "components/hooks/useTagsCRUD";
import { APICancerType } from "dux/cancerTypes/model";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";
import { useSelector } from "react-redux";
import { isAnalyzerUserSelector } from "dux/user/selectors";
import SingleAutocomplete from "components/SlideList/common/SingleAutocomplete";

interface IEditSlideDialogContentProps extends CommonFormProps {
  slideInfo: SlideItem;
  totalProjects: Project[];
  totalTags: Tag[];
  totalCancerTypes: APICancerType[];
  mode: FormMode;
  tagEventHandlers: {
    onCreateTag: (input: string) => void;
    onDeleteTag: (input: Tag) => void;
    onEditTag: (input: Tag, changed: string) => void;
  };
  projectEventHandlers: {
    onCreateProject: (input: string) => void;
    onEditProject: (input: Project, changed: string) => void;
    onDeleteProject: (input: Project) => void;
  };
  cancerTypeEventHandlers: {
    onCreateCancerType: (input: string) => void;
    onEditCancerType: (input: APICancerType, changed: string) => void;
    onDeleteCancerType: (input: APICancerType) => void;
  };
}

const FormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  /*"&:last-child": {
    display: "flex",
    marginBottom: theme.spacing(0),
  },*/
}));

export default function SlideForm({
  slideInfo,
  totalProjects,
  totalTags,
  totalCancerTypes,
  onCancel,
  onSubmit,
  open,
  mode,
  loading,
  tagEventHandlers,
  projectEventHandlers,
  cancerTypeEventHandlers,
}: IEditSlideDialogContentProps) {
  const [mappedProjects, setMappedProjects] = useState<Project[]>([]);
  const [mappedTags, setMappedTags] = useState<Tag[]>([]);
  const { onCreateTag, onDeleteTag, onEditTag } = tagEventHandlers;
  const { onCreateProject, onEditProject, onDeleteProject } =
    projectEventHandlers;
  const { onCreateCancerType, onEditCancerType, onDeleteCancerType } =
    cancerTypeEventHandlers;
  const isAnalyzer = useSelector(isAnalyzerUserSelector);
  useEffect(() => {
    setMappedProjects(
      totalProjects.filter(
        (value) =>
          slideInfo &&
          slideInfo.projects.some((innerVal) => innerVal === value.id)
      )
    );
  }, [slideInfo, totalProjects]);

  useEffect(() => {
    setMappedTags(
      totalTags.filter(
        (value) =>
          slideInfo && slideInfo.tags.some((innerVal) => innerVal === value.id)
      )
    );
  }, [slideInfo, totalTags]);

  const { register, control, handleSubmit, setValue, errors, getValues } =
    useForm({
      mode: "onChange",
    });

  useProjectsCRUD({
    onCreated: (createdProject) => {
      if (open) {
        const currentProjects = getValues("projects");
        const newProjects = totalProjects
          .filter((value) =>
            currentProjects.some(
              (innerVal) => (innerVal.id || innerVal) === value.id
            )
          )
          .concat(createdProject);
        setValue("projects", newProjects);
      }
    },
  });

  useTagsCRUD({
    onCreated: (createdTag) => {
      if (open) {
        const currentTags = getValues("tags");
        const newTags = totalTags
          .filter((value) =>
            currentTags.some(
              (innerVal) => (innerVal.id || innerVal) === value.id
            )
          )
          .concat(createdTag);
        setValue("tags", newTags);
      }
    },
  });

  const {
    cancerTypeMgmtState: { loading: cancerTypeLoading },
  } = useCancerTypeCRUD({
    onCreated: (createdCancerType) => {
      if (open) {
        setValue("cancerType", createdCancerType);
      }
    },
  });

  const wrappedOnSubmit = useCallback(
    (submittedValues) =>
      onSubmit({
        ...submittedValues,
        projects: submittedValues.projects.map(
          (project) => project.id || project
        ),
        tags: submittedValues.tags.map((tag) => tag.id || tag),
        cancerType: submittedValues.cancerType
          ? submittedValues.cancerType.name
          : null,
      }),
    [onSubmit]
  );

  const onDeleteCancerTypeWrapper = useCallback(
    (prevVal) => (input: APICancerType) => {
      if (input?.name === prevVal?.name) {
        setValue(
          "cancerType",
          totalCancerTypes.find((t) => t.name === "Pan cancer"),
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
      onDeleteCancerType(input);
    },
    [onDeleteCancerType, setValue, totalCancerTypes]
  );

  const onEditCancerTypeWrapper = useCallback(
    (prevVal) => (input: APICancerType, changed: string) => {
      if (input?.name === prevVal?.name) {
        setValue("cancerType", {
          ...input,
          name: changed,
        });
      }
      onEditCancerType(input, changed);
    },
    [onEditCancerType, setValue]
  );

  return (
    <FormTemplate
      open={open}
      onSubmit={handleSubmit(wrappedOnSubmit)}
      onCancel={onCancel}
      loading={loading}
      title={`${mode} Slide`}
    >
      <Box
        sx={{
          width: 436,
        }}
      >
        <FormRow>
          <InputLabel htmlFor="name" required>
            <Typography variant="body3" color="darkGrey.5">
              Name
            </Typography>
          </InputLabel>
          <TextField
            id="name"
            name="name"
            placeholder="Slide name"
            inputRef={register({
              required: "This field is required",
              maxLength: {
                value: 200,
                message: "Please type less than 200 characters",
              },
            })}
            defaultValue={slideInfo && slideInfo.name}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            variant="outlined"
            InputProps={{
              "aria-label": "name",
            }}
          />
        </FormRow>
        <FormRow>
          <InputLabel htmlFor="cancerType" required>
            <Typography variant="body3" color="darkGrey.5">
              Cancer Type
            </Typography>
          </InputLabel>
          <Controller
            name="cancerType"
            control={control}
            defaultValue={
              slideInfo &&
              totalCancerTypes &&
              totalCancerTypes.find(
                (cancerType) => cancerType.name === slideInfo.cancerType
              )
            }
            rules={{
              setValueAs: (value) => value,
            }}
            render={({ onChange, value }) => (
              <SingleAutocomplete
                totalOptions={totalCancerTypes}
                selectedOption={value}
                onChangeValue={onChange}
                placeholder="Select a cancer type or create one"
                onCreateRequest={onCreateCancerType}
                onDeleteRequest={onDeleteCancerTypeWrapper(value)}
                onEditRequest={onEditCancerTypeWrapper(value)}
                loading={cancerTypeLoading}
                disabled={
                  slideInfo?.status === SlideStatus.Analyzing ||
                  slideInfo?.status === SlideStatus.PreAnalyzing
                }
                readonlyItems={totalCancerTypes?.filter(
                  (item) => item.name === "Pan cancer"
                )}
              />
            )}
          />
        </FormRow>
        <FormRow>
          <InputLabel htmlFor="projects">
            <Typography variant="body3" color="darkGrey.5">
              Projects
            </Typography>
          </InputLabel>
          <Controller
            name="projects"
            control={control}
            defaultValue={mappedProjects}
            rules={{
              setValueAs: (value) => value.map((obj) => obj.id),
            }}
            render={({ onChange, value }) => (
              <SlideAutocomplete
                totalOptions={totalProjects}
                selectedOption={value}
                onChangeValue={onChange}
                placeholder="Create a project or select one"
                onCreateRequest={!isAnalyzer ? onCreateProject : undefined}
                onDeleteRequest={!isAnalyzer ? onDeleteProject : undefined}
                onEditRequest={!isAnalyzer ? onEditProject : undefined}
              />
            )}
          />
        </FormRow>
        <FormRow>
          <InputLabel htmlFor="tags">
            <Typography variant="body3" color="darkGrey.5">
              Tags
            </Typography>
          </InputLabel>
          <Controller
            name="tags"
            control={control}
            defaultValue={mappedTags}
            rules={{
              setValueAs: (value) => value.map((obj) => obj.id),
            }}
            render={({ onChange, value }) => (
              <SlideAutocomplete
                totalOptions={totalTags}
                selectedOption={value}
                onChangeValue={onChange}
                placeholder="Create or select a tag"
                onCreateRequest={onCreateTag}
                onDeleteRequest={onDeleteTag}
                onEditRequest={onEditTag}
              />
            )}
          />
        </FormRow>
      </Box>
    </FormTemplate>
  );
}
