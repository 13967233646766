import {
  Project,
  ProjectRequestValues,
  ProjectUUID,
  APIDeleteProjectsParams,
  APIGetProjectsPerPageParams,
  APIGetProjectsParams,
  APIProjectBulkMappingDeleteParams,
} from "./model";
import {
  asyncActionCreator,
  asyncAction,
} from "dux/utils/actionHandlingHelper";
import { action } from "typesafe-actions";
import { Paginate } from "dux/utils/commonModels";

/**
 * Action Creators
 * Usage: getAllProjects[type: request | success | failure](params)
 */
export const GET_ALL_PROJECTS_TYPES = asyncActionCreator("GET_ALL_PROJECTS");
export const getAllProjects = asyncAction<
  APIGetProjectsParams,
  Project[],
  string
>(GET_ALL_PROJECTS_TYPES);

/**
 * Action Creators
 * Usage: getProjectsPerPage[type: request | success | failure](params)
 */
export const GET_PROJECTS_PER_PAGE_TYPES = asyncActionCreator(
  "GET_PROJECTS_PER_PAGE"
);
export const getProjectsPerPage = asyncAction<
  APIGetProjectsPerPageParams,
  Paginate<Project>,
  string
>(GET_PROJECTS_PER_PAGE_TYPES);

export const RESET_PROJECT_MGMT_STATE = "RESET_PROJECT_MGMT_STATE";
export const resetProjectMgmtState = () => action(RESET_PROJECT_MGMT_STATE);
export const RESET_CREATED_PROJECT = "RESET_CREATED_PROJECT";
export const resetCreatedProject = () => action(RESET_CREATED_PROJECT);

/**
 * Action Creators
 * Usage: createProject[type: request | success | failure](params)
 */
export const CREATE_PROJECT_TYPES = asyncActionCreator("CREATE_PROJECT");
export const createProject = asyncAction<ProjectRequestValues, Project, string>(
  CREATE_PROJECT_TYPES
);

/**
 * Action Creators
 * Usage: updateProject[type: request | success | failure](params)
 */
export const UPDATE_PROJECT_TYPES = asyncActionCreator("UPDATE_PROJECT");
export const updateProject = asyncAction<ProjectRequestValues, Project, string>(
  UPDATE_PROJECT_TYPES
);

/**
 * Action Creators
 * Usage: deleteProjects[type: request | success | failure](params)
 */
export const DELETE_PROJECTS_TYPES = asyncActionCreator("DELETE_PROJECTS");
export const deleteProjects = asyncAction<APIDeleteProjectsParams, any, string>(
  DELETE_PROJECTS_TYPES
);

/**
 * Action Creators
 * Usage: enterProjects[type: request | success | failure](params)
 */
export const ENTER_PROJECT_TYPES = asyncActionCreator("ENTER_PROJECT");
export const enterProject = asyncAction<ProjectUUID, any, string>(
  ENTER_PROJECT_TYPES
);

/**
 * Action Creators
 * Usage: deleteProjectMappings[type: request | success | failure](params)
 */
export const DELETE_PROJECT_MAPPINGS_TYPES = asyncActionCreator(
  "DELETE_PROJECT_MAPPINGS"
);
export const deleteProjectMappings = asyncAction<
  APIProjectBulkMappingDeleteParams,
  any,
  string
>(DELETE_PROJECT_MAPPINGS_TYPES);
