import { Card, IconButton, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Project } from "dux/projects/model";
import EllipsizedTypography from "components/EllipsizedTypography";
import { styled } from "@mui/styles";
import { MoreIcon } from "assets/icons";
import usePopper from "hooks/usePopper";
import clsx from "clsx";
import ProjectContextMenu, {
  ProjectContextMenuProps,
} from "components/common/ProjectContextMenu";
import { DATE_FORMAT } from "utils/format";

interface IProjectCardProps {
  project: Project;
  isAnalyzer: boolean;
  isViewer: boolean;
  handleEditProject?: ProjectContextMenuProps["onEdit"];
  handleDeleteProject?: ProjectContextMenuProps["onDelete"];
}

const CardHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flex: "0 0",
});

const CardFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flex: "0 0",
  marginTop: "auto",
});

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
  opacity: 0,
});

const ProjectCard = ({
  project,
  isAnalyzer,
  isViewer,
  handleEditProject,
  handleDeleteProject,
}: IProjectCardProps) => {
  const menuPopper = usePopper();
  return (
    <>
      <Link to={`/projects/${project.id}/slides`}>
        <Card
          className={clsx(menuPopper.open ? "Mui-focused" : undefined)}
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: (theme) => theme.palette.background.secondary,
            border: "1px solid transparent",
            borderRadius: 2,
            height: 260,
            padding: 3,
            cursor: "pointer",
            "&:hover, &.Mui-focused": {
              backgroundColor: (theme) => theme.palette.background.selected,
              "& .MoreIcon": {
                opacity: 1,
              },
            },
          }}
          variant="outlined"
        >
          <CardHeader>
            <EllipsizedTypography
              sx={{
                maxHeight: 90,
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              direction="column"
              variant="subtitle1"
            >
              {project.title}
            </EllipsizedTypography>
            {!isAnalyzer && !isViewer && (
              <IconButton
                aria-label="Ellipsis Button"
                aria-expanded={menuPopper.open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  menuPopper.onOpen(e);
                }}
                sx={{
                  '&:hover, &[aria-expanded*="true"]': {
                    backgroundColor: (theme) => theme.palette.darkGrey[70],
                  },
                  color: "#fff",
                }}
              >
                <MoreIconComponent className="MoreIcon" />
              </IconButton>
            )}
          </CardHeader>
          <EllipsizedTypography
            sx={{
              flex: "0 1 auto",
              marginTop: 3,
              color: (theme) => theme.palette.darkGrey[40],
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
            direction="column"
            variant="body5"
          >
            {project.description}
          </EllipsizedTypography>
          <CardFooter>
            <Tooltip title="Date Range">
              <Typography variant="body6">
                {project.fromDate &&
                  format(new Date(project.fromDate), DATE_FORMAT)}
                &nbsp;
                {project.toDate &&
                  `- ${format(new Date(project.toDate), DATE_FORMAT)}`}
              </Typography>
            </Tooltip>
            <Tooltip title="No. of Slides">
              <Typography variant="body6">{project.slideCount}</Typography>
            </Tooltip>
          </CardFooter>
        </Card>
      </Link>
      <ProjectContextMenu
        anchorEl={menuPopper.anchorEl}
        open={menuPopper.open}
        onClose={menuPopper.onClose}
        onDelete={handleDeleteProject}
        onEdit={handleEditProject}
        project={project}
      />
    </>
  );
};

export default ProjectCard;
