import SlideTable from "components/SlideList/SlideTable";
import SlideCard from "components/SlideList/SlideCard";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { subScrollbarWidth } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { FileWithPath, useDropzone } from "react-dropzone";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SortField, { SortOrder } from "components/Forms/SortField";
import { DialogContext } from "components/Dialog/context";
import { useElementBreakpoints } from "components/hooks/useElementBreakpoints";
import { map } from "ramda";
import SearchField from "components/Forms/SearchField";
import ActiveIconButton from "components/common/ActiveIconButton";
import {
  DeleteIcon,
  EditIcon,
  FilterDotIcon,
  FilterIcon,
  ReloadIcon,
  StartIcon,
  ViewGridIcon,
  ViewListIcon,
} from "assets/icons";
import {
  FilterDrawerParams,
  IoResultType,
  SlideDateType,
  SlideItem,
  SlideStatus,
  SlideUUID,
} from "dux/slide/model";
import { SlideDndZone } from "./component/SlideDndZone";
import SlideForm from "components/SlideList/dialog/SlideForm";
import useFormTemplate from "components/hooks/useFormTemplate";
import { FormMode } from "components/Forms/Template";
import {
  bulkUpdateCancerTypes,
  deleteSlideSummaries,
  getSlideSummaries,
  openSlideViewerActionCreator,
  requestSlideInferences,
  requestSlidesAnalyze,
  resetSlideMgmtState,
  updateSlideSummary,
} from "dux/slide/actions";
import {
  slideAnalyzeFetchStateSelector,
  slideItemSelector,
  slideMgmtStateSelector,
} from "dux/slide/selectors";
import SlideInfoDialog from "./dialog/SlideInfo";
import FailedInfoDialog from "./dialog/FailedInfo";
import FilterDrawer from "./component/FilterDrawer";
import { DEFAULT_ACCEPTABLE_FILE_TYPES } from "components/WSIUpload/dux/model";
import {
  getAllowedFormatsSelector,
  getSlideLimitSelector,
} from "components/WSIUpload/dux/selectors";
import { Dispatch } from "redux";
import {
  addFilesToUpload,
  getAllowedWSIFormats,
  getSlideLimit,
} from "components/WSIUpload/dux/actions";
import { FetchStatus } from "dux/utils/commonEnums";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { DatetoIsoConverter } from "utils/format";
import { DEFAULT_PAGE_SIZE } from "utils/pagination";
import Pagination from "components/common/PageTemplate/Pagination";
import NoMatchContainer from "components/common/NoMatchContainer";
import { getProjectMgmtStateSelector } from "dux/projects/selectors";
import { deleteProjectMappings } from "dux/projects/actions";
import { Project } from "dux/projects/model";
import useDebounce from "components/hooks/useDebounce";
import { useHistory } from "react-router-dom";
import GenerateReportButton from "components/report/GenerateReportButton";
import useStompClient from "hooks/useStompClient";
import { isEqual, omit, partition, some } from "lodash";
import { isViewerUserSelector } from "dux/user/selectors";
import CancerTypeForm from "./dialog/CancerTypeForm";
import useTagsCRUD from "components/hooks/useTagsCRUD";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";
import EllipsizedTypography from "components/EllipsizedTypography";
import LoadingIndicator from "components/analysis/LoadingIndicator";
import {
  getSlideBulkActionDisabled,
  getSlideBulkActionTooltip,
} from "components/utils";
import { addDays, isValid } from "date-fns";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";

enum SlideViewMode {
  Grid,
  List,
}

// 40*2(margin of left and right side) + 520*'# of cards in a row' + 20*'# of cards in a row -1'(gap between cards)
const breakpoints = [...Array(10).keys()].map(
  (value) => 80 + 520 * (value + 1) + 20 * value
); // # of cards = value+1 because it starts from 0...

const SlidesContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  minWidth: "860px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "calc(100% - 240px)",
  }),
}));

const SlidesTopField = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3, subScrollbarWidth(theme.spacing(5)), 0, 5),
  marginBottom: theme.spacing(2),
  minWidth: "1000px",
}));

const SlidesSearchField = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "36px",
  minWidth: "1000px",
  padding: theme.spacing(0, 5, 0, 5),
  marginBottom: theme.spacing(3),
}));

const SlidesCheckField = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  height: "40px",
  alignItems: "center",
  color: theme.palette.darkGrey[30],
  margin: theme.spacing(0, 5, 0, 5),
  borderBottom: "1px solid " + theme.palette.darkGrey[80],
}));

const TooltipField = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  right: subScrollbarWidth(theme.spacing(5)),
}));

const TooltipDivider = styled(Divider)({
  height: "20px",
  alignSelf: "center",
  margin: "0 8px",
});

const ContentContainer = styled("div")({
  display: "flex",
  height: "100%",
  overflow: "hidden",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
  overflowX: "auto",
}));

const FilterField = styled(Button)(({ theme }) => ({
  display: "flex",
  height: "36px",
  width: "140px",
  marginLeft: "8px",
  borderRadius: "8px",
  justifyContent: "space-between",
  border: "1px solid" + theme.palette.darkGrey[80],
  backgroundColor: theme.palette.darkGrey[80],
  color: theme.palette.darkGrey[0],
  "&:hover": {
    borderColor: theme.palette.darkGrey[40],
  },
}));

const slideFields = [
  "name",
  "cancer",
  "score",
  "status",
  "status_updated_at",
  "uploaded_at",
];

const slideLabels = [
  "Slide Name",
  "Cancer Type",
  "IO Result",
  "Status",
  "Status updated at",
  "Slide uploaded at",
];

const FILTER_CHECK_PARAMS = ["selectedPerPage", "sortOrder", "sort"];

const FILTER_SET_PARAMS = [
  "slideName",
  "sort",
  "sortOrder",
  "selectedPerPage",
  "selectedPage",
  "dateType",
];

function isFilterDrawerSet(state: FilterDrawerParams): boolean {
  return !isEqual(
    omit(state, FILTER_SET_PARAMS),
    omit(INITIAL_FILTER_PARAMS, FILTER_SET_PARAMS)
  );
}

function useWSIDropZone(dispatch: Dispatch, projectId?: number) {
  const [isUploadOpen, setUploadOpen] = useState<boolean>(false);
  const isViewer = useSelector(isViewerUserSelector);

  const initiateTargetFile = useCallback(
    (fileList: FileWithPath[]) => {
      dispatch(addFilesToUpload(fileList, projectId));
    },
    [dispatch, projectId]
  );

  const onDropAccepted = (acceptedFiles: Array<File>) => {
    initiateTargetFile(acceptedFiles);
    if (isUploadOpen) setUploadOpen(false);
  };

  const onDropRejected = () => {
    alert(
      `Not acceptable file format detected.\nPlease upload supported files only. (${getAcceptableFileFormats()})`
    );
  };

  const fetchedAllowedFormats = useSelector(getAllowedFormatsSelector);
  const { currentSlideCount, limitSlideCount } = useSelector(
    getSlideLimitSelector
  );
  const allowedFormats =
    fetchedAllowedFormats.length > 0
      ? fetchedAllowedFormats
      : DEFAULT_ACCEPTABLE_FILE_TYPES;

  useEffect(() => {
    dispatch(getAllowedWSIFormats.request(null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSlideLimit.request(null));
  }, [dispatch]);

  const getAcceptableFileFormats = useCallback(() => {
    const accept = () =>
      allowedFormats
        .reduce((acc, type) => [...acc, ...type.formats], [])
        .join();
    return accept().concat(",.ini,.dat");
  }, [allowedFormats]);

  return {
    ...useDropzone({
      disabled: isViewer,
      onDropAccepted,
      onDropRejected,
      noKeyboard: true,
      noClick: true,
      accept: getAcceptableFileFormats(),
    }),
    isUploadOpen,
    setUploadOpen,
    currentSlideCount,
    limitSlideCount,
  };
}

const slideNotAnalyzable = (slide) =>
  slide.status === SlideStatus.Analyzing ||
  slide.status === SlideStatus.Analyzed ||
  slide.status === SlideStatus.PreAnalyzing;
const slideCancerTypeNotEditable = (slide) =>
  slide.status === SlideStatus.Analyzing ||
  slide.status === SlideStatus.PreAnalyzing;
const slideReportNotGeneratable = (slide) =>
  slide.status !== SlideStatus.Analyzed;

interface ISlideListParams {
  projectId?: number;
}

const INITIAL_FILTER_PARAMS: FilterDrawerParams = {
  slideName: "",
  dateType: SlideDateType.UploadedAt,
  dateValue: [null, null],
  cancerTypes: [],
  ioResult: null,
  selectedProjects: [],
  selectedTags: [],
  selectedStatuses: [],
  sort: slideFields[4],
  sortOrder: SortOrder.Descending,
  selectedPerPage: DEFAULT_PAGE_SIZE,
  selectedPage: 1,
};

const SlideList = ({ projectId }: ISlideListParams) => {
  const dispatch = useDispatch();
  const isProjectDashboard = Boolean(projectId);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<SlideViewMode>(SlideViewMode.List);
  const { messages: notificationMessages } = useStompClient();
  const [lastNotificationCount, setLastNotificationCount] = useState(0);
  const { handleDialog: openDialog, open: isDialogOpen } =
    useContext(DialogContext);

  const [selectedSlides, setSelectedSlide] = useState<SlideItem[]>([]);
  const [targetSlides, setTargetSlides] = useState<SlideItem[]>([]);
  const [drawerFilter, setDrawerFilter] = useState<FilterDrawerParams>(
    INITIAL_FILTER_PARAMS
  );
  const isClearedParam = useMemo(
    () =>
      isEqual(
        omit(drawerFilter, FILTER_CHECK_PARAMS),
        omit(INITIAL_FILTER_PARAMS, FILTER_CHECK_PARAMS)
      ),
    [drawerFilter]
  );

  const [filteredProjects, setFilteredProjects] = useState<{
    currentProject?: Project;
    exceptCurrentProject: Project[];
  }>({
    currentProject: null,
    exceptCurrentProject: [],
  });

  const {
    getRootProps,
    isDragActive,
    isUploadOpen,
    setUploadOpen,
    limitSlideCount,
    currentSlideCount,
  } = useWSIDropZone(dispatch, projectId);

  // detect element wrap change
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpointIndex = useElementBreakpoints(containerRef, breakpoints);

  //const isAnalyzer = useSelector(isAnalyzerUserSelector);
  const isViewer = useSelector(isViewerUserSelector);
  const slideList = useSelector(slideItemSelector);
  const slideMgmtState = useSelector(slideMgmtStateSelector);
  const {
    cancerTypes,
    cancerTypeMgmtState,
    requestCreateCancerType,
    requestDeleteCancerType,
    requestEditCancerType,
  } = useCancerTypeCRUD({});
  const {
    tags,
    tagMgmtState,
    requestCreateTag,
    requestDeleteTag,
    requestEditTag,
  } = useTagsCRUD({});
  const {
    projects: totalProjects,
    projectsMgmtState,
    requestCreateProject,
    requestDeleteProject,
    requestEditProject,
  } = useProjectsCRUD({});
  const projectMgmtState = useSelector(getProjectMgmtStateSelector);
  const slideAnalyzeFetchState = useSelector(slideAnalyzeFetchStateSelector);

  const history = useHistory();

  useEffect(() => {
    setFilteredProjects({
      currentProject: totalProjects.find((value) => value.id === projectId),
      exceptCurrentProject: totalProjects.filter(
        (value) => value.id !== projectId
      ),
    });
  }, [totalProjects, projectId]);

  const {
    state: slideFormState,
    dispatch: dispatchSlideForm,
    actionCreators: slideFormActions,
  } = useFormTemplate<SlideItem>();

  const {
    state: cancerTypeFormState,
    dispatch: dispatchCancerTypeForm,
    actionCreators: cancerTypeFormActions,
  } = useFormTemplate<{ cancerType: string }>();

  const handleSelectedList = useCallback(() => {
    setSelectedSlide([]);
  }, []);

  const handleEditCancerType = useCallback(
    (targetSlides: SlideItem[]) => {
      if (!slideMgmtState.loading) {
        dispatch(resetSlideMgmtState());
        setTargetSlides(targetSlides);
        dispatchCancerTypeForm(
          cancerTypeFormActions.openForm(FormMode.Edit, { cancerType: "" })
        );
      }
    },
    [dispatchCancerTypeForm, cancerTypeFormActions, slideMgmtState, dispatch]
  );

  const handleSubmitCancerType = useCallback(
    (data) => {
      const submittedValues = {
        slideIds: targetSlides
          .filter((slide) => !(slide.status === SlideStatus.Analyzing))
          .map((slide) => slide.id),
        cancerType: data.cancerType?.name,
      };
      handleSelectedList();
      switch (cancerTypeFormState.mode) {
        case FormMode.Add:
          return;
        case FormMode.Edit:
          dispatch(bulkUpdateCancerTypes.request(submittedValues));
          return;
      }
    },
    [targetSlides, handleSelectedList, cancerTypeFormState.mode, dispatch]
  );

  const onSlideSelected = useCallback(
    (itm: SlideItem, checked: boolean) => {
      if (checked) {
        setSelectedSlide([...selectedSlides, itm]);
      } else {
        setSelectedSlide(
          [...selectedSlides].filter((value) => value.id !== itm.id)
        );
      }
    },
    [selectedSlides]
  );

  const onAllSlideSelected = useCallback(
    (_, checked: boolean) => {
      if (checked) setSelectedSlide(slideList.contents);
      else handleSelectedList();
    },
    [handleSelectedList, slideList.contents]
  );

  const [searchTitle, setSearchTitle] = useState<string>("");
  const debouncedSearchTitle = useDebounce(searchTitle, 500);

  useEffect(() => {
    setDrawerFilter((state) => ({
      ...state,
      slideName: debouncedSearchTitle,
    }));
  }, [debouncedSearchTitle]);

  const handlePerPageChange = (event, perPage) => {
    setDrawerFilter((state) => ({
      ...state,
      selectedPerPage: perPage,
    }));
  };

  const handlePageChange = (event, page) => {
    setDrawerFilter((state) => ({
      ...state,
      selectedPage: page,
    }));
  };

  const refreshSlides = useCallback(() => {
    const filterProject = drawerFilter.selectedProjects.map(
      (value) => value.id
    );
    dispatch(
      getSlideSummaries.request({
        slideName: drawerFilter.slideName,
        page: drawerFilter.selectedPage - 1,
        size: drawerFilter.selectedPerPage,
        sort: drawerFilter.sort,
        sortOrder: drawerFilter.sortOrder,
        fromDate:
          drawerFilter.dateValue[0] && isValid(drawerFilter.dateValue[0])
            ? DatetoIsoConverter(drawerFilter.dateValue[0])
            : null,
        toDate:
          drawerFilter.dateValue[1] && isValid(drawerFilter.dateValue[1])
            ? DatetoIsoConverter(addDays(drawerFilter.dateValue[1], 1))
            : null,
        dateType: drawerFilter.dateType,
        statusList: drawerFilter.selectedStatuses,
        projectIds: isProjectDashboard
          ? [projectId, ...filterProject]
          : filterProject,
        ioResult:
          drawerFilter.ioResult &&
          drawerFilter.ioResult !== IoResultType.QCFailed
            ? drawerFilter.ioResult === IoResultType.Positive
            : undefined,
        qualityControl:
          drawerFilter.ioResult &&
          drawerFilter.ioResult === IoResultType.QCFailed
            ? false
            : !!drawerFilter.ioResult || undefined,
        cancerType: drawerFilter.cancerTypes.map((cancerType) => cancerType.id),
        tagIds: drawerFilter.selectedTags.map((value) => value.id),
      })
    );
  }, [dispatch, drawerFilter, isProjectDashboard, projectId]);

  useEffect(() => {
    refreshSlides();
  }, [refreshSlides]);

  const handleRefresh = useCallback(() => {
    refreshSlides();
    handleSelectedList();
  }, [refreshSlides, handleSelectedList]);

  useEffect(() => {
    // check if need to refresh slide TODO: simplify it with selector later...
    let needToRefresh = false;
    needToRefresh ||=
      slideMgmtState.success &&
      slideMgmtState.currentMethod === FetchMethod.Delete;
    if (needToRefresh && !slideMgmtState.loading) {
      refreshSlides();
    }
  }, [dispatch, slideMgmtState, projectMgmtState, refreshSlides]);

  useEffect(() => {
    let needToRefresh = false;
    needToRefresh ||=
      projectMgmtState.success &&
      projectMgmtState.currentMethod === FetchMethod.Patch;
    needToRefresh ||=
      slideAnalyzeFetchState.fetchStatus === FetchStatus.Fulfilled;
    if (needToRefresh) {
      refreshSlides();
    }
  }, [dispatch, projectMgmtState, slideAnalyzeFetchState, refreshSlides]);

  useEffect(() => {
    let needToRefresh = false;
    needToRefresh ||=
      cancerTypeMgmtState.success &&
      cancerTypeMgmtState.currentMethod === FetchMethod.Patch;
    needToRefresh ||=
      cancerTypeMgmtState.success &&
      cancerTypeMgmtState.currentMethod === FetchMethod.Delete;
    if (needToRefresh) {
      refreshSlides();
    }
  }, [dispatch, cancerTypeMgmtState, refreshSlides]);

  useEffect(() => {
    if (
      slideFormState.open &&
      slideMgmtState.currentMethod === FetchMethod.Patch &&
      slideMgmtState.fetchStatus === FetchStatus.Fulfilled
    ) {
      dispatchSlideForm(slideFormActions.closeForm());
    } else if (
      cancerTypeFormState.open &&
      slideMgmtState.currentMethod === FetchMethod.Patch &&
      slideMgmtState.fetchStatus === FetchStatus.Fulfilled
    ) {
      dispatchCancerTypeForm(cancerTypeFormActions.closeForm());
    }
  }, [
    slideFormState,
    cancerTypeFormState,
    dispatchSlideForm,
    dispatchCancerTypeForm,
    slideFormActions,
    cancerTypeFormActions,
    slideMgmtState,
  ]);

  useEffect(() => {
    if (
      isDialogOpen &&
      slideMgmtState.currentMethod === FetchMethod.Delete &&
      slideMgmtState.success
    ) {
      openDialog(null);
    }
  }, [isDialogOpen, openDialog, slideMgmtState]);

  useEffect(() => {
    const filtered = slideList.contents.filter((value) =>
      selectedSlides.some((selected) => selected.id === value.id)
    );
    if (filtered.length !== selectedSlides.length)
      setSelectedSlide([...filtered]);
  }, [slideList, selectedSlides]);

  useEffect(() => {
    if (
      notificationMessages &&
      notificationMessages.length > lastNotificationCount
    ) {
      refreshSlides();
    }
    setLastNotificationCount(
      notificationMessages && notificationMessages.length
    );
  }, [refreshSlides, notificationMessages, lastNotificationCount]);

  const handleSlideFormSubmit = useCallback(
    (data) => {
      let submittedValues: SlideItem = {
        ...slideFormState.defaultValues,
        ...data,
      };
      switch (slideFormState.mode) {
        case FormMode.Add:
          return;
        case FormMode.Edit:
          dispatch(
            updateSlideSummary.request({
              id: submittedValues.id,
              wsiId: submittedValues.wsiId,
              name: submittedValues.name,
              tagIds: submittedValues.tags,
              projectIds: submittedValues.projects,
              cancerType:
                submittedValues.cancerType !==
                slideFormState.defaultValues.cancerType
                  ? submittedValues.cancerType
                  : undefined,
            })
          );
          return;
      }
    },
    [dispatch, slideFormState]
  );

  const handleEditSlide = useCallback(
    (slide: SlideItem) => {
      if (!slideMgmtState.loading) {
        dispatch(resetSlideMgmtState());
        dispatchSlideForm(slideFormActions.openForm(FormMode.Edit, slide));
      }
    },
    [dispatchSlideForm, slideFormActions, slideMgmtState, dispatch]
  );

  const handleRowSelect = useCallback((items: SlideItem[]) => {
    setSelectedSlide(items);
  }, []);

  const handleDeleteSlide = useCallback(
    (slides: SlideItem[]) =>
      slides.length > 0 &&
      openDialog({
        width: 320,
        agree: "Delete slide",
        disagree: isProjectDashboard ? "Remove from project" : "Cancel",
        content: (
          <>
            <Typography variant="subtitle3" sx={{ marginBottom: 1 }}>
              Delete Slide
            </Typography>
            <Typography variant="body5">
              Do you want the remove the slide from the project or do you want
              to delete the slide file? (if you delete the slide, the slide
              won’t be accessible from all other projects)
              <br />
              (Be careful, this action cannot be undone)
            </Typography>
          </>
        ),
        handleAgreementCallback: () => {
          dispatch(
            deleteSlideSummaries.request(slides.map((value) => value.wsiId))
          );
        },
        handleDisagreementCallback: () => {
          if (isProjectDashboard) {
            dispatch(
              deleteProjectMappings.request({
                id: projectId,
                targets: slides.map((value) => value.wsiId),
              })
            );
          }
        },
        disableBackdropClick: true,
        loading: false,
      }),
    [dispatch, openDialog, isProjectDashboard, projectId]
  );

  const handleDrawer = () => {
    setFilterOpen((prev: boolean) => !prev);
  };

  const handleUploadSlide = () => {
    setUploadOpen((prev: boolean) => !prev);
  };

  const handleDrawerFilter = useCallback((data: FilterDrawerParams) => {
    setDrawerFilter(data);
  }, []);

  const handleSlideInfo = useCallback(
    (slide: SlideItem) =>
      openDialog({
        width: 500,
        title: "Slide Information",
        content: <SlideInfoDialog slide={slide} />,
        disableBackdropClick: true,
        loading: false,
        divider: true,
        useCustomActionBtns: true,
      }),
    [openDialog]
  );

  const handleFailedInfo = useCallback(
    (id: SlideUUID) =>
      openDialog({
        width: 500,
        title: "Failed",
        content: <FailedInfoDialog id={id} />,
        disableBackdropClick: true,
        loading: false,
        divider: true,
        useCustomActionBtns: true,
      }),
    [openDialog]
  );

  const handleSortChange = useCallback((value) => {
    setDrawerFilter((state) => ({
      ...state,
      sort: value[0],
      sortOrder: value[1],
      selectedPage: 1,
    }));
  }, []);

  const handleStartAnalyze = useCallback(
    (slides: SlideItem[]) => {
      const seperatedSlides = partition(
        slides,
        (slide: SlideItem) => slide.status !== SlideStatus.PreAnalFailed
      );
      if (seperatedSlides[0].length > 0) {
        dispatch(
          requestSlidesAnalyze.request({
            sourceIds: seperatedSlides[0].map((value) => value.id),
          })
        );
      }
      if (seperatedSlides[1].length > 0) {
        dispatch(requestSlideInferences.request(seperatedSlides[1]));
      }
      handleSelectedList();
    },
    [dispatch, handleSelectedList]
  );

  const openSlideViewer = useCallback(
    (slide?: SlideItem) => {
      if (!slide && selectedSlides.length === 0) return;
      const toOpen = Boolean(slide) ? slide : selectedSlides[0];

      dispatch(
        openSlideViewerActionCreator({
          slide: toOpen,
          projectId: projectId,
          history: history,
          selectedSlides: !slide && selectedSlides,
        })
      );
    },
    [dispatch, projectId, history, selectedSlides]
  );

  const handleSearchTitle = (e) => {
    setSearchTitle(e.target.value);
  };

  const handleResetInput = () => {
    setSearchTitle("");
  };

  const slideContent = () => {
    if (
      (!isDragActive && !isUploadOpen && slideList.contents.length !== 0) ||
      isViewer
    )
      return renderList();
    if (isClearedParam || isDragActive || isUploadOpen)
      return (
        <SlideDndZone
          currentSlideCount={currentSlideCount}
          slideLimit={limitSlideCount}
          noSlideLimit={false}
        />
      );
    return <NoMatchContainer />;
  };

  const renderList = () => {
    switch (viewMode) {
      case SlideViewMode.Grid:
        return (
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <SlidesCheckField>
              <Checkbox
                disableRipple
                sx={{
                  padding: 1,
                  marginRight: 0.5,
                }}
                checked={isEqual(selectedSlides, slideList.contents)}
                onChange={onAllSlideSelected}
              />
              <Typography variant="body3" sx={{ height: "20px" }}>
                Select All
              </Typography>
            </SlidesCheckField>
            <ImageList
              rowHeight={284}
              gap={20}
              sx={{
                flex: 1,
                width: "100%",
                // maxWidth: breakpoints[breakpointIndex - 1] || 1248,
                //minWidth: breakpoints[breakpointIndex - 1] || 0,
                padding: (theme) =>
                  `16px ${subScrollbarWidth(
                    theme.spacing(5)
                  )} 10px ${theme.spacing(5)}`,
                margin: 0,
                justifyContent: "center",
              }}
              cols={breakpointIndex}
              style={{
                gridTemplateColumns: `repeat(auto-fill, 520px)`,
                gridAutoRows: "min-content",
              }}
            >
              {map(
                (slide) => (
                  <ImageListItem cols={1} rows={1} key={slide.id}>
                    <SlideCard
                      slide={slide}
                      isChecked={some(selectedSlides, slide)}
                      totalProjects={totalProjects}
                      totalTags={tags}
                      handleSelectedList={handleSelectedList}
                      handleFailedInfo={handleFailedInfo}
                      handleEditSlide={handleEditSlide}
                      handleDeleteSlide={handleDeleteSlide}
                      handleSlideInfo={handleSlideInfo}
                      handleChecked={onSlideSelected}
                      handleOpenSlideViewer={openSlideViewer}
                    />
                  </ImageListItem>
                ),
                slideList.contents
              )}
            </ImageList>
          </Box>
        );
      case SlideViewMode.List:
        return (
          <TableContainer>
            <SlideTable
              slides={slideList.contents}
              totalProjects={totalProjects}
              totalTags={tags}
              totalCancerTypes={cancerTypes}
              selectedSlides={selectedSlides}
              pageSize={drawerFilter.selectedPerPage}
              handleSelectedList={handleSelectedList}
              handleFailedInfo={handleFailedInfo}
              handleRowSelect={handleRowSelect}
              handleEditSlide={handleEditSlide}
              handleDeleteSlide={handleDeleteSlide}
              handleSlideInfo={handleSlideInfo}
              handleOpenSlideViewer={openSlideViewer}
              handleEditCancerType={handleEditCancerType}
            />
          </TableContainer>
        );
    }
  };
  return (
    <SlidesContainer ref={containerRef} open={isFilterOpen}>
      <SlidesTopField>
        <EllipsizedTypography variant="subtitle1">
          {isProjectDashboard && filteredProjects.currentProject
            ? filteredProjects.currentProject.title
            : "All slides"}
        </EllipsizedTypography>
        {!isViewer && (
          <Button
            onClick={handleUploadSlide}
            variant="contained"
            size="small"
            sx={{ marginLeft: "16px" }}
          >
            {isUploadOpen ? "Close" : "Upload"}
          </Button>
        )}
      </SlidesTopField>
      <SlidesSearchField>
        <SearchField
          placeholder="Search by slide name"
          value={searchTitle}
          onSearch={handleSearchTitle}
          onResetInput={handleResetInput}
        />
        <SortField
          order={drawerFilter.sortOrder}
          field={drawerFilter.sort}
          fields={slideFields}
          labels={slideLabels}
          onChange={handleSortChange}
        />
        <FilterField onClick={handleDrawer} disableRipple disableFocusRipple>
          <Typography variant="body5">Filters</Typography>
          {isFilterDrawerSet(drawerFilter) ? <FilterDotIcon /> : <FilterIcon />}
        </FilterField>
        <TooltipField>
          {selectedSlides.length !== 0 && (
            <Button
              variant="text"
              onClick={() => openSlideViewer()}
              sx={{ marginRight: "14px" }}
            >
              <Typography
                variant="button2"
                color="scope1.main"
              >{`View ${selectedSlides.length} slide`}</Typography>
            </Button>
          )}
          <Tooltip
            title={getSlideBulkActionTooltip(
              selectedSlides,
              slideCancerTypeNotEditable,
              "Edit cancer type"
            )}
            key="edit-cancer-type"
          >
            <span>
              <IconButton
                onClick={() => handleEditCancerType(selectedSlides)}
                size="large"
                disabled={
                  getSlideBulkActionDisabled(
                    selectedSlides,
                    slideCancerTypeNotEditable
                  ) || isViewer
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={getSlideBulkActionTooltip(
              selectedSlides,
              slideNotAnalyzable,
              "Analyze start"
            )}
            key="anaylze-start"
          >
            <span>
              <IconButton
                onClick={() => handleStartAnalyze(selectedSlides)}
                size="large"
                disabled={
                  getSlideBulkActionDisabled(
                    selectedSlides,
                    slideNotAnalyzable
                  ) || isViewer
                }
              >
                <StartIcon />
              </IconButton>
            </span>
          </Tooltip>
          <GenerateReportButton
            tooltip={getSlideBulkActionTooltip(
              selectedSlides,
              slideReportNotGeneratable,
              "PDF download"
            )}
            disabled={getSlideBulkActionDisabled(
              selectedSlides,
              slideReportNotGeneratable
            )}
            slides={selectedSlides.filter(
              (value) => value.status === SlideStatus.Analyzed
            )}
          />
          {!isViewer && (
            <Tooltip title="Delete" key="delete">
              <span>
                <IconButton
                  onClick={() => handleDeleteSlide(selectedSlides)}
                  size="large"
                  disabled={selectedSlides.length === 0}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <TooltipDivider orientation="vertical" flexItem />
          <Tooltip title="Reload" key="reload">
            <ActiveIconButton onClick={handleRefresh} size="large">
              <ReloadIcon />
            </ActiveIconButton>
          </Tooltip>
          <TooltipDivider orientation="vertical" flexItem />
          <Tooltip title="Show as grid" key="showGrid">
            <ActiveIconButton
              active={viewMode === SlideViewMode.Grid}
              onClick={() => {
                if (viewMode !== SlideViewMode.Grid) {
                  setViewMode(SlideViewMode.Grid);
                  handleSelectedList();
                }
              }}
              size="large"
            >
              <ViewGridIcon />
            </ActiveIconButton>
          </Tooltip>
          <Tooltip title="Show as list" key="showList">
            <ActiveIconButton
              active={viewMode === SlideViewMode.List}
              onClick={() => {
                if (viewMode !== SlideViewMode.List) {
                  setViewMode(SlideViewMode.List);
                  handleSelectedList();
                }
              }}
              size="large"
            >
              <ViewListIcon />
            </ActiveIconButton>
          </Tooltip>
        </TooltipField>
      </SlidesSearchField>
      <ContentContainer {...getRootProps()}>
        {slideContent()}
        {((slideMgmtState.fetchStatus === FetchStatus.Pending &&
          (slideMgmtState.currentMethod === FetchMethod.Get ||
            slideMgmtState.currentMethod === FetchMethod.Patch ||
            slideMgmtState.currentMethod === FetchMethod.Delete)) ||
          tagMgmtState.loading ||
          projectsMgmtState.loading ||
          cancerTypeMgmtState.loading) && (
          <LoadingIndicator message="Loading slide list..." />
        )}
      </ContentContainer>
      <SlideForm
        onSubmit={handleSlideFormSubmit}
        onCancel={() => dispatchSlideForm(slideFormActions.closeForm())}
        open={slideFormState.open}
        mode={slideFormState.mode}
        loading={
          (slideMgmtState.currentMethod === FetchMethod.Patch &&
            slideMgmtState.loading) ||
          tagMgmtState.loading ||
          projectsMgmtState.loading ||
          cancerTypeMgmtState.loading
        }
        slideInfo={slideFormState.defaultValues}
        totalProjects={totalProjects}
        totalTags={tags}
        totalCancerTypes={cancerTypes}
        tagEventHandlers={{
          onCreateTag: requestCreateTag,
          onDeleteTag: requestDeleteTag,
          onEditTag: requestEditTag,
        }}
        projectEventHandlers={{
          onCreateProject: requestCreateProject,
          onEditProject: requestEditProject,
          onDeleteProject: requestDeleteProject,
        }}
        cancerTypeEventHandlers={{
          onCreateCancerType: requestCreateCancerType,
          onEditCancerType: requestEditCancerType,
          onDeleteCancerType: requestDeleteCancerType,
        }}
      />
      <CancerTypeForm
        open={cancerTypeFormState.open}
        loading={slideMgmtState.currentMethod === FetchMethod.Patch}
        onCancel={() =>
          dispatchCancerTypeForm(cancerTypeFormActions.closeForm())
        }
        onSubmit={handleSubmitCancerType}
        selectedSlides={targetSlides}
      />
      <FilterDrawer
        drawerOpen={isFilterOpen}
        drawerFilter={drawerFilter}
        handleDrawer={handleDrawer}
        handleDrawerFilter={handleDrawerFilter}
        totalProjects={filteredProjects.exceptCurrentProject}
      />

      {slideList.totalPages >= 1 && (
        <Box
          sx={{
            padding: (theme) =>
              `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
          }}
        >
          {!isDragActive && !isUploadOpen && (
            <Pagination
              page={drawerFilter.selectedPage}
              perPage={drawerFilter.selectedPerPage}
              onPerPageChange={handlePerPageChange}
              onPageChange={handlePageChange}
              count={slideList.totalElements}
            />
          )}
        </Box>
      )}
    </SlidesContainer>
  );
};

export default SlideList;

export { SlideViewMode };
