import {
  cancerTotalSelector,
  cancerTypeMgmtStateSelector,
} from "dux/cancerTypes/selectors";
import { enqueueSnackbar } from "dux/snackbar/actions";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { FetchStatus } from "dux/utils/commonEnums";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APICancerType, CancerUUID } from "dux/cancerTypes/model";
import {
  createCancerType,
  deleteCancerTypes,
  editCancerType,
  getCancers,
  resetCreatedCancerType,
} from "dux/cancerTypes/actions";

interface UseCancerTypeCRUDProps {
  onCreated?: (cancerType: APICancerType) => void;
  onEdited?: (cancerType: APICancerType) => void;
  onDelete?: (cancerID: CancerUUID) => void;
}

export default function useCancerTypeCRUD({
  onCreated,
  onEdited,
  onDelete,
}: UseCancerTypeCRUDProps) {
  const cancerTypes = useSelector(cancerTotalSelector);
  const cancerTypeMgmtState = useSelector(cancerTypeMgmtStateSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancerTypeMgmtState.fetchStatus === FetchStatus.Fulfilled) {
      if (
        cancerTypeMgmtState.currentMethod === FetchMethod.Post &&
        !!cancerTypeMgmtState.createdCancerType &&
        !!onCreated
      ) {
        dispatch(resetCreatedCancerType());
        onCreated(cancerTypeMgmtState.createdCancerType);
      } else if (
        cancerTypeMgmtState.currentMethod === FetchMethod.Patch &&
        !!cancerTypeMgmtState.createdCancerType &&
        !!onEdited
      ) {
        dispatch(resetCreatedCancerType());
        onEdited(cancerTypeMgmtState.createdCancerType);
      } else if (
        cancerTypeMgmtState.currentMethod === FetchMethod.Delete &&
        !!onDelete
      ) {
        dispatch(resetCreatedCancerType());
        onDelete(cancerTypeMgmtState.requestedToDeleteCancerUUID);
      }
    }
  }, [cancerTypeMgmtState, onCreated, onEdited, onDelete, dispatch]);

  useEffect(() => {
    // check if need to refresh tag TODO: simplify it with selector later...
    let needToLoad = false;
    needToLoad ||=
      cancerTypeMgmtState.fetchStatus === FetchStatus.Idle &&
      cancerTypeMgmtState.currentMethod === FetchMethod.Get;
    if (needToLoad && !cancerTypeMgmtState.loading) {
      dispatch(dispatch(getCancers.request({})));
    }
  }, [dispatch, cancerTypeMgmtState]);

  const requestCreateCancerType = useCallback(
    (input: string) => {
      if (input.length <= 200 && input.length > 0) {
        dispatch(
          createCancerType.request({
            name: input,
            description: "",
          })
        );
      } else if (input.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Cancer type title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (input.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Cancer type title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestEditCancerType = useCallback(
    (input: APICancerType, changed: string) => {
      const { id, description } = input;
      if (changed.length <= 200 && changed.length > 0) {
        dispatch(
          editCancerType.request({
            id,
            name: changed,
            description,
          })
        );
      } else if (changed.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Cancer type title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (changed.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Cancer type title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestDeleteCancerType = useCallback(
    (input: APICancerType) => {
      const { id } = input;
      dispatch(deleteCancerTypes.request(id));
    },
    [dispatch]
  );

  return {
    cancerTypes,
    cancerTypeMgmtState,
    requestCreateCancerType,
    requestDeleteCancerType,
    requestEditCancerType,
  };
}
