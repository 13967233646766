import { styled } from "@mui/material/styles";

const AuthSection = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.darkGrey[80]}`,
  borderRadius: "12px",
  boxSizing: "border-box",
  width: "528px",
  padding: theme.spacing(8),
  margin: "auto",
  fontWeight: 400,
}));

export default AuthSection;
