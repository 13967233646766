import { Typography } from "@mui/material";

interface DialogActionsMessageProps {
  children: React.ReactNode;
  error?: boolean;
}

const DialogActionsMessage = ({
  children,
  error = false,
}: DialogActionsMessageProps) => {
  return (
    <Typography
      color={error ? "error" : "#00C9EA"}
      variant="body5"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 1,
      }}
    >
      {children}
    </Typography>
  );
};

export default DialogActionsMessage;
