import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import {
  closeSnackbar,
  CLOSE_SNACKBAR,
  enqueueSnackbar,
  ENQUEUE_SNACKBAR,
  removeSnackbar,
  REMOVE_SNACKBAR,
} from "./actions";
import { SnackbarsState, SnackbarState } from "./model";

const snackbarsInitialState: SnackbarsState = [];

const snackbarsReducer = createReducer<SnackbarsState>(snackbarsInitialState, {
  [ENQUEUE_SNACKBAR]: (state, action: ActionType<typeof enqueueSnackbar>) =>
    state.concat(action.payload),
  [CLOSE_SNACKBAR]: (state, action: ActionType<typeof closeSnackbar>) =>
    state.map((snackbar) =>
      action.payload.dismissAll || snackbar.options.key === action.payload.key
        ? { ...snackbar, options: { ...snackbar.options, dismissed: true } }
        : snackbar
    ),
  [REMOVE_SNACKBAR]: (state, action: ActionType<typeof removeSnackbar>) =>
    state.filter((snackbar) => snackbar.options.key !== action.payload.key),
});

export const initialState: SnackbarState = {
  snackbars: snackbarsInitialState,
};

const snackbarReducer = combineReducers({
  snackbars: snackbarsReducer,
});

export default snackbarReducer;
