import storage from "redux-persist/lib/storage/session";
import { createTransform } from "redux-persist";

const selectedSlidesFilter = createTransform((subState, key) => {
  if (key === "slide") {
    return {
      slideViewerListParams: subState["slideViewerListParams"],
    };
  }
  return subState;
});

export const IO_STORAGE_KEY = "lunit.oc.io";
export const persistConfig = {
  key: IO_STORAGE_KEY,
  storage,
  whitelist: ["slide"],
  transforms: [selectedSlidesFilter],
};
