import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Popover,
  Popper,
  TextField,
  Typography,
  useAutocomplete,
} from "@mui/material";
import { Project } from "dux/projects/model";
import { styled, Theme } from "@mui/material/styles";
import { CheckIcon, CloseIcon, DeleteIcon, MoreIcon } from "assets/icons";
import React, { HTMLProps, useCallback, useEffect, useState } from "react";
import { isArray } from "lodash";
import { Tag } from "dux/tags/model";
import { SxProps } from "@mui/system";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import EllipsizedTypography from "components/EllipsizedTypography";
import { APICancerType } from "dux/cancerTypes/model";

interface ISlideAutocompleteProps<T> extends HTMLProps<HTMLElement> {
  defaultShowAllOptions?: boolean;
  usePlainText?: boolean;
  totalOptions: T[];
  selectedOption: T[];
  onChangeValue: (value: T[]) => void;
  handleEditTitle?: (target: T, newTitle: string) => void;
  maxFilteredOptionLength?: number;
  onCreateRequest?: (input: string) => void;
  onDeleteRequest?: (value: T) => void;
  onEditRequest?: (value: T, changed: string) => void;
  sx?: SxProps<Theme>;
  renderInput?: (props: TextFieldProps) => React.ReactNode;
  filterSelectedOptions?: boolean;
  single?: boolean;
  readonlyItems?: T[];
  popperSx?: SxProps<Theme>;
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  justifyContent: "start",
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

const StyledList = styled(List)(({ theme }) => ({
  "& .Mui-focused": {
    backgroundColor: theme.palette.darkGrey[80],
  },
}));

const StyledMoreIcon = styled(MoreIcon)(({ theme }) => ({
  "&": {
    opacity: 0,
    color: theme.palette.darkGrey[40],
  },
  ".Mui-focused &": {
    opacity: 1,
  },
}));

const StyledMoreButton = styled(Button)(({ theme }) => ({
  minWidth: 20,
  position: "absolute",
  right: 8,
  padding: 4,
  "&:hover": {
    backgroundColor: theme.palette.darkGrey[90],
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.darkGrey[70],
  maxWidth: "calc(100% - 34px)",
  height: 22,
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  ".MuiChip-label": {
    height: 20,
  },
  "& .MuiChip-deleteIcon": {
    color: theme.palette.text.primary,
    minWidth: 16,
    minHeight: 16,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    padding: theme.spacing(1, 2),
    flexFlow: "row wrap",
  },
  "& .MuiInputBase-input": {
    flexBasis: "50px",
    flexGrow: 1,
    flexShrink: 0,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.darkGrey[70],
  marginTop: theme.spacing(0.5),
}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: "14px !important",
}));

function InstantEditTitlePopper({
  popover,
  setPopover,
  onDelete,
  onEditOption,
}) {
  const [value, setValue] = useState("");
  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    []
  );
  const onClose = useCallback(() => {
    setPopover({
      anchor: null,
      target: null,
    });
  }, [setPopover]);
  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        if (Boolean(onEditOption)) {
          onEditOption(popover.target, value);
          onClose();
        }
      }
    },
    [onEditOption, popover, onClose, value]
  );
  const onDeleteClick = useCallback(
    (e) => {
      e.stopPropagation();
      onDelete(popover.target);
      onClose();
    },
    [onDelete, popover, onClose]
  );
  useEffect(() => {
    if (popover.target) {
      setValue(popover.target.title || popover.target.name);
    }
  }, [popover]);
  return (
    <>
      {popover.anchor && (
        <Popover
          open
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={onClose}
          anchorEl={popover.anchor}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            sx={{
              margin: "12px 8px",
              display: "flex",
              flexDirection: "column",
              width: 280,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <TextField
              variant="outlined"
              sx={{
                marginBottom: 1,
              }}
              onChange={onChangeText}
              value={value}
              onKeyUp={onKeyUp}
              onClick={(e) => e.stopPropagation()}
              disabled={!Boolean(onEditOption)}
            />
            {onDelete && (
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    padding: "0 8px",
                    flexGrow: 1,
                    justifyContent: "flex-start",
                    color: (theme) => theme.palette.darkGrey[0],
                  }}
                  onClick={onDeleteClick}
                >
                  <DeleteIcon />
                  <Typography
                    variant="body5"
                    sx={{
                      marginLeft: 1,
                    }}
                  >
                    Delete
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
}

const defaultRenderInput = (props: TextFieldProps) => (
  <StyledTextField
    fullWidth
    placeholder={
      isArray(props.InputProps.startAdornment) &&
      props.InputProps.startAdornment.length > 0
        ? ""
        : "Search for an option"
    }
    variant={"outlined" as any}
    id={props.id}
    inputProps={{
      ...props.inputProps,
    }}
    InputProps={{
      ...props.InputProps,
    }}
  />
);

export default function SlideAutocomplete<T = Project | Tag | APICancerType>({
  defaultShowAllOptions,
  usePlainText,
  totalOptions,
  selectedOption,
  onChangeValue,
  placeholder,
  maxFilteredOptionLength = 5,
  onCreateRequest,
  onDeleteRequest,
  onEditRequest,
  filterSelectedOptions,
  sx,
  renderInput,
  single,
  onFocus,
  onBlur,
  readonlyItems,
  popperSx,
  disabled,
}: ISlideAutocompleteProps<T>) {
  const getOptionLabel = useCallback(
    (option) => (usePlainText ? option : option.title || option.name),
    [usePlainText]
  );
  const [typingText, setTypingText] = useState("");
  const [popover, setPopover] = useState({
    anchor: null,
    target: null,
  });
  const [open, setOpen] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState<T>(null);
  const onHighlightChange = useCallback(
    (_, item: T) => setHighlightedItem(item),
    []
  );
  const onTyping = useCallback(
    (event, newInputValue, reason) => {
      if (popover.anchor === null) {
        setHighlightedItem(null);
        setTypingText(typeof newInputValue === "string" ? newInputValue : "");
      }
    },
    [popover]
  );
  const isOptionEqualToValue = useCallback(
    (option, value) =>
      usePlainText ? option === value : option.id === value.id,
    [usePlainText]
  );
  const onClickMore = useCallback((e, option) => {
    setPopover({
      anchor: e.currentTarget,
      target: option,
    });
    e.stopPropagation();
  }, []);
  const onChangeTag = useCallback(
    (event, value, reason, details) => {
      setTypingText("");
      if (single && value.length > 0) {
        onChangeValue([details.option]);
      } else {
        onChangeValue(value);
      }
    },
    [single, onChangeValue]
  );
  const onCreate = useCallback(() => {
    onCreateRequest && onCreateRequest(typingText);
  }, [onCreateRequest, typingText]);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(
    (e, reason) => {
      if (!(reason === "blur" && popover.anchor !== null)) {
        setOpen(false);
        if (single) {
          setTypingText(
            selectedOption.length > 0 ? getOptionLabel(selectedOption[0]) : ""
          );
        }
      }
    },
    [getOptionLabel, single, popover, selectedOption]
  );

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    value,
    id,
    setAnchorEl,
    anchorEl,
    groupedOptions,
  } = useAutocomplete({
    options: totalOptions,
    isOptionEqualToValue: isOptionEqualToValue,
    componentName: "SlideAutocomplete",
    multiple: true,
    openOnFocus: true,
    defaultValue: [],
    filterSelectedOptions,
    value: selectedOption,
    inputValue: typingText,
    onInputChange: onTyping,
    getOptionLabel: getOptionLabel,
    onChange: onChangeTag,
    freeSolo: true,
    onOpen,
    onClose,
    open,
    onHighlightChange,
  });

  useEffect(() => {
    if (single) {
      setTypingText(
        selectedOption.length > 0 ? getOptionLabel(selectedOption[0]) : ""
      );
      setOpen(false);
    }
  }, [single, getOptionLabel, selectedOption]);

  useEffect(() => {
    if (open) {
      onFocus && onFocus(null);
    } else {
      onBlur && onBlur(null);
    }
  }, [onFocus, onBlur, open]);

  const startAdornment = !single
    ? value?.map((option, index) => (
        <StyledChip
          label={
            <EllipsizedTypography
              variant="body5"
              color="darkGrey.15"
              direction="row"
              sx={{
                marginLeft: 0.5,
                // maxWidth: "calc(100% - 16px)",
              }}
            >
              {getOptionLabel(option)}
            </EllipsizedTypography>
          }
          size={"small"}
          {...getTagProps({ index })}
          deleteIcon={<StyledCloseIcon />}
        />
      ))
    : [];

  const $renderInput = renderInput ? renderInput : defaultRenderInput;

  return (
    <>
      <Box {...getRootProps()} sx={sx}>
        {$renderInput({
          inputProps: {
            ...getInputProps(),
            onKeyDown: (e) => {
              if (e.nativeEvent.isComposing || e.keyCode === 229) {
                return;
              }
              if (e.key === "Enter" && typingText) {
                e.preventDefault();
                e.stopPropagation();
                if (highlightedItem) {
                  const isSelected = groupedOptions
                    .filter(
                      (option, index) =>
                        getOptionProps({ option, index })["aria-selected"]
                    )
                    .filter((option) => option === highlightedItem);
                  if (single) {
                    onChangeValue([highlightedItem]);
                  } else {
                    if (isSelected.length) {
                      onChangeValue(
                        value.filter((option) => option !== highlightedItem)
                      );
                    } else {
                      onChangeValue(value.concat(highlightedItem));
                    }
                  }
                  if (single) onClose(null, null);
                  return;
                }
                if (groupedOptions.length === 0) {
                  onCreate();
                  return;
                }
                const exactMatch = groupedOptions.filter(
                  (option) => getOptionLabel(option) === typingText
                );
                if (exactMatch.length === 0) {
                  onCreate();
                  return;
                }
                const nonSelected = groupedOptions.filter(
                  (option, index) =>
                    !getOptionProps({ option, index })["aria-selected"]
                );
                if (nonSelected.length > 0) {
                  if (!single) {
                    onChangeValue(value.concat(nonSelected[0]));
                    setTypingText("");
                  } else {
                    onChangeValue([nonSelected[0]]);
                    setTypingText(getOptionLabel(nonSelected[0]));
                  }
                } else {
                  setTypingText("");
                }
              }
            },
          },
          id: id,
          disabled,
          InputProps: {
            startAdornment,
            ref: setAnchorEl,
          },
        })}
        {open && anchorEl && (
          <StyledPopper
            style={{
              width: anchorEl ? anchorEl.clientWidth : null,
            }}
            role="presentation"
            anchorEl={anchorEl}
            open
            sx={popperSx}
          >
            <StyledPaper onClick={(e) => e.stopPropagation()}>
              {!defaultShowAllOptions && typingText.length === 0 ? (
                <StyledButtonContainer>
                  <StyledButton>
                    <Typography variant="body5" color="text.primary">
                      {placeholder}
                    </Typography>
                  </StyledButton>
                </StyledButtonContainer>
              ) : groupedOptions.length === 0 ? (
                onCreateRequest && (
                  <StyledButtonContainer>
                    <StyledButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onCreate();
                      }}
                    >
                      <EllipsizedTypography
                        variant="body5"
                        color="text.primary"
                      >
                        {`Create “${typingText}”`}
                      </EllipsizedTypography>
                    </StyledButton>
                  </StyledButtonContainer>
                )
              ) : (
                <StyledList {...getListboxProps()}>
                  <ListSubheader
                    sx={{
                      position: "static",
                    }}
                  >
                    {placeholder}
                  </ListSubheader>
                  {groupedOptions
                    .slice(0, maxFilteredOptionLength)
                    .map((option, index) => {
                      const optionProps = getOptionProps({ option, index });
                      const selected = optionProps["aria-selected"];
                      return (
                        <MenuItem
                          {...optionProps}
                          onClick={(e) => {
                            e.stopPropagation();
                            optionProps.onClick(e);
                          }}
                          key={
                            usePlainText
                              ? `menuItem-${option}`
                              : `menuItem-${option.id}-${option.title}`
                          }
                        >
                          {!filterSelectedOptions && (
                            <ListItemIcon>
                              <CheckIcon
                                style={{
                                  opacity: selected ? 1.0 : 0,
                                }}
                              />
                            </ListItemIcon>
                          )}
                          <EllipsizedTypography
                            sx={{
                              width: `calc(100% - ${
                                filterSelectedOptions ? 30 : 56
                              }px)`,
                            }}
                            variant="body5"
                            color="text.primary"
                            direction="row"
                          >
                            {getOptionLabel(option)}
                          </EllipsizedTypography>
                          {(Boolean(onEditRequest) ||
                            Boolean(onDeleteRequest)) &&
                            !readonlyItems?.includes(option) && (
                              <StyledMoreButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onMouseDown={(e) => {
                                  onClickMore(e, option);
                                }}
                                aria-expanded={
                                  popover.anchor !== null ? "true" : null
                                }
                                variant="text"
                                size="small"
                              >
                                <StyledMoreIcon />
                              </StyledMoreButton>
                            )}
                        </MenuItem>
                      );
                    })}
                  {onCreateRequest && (
                    <>
                      <Divider />
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onCreate();
                        }}
                      >
                        <ListItemText
                          sx={{ display: "block" }}
                          primary={
                            <EllipsizedTypography
                              variant="body5"
                              color="text.primary"
                              direction="row"
                              sx={{
                                maxWidth: "calc(100% - 24px)",
                              }}
                            >{`Create “${typingText}”`}</EllipsizedTypography>
                          }
                        />
                      </MenuItem>
                    </>
                  )}
                </StyledList>
              )}
            </StyledPaper>
          </StyledPopper>
        )}
      </Box>
      <InstantEditTitlePopper
        popover={popover}
        setPopover={setPopover}
        onDelete={onDeleteRequest}
        onEditOption={onEditRequest}
      />
    </>
  );
}
