import { useDispatch, useSelector } from "react-redux";
import { getAllProjectsSelector } from "dux/projects/selectors";
import { tagMgmtStateSelector, tagsTotalSelector } from "dux/tags/selectors";
import usePopper from "hooks/usePopper";
import { Typography } from "@mui/material";
import { slideMgmtStateSelector } from "dux/slide/selectors";
import { useCallback, useContext, useEffect } from "react";
import { SlideItem } from "dux/slide/model";
import { DialogContext } from "components/Dialog/context";
import SlideInfoDialog from "components/SlideList/dialog/SlideInfo";
import {
  deleteSlideSummaries,
  resetSlideMgmtState,
  updateSlideSummary,
} from "dux/slide/actions";
import useFormTemplate from "components/hooks/useFormTemplate";
import { FormMode } from "components/Forms/Template";
import SlideForm from "components/SlideList/dialog/SlideForm";
import { createTag, deleteTags, editTag, getTags } from "dux/tags/actions";
import { Tag } from "dux/tags/model";
import {
  createProject,
  deleteProjects,
  updateProject,
} from "dux/projects/actions";
import { Project } from "dux/projects/model";
import { FetchStatus } from "dux/utils/commonEnums";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import SlideContextMenu from "components/common/SlideContextMenu";
import { enqueueSnackbar } from "dux/snackbar/actions";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";

interface UseSlideContextMenuProps {
  slide: SlideItem | null;
  onSlideDelete: () => void;
  onSlideEdit: () => void;
}

export default function useSlideContextMenu({
  slide,
  onSlideDelete,
  onSlideEdit,
}: UseSlideContextMenuProps) {
  const dispatch = useDispatch();
  const { anchorEl, onClose, onOpen: onMenuOpen, open } = usePopper();
  const totalProjects = useSelector(getAllProjectsSelector);
  const totalTags = useSelector(tagsTotalSelector);
  const {
    cancerTypes,
    requestCreateCancerType,
    requestEditCancerType,
    requestDeleteCancerType,
  } = useCancerTypeCRUD({});
  const {
    state: slideFormState,
    dispatch: dispatchSlideForm,
    actionCreators: slideFormActions,
  } = useFormTemplate<SlideItem>();
  const { handleDialog: openDialog, open: isDialogOpen } =
    useContext(DialogContext);
  const tagMgmtState = useSelector(tagMgmtStateSelector);
  const slideMgmtState = useSelector(slideMgmtStateSelector);
  useEffect(() => {
    // check if need to refresh tag TODO: simplify it with selector later...
    let needToRefresh = false;
    needToRefresh ||=
      tagMgmtState.fetchStatus === FetchStatus.Idle &&
      tagMgmtState.currentMethod === FetchMethod.Get;
    needToRefresh ||=
      tagMgmtState.fetchStatus === FetchStatus.Fulfilled &&
      tagMgmtState.currentMethod !== FetchMethod.Get;
    if (needToRefresh && !tagMgmtState.loading) {
      dispatch(
        getTags.request({
          title: "",
        })
      );
    }
  }, [dispatch, tagMgmtState]);

  useEffect(() => {
    if (
      isDialogOpen &&
      slideMgmtState.currentMethod === FetchMethod.Delete &&
      slideMgmtState.success
    ) {
      openDialog(null);
      onSlideDelete();
      dispatch(resetSlideMgmtState());
    }
  }, [isDialogOpen, openDialog, slideMgmtState, onSlideDelete, dispatch]);

  useEffect(() => {
    if (
      slideFormState.open &&
      slideMgmtState.currentMethod === FetchMethod.Patch &&
      slideMgmtState.fetchStatus === FetchStatus.Fulfilled
    ) {
      dispatchSlideForm(slideFormActions.closeForm());
      onSlideEdit();
      dispatch(resetSlideMgmtState());
    }
  }, [
    slideFormState,
    dispatchSlideForm,
    slideFormActions,
    slideMgmtState,
    onSlideEdit,
    dispatch,
  ]);

  // contextmenu
  const handleSlideInfo = useCallback(
    (slide: SlideItem) =>
      openDialog({
        width: 500,
        title: "Slide Information",
        content: <SlideInfoDialog slide={slide} />,
        disableBackdropClick: true,
        loading: false,
        divider: true,
        useCustomActionBtns: true,
      }),
    [openDialog]
  );

  const handleDeleteSlide = useCallback(
    (slides: SlideItem[]) =>
      slides.length > 0 &&
      openDialog({
        width: 320,
        agree: "Delete slide",
        disagree: "Cancel",
        content: (
          <>
            <Typography variant="subtitle3" sx={{ marginBottom: 1 }}>
              Delete Slide
            </Typography>
            <Typography variant="body5">
              Do you want the remove the slide from the project or do you want
              to delete the slide file? (if you delete the slide, the slide
              won’t be accessible from all other projects)
              <br />
              (Be careful, this action cannot be undone)
            </Typography>
          </>
        ),
        handleAgreementCallback: () => {
          dispatch(
            deleteSlideSummaries.request(slides.map((value) => value.wsiId))
          );
        },
        handleDisagreementCallback: () => {},
        disableBackdropClick: true,
        loading: false,
      }),
    [dispatch, openDialog]
  );

  const handleEditSlide = useCallback(
    (slide: SlideItem) => {
      dispatchSlideForm(slideFormActions.openForm(FormMode.Edit, slide));
    },
    [dispatchSlideForm, slideFormActions]
  );

  const handleSlideFormSubmit = useCallback(
    (data) => {
      let submittedValues: SlideItem = {
        ...slideFormState.defaultValues,
        ...data,
      };
      switch (slideFormState.mode) {
        case FormMode.Add:
          return;
        case FormMode.Edit:
          dispatch(
            updateSlideSummary.request({
              id: submittedValues.id,
              wsiId: submittedValues.wsiId,
              name: submittedValues.name,
              tagIds: submittedValues.tags,
              projectIds: submittedValues.projects,
              cancerType:
                submittedValues.cancerType !==
                slideFormState.defaultValues.cancerType
                  ? submittedValues.cancerType
                  : undefined,
            })
          );
          return;
      }
    },
    [dispatch, slideFormState]
  );

  // tags
  const requestCreateTag = useCallback(
    (input: string) => {
      if (input.length <= 200) {
        dispatch(
          createTag.request({
            title: input,
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestDeleteTag = useCallback(
    (input: Tag) => {
      dispatch(deleteTags.request([input.id]));
    },
    [dispatch]
  );

  const requestEditTag = useCallback(
    (input: Tag, changed: string) => {
      const { id, description } = input;
      if (changed.length <= 200) {
        dispatch(
          editTag.request({
            id,
            title: changed,
            description,
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  // projects
  const requestCreateProject = useCallback(
    (input: string) => {
      if (input.length <= 200) {
        dispatch(
          createProject.request({
            title: input,
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestEditProject = useCallback(
    (input: Project, changed: string) => {
      const { id, description, toDate, fromDate } = input;
      if (changed.length <= 200) {
        dispatch(
          updateProject.request({
            id,
            title: changed,
            description,
            toDate,
            fromDate,
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestDeleteProject = useCallback(
    (input: Project) => {
      const { id } = input;
      dispatch(
        deleteProjects.request({
          ids: [id],
        })
      );
    },
    [dispatch]
  );

  const renderContextMenu = useCallback(
    () =>
      !!slide && (
        <SlideContextMenu
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          handleInfo={handleSlideInfo}
          onDelete={handleDeleteSlide}
          onEdit={handleEditSlide}
          slide={slide}
        />
      ),
    [
      slide,
      anchorEl,
      open,
      onClose,
      handleSlideInfo,
      handleDeleteSlide,
      handleEditSlide,
    ]
  );

  const renderForm = useCallback(
    () => (
      <SlideForm
        onSubmit={handleSlideFormSubmit}
        onCancel={() => dispatchSlideForm(slideFormActions.closeForm())}
        open={slideFormState.open}
        mode={slideFormState.mode}
        loading={false} //slideFormState.loading}
        slideInfo={slideFormState.defaultValues}
        totalProjects={totalProjects}
        totalCancerTypes={cancerTypes}
        totalTags={totalTags}
        tagEventHandlers={{
          onCreateTag: requestCreateTag,
          onDeleteTag: requestDeleteTag,
          onEditTag: requestEditTag,
        }}
        projectEventHandlers={{
          onCreateProject: requestCreateProject,
          onEditProject: requestEditProject,
          onDeleteProject: requestDeleteProject,
        }}
        cancerTypeEventHandlers={{
          onCreateCancerType: requestCreateCancerType,
          onEditCancerType: requestEditCancerType,
          onDeleteCancerType: requestDeleteCancerType,
        }}
      />
    ),
    [
      handleSlideFormSubmit,
      dispatchSlideForm,
      slideFormActions,
      slideFormState.open,
      slideFormState.mode,
      slideFormState.defaultValues,
      totalProjects,
      totalTags,
      cancerTypes,
      requestCreateTag,
      requestDeleteTag,
      requestEditTag,
      requestCreateProject,
      requestEditProject,
      requestDeleteProject,
      requestCreateCancerType,
      requestEditCancerType,
      requestDeleteCancerType,
    ]
  );
  return {
    renderContextMenu,
    renderForm,
    onMenuOpen,
  };
}
