import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ImageList,
  ImageListItem,
  Typography,
  styled,
  Tooltip,
  Box,
} from "@mui/material";
import { useElementBreakpoints } from "components/hooks/useElementBreakpoints";
import { isNil, map } from "ramda";
import ProjectCard from "./ProjectCard";
import {
  getProjectListLoadingSelector,
  getProjectListPaginationInfoSelector,
  getProjectListSelector,
  getProjectMgmtStateSelector,
} from "dux/projects/selectors";
import {
  isAnalyzerUserSelector,
  isViewerUserSelector,
} from "dux/user/selectors";
import {
  createProject,
  updateProject,
  deleteProjects,
  getProjectsPerPage,
  getAllProjects,
} from "dux/projects/actions";
import { useDispatch, useSelector } from "react-redux";
import ProjectTable from "./ProjectTable";
import ActiveIconButton from "components/common/ActiveIconButton";
import SearchField from "components/Forms/SearchField";
import SortField, { SortOrder } from "components/Forms/SortField";
import { ViewGridIcon, ViewListIcon } from "assets/icons";
import { subScrollbarWidth } from "utils/styles";
import ProjectForm from "components/Forms/ProjectForm";
import useFormTemplate from "components/hooks/useFormTemplate";
import {
  Project,
  ProjectFormValues,
  createProjectRequestValues,
  createProjectFormValues,
} from "dux/projects/model";
import { FormMode } from "components/Forms/Template";
import { DialogContext } from "components/Dialog/context";
import Pagination from "components/common/PageTemplate/Pagination";
import { DEFAULT_PAGE_SIZE } from "utils/pagination";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import useDebounce from "components/hooks/useDebounce";
import NoMatchContainer from "components/common/NoMatchContainer";
import LoadingIndicator from "components/analysis/LoadingIndicator";

enum ProjectViewMode {
  Grid,
  List,
}

const breakpoints = [...Array(10).keys()].map(
  (value) => 520 * (value + 1) + 20 * value
);

const ProjectsContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  minWidth: "860px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "calc(100% - 240px)",
  }),
}));

const ProjectsTopField = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3, subScrollbarWidth(theme.spacing(5)), 0, 5),
  marginBottom: theme.spacing(2),
  minWidth: "1000px",
}));

const ProjectsSearchField = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "36px",
  minWidth: "1000px",
  padding: theme.spacing(0, 5, 0, 5),
  marginBottom: theme.spacing(3),
}));

const TooltipField = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  right: subScrollbarWidth(theme.spacing(5)),
}));

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
  overflowX: "auto",
}));

const ContentContainer = styled("div")({
  display: "flex",
  height: "100%",
  overflow: "hidden",
});

const projectFields = [
  "title",
  "slideCount",
  "createdAt",
  "fromDate",
  "toDate",
];
const projectLabels = [
  "Title",
  "No. of Slides",
  "Date created",
  "Date From.",
  "Date To.",
];

const ProjectList = () => {
  const dispatch = useDispatch();

  const projectPerPage = useSelector(getProjectListSelector);
  const projectListLoading = useSelector(getProjectListLoadingSelector);
  const projectPerPageInfo = useSelector(getProjectListPaginationInfoSelector);
  const projectMgmtState = useSelector(getProjectMgmtStateSelector);
  const isAnalyzer = useSelector(isAnalyzerUserSelector);
  const isViewer = useSelector(isViewerUserSelector);
  const containerRef = useRef<HTMLDivElement>(null);

  const [viewMode, setViewMode] = useState<ProjectViewMode>(
    ProjectViewMode.List
  );
  const [selectedPerPage, setSelectedPerPage] =
    useState<number>(DEFAULT_PAGE_SIZE);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [searchTitle, setSearchTitle] = useState<string>("");
  const [[sortField, sortOrder], setSort] = useState<[string, SortOrder]>([
    projectFields[2],
    SortOrder.Descending,
  ]);

  const debouncedSearchTitle = useDebounce(searchTitle, 500);

  const {
    handleDialog: openDialog,
    dialogContent,
    open,
  } = useContext(DialogContext);

  // detect element wrap change
  const breakpointIndex = useElementBreakpoints(containerRef, breakpoints);
  const {
    state: projectFormState,
    dispatch: dispatchProjectForm,
    actionCreators: projectFormActions,
  } = useFormTemplate<ProjectFormValues>();

  const handleCreateProject = useCallback(() => {
    dispatchProjectForm(projectFormActions.openForm(FormMode.Add, null));
  }, [dispatchProjectForm, projectFormActions]);

  const handleEditProject = useCallback(
    (project: Project) => {
      dispatchProjectForm(
        projectFormActions.openForm(
          FormMode.Edit,
          createProjectFormValues(project)
        )
      );
    },
    [dispatchProjectForm, projectFormActions]
  );

  const handleDeleteProject = useCallback(
    (project: Project) =>
      openDialog({
        width: 320,
        agree: "Confirm",
        content: (
          <>
            <Typography variant="subtitle3">Delete Project</Typography>
            <Typography variant="body5">
              Are you sure you want to delete the project? This action cannot be
              undone.
              <br />
              (Even if the project is deleted, the slides files won’t be
              deleted.)
            </Typography>
          </>
        ),
        handleAgreementCallback: () => {
          dispatch(deleteProjects.request({ ids: [project.id] }));
        },
        disableBackdropClick: true,
        loading: false,
      }),
    [openDialog, dispatch]
  );
  const handleSubmitProject = useCallback(
    (data) => {
      let submittedValues: ProjectFormValues = {
        ...projectFormState.defaultValues,
        ...data,
      };
      switch (projectFormState.mode) {
        case FormMode.Add:
          dispatch(
            createProject.request(createProjectRequestValues(submittedValues))
          );
          return;
        case FormMode.Edit:
          dispatch(
            updateProject.request(createProjectRequestValues(submittedValues))
          );
          return;
      }
    },
    [projectFormState, dispatch]
  );

  const handleSortChange = useCallback((value) => {
    setSort(value);
    setSelectedPage(1);
  }, []);

  const handlePerPageChange = useCallback((event, perPage) => {
    setSelectedPerPage(perPage);
  }, []);

  const handlePageChange = useCallback((event, page) => {
    setSelectedPage(page);
  }, []);

  useEffect(
    () => {
      if (
        open &&
        projectMgmtState.currentMethod === FetchMethod.Delete &&
        !isNil(dialogContent.content)
      ) {
        openDialog({
          ...dialogContent,
          loading: projectMgmtState.loading,
        });
      }
    },
    // Disable this rule to prevent from being in the infinite loop
    // caused by 'handleDialog'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectMgmtState, open]
  );

  const fetchProjects = useCallback(() => {
    dispatch(
      getProjectsPerPage.request({
        page: selectedPage - 1,
        size: selectedPerPage,
        title: debouncedSearchTitle ? debouncedSearchTitle : undefined,
        sort: [sortField + "," + sortOrder],
      })
    );
  }, [
    dispatch,
    selectedPage,
    selectedPerPage,
    debouncedSearchTitle,
    sortField,
    sortOrder,
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (
      projectMgmtState.success &&
      (projectFormState.open || !isNil(dialogContent.content))
    ) {
      dispatchProjectForm(projectFormActions.closeForm());
      openDialog(null);
      dispatch(getAllProjects.request({}));
      if (projectMgmtState.currentMethod === FetchMethod.Post) {
        // load the first page with no search on create to show the created project
        setSearchTitle("");
        setSelectedPage(1);
        // avoid fetch duplication by useEffect
        if (debouncedSearchTitle === "" && selectedPage === 1) {
          fetchProjects();
        }
        return;
      } else if (projectMgmtState.currentMethod === FetchMethod.Put) {
        // just refetch current page with current search because this item is already on the screen
        fetchProjects();
        return;
      } else if (projectMgmtState.currentMethod === FetchMethod.Delete) {
        // just refetch on delete too. if current page doesn't exist, previous page will be loaded by another effect
        fetchProjects();
        return;
      }
      fetchProjects();
    }
  }, [
    projectMgmtState,
    projectFormState,
    dialogContent,
    debouncedSearchTitle,
    dispatch,
    dispatchProjectForm,
    fetchProjects,
    openDialog,
    projectFormActions,
    selectedPage,
  ]);

  const renderList = () => {
    switch (viewMode) {
      case ProjectViewMode.Grid:
        return (
          <ImageList
            rowHeight={260}
            gap={20}
            sx={{
              flex: 1,
              width: "100%",
              // maxWidth: breakpoints[breakpointIndex - 1] || 1248,
              //minWidth: breakpoints[breakpointIndex - 1] || 0,
              margin: 0,
              justifyContent: "center",
            }}
            cols={breakpointIndex}
            style={{
              gridTemplateColumns: `repeat(auto-fill, 520px)`,
              gridAutoRows: "min-content",
            }}
          >
            {map(
              (project) => (
                <ImageListItem cols={1} rows={1} key={project.id}>
                  <ProjectCard
                    project={project}
                    isViewer={isViewer}
                    isAnalyzer={isAnalyzer}
                    handleEditProject={handleEditProject}
                    handleDeleteProject={handleDeleteProject}
                  />
                </ImageListItem>
              ),
              projectPerPage
            )}
          </ImageList>
        );
      case ProjectViewMode.List:
        return (
          <TableContainer>
            <ProjectTable
              projects={projectPerPage}
              isViewer={isViewer}
              isAnalyzer={isAnalyzer}
              handleEditProject={handleEditProject}
              handleDeleteProject={handleDeleteProject}
            />
          </TableContainer>
        );
    }
  };

  const handleSearchTitle = (e) => {
    setSearchTitle(e.target.value);
  };

  const handleResetInput = () => {
    setSearchTitle("");
  };

  return (
    <ProjectsContainer ref={containerRef}>
      <ProjectsTopField>
        <Typography variant="subtitle1">All Projects</Typography>
        {!isAnalyzer && !isViewer && (
          <Button
            variant="contained"
            onClick={handleCreateProject}
            size="small"
          >
            Create
          </Button>
        )}
      </ProjectsTopField>
      <ProjectsSearchField>
        <SearchField
          placeholder="Search by project title"
          value={searchTitle}
          onSearch={handleSearchTitle}
          onResetInput={handleResetInput}
        />
        <SortField
          order={sortOrder}
          field={sortField}
          fields={projectFields}
          labels={projectLabels}
          onChange={handleSortChange}
        />
        <TooltipField>
          <Tooltip title="Show as grid" key="showGrid">
            <ActiveIconButton
              active={viewMode === ProjectViewMode.Grid}
              onClick={() => setViewMode(ProjectViewMode.Grid)}
              size="large"
            >
              <ViewGridIcon />
            </ActiveIconButton>
          </Tooltip>
          <Tooltip title="Show as list" key="showList">
            <ActiveIconButton
              active={viewMode === ProjectViewMode.List}
              onClick={() => setViewMode(ProjectViewMode.List)}
              size="large"
            >
              <ViewListIcon />
            </ActiveIconButton>
          </Tooltip>
        </TooltipField>
      </ProjectsSearchField>
      <ContentContainer>
        {debouncedSearchTitle === "" || projectPerPage.length !== 0 ? (
          renderList()
        ) : (
          <NoMatchContainer />
        )}
        {projectListLoading && (
          <LoadingIndicator message="Loading project list..." />
        )}
      </ContentContainer>
      <ProjectForm
        defaultValues={projectFormState.defaultValues}
        loading={projectMgmtState.loading}
        mode={projectFormState.mode}
        open={projectFormState.open}
        onCancel={() => dispatchProjectForm(projectFormActions.closeForm())}
        onSubmit={handleSubmitProject}
      />
      {projectPerPageInfo.totalPages >= 1 && (
        <Box
          sx={{
            padding: (theme) =>
              `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
          }}
        >
          <Pagination
            page={selectedPage}
            perPage={selectedPerPage}
            onPerPageChange={handlePerPageChange}
            onPageChange={handlePageChange}
            count={projectPerPageInfo.totalElements}
          />
        </Box>
      )}
    </ProjectsContainer>
  );
};

export default ProjectList;

export { ProjectViewMode };
