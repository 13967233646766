import Tooltip from "@mui/material/Tooltip";
import { BellDotIcon, BellIcon } from "assets/icons";
import HeaderButton from "../HeaderButton";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import usePopper from "hooks/usePopper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import NotificationList from "./NotificationList";
import useStompClient from "hooks/useStompClient";
import { useCallback } from "react";

const Notification = () => {
  const { open, anchorEl, onClose, onOpen } = usePopper();
  const { messages, unread, checkRead, removeAllMessages, removeMessage } =
    useStompClient();
  const handleOpen = useCallback(
    (event: React.MouseEvent) => {
      checkRead();
      onOpen(event);
    },
    [checkRead, onOpen]
  );
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <Box sx={{ position: "relative" }}>
        <Tooltip title={!open ? "Notification" : ""}>
          <HeaderButton onClick={open ? onClose : handleOpen} selected={open}>
            {unread ? <BellDotIcon /> : <BellIcon />}
          </HeaderButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          modifiers={[
            {
              name: "offset",
              enabled: true,
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          <Paper
            elevation={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 500,
              height: 544,
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                color: "#fff",
                height: 64,
                padding: (theme) => ({ sm: theme.spacing(0, 4) }),
              }}
            >
              <Typography variant="subtitle3" sx={{ marginRight: 2 }}>
                Notifications
              </Typography>
              <Typography variant="body2">
                {messages && messages.length}
              </Typography>
              <Button
                color="scope1"
                sx={{
                  position: "absolute",
                  right: 40,
                }}
                onClick={removeAllMessages}
              >
                <Typography variant="button2">Remove all</Typography>
              </Button>
            </Toolbar>
            <Divider />
            <NotificationList
              messages={messages}
              handleRemoveMessage={removeMessage}
            />
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default Notification;
