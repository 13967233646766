import constate from "constate";
import { useReducer } from "react";

export enum ViewerToolType {
  ROI,
  Cell,
  Pointer,
}

export interface ViewerToolbarState {
  roiType: "TTR" | "PSR";
  cellEditable: boolean;
  activeTool: ViewerToolType;
  roiDraw: string;
  cellSelection: string;
  cellClass: string;
  pointerType: string;
  toolsEnabled: boolean;
}

const initialToolbarState: ViewerToolbarState = {
  roiType: "TTR",
  cellEditable: false,
  activeTool: ViewerToolType.Pointer,
  roiDraw: "polygon",
  cellSelection: "polygon",
  cellClass: "tp-",
  pointerType: "hand",
  toolsEnabled: false,
};

interface SetROITypeAction {
  type: "SET_ROI_TYPE";
  payload: "TTR" | "PSR";
}

interface SelectCellClassAction {
  type: "SELECT_CELL_CLASS";
  payload: string;
}

interface SelectToolAction {
  type: "SELECT_TOOL";
  payload: {
    toolType: ViewerToolType;
    toolId: string;
  };
}

interface SetToolsEnabledAction {
  type: "SET_TOOLS_ENABLED";
  payload: boolean;
}

type ToolbarActions =
  | SelectToolAction
  | SetROITypeAction
  | SelectCellClassAction
  | SetToolsEnabledAction;

function setToolId(
  state: ViewerToolbarState,
  payload: SelectToolAction["payload"]
): ViewerToolbarState {
  switch (payload.toolType) {
    case ViewerToolType.ROI:
      return {
        ...state,
        roiDraw: payload.toolId,
      };
    case ViewerToolType.Cell:
      return {
        ...state,
        cellSelection: payload.toolId,
      };
    case ViewerToolType.Pointer:
      return {
        ...state,
        pointerType: payload.toolId,
      };
    default:
      return state;
  }
}

const reducer = function (
  state: ViewerToolbarState,
  action: ToolbarActions
): ViewerToolbarState {
  switch (action.type) {
    case "SELECT_TOOL":
      return setToolId(
        { ...state, activeTool: action.payload.toolType },
        action.payload
      );
    case "SET_ROI_TYPE":
      return {
        ...state,
        roiType: action.payload,
        activeTool:
          action.payload === "TTR" && state.activeTool === ViewerToolType.Cell
            ? ViewerToolType.Pointer
            : state.activeTool,
        cellEditable: action.payload === "PSR",
      };
    case "SELECT_CELL_CLASS":
      return {
        ...state,
        cellClass: action.payload,
      };
    case "SET_TOOLS_ENABLED":
      return {
        ...state,
        toolsEnabled: action.payload,
      };
    default:
      return state;
  }
};

export function getActiveToolId(state: ViewerToolbarState): string {
  switch (state.activeTool) {
    case ViewerToolType.ROI:
      return state.roiDraw;
    case ViewerToolType.Cell:
      return state.cellSelection;
    case ViewerToolType.Pointer:
      return state.pointerType;
    default:
      return "none";
  }
}

const setROIType = (roiType: "TTR" | "PSR"): SetROITypeAction => ({
  type: "SET_ROI_TYPE",
  payload: roiType,
});

const selectTool = (
  toolType: ViewerToolType,
  toolId: string
): SelectToolAction => ({
  type: "SELECT_TOOL",
  payload: {
    toolType,
    toolId,
  },
});

const selectCellClass = (cellClass: string): SelectCellClassAction => ({
  type: "SELECT_CELL_CLASS",
  payload: cellClass,
});
const setToolsEnabled = (enabled: boolean): SetToolsEnabledAction => ({
  type: "SET_TOOLS_ENABLED",
  payload: enabled,
});
export { setROIType, selectCellClass, selectTool, setToolsEnabled };

const useViewerToolbar = () => {
  const [toolbarState, dispatchToolbar] = useReducer(
    reducer,
    initialToolbarState
  );
  return {
    toolbarState,
    dispatchToolbar,
  };
};

const [ViewerToolbarProvider, useViewerToolbarContext] =
  constate(useViewerToolbar);

export { ViewerToolbarProvider };
export default useViewerToolbarContext;
