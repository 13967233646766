import { getIsLoginSelector } from "dux/user/selectors";
import { ComponentType } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import accessTokenManager from "../auth/accessTokenManager";

export default function withAuthGuard(
  Component: ComponentType<any>
): ComponentType<any> {
  return () => {
    const isLogin = useSelector(getIsLoginSelector);
    if (
      !isLogin ||
      !accessTokenManager.hasAccessTokenInStorage() ||
      (accessTokenManager.getMFARequiredFromToken() &&
        !accessTokenManager.getMFAVerifiedFromToken())
    ) {
      return <Redirect to="/login" />;
    }
    return <Component />;
  };
}
