import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DialogActions,
  DialogContent,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getNotiSettings, updateNotiSettings } from "dux/user/actions";
import {
  getEmailNotiSettingsSelector,
  getSettingsStateSelector,
} from "dux/user/selectors";
import { FetchStatus } from "dux/utils/commonEnums";
import DialogActionsMessage from "components/Dialog/DialogActionsMessage";
import {
  createNotiSettingsFormValues,
  createNotiSettingsRequestValues,
  NotificationMethod,
  NotificationReceiveRange,
} from "dux/user/model";
import _ from "lodash";
import LoadingIndicator from "components/analysis/LoadingIndicator";

interface NotificationFormProps {
  onCancel: () => void;
}

const SettingsFormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:not(:first-of-type)": {
    marginTop: "inherit",
  },
}));

const SettingsLabel = styled(FormControlLabel)(({ theme }) => ({
  ...theme.typography.body5,
  width: "100%",
  color: theme.palette.darkGrey[30],
  ".MuiTypography-root": {
    fontWeight: "400",
  },
}));

export default function NotificationForm({ onCancel }: NotificationFormProps) {
  const dispatch = useDispatch();

  const settingsState = useSelector(getSettingsStateSelector);
  const notiSettings = useSelector(getEmailNotiSettingsSelector);

  const formDefaultValues = useMemo(
    () => createNotiSettingsFormValues(notiSettings, NotificationMethod.Email),
    [notiSettings]
  );

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: formDefaultValues,
  });
  const { fields: notiSettingsFields } = useFieldArray({
    control,
    name: "notiSettings",
  });

  // SettingsDailog의 Form별로 Idle 상태를 분리 (settingsState.fetchStatus는 모든 Form에서 공유됨)
  const [firstrun, setFirstrun] = useState<boolean>(true);

  const isSuccess =
    !firstrun && settingsState.fetchStatus === FetchStatus.Fulfilled;
  const hasError =
    !firstrun && settingsState.fetchStatus === FetchStatus.Rejected;

  useEffect(() => {
    dispatch(getNotiSettings.request(""));
  }, [dispatch]);

  const onSubmit = useCallback(
    (data) => {
      const newFormValues = _.merge({}, formDefaultValues, data);
      const newRequestValues = createNotiSettingsRequestValues(newFormValues);
      dispatch(updateNotiSettings.request(newRequestValues));
      setFirstrun(false);
    },
    [dispatch, formDefaultValues]
  );

  useEffect(() => {
    reset(formDefaultValues);
  }, [reset, formDefaultValues]);

  if (settingsState.fetchStatus === FetchStatus.Idle) {
    return null;
  }

  if (settingsState.fetchStatus === FetchStatus.Pending) {
    return <LoadingIndicator message="Loading..." />;
  }

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderTop: "none",
          width: "100%",
        }}
      >
        <SettingsFormRow>
          <Typography color="#fff" variant="body3" sx={{ marginBottom: 1 }}>
            Email notification for
          </Typography>
          {notiSettingsFields.map((setting, index) => (
            <SettingsLabel
              key={setting.id}
              control={
                <Controller
                  control={control}
                  name={`notiSettings[${index}].isActive`}
                  render={({ onChange, onBlur, value, ref }) => (
                    <Checkbox
                      inputRef={ref}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={setting.notificationName}
            />
          ))}
        </SettingsFormRow>
        <SettingsFormRow>
          <Typography color="#fff" variant="body3" sx={{ marginBottom: 1 }}>
            Notification by
          </Typography>
          <Controller
            control={control}
            name="receiveRange"
            render={({ value, ref, onBlur, onChange }) => (
              <RadioGroup name="receiveRange" value={value} onChange={onChange}>
                <SettingsLabel
                  control={
                    <Radio
                      inputRef={ref}
                      value={NotificationReceiveRange.OnMyActivity}
                      onBlur={onBlur}
                    />
                  }
                  label="My activity"
                />
                <SettingsLabel
                  control={
                    <Radio
                      inputRef={ref}
                      value={NotificationReceiveRange.OnAllActivity}
                      onBlur={onBlur}
                    />
                  }
                  label="All activity"
                />
              </RadioGroup>
            )}
          />
        </SettingsFormRow>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          "& > button": {
            padding: (theme) => theme.spacing(1, 2),
            width: 96,
            height: 36,
          },
        }}
      >
        {isSuccess && !formState.isDirty && (
          <DialogActionsMessage>Change saved</DialogActionsMessage>
        )}
        {hasError && (
          <DialogActionsMessage error={true}>
            Failed to save change. Please try again
          </DialogActionsMessage>
        )}
        <Button
          color="primary"
          onClick={onCancel}
          disabled={settingsState.loading}
        >
          <Typography variant="button">Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={settingsState.loading || !formState.isDirty}
        >
          <Typography variant="button">Confirm</Typography>
        </Button>
      </DialogActions>
    </form>
  );
}
