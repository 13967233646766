import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import { CancerTypeState } from "dux/cancerTypes/reducers";
import {
  APICancerTypeList,
  CancerListState,
  CancerTypesMgmtState,
} from "dux/cancerTypes/model";
import { FetchStatus } from "dux/utils/commonEnums";

const cancerTypeStateSelector: Selector<RootState, CancerTypeState> = (state) =>
  state.cancerType;

export const cancerListSelector: Selector<RootState, CancerListState> =
  createSelector(cancerTypeStateSelector, (cancerType) => cancerType.cancers);

export const cancerTotalSelector: Selector<RootState, APICancerTypeList> =
  createSelector(
    cancerTypeStateSelector,
    (cancerType) => cancerType.cancers.list
  );

export const cancerTypeMgmtStateSelector: Selector<
  RootState,
  CancerTypesMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = createSelector(cancerTypeStateSelector, (cancerType) => ({
  ...cancerType.cancerMgmtState,
  loading: cancerType.cancerMgmtState.fetchStatus === FetchStatus.Pending,
  success: cancerType.cancerMgmtState.fetchStatus === FetchStatus.Fulfilled,
}));
