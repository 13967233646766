import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  DeleteIcon,
  MoreIcon,
  MoveDownIcon,
  MoveLeftIcon,
  MoveRightIcon,
  MoveUpIcon,
  PanIcon,
  PointIcon,
  PolygonIcon,
  RedoIcon,
  UndoIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "assets/icons";
import { HeaderButtonMenuItem } from "components/common/HeaderButton";
import isMac from "utils/isMac";
import range from "lodash/range";

const CellClassTypography = styled(Typography)({
  width: 36,
  height: 28,
  textAlign: "center",
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 4,
});

export const ROI_POLYGON_ITEM: HeaderButtonMenuItem = {
  id: "polygon",
  icon: <PolygonIcon />,
  title: "Draw :roiType",
  shortcut: "R",
};

export const CELL_MENU_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "polygon",
    icon: <PolygonIcon />,
    title: "Select cell",
    shortcut: "Shift+R",
  },
  { id: "divider", title: "divider" },
  {
    id: "pointer",
    icon: <PointIcon />,
    title: "Add cell",
    shortcut: "Shift+A",
  },
];

export const CELL_CLASS_MENU_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "tp-",
    icon: (
      <CellClassTypography
        variant="button2"
        sx={{
          backgroundColor: "#1EFCFF",
          color: (theme) => theme.palette.darkGrey[100],
        }}
      >
        TP-
      </CellClassTypography>
    ),
    title: "PD-L1 Negative Tumor Cell",
    shortcut: "Shift+1",
  },
  {
    id: "tp+",
    icon: (
      <CellClassTypography
        variant="button2"
        sx={{
          backgroundColor: "#AD1EFF",
          color: (theme) => theme.palette.darkGrey[100],
        }}
      >
        TP+
      </CellClassTypography>
    ),
    title: "PD-L1 Positive Tumor Cell",
    shortcut: "Shift+2",
  },
];

export const POINTER_MENU_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "pointer",
    icon: <PointIcon />,
    title: "Point",
    shortcut: "V",
  },
  {
    id: "hand",
    icon: <PanIcon />,
    title: "Hand tool",
    shortcut: "H",
  },
];

export const UNDO_SHORTCUT = isMac() ? "Cmd+Z" : "Ctrl+Z";
export const REDO_SHORTCUT = isMac() ? "Cmd+Shift+Z" : "Ctrl+Shift+Z";

export const MISC_TOOL_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "undo",
    icon: <UndoIcon />,
    title: "Undo",
    shortcut: UNDO_SHORTCUT,
  },
  {
    id: "redo",
    icon: <RedoIcon />,
    title: "Redo",
    shortcut: REDO_SHORTCUT,
  },
  {
    id: "delete",
    icon: <DeleteIcon />,
    title: "Delete selected",
    shortcut: "Backspace",
  },
];

export const RESET_ITEM: HeaderButtonMenuItem = {
  id: "reset",
  icon: null,
  title: "Reset",
};

const ViewIconContainer = styled("div")({
  minWidth: 32,
  display: "flex",
  alignItems: "center",
});

export const PAN_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "up",
    icon: <MoveUpIcon />,
    title: "Slide move up",
    shortcut: "↑+W",
  },
  {
    id: "down",
    icon: <MoveDownIcon />,
    title: "Slide move down",
    shortcut: "↓+S",
  },
  {
    id: "left",
    icon: <MoveLeftIcon />,
    title: "Slide move left",
    shortcut: "←+A",
  },
  {
    id: "right",
    icon: <MoveRightIcon />,
    title: "Slide move right",
    shortcut: "→+D",
  },
];

const zoomLevelLabels = [0.1, 0.5, 1, 2, 5, 10, 20, 40, 160];

const getZoomLevelForShortcut = (shortcutKey: string) => {
  const pressedNum = Number(shortcutKey);
  if (pressedNum % 8) {
    return zoomLevelLabels[pressedNum % 8];
  }
  return -1;
};

export const ZOOM_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: "in",
    icon: (
      <ViewIconContainer>
        <ZoomInIcon />
      </ViewIconContainer>
    ),
    title: "Zoom in",
    shortcut: "=",
  },
  {
    id: "out",
    icon: (
      <ViewIconContainer>
        <ZoomOutIcon />
      </ViewIconContainer>
    ),
    title: "Zoom out",
    shortcut: "-",
  },
  ...range(7).map((val) => ({
    id: `zoom${val + 1}`,
    icon: (
      <ViewIconContainer>
        <Typography sx={{ marginLeft: "6.5px" }}>
          {`x${getZoomLevelForShortcut(`${val + 1}`)}`}
        </Typography>
      </ViewIconContainer>
    ),
    title: `Magnification ${getZoomLevelForShortcut(`${val + 1}`)}`,
    shortcut: `${val + 1}`,
  })),
];

export const TOGGLE_SHORTCUT_ITEM: HeaderButtonMenuItem = {
  id: "shortcut menu",
  icon: <MoreIcon />,
  title: "Toggle Shortcut menu",
  shortcut: "T",
};
