import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import store, { persistor } from "store/configureStore";
import { App } from "App";
import theme from "./theme";
import { styled } from "@mui/material";
import SlideTransition from "@mui/material/Slide";
import { DialogProvider } from "components/Dialog/context";
import Router from "components/Router";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

const SnackbarNotiProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
`;

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.NODE_ENV,
//   tracesSampleRate: 1.0,
//   normalizeDepth: 10,
// });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className="App" tabIndex={-1} style={{ outline: "none" }}>
            <DialogProvider>
              <Router>
                <SnackbarNotiProvider
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  TransitionComponent={SlideTransition}
                  hideIconVariant
                >
                  <CssBaseline />
                  <App />
                </SnackbarNotiProvider>
                <div id="dialog-root" />
                <div id="mui-dialog-root" />
              </Router>
            </DialogProvider>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
