import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import { SlideItem } from "dux/slide/model";
import { DateFormatter, SLIDE_DATE_FORMAT } from "utils/format";

interface SlideInfoProps {
  slide: SlideItem;
}

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

export default function SlideInfo({ slide }: SlideInfoProps) {
  const theme = useTheme();
  return (
    <Grid container spacing={1} columns={10}>
      <StyledGrid item xs={3} sx={{ alignItems: "flex-start" }}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Slide name
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{slide.name}</Typography>
      </Grid>
      <StyledGrid item xs={3} sx={{ alignItems: "flex-start" }}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          File name
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">
          {slide.fileName || slide.filePath?.split("/").pop()}
        </Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Scanner vendor
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{slide.properties.vendor}</Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Objective power
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">
          x{slide.properties.objectivePower}
        </Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          mpp-x
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{slide.properties.mppX} μm</Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          mpp-y
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{slide.properties.mppY} μm</Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Uploaded by
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{slide.uploadedBy}</Typography>
      </Grid>
      <StyledGrid item xs={3} sx={{ alignItems: "flex-start" }}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Uploaded at
        </Typography>
      </StyledGrid>
      <StyledGrid item xs={7}>
        <Typography variant="body5" sx={{ marginBottom: "24px" }}>
          {DateFormatter(slide.uploadedAt, SLIDE_DATE_FORMAT)}
        </Typography>
      </StyledGrid>
    </Grid>
  );
}
