import { useCallback, useRef } from "react";
import OpenSeadragon from "openseadragon";
import { MouseTrackerEvent } from "@lunit/osd-react-renderer/dist/types/types";

interface UseWheelButtonPanningProps {
  throttle?: number;
  viewer: OpenSeadragon.Viewer | undefined;
}

const WHEEL_BUTTON = 1;

export default function useWheelButtonPanning({
  throttle,
  viewer,
}: UseWheelButtonPanningProps) {
  const lastPoint = useRef<OpenSeadragon.Point>(null);
  const isPanning = !lastPoint;

  const prevDelta = useRef<OpenSeadragon.Point>(null);
  const prevTime = useRef<number>(-1);

  const cancelPanning = useCallback(() => {
    lastPoint.current = null;
    prevDelta.current = null;
    prevTime.current = -1;
  }, []);

  const onNonPrimaryPress = useCallback((e: MouseTrackerEvent) => {
    if (e.button === WHEEL_BUTTON) {
      lastPoint.current = e.position.clone();
      prevDelta.current = new OpenSeadragon.Point(0, 0);
      prevTime.current = 0;
    }
  }, []);

  const onNonPrimaryRelease = useCallback(
    (e: MouseTrackerEvent) => {
      if (e.button === WHEEL_BUTTON) {
        cancelPanning();
      }
    },
    [cancelPanning]
  );

  const onMove = useCallback(
    (e: MouseTrackerEvent) => {
      if (viewer && viewer.viewport) {
        if (lastPoint.current) {
          const deltaPixels = lastPoint.current.minus(e.position);
          const deltaPoints =
            viewer.viewport.deltaPointsFromPixels(deltaPixels);
          lastPoint.current = e.position.clone();
          if (!throttle || throttle < 0) {
            viewer.viewport.panBy(deltaPoints);
          } else {
            const newTimeDelta = Date.now() - prevTime.current;
            const newDelta = prevDelta.current.plus(deltaPoints);
            if (newTimeDelta > throttle) {
              viewer.viewport.panBy(newDelta);
              prevDelta.current = new OpenSeadragon.Point(0, 0);
              prevTime.current = 0;
            } else {
              prevDelta.current = newDelta;
              prevTime.current = newTimeDelta;
            }
          }
        }
      }
    },
    [viewer, throttle]
  );

  return {
    isPanning,
    cancelPanning,
    onNonPrimaryPress,
    onNonPrimaryRelease,
    onMove,
  };
}
