import React, { useEffect, useMemo, useState } from "react";
import { isArray, uniqBy } from "lodash";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import SlideContextMenu, {
  SlideContextMenuProps,
} from "components/common/SlideContextMenu";
import EllipsizedTypography from "components/EllipsizedTypography";
import { DateFormatter, DATETIME_FORMAT } from "utils/format";
import usePopper from "hooks/usePopper";
import SlideTagChip from "../common/SlideTagChip";
import SlideAutocomplete from "../common/SlideAutocomplete";

import { Project } from "dux/projects/model";
import { MoreIcon, StartIcon } from "assets/icons";
import { Tag } from "dux/tags/model";
import { Button, TextField } from "@mui/material";
import FallbackImage from "components/SlideList/common/FallbackImage";
import {
  IOResult,
  IoResultType,
  SlideItem,
  SlideStatus,
} from "dux/slide/model";
import { useDispatch, useSelector } from "react-redux";
import {
  requestSlideInferences,
  requestSlidesAnalyze,
  resetSlideMgmtState,
  updateSlideSummary,
} from "dux/slide/actions";
import { StyledIconButton } from "../SlideCard";
import { GridApi } from "@mui/x-data-grid";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";
import useTagsCRUD from "components/hooks/useTagsCRUD";
import { APICancerTypeList } from "dux/cancerTypes/model";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";
import {
  isAnalyzerUserSelector,
  isViewerUserSelector,
} from "dux/user/selectors";

interface ISlideNameCellProps {
  value: string;
  slide: SlideItem;
  handleSelectedList: () => void;
  handleOpenSlideViewer: (item: SlideItem) => void;
}

interface IIOResultCellProps {
  value: IOResult;
}
interface IChipCellProps {
  value: Tag[] | Project[];
  slide: SlideItem;
  api?: GridApi;
  totalTags?: Tag[];
  totalProjects?: Project[];
}

interface CancerTypeCellProps {
  value: string;
  slide: SlideItem;
  api?: GridApi;
  cancerTypes: APICancerTypeList;
  handleSelectedList: () => void;
  handleEditCancerType: (targetSlide: SlideItem[]) => void;
}

interface ISlideMenuProps {
  row: SlideItem;
  value: string;
  onEdit: SlideContextMenuProps["onEdit"];
  onDelete: SlideContextMenuProps["onDelete"];
  handleInfo: SlideContextMenuProps["handleInfo"];
}

const CellContainer = styled("span")({
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

const IOContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const TooltipContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "160px",
});

const SlideTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "0px",
    borderRadius: "8px",
  },
});

export const ChipTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "8px",
  },
});

const SlideName = styled("p")({
  width: "95px",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  margin: "6px 8px",
});

const SlideImage = styled(FallbackImage)({
  width: "95px",
  height: "auto",
  borderRadius: "8px 0 0 8px",
});

interface PlainButtonProps {
  disabled?: boolean;
}

const PlainButton = styled("a", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<PlainButtonProps>(({ disabled }) => ({
  display: "flex",
  alignItems: "center",
  cursor: disabled ? "default" : "pointer",
  width: "100%",
  height: "100%",
}));

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
});

const chipSizeSelector = (count: number): number | string => {
  if (count > 2) return 52;
  if (count > 1) return 61;
  return "auto";
};

export const SlideNameCell = React.memo(
  ({
    value,
    slide,
    handleOpenSlideViewer,
    handleSelectedList,
  }: ISlideNameCellProps) => {
    const dispatch = useDispatch();
    const isViewer = useSelector(isViewerUserSelector);
    return (
      <CellContainer>
        <SlideTooltip
          title={
            <TooltipContainer
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "124px",
                width: "190px",
              }}
            >
              <SlideImage
                src={slide.thumbnailPath}
                fallback="/images/empty-thumbnail.png"
              />
              <SlideName>{value}</SlideName>
            </TooltipContainer>
          }
          placement="right"
        >
          <span>
            <StyledIconButton
              disabled={
                (slide.status !== SlideStatus.Ready &&
                  slide.status !== SlideStatus.AnalFailed &&
                  slide.status !== SlideStatus.PreAnalFailed) ||
                isViewer
              }
              onClick={() => {
                handleSelectedList();
                slide.status === SlideStatus.PreAnalFailed
                  ? dispatch(requestSlideInferences.request([slide]))
                  : dispatch(
                      requestSlidesAnalyze.request({
                        sourceIds: [slide.id],
                      })
                    );
              }}
            >
              <StartIcon style={{ height: "17px", minWidth: "17px" }} />
            </StyledIconButton>
          </span>
        </SlideTooltip>
        <Tooltip title={"View slide"} key="view-slide" placement="right">
          <Button
            onClick={() => handleOpenSlideViewer(slide)}
            variant="text"
            sx={{
              marginLeft: "17px",
              height: "auto",
              padding: 1,
            }}
          >
            <EllipsizedTypography
              direction="row"
              variant="body4"
              color="darkGrey.10"
            >
              {value}
            </EllipsizedTypography>
          </Button>
        </Tooltip>
      </CellContainer>
    );
  }
);

export const IOResultCell = React.memo(({ value }: IIOResultCellProps) => {
  return (
    !!value.ioType && (
      <IOContainer>
        {value.ioType !== IoResultType.QCFailed && (
          <>
            <Typography
              variant="subtitle3"
              color={
                value.ioType === IoResultType.Positive ? "#EE5140" : "#7292FD"
              }
              sx={{
                whiteSpace: "pre",
              }}
            >
              {`${value.ioType} `}
            </Typography>
            <Typography variant="body1">{`${value.score}%`}</Typography>
          </>
        )}
        {value.ioType === IoResultType.QCFailed && (
          <Typography variant="body1">{IoResultType.QCFailed}</Typography>
        )}
      </IOContainer>
    )
  );
});

export const CancerType = React.memo(
  ({
    value,
    cancerTypes,
    slide,
    api,
    handleSelectedList,
    handleEditCancerType,
  }: CancerTypeCellProps) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const open = Boolean(anchorEl);
    const id = open ? "cancerType-popover" : undefined;
    const isViewer = useSelector(isViewerUserSelector);
    const selectedCancerType = useMemo(
      () =>
        cancerTypes.filter(
          (cancerType) => cancerType.name === slide.cancerType
        ),
      [cancerTypes, slide]
    );
    const removeCellFocus = () =>
      api.setState((state) => ({
        ...state,
        focus: {
          cell: null,
          columnHeader: null,
        },
      }));
    const handlePopoverOpen = (event) => {
      removeCellFocus();
      if (
        slide.status === SlideStatus.Analyzing ||
        slide.status === SlideStatus.PreAnalyzing
      )
        return;
      handleEditCancerType([slide]); // remove this line to roll back
      //setAnchorEl(event.currentTarget); // uncomment this line to roll back
      // removed popover because of design changes...
      // if we need to roll back to popover style, then follow above instructions
    };

    const handleClose = () => {
      setAnchorEl(null);
      dispatch(resetSlideMgmtState());
    };

    const onChange = (newValue: APICancerTypeList) => {
      if (!newValue || newValue.length < 1 || !newValue[0].name) return;
      removeCellFocus();
      handleSelectedList();
      dispatch(
        updateSlideSummary.request({
          id: slide.id,
          wsiId: slide.wsiId,
          name: slide.name,
          cancerType: newValue[0].name,
          tagIds: slide.tags,
          projectIds: slide.projects,
        })
      );
      handleClose();
    };

    const {
      requestCreateCancerType,
      requestDeleteCancerType,
      requestEditCancerType,
    } = useCancerTypeCRUD({
      onCreated: (cancerType) => {
        if (open) {
          dispatch(
            updateSlideSummary.request({
              wsiId: slide.wsiId,
              name: slide.name,
              tagIds: slide.tags,
              projectIds: slide.projects,
              cancerType: cancerType.name,
            })
          );
        }
      },
    });

    if (slide.status === SlideStatus.Analyzing || isViewer) {
      return (
        <EllipsizedTypography variant="body5" color="darkGrey.5">
          {value}
        </EllipsizedTypography>
      );
    }

    return (
      <>
        <CellContainer sx={{ cursor: "pointer" }} onClick={handlePopoverOpen}>
          <EllipsizedTypography variant="body5" color="darkGrey.5">
            {value}
          </EllipsizedTypography>
        </CellContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ width: "308px" }}>
            <SlideAutocomplete
              totalOptions={cancerTypes}
              selectedOption={selectedCancerType}
              onChangeValue={onChange}
              placeholder="Select a cancer type or create one"
              onCreateRequest={requestCreateCancerType}
              onDeleteRequest={requestDeleteCancerType}
              onEditRequest={requestEditCancerType}
              readonlyItems={cancerTypes?.filter(
                (item) => item.name === "Pan cancer"
              )}
              renderInput={(props) => (
                <TextField
                  {...props}
                  onFocus={(e) => e.stopPropagation()}
                  onBlur={(e) => console.log(e.nativeEvent.relatedTarget)}
                  autoFocus
                  variant="outlined"
                  placeholder={
                    isArray(props.InputProps.startAdornment) &&
                    props.InputProps.startAdornment.length > 0
                      ? ""
                      : "Search for an option"
                  }
                  sx={{
                    borderRadius: 0,
                    "& .MuiInputBase-root": {
                      padding: (theme) => theme.spacing(1, 2),
                      flexFlow: "row wrap",
                      borderRadius: 0,
                    },
                    "& .MuiInputBase-input": {
                      flexBasis: "50px",
                      flexGrow: 1,
                      flexShrink: 0,
                    },
                  }}
                />
              )}
              single
            />
          </Box>
        </Popover>
      </>
    );
  }
);

export const ProjectChips = React.memo(
  ({ value, totalProjects, slide, api }: IChipCellProps) => {
    const [mappedProjects, setMappedProjects] = useState<Project[]>([]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const isAnalyzer = useSelector(isAnalyzerUserSelector);
    const isViewer = useSelector(isViewerUserSelector);
    const open = Boolean(anchorEl);
    const id = open ? "project-popover" : undefined;
    const { requestCreateProject, requestDeleteProject, requestEditProject } =
      useProjectsCRUD({
        onCreated: (project) => {
          if (open) {
            dispatch(
              updateSlideSummary.request({
                wsiId: slide.wsiId,
                name: slide.name,
                tagIds: slide.tags,
                projectIds: mappedProjects
                  .concat(project)
                  .map((project) => project.id),
              })
            );
          }
        },
      });
    const removeCellFocus = () =>
      api.setState((state) => ({
        ...state,
        focus: {
          cell: null,
          columnHeader: null,
        },
      }));
    const handlePopoverOpen = (event) => {
      removeCellFocus();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      dispatch(resetSlideMgmtState());
    };

    useEffect(() => {
      setMappedProjects(
        uniqBy(
          [
            ...totalProjects.filter((val) =>
              value.some((innerVal) => innerVal === val.id)
            ),
          ],
          "id"
        )
      );
    }, [value, totalProjects]);

    const onChange = (value: Project[]) => {
      removeCellFocus();
      dispatch(
        updateSlideSummary.request({
          wsiId: slide.wsiId,
          name: slide.name,
          tagIds: slide.tags,
          projectIds: value.map((project) => project.id),
        })
      );
    };

    return (
      <>
        <ChipTooltip
          title={
            <TooltipContainer>
              <Typography variant="body5">
                This task is also part of:
              </Typography>
              {mappedProjects.map((project, index) => (
                <Typography key={`projects-${index}`} variant="body5">
                  {`• ${project.title}`}
                </Typography>
              ))}
              <Typography
                variant="body5"
                color="#AFAFB1"
                sx={{ marginTop: "4px" }}
              >
                Click to add or edit this
              </Typography>
            </TooltipContainer>
          }
        >
          <CellContainer>
            <PlainButton
              disabled={isViewer}
              onClick={!isViewer ? handlePopoverOpen : undefined}
            >
              {mappedProjects.slice(0, 2).map((value, index) => (
                <SlideTagChip
                  disabled={isViewer}
                  key={`projects-${index}`}
                  label={value.title}
                  sx={{ width: `${chipSizeSelector(mappedProjects.length)}px` }}
                />
              ))}
              {mappedProjects.length > 2 && <Typography>...</Typography>}
            </PlainButton>
          </CellContainer>
        </ChipTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ width: "308px" }}>
            <SlideAutocomplete
              totalOptions={totalProjects}
              selectedOption={mappedProjects}
              onChangeValue={onChange}
              placeholder={
                isAnalyzer
                  ? "Select a project"
                  : "Select a project or create one"
              }
              onCreateRequest={!isAnalyzer ? requestCreateProject : undefined}
              onDeleteRequest={!isAnalyzer ? requestDeleteProject : undefined}
              onEditRequest={!isAnalyzer ? requestEditProject : undefined}
              renderInput={(props) => (
                <TextField
                  {...props}
                  onFocus={(e) => e.stopPropagation()}
                  onBlur={(e) => console.log(e.nativeEvent.relatedTarget)}
                  autoFocus
                  variant="outlined"
                  placeholder={
                    isArray(props.InputProps.startAdornment) &&
                    props.InputProps.startAdornment.length > 0
                      ? ""
                      : "Search for an option"
                  }
                  sx={{
                    borderRadius: 0,
                    "& .MuiInputBase-root": {
                      padding: (theme) => theme.spacing(1, 2),
                      flexFlow: "row wrap",
                      borderRadius: 0,
                    },
                    "& .MuiInputBase-input": {
                      flexBasis: "50px",
                      flexGrow: 1,
                      flexShrink: 0,
                    },
                  }}
                />
              )}
            />
          </Box>
        </Popover>
      </>
    );
  }
);

export const TagChips = React.memo(
  ({ value, totalTags, slide, api }: IChipCellProps) => {
    const [mappedTags, setMappedTags] = useState<Tag[]>([]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const isViewer = useSelector(isViewerUserSelector);
    const open = Boolean(anchorEl);
    const id = open ? "tag-popover" : undefined;
    const { requestCreateTag, requestDeleteTag, requestEditTag } = useTagsCRUD({
      onCreated: (tag) => {
        if (open) {
          dispatch(
            updateSlideSummary.request({
              wsiId: slide.wsiId,
              name: slide.name,
              tagIds: mappedTags.concat(tag).map((tag) => tag.id),
              projectIds: slide.projects,
            })
          );
        }
      },
    });
    const removeCellFocus = () =>
      api.setState((state) => ({
        ...state,
        focus: {
          cell: null,
          columnHeader: null,
        },
      }));

    const handlePopoverOpen = (event) => {
      removeCellFocus();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      dispatch(resetSlideMgmtState());
    };

    useEffect(() => {
      setMappedTags(
        uniqBy(
          [
            ...totalTags.filter((val) =>
              value.some((innerVal) => innerVal === val.id)
            ),
          ],
          "id"
        )
      );
    }, [value, totalTags]);

    const onChange = (value: Tag[]) => {
      removeCellFocus();
      dispatch(
        updateSlideSummary.request({
          wsiId: slide.wsiId,
          name: slide.name,
          tagIds: value.map((tag) => tag.id),
          projectIds: slide.projects,
        })
      );
    };

    return (
      <>
        <ChipTooltip
          title={
            <TooltipContainer>
              <Typography variant="body5">
                This task is also part of:
              </Typography>
              {mappedTags.map((tag, index) => (
                <Typography key={`tag-${index}`} variant="body5">
                  {`• ${tag.title}`}
                </Typography>
              ))}
              <Typography
                variant="body5"
                color="#AFAFB1"
                sx={{ marginTop: "4px" }}
              >
                Click to add or edit this
              </Typography>
            </TooltipContainer>
          }
        >
          <CellContainer>
            <PlainButton
              disabled={isViewer}
              onClick={!isViewer ? handlePopoverOpen : undefined}
            >
              {mappedTags.slice(0, 2).map((value, index) => (
                <SlideTagChip
                  disabled={isViewer}
                  key={`tags-${index}`}
                  label={value.title}
                  sx={{ width: `${chipSizeSelector(mappedTags.length)}px` }}
                />
              ))}
              {mappedTags.length > 2 && <Typography>...</Typography>}
            </PlainButton>
          </CellContainer>
        </ChipTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ width: "308px" }}>
            <SlideAutocomplete
              totalOptions={totalTags}
              selectedOption={mappedTags}
              onChangeValue={onChange}
              placeholder="Select a tag or create one"
              onCreateRequest={requestCreateTag}
              onDeleteRequest={requestDeleteTag}
              onEditRequest={requestEditTag}
              renderInput={(props) => (
                <TextField
                  {...props}
                  onFocus={(e) => e.stopPropagation()}
                  autoFocus
                  variant="outlined"
                  placeholder={
                    isArray(props.InputProps.startAdornment) &&
                    props.InputProps.startAdornment.length > 0
                      ? ""
                      : "Search for an option"
                  }
                  sx={{
                    borderRadius: 0,
                    "& .MuiInputBase-root": {
                      padding: (theme) => theme.spacing(1, 2),
                      flexFlow: "row wrap",
                      borderRadius: 0,
                    },
                    "& .MuiInputBase-input": {
                      flexBasis: "50px",
                      flexGrow: 1,
                      flexShrink: 0,
                    },
                  }}
                />
              )}
            />
          </Box>
        </Popover>
      </>
    );
  }
);

export const SlideMenu = React.memo(
  ({ row, value, onEdit, onDelete, handleInfo }: ISlideMenuProps) => {
    const menuPopper = usePopper();
    return (
      <>
        <Typography variant="body6" color="darkGrey.30">
          {DateFormatter(value, DATETIME_FORMAT)}
        </Typography>
        <IconButton
          aria-label="Ellipsis Button"
          aria-expanded={menuPopper.open ? "true" : undefined}
          onClick={menuPopper.onOpen}
          className="hover-shown"
          sx={{
            position: "absolute",
            right: 4,
            '&:hover, &.Mui-focused, &[aria-expanded*="true"]': {
              backgroundColor: (theme) => theme.palette.background.selected,
              color: "#fff",
            },
          }}
        >
          <MoreIconComponent className="MoreIcon" />
        </IconButton>
        <SlideContextMenu
          anchorEl={menuPopper.anchorEl}
          open={menuPopper.open}
          handleInfo={handleInfo}
          onClose={menuPopper.onClose}
          onDelete={onDelete}
          onEdit={onEdit}
          slide={row}
        />
      </>
    );
  }
);
