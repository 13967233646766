import { Chip, styled, Theme } from "@mui/material";
import { SlideStatus } from "dux/slide/model";
import { ErrorIcon } from "assets/icons";

export const GetChipProps = (status: SlideStatus, theme: Theme) => {
  const palette = theme.palette.slideStatus;
  if (status === SlideStatus.AnalFailed || status === SlideStatus.PreAnalFailed)
    return {
      sx: {
        "& .MuiChip-label": {
          paddingRight: "1px",
        },
        "& .MuiChip-icon": {
          order: 1,
          marginRight: "12px",
        },
      },
      icon: <ErrorIcon style={{ color: palette.failed.contrastText }} />,
    };
  return {
    sx: {
      cursor: "default",
    },
  };
};

const SlideTagChip = styled(Chip)(({ theme }) => ({
  height: "22px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  marginRight: "8px",
  maxWidth: "139px",
  whiteSpace: "pre-wrap",
  cursor: "pointer",
  "& span": {
    whiteSpace: "pre-wrap",
    lineBreak: "anywhere",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  "&.MuiChip-colorDefault": {
    color: theme.palette.darkGrey[15],
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
}));

export default SlideTagChip;
