import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import NotificationListItem from "./NotificationListItem";
import { NotificationMessage } from "hooks/useStompClient";
import map from "lodash/map";
import React from "react";

interface NotificationListProps {
  messages: NotificationMessage[];
  handleRemoveMessage: (NotificationMessage) => void;
}

const NotificationList = ({
  messages,
  handleRemoveMessage,
}: NotificationListProps) => {
  return (
    <List
      disablePadding
      sx={{
        flex: 1,
        padding: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {map(messages, (message, index) => (
        <React.Fragment key={`${message.receivedAt}_${index}`}>
          <NotificationListItem
            events={message.eventData}
            onRemove={() => {
              handleRemoveMessage(message);
            }}
          />
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default NotificationList;
