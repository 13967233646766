import PageTemplate from "components/common/PageTemplate";
import UserList from "components/UserList/UserList";
import withAdminGuard from "hoc/withAdminGuard";

const Users = () => {
  return (
    <PageTemplate>
      <UserList />
    </PageTemplate>
  );
};

export default withAdminGuard(Users);
