import React from "react";
import ReactDOM from "react-dom";

interface PageTemplateProps {
  children: React.ReactNode;
  toolbar?: React.ReactNode;
}

function Toolbar({ children }) {
  const toolbarEl = document.getElementById("toolbar-container");
  return ReactDOM.createPortal(children, toolbarEl, "toolbar");
}

export default function PageTemplate({ children, toolbar }: PageTemplateProps) {
  return (
    <>
      <Toolbar>{toolbar}</Toolbar>
      {children}
    </>
  );
}
