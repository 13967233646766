import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import {
  getAccountProfileSelector,
  getSettingsStateSelector,
} from "dux/user/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "dux/user/actions";
import DialogActionsMessage from "components/Dialog/DialogActionsMessage";
import { FetchStatus } from "dux/utils/commonEnums";

interface ProfileFormProps {
  onCancel: () => void;
}

const SettingsFormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:not(:first-of-type)": {
    marginTop: "inherit",
  },
}));

export default function ProfileForm({ onCancel }: ProfileFormProps) {
  const dispatch = useDispatch();

  const profile = useSelector(getAccountProfileSelector);
  const settingsState = useSelector(getSettingsStateSelector);

  // SettingsDailog의 Form별로 Idle 상태를 분리 (settingsState.fetchStatus는 모든 Form에서 공유됨)
  const [firstrun, setFirstrun] = useState<boolean>(true);

  const isSuccess =
    !firstrun && settingsState.fetchStatus === FetchStatus.Fulfilled;
  const hasError =
    !firstrun && settingsState.fetchStatus === FetchStatus.Rejected;

  const { register, handleSubmit, formState, reset, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = useCallback(
    (data) => {
      dispatch(updateProfile.request(data));
      setFirstrun(false);
    },
    [dispatch]
  );

  useEffect(() => {
    reset({ ...profile });
  }, [reset, profile]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderTop: "none",
          width: "100%",
        }}
      >
        <SettingsFormRow>
          <InputLabel htmlFor="email">
            <Typography color="#fff" variant="body3">
              User ID
            </Typography>
          </InputLabel>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            disabled
            defaultValue={profile.email}
            type="email"
          />
        </SettingsFormRow>
        <SettingsFormRow>
          <InputLabel htmlFor="name" required>
            <Typography color="#fff" variant="body3">
              User name
            </Typography>
          </InputLabel>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            defaultValue={profile.name}
            inputRef={register({
              required: "Please fillout this field",
              maxLength: {
                value: 50,
                message: "Maximun length cannot exceed 50",
              },
            })}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
        </SettingsFormRow>
        <SettingsFormRow>
          <InputLabel htmlFor="phoneNumber">
            <Typography color="#fff" variant="body3">
              Telephone
            </Typography>
          </InputLabel>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            variant="outlined"
            defaultValue={profile.phoneNumber}
            inputRef={register({
              pattern: {
                value: new RegExp(
                  "^\\+?(\\d{1,3}-)?\\d{2,4}-\\d{3,4}(-\\d{4})?$"
                ),
                message:
                  "Phone numbers should be in the format of ###-####-#### or ###-####.",
              },
            })}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber && errors.phoneNumber.message}
          />
        </SettingsFormRow>
        <SettingsFormRow>
          <InputLabel htmlFor="customerCode">
            <Typography color="#fff" variant="body3">
              Customer code
            </Typography>
          </InputLabel>
          <TextField
            id="customerCode"
            name="customerCode"
            variant="outlined"
            disabled
            defaultValue={profile.customerCode}
          />
        </SettingsFormRow>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          "& > button": {
            padding: (theme) => theme.spacing(1, 2),
            width: 96,
            height: 36,
          },
        }}
      >
        {isSuccess && <DialogActionsMessage>Change saved</DialogActionsMessage>}
        {hasError && (
          <DialogActionsMessage error={true}>
            Failed to save change. Please try again
          </DialogActionsMessage>
        )}
        <Button
          color="scope1"
          onClick={onCancel}
          disabled={settingsState.loading}
        >
          <Typography variant="button">Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="scope2"
          type="submit"
          disabled={settingsState.loading || !formState.isDirty}
        >
          <Typography variant="button">Confirm</Typography>
        </Button>
      </DialogActions>
    </form>
  );
}
