import React, { useEffect, useState } from "react";
import { GridApi } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { Project } from "dux/projects/model";
import { Tag } from "dux/tags/model";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";
import { isArray, uniqBy } from "lodash";
import {
  ChipTooltip,
  TooltipContainer,
} from "components/SlideList/component/Columns";
import { Box, Popover, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SlideTagChip from "components/SlideList/common/SlideTagChip";
import SlideAutocomplete from "components/SlideList/common/SlideAutocomplete";
import { updateUserProjectMappings } from "dux/user/actions";

interface IChipCellProps {
  value: Tag[] | Project[];
  userId: string;
  api?: GridApi;
  totalTags?: Tag[];
  totalProjects?: Project[];
}

const CellContainer = styled("span")({
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

const PlainButton = styled("a")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  height: "100%",
}));

const chipSizeSelector = (count: number): number | string => {
  if (count > 2) return 52;
  if (count > 1) return 61;
  return "auto";
};

export const ProjectChips = React.memo(
  ({ value, totalProjects, userId, api }: IChipCellProps) => {
    const [mappedProjects, setMappedProjects] = useState<Project[]>([]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const open = Boolean(anchorEl);
    const id = open ? "project-popover" : undefined;
    const { requestCreateProject, requestDeleteProject, requestEditProject } =
      useProjectsCRUD({
        onCreated: (project) => {
          if (open) {
            // update user project mapping
            dispatch(
              updateUserProjectMappings.request({
                userId,
                projectIds: mappedProjects
                  .concat(project)
                  .map((project) => project.id),
              })
            );
          }
        },
      });
    const removeCellFocus = () =>
      api.setState((state) => ({
        ...state,
        focus: {
          cell: null,
          columnHeader: null,
        },
      }));
    const handlePopoverOpen = (event) => {
      removeCellFocus();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setMappedProjects(
        uniqBy(
          [
            ...totalProjects.filter((val) =>
              value.some((innerVal) => innerVal === val.id)
            ),
          ],
          "id"
        )
      );
    }, [value, totalProjects]);

    const onChange = (value: Project[]) => {
      removeCellFocus();
      // update user project mapping
      dispatch(
        updateUserProjectMappings.request({
          userId,
          projectIds: value.map((project) => project.id),
        })
      );
    };

    return (
      <>
        <ChipTooltip
          title={
            <TooltipContainer>
              <Typography variant="body5">This user has access to:</Typography>
              {mappedProjects.map((project, index) => (
                <Typography key={`projects-${index}`} variant="body5">
                  {`• ${project.title}`}
                </Typography>
              ))}
              <Typography
                variant="body5"
                color="#AFAFB1"
                sx={{ marginTop: "4px" }}
              >
                Click to add or edit this
              </Typography>
            </TooltipContainer>
          }
        >
          <CellContainer>
            <PlainButton onClick={handlePopoverOpen}>
              {mappedProjects.slice(0, 2).map((value, index) => (
                <SlideTagChip
                  key={`projects-${index}`}
                  label={value.title}
                  sx={{ width: `${chipSizeSelector(mappedProjects.length)}px` }}
                />
              ))}
              {mappedProjects.length > 2 && <Typography>...</Typography>}
            </PlainButton>
          </CellContainer>
        </ChipTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ width: "308px" }}>
            <SlideAutocomplete
              totalOptions={totalProjects}
              selectedOption={mappedProjects}
              onChangeValue={onChange}
              placeholder="Select a project or create one"
              onCreateRequest={requestCreateProject}
              onDeleteRequest={requestDeleteProject}
              onEditRequest={requestEditProject}
              renderInput={(props) => (
                <TextField
                  {...props}
                  onFocus={(e) => e.stopPropagation()}
                  onBlur={(e) => console.log(e.nativeEvent.relatedTarget)}
                  autoFocus
                  variant="outlined"
                  placeholder={
                    isArray(props.InputProps.startAdornment) &&
                    props.InputProps.startAdornment.length > 0
                      ? ""
                      : "Search for an option"
                  }
                  sx={{
                    borderRadius: 0,
                    "& .MuiInputBase-root": {
                      padding: (theme) => theme.spacing(1, 2),
                      flexFlow: "row wrap",
                      borderRadius: 0,
                    },
                    "& .MuiInputBase-input": {
                      flexBasis: "50px",
                      flexGrow: 1,
                      flexShrink: 0,
                    },
                  }}
                />
              )}
            />
          </Box>
        </Popover>
      </>
    );
  }
);
