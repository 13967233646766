import { Divider, ListSubheader } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import Select from "../common/Select";
import SelectItem from "components/common/SelectItem";

const MenuProps = {
  PaperProps: {
    style: {
      width: 280,
    },
  },
};

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

interface SortFieldProps {
  field: string;
  fields: string[];
  labels: string[];
  onChange?: (value: [string, SortOrder]) => void;
  order: SortOrder;
}

export default function SortField({
  field: selectedField,
  fields,
  labels,
  onChange,
  order: selectedOrder,
}: SortFieldProps) {
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const newValue: [string, SortOrder] = fields.includes(value)
      ? [value, selectedOrder]
      : [selectedField, value as SortOrder];
    onChange?.(newValue);
  };

  return (
    <FormControl sx={{ width: 160 }}>
      <Select
        value={selectedField}
        displayEmpty
        onChange={handleChange}
        MenuProps={MenuProps}
        inputProps={{
          "aria-label": "sort",
        }}
      >
        <ListSubheader>Sort by</ListSubheader>
        {fields.map((name, index) => (
          <SelectItem key={name} value={name}>
            {labels[index]}
          </SelectItem>
        ))}
        <Divider sx={{ marginBottom: "14px!important" }} />
        <ListSubheader>Order</ListSubheader>
        <SelectItem
          value={SortOrder.Ascending}
          checked={SortOrder.Ascending === selectedOrder}
        >
          Ascending
        </SelectItem>
        <SelectItem
          value={SortOrder.Descending}
          checked={SortOrder.Descending === selectedOrder}
        >
          Descending
        </SelectItem>
      </Select>
    </FormControl>
  );
}
