import { findIndex, range, reduce, toLower, trim } from "lodash";
import { FileWithPath } from "react-dropzone";

export async function validateMRXSDataFiles(
  files: FileWithPath[]
): Promise<boolean> {
  const dirName = files[0].name.split(".")[0];
  const slidedatIdx = findIndex(files, (file) =>
    toLower(file.name).includes("slidedat.ini")
  );
  if (slidedatIdx < 0) return false;
  const slidedatFile = files[slidedatIdx];
  try {
    const slidedatFileText = await readFileAsText(slidedatFile);
    const lines = slidedatFileText.split("\n");
    const datafileSectionIndex = findIndex(lines, (line) =>
      line.includes("[DATAFILE]")
    );
    if (datafileSectionIndex < 0) return false;
    const fileCount = parseInt(
      trim(lines[datafileSectionIndex + 1].split("=")[1])
    );
    if (isNaN(fileCount)) return false;
    const filenames = files.map((file) =>
      file.name.includes(".mrxs") ? file.name : `${dirName}/${file.name}`
    );
    return reduce(
      range(fileCount),
      (ret, fileIdx) => {
        const dataFileName = trim(
          lines[datafileSectionIndex + fileIdx + 2].split("=")[1]
        );
        return (
          ret &&
          findIndex(filenames, (filename) => filename.includes(dataFileName)) >=
            0
        );
      },
      true
    );
  } catch {
    return false;
  }
}

function readFileAsText(file: FileWithPath): Promise<string> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (e) => {
      console.log(e);
      reject(e);
    };

    reader.readAsText(file, "UTF-8");
  });
}
