import { Typography } from "@mui/material";
import { DialogContext } from "components/Dialog/context";
import useSheets from "components/hooks/useSheets";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { abortAllWSIUpload } from "./dux/actions";
import {
  getAnyWSIUploading,
  getNumUploadedWSISelector,
  getWSIKeyOrderSelector,
} from "./dux/selectors";
import FileProgressSheet from "./FileProgressSheet";

export default function UploadingSheetManager() {
  const dispatch = useDispatch();
  const wsiKeyOrder = useSelector(getWSIKeyOrderSelector);
  const numUploadedWSI = useSelector(getNumUploadedWSISelector);
  const { putSheet, removeSheet } = useSheets();
  const { handleDialog } = useContext(DialogContext);
  const wsiUploading = useSelector(getAnyWSIUploading);

  useEffect(() => {
    function onBeforeUnload(e) {
      e.preventDefault();
      e.returnValue =
        "File upload in progress. Are you sure you want to cancel and leave?";
      return "File upload in progress. Are you sure you want to cancel and leave?";
    }
    if (wsiUploading) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, [wsiUploading]);

  useEffect(() => {
    if (wsiKeyOrder.length > 0) {
      putSheet({
        key: "uploading",
        title: "Uploading WSI file",
        subtitle: `${numUploadedWSI}/${wsiKeyOrder.length}`,
        paperStyle: {
          width: 500,
        },
        content: <FileProgressSheet />,
        onClose: () => {
          if (numUploadedWSI === wsiKeyOrder.length) {
            dispatch(abortAllWSIUpload());
            return true;
          } else {
            handleDialog({
              width: 320,
              agree: "Cancel upload",
              disagree: "Continue upload",
              content: (
                <>
                  <Typography variant="subtitle3" sx={{ marginBottom: 1 }}>
                    Cancel Upload?
                  </Typography>
                  <Typography variant="body5" sx={{ marginBottom: 1 }}>
                    Your upload is not complete. Would you like to cancel the
                    upload?
                  </Typography>
                </>
              ),
              handleAgreementCallback: () => {
                dispatch(abortAllWSIUpload());
              },
              disableBackdropClick: true,
            });
          }
          return false;
        },
      });
    } else {
      removeSheet("uploading");
    }
  }, [
    wsiKeyOrder,
    numUploadedWSI,
    dispatch,
    handleDialog,
    putSheet,
    removeSheet,
  ]);

  return null;
}
