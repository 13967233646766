import { InputLabel, styled, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { Controller, useForm } from "react-hook-form";
import FormTemplate, { CommonFormProps, FormMode } from "./Template";
import { ProjectFormValues } from "dux/projects/model";
import { DATE_FORMAT } from "utils/format";

interface ProjectFormProps extends CommonFormProps {
  mode: FormMode;
  defaultValues?: ProjectFormValues | null;
}

const ProjectFormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:last-child": {
    display: "flex",
    marginBottom: theme.spacing(0),
  },
}));

const DateRangeRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  "&:first-of-type": {
    marginRight: theme.spacing(2.5),
  },
  "& > *": {
    flex: 1,
  },
}));

function ProjectForm({
  onCancel,
  onSubmit,
  open,
  mode,
  defaultValues,
  loading,
}: ProjectFormProps) {
  const { register, control, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  return (
    <FormTemplate
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      loading={loading}
      title={`${mode} Project`}
    >
      <ProjectFormRow>
        <InputLabel htmlFor="title" required>
          <Typography variant="body3" color="darkGrey.5">
            Title
          </Typography>
        </InputLabel>
        <TextField
          id="title"
          name="title"
          placeholder="Project title"
          inputRef={register({
            required: "This field is required",
            maxLength: {
              value: 200,
              message: "Please type less than 200 characters",
            },
          })}
          defaultValue={defaultValues && defaultValues.title}
          error={!!errors.title}
          helperText={errors.title && errors.title.message}
          variant="outlined"
          InputProps={{
            "aria-label": "title",
          }}
        />
      </ProjectFormRow>
      <ProjectFormRow>
        <InputLabel htmlFor="description">
          <Typography variant="body3" color="darkGrey.5">
            Description
          </Typography>
        </InputLabel>
        <TextField
          id="description"
          name="description"
          placeholder="Max 1000 characters"
          inputRef={register({
            maxLength: {
              value: 1000,
              message: "Please type less than 1000 characters",
            },
          })}
          defaultValue={defaultValues && defaultValues.description}
          variant="outlined"
          multiline
          rows={5}
          InputProps={{
            size: "medium",
            "aria-label": "description",
          }}
          error={!!errors.description}
          helperText={errors.description && errors.description.message}
        />
      </ProjectFormRow>
      <ProjectFormRow>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div>
            <Controller
              name="dateRange"
              control={control}
              defaultValue={defaultValues?.dateRange}
              render={({ onChange, value }) => (
                <DateRangePicker
                  calendars={1}
                  value={value ?? [null, null]}
                  onChange={onChange}
                  startText=""
                  endText=""
                  PopperProps={{
                    placement: "bottom-start",
                  }}
                  inputFormat={DATE_FORMAT}
                  renderInput={(startProps, endProps) => (
                    <>
                      <DateRangeRow>
                        <InputLabel htmlFor="fromDate">
                          <Typography variant="body3" color="darkGrey.5">
                            Date Range (From)
                          </Typography>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          {...startProps}
                          InputProps={{
                            "aria-label": "fromDate",
                          }}
                        />
                      </DateRangeRow>
                      <DateRangeRow>
                        <InputLabel htmlFor="toDate">
                          <Typography variant="body3" color="darkGrey.5">
                            Date Range (To)
                          </Typography>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          {...endProps}
                          InputProps={{
                            "aria-label": "toDate",
                          }}
                        />
                      </DateRangeRow>
                    </>
                  )}
                />
              )}
            ></Controller>
          </div>
        </LocalizationProvider>
        {/* {(errors.fromDate || errors.toDate) && (
          <Typography
            sx={{
              fontSize: "0.75rem",
              position: "absolute",
              top: "calc(100% + 3px)",
              fontWeight: 400,
              lineHeight: 1.66,
            }}
            color="error"
          >
            {errors.fromDate ? errors.fromDate.message : errors.toDate.message}
          </Typography>
        )} */}
      </ProjectFormRow>
    </FormTemplate>
  );
}

export default ProjectForm;
