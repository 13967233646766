import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";

export default function asyncComponent(importComponent) {
  return function (props) {
    const [{ Component }, setComponent] = useState({ Component: null });
    const { onImportFail, ...otherProps } = props;

    useEffect(() => {
      async function fetchComponent() {
        try {
          const { default: Component } = await importComponent();
          setComponent(() => ({ Component }));
        } catch (e) {
          console.log(e.message);
          onImportFail();
        }
      }
      fetchComponent();
    }, [setComponent, onImportFail]);

    return isNil(Component) ? null : <Component {...otherProps} />;
  };
}
