import { useDispatch, useSelector } from "react-redux";
import { TabPanel, TabPanelProps } from "@mui/lab";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useTheme from "@mui/material/styles/useTheme";
import IconButton from "@mui/material/IconButton";
import { MoreIcon } from "assets/icons";
import Typography from "@mui/material/Typography";
import { SectionContainer, SectionHeader, SectionContent } from "./common";
import SlideTagChip, {
  GetChipProps,
} from "components/SlideList/common/SlideTagChip";
import {
  apiSlideStatusToEnum,
  slideStatusColorProps,
  slideStatusLabels,
} from "components/SlideList/common/literals";
import { DetailsSlideInformation } from "dux/analysis/model";
import map from "lodash/map";
import { slideViewerListParamsSelector } from "dux/slide/selectors";
import { useCallback } from "react";
import useSlideContextMenu from "components/hooks/useSlideContextMenu";
import { useHistory, useLocation } from "react-router-dom";
import { getAnalysisSourceDetailsById } from "dux/analysis/actions";
import { wsiIdSelector } from "dux/analysis/selectors";
import { SlideStatus } from "dux/slide/model";

interface InformationPanelProps extends TabPanelProps {
  slideId: string;
  slide?: DetailsSlideInformation;
}

function InformationPanel(props: InformationPanelProps) {
  const theme = useTheme();
  const { slide, slideId, ...tabPanelProps } = props;
  const slideStatus = apiSlideStatusToEnum(slide && slide.status);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedSlides = useSelector(slideViewerListParamsSelector);
  const wsiId = useSelector(wsiIdSelector);
  const currentSlide =
    (!!slide && {
      ...slide,
      wsiId,
      id: slideId,
      status: slideStatus,
      fileName: slide.fileName,
      uploadedBy: slide.uploadedBy,
      uploadedAt: slide.uploadedAt,
      projects: map(slide.projectInfos, (projectInfo) => projectInfo.id),
      tags: map(slide.tagInfos, (tagInfo) => tagInfo.id),
    }) ||
    selectedSlides.filter((slideItem) => slideItem.id === slideId)[0];

  const onSlideDelete = useCallback(() => {
    const pathname = location.pathname;
    const nextPath = pathname.split("/").slice(0, -1).join("/");
    history.push(nextPath);
  }, [location, history]);

  const onSlideEdit = useCallback(() => {
    dispatch(getAnalysisSourceDetailsById.request(slideId));
  }, [dispatch, slideId]);

  const { renderContextMenu, renderForm, onMenuOpen } = useSlideContextMenu({
    slide: currentSlide,
    onSlideDelete,
    onSlideEdit,
  });

  return (
    <TabPanel
      {...tabPanelProps}
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <SectionContainer
          sx={{
            flex: "0 0 156px",
            maxHeight: 156,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <SectionHeader>
            {slide && (
              <SlideTagChip
                label={slideStatusLabels(slideStatus)}
                {...GetChipProps(slideStatus, theme)}
                color={slideStatusColorProps(slideStatus)}
                sx={{
                  marginRight: "auto",
                }}
              />
            )}

            <IconButton
              sx={{
                padding: 0.5,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.darkGrey[80],
                },
              }}
              onClick={(e) => {
                onMenuOpen(e);
              }}
            >
              <MoreIcon />
            </IconButton>
          </SectionHeader>
          <SectionContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <SlideTagChip label={slide?.cancerType} />
              </div>
              {slideStatus === SlideStatus.Analyzed && (
                <Typography
                  variant="body5"
                  sx={{
                    marginTop: 0.5,
                    wordBreak: "break-word",
                  }}
                >
                  Tissue Quality{" "}
                  {slide?.qualityControl ? (
                    <span style={{ color: "#4ECB71" }}>Success</span>
                  ) : (
                    <span style={{ color: "#CB4E4E" }}>Failure</span>
                  )}
                </Typography>
              )}
              <Typography
                variant="body5"
                sx={{
                  marginTop: 2,
                  wordBreak: "break-word",
                }}
              >
                {slide?.name}
              </Typography>
            </Box>
          </SectionContent>
        </SectionContainer>
        <Divider />
        <SectionContainer
          sx={{
            flex: "1",
            overflowY: "auto",
          }}
        >
          <SectionHeader>
            <Typography variant="body3">Project List</Typography>
          </SectionHeader>
          <SectionContent>
            {!!slide &&
              map(slide.projectInfos, (projectInfo) => (
                <SlideTagChip
                  sx={{ marginBottom: "12px" }}
                  key={projectInfo.id}
                  label={projectInfo.title}
                />
              ))}
          </SectionContent>
        </SectionContainer>
        <Divider />
        <SectionContainer
          sx={{
            flex: "1",
            overflowY: "auto",
          }}
        >
          <SectionHeader>
            <Typography variant="body3">Tag List</Typography>
          </SectionHeader>
          <SectionContent>
            {!!slide &&
              map(slide.tagInfos, (tagInfo) => (
                <SlideTagChip
                  sx={{ marginBottom: "12px" }}
                  key={tagInfo.id}
                  label={tagInfo.title}
                />
              ))}
          </SectionContent>
        </SectionContainer>
      </Box>
      {!!slide && renderContextMenu()}
      {!!slide && renderForm()}
    </TabPanel>
  );
}

export default InformationPanel;
