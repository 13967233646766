import { useCallback } from "react";
import asyncComponent from "components/AsyncComponent";
import { useDispatch, useSelector } from "react-redux";
import { getReportStateSelector } from "dux/WSIAnalysisResult/selectors";
import Portal from "@mui/material/Portal";
import {
  doneGeneratingReport,
  updateReportState,
} from "dux/WSIAnalysisResult/actions";
import { filter, first, flow } from "lodash/fp";
import { enqueueSnackbar } from "dux/snackbar/actions";

const AsyncReportProvider = asyncComponent(() => import("./ReportProvider"));

const RootReportGenerator = () => {
  const wsiReportStatus = useSelector(getReportStateSelector);

  const dispatch = useDispatch();
  const onLoading = useCallback(
    (wsiId) =>
      dispatch(
        updateReportState({
          wsiId,
          loading: true,
          done: false,
        })
      ),
    [dispatch]
  );
  const onComplete = useCallback(
    (wsiId) =>
      dispatch(
        updateReportState({
          wsiId,
          loading: false,
          done: true,
        })
      ),
    [dispatch]
  );
  const onError = useCallback(
    (wsiId, error) => {
      dispatch(
        enqueueSnackbar({
          message: `Report generation failed for: ${wsiReportStatus.states[wsiId].name}`,
          options: {
            variant: "error",
          },
        })
      );
      dispatch(
        updateReportState({
          wsiId,
          loading: false,
          done: true,
          error,
        })
      );
    },
    [dispatch, wsiReportStatus]
  );
  const onImportFail = useCallback(() => {
    dispatch(
      enqueueSnackbar({
        message: `Report generation failed becuase report generating module could not be downloaded.`,
        options: {
          variant: "error",
        },
      })
    );
    dispatch(doneGeneratingReport());
  }, [dispatch]);
  const reportToGenerate = flow(
    filter((wsiId: string) => !wsiReportStatus.states[wsiId].done),
    first
  )(wsiReportStatus.wsiIds);
  return (
    <Portal>
      {!!reportToGenerate && (
        <AsyncReportProvider
          debug={false}
          wsiId={reportToGenerate}
          key={`${reportToGenerate}`}
          createTime={wsiReportStatus.states[reportToGenerate].createTime}
          onLoading={onLoading}
          onComplete={onComplete}
          onError={onError}
          onImportFail={onImportFail}
        />
      )}
    </Portal>
  );
};

export default RootReportGenerator;
