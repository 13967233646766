import React, { useContext, useEffect, useRef, useState } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { ResizeObserverContext } from "./ResizeObserverContext";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

export interface EllipsizedTypographyProps extends TypographyProps {
  direction?: "column" | "row";
  heightThreshold?: number;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "direction",
})<EllipsizedTypographyProps>(({ direction }) => ({
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  ...(direction === "row"
    ? {
        lineBreak: "anywhere",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
      }
    : {
        lineBreak: "anywhere",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
      }),
}));

export default function EllipsizedTypography(props: EllipsizedTypographyProps) {
  const typographyRef = useRef<HTMLElement>(null);
  const direction = props.direction || "row";
  const heightThreshold =
    props.heightThreshold != null ? props.heightThreshold : 1;
  const [showTooltip, setShowTooltip] = useState(false);
  const { addResizeHandler, removeResizeHandler } = useContext(
    ResizeObserverContext
  );
  useEffect(() => {
    const target = typographyRef.current;
    const resizeHandler = (target) => {
      switch (direction) {
        case "row":
          if (
            target.scrollHeight - target.clientHeight > heightThreshold &&
            !showTooltip
          ) {
            setShowTooltip(true);
          } else if (
            target.scrollHeight - target.clientHeight <= heightThreshold &&
            showTooltip
          ) {
            setShowTooltip(false);
          }
          break;
        case "column":
          if (
            target.scrollHeight - target.clientHeight > heightThreshold &&
            !showTooltip
          ) {
            setShowTooltip(true);
          } else if (
            target.scrollHeight - target.clientHeight <= heightThreshold &&
            showTooltip
          ) {
            setShowTooltip(false);
          }
      }
    };
    addResizeHandler(target, resizeHandler);
    return () => removeResizeHandler(target);
  }, [
    typographyRef,
    direction,
    showTooltip,
    heightThreshold,
    addResizeHandler,
    removeResizeHandler,
  ]);
  if (showTooltip) {
    return (
      <Tooltip title={props.children}>
        <StyledTypography {...props} direction={direction} ref={typographyRef}>
          {props.children}
        </StyledTypography>
      </Tooltip>
    );
  }
  return (
    <StyledTypography {...props} direction={direction} ref={typographyRef}>
      {props.children}
    </StyledTypography>
  );
}
