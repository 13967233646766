import OpenSeadragon from "openseadragon";
import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import { AnalysisSourceDetails } from "./model";
import { action } from "typesafe-actions";

//  Simple Action Types
export const SET_ZOOM = "SET_ZOOM";
export const SET_PHYSICAL_WIDTH_PX = "SET_PHYSICAL_WIDTH_PX";
export const UPDATE_INFERENCE_RESULT_LOADED = "UPDATE_INFERENCE_RESULT_LOADED";
export const RESET_VIEWER_STATE = "RESET_VIEWER_STATE";
export const RESET_ANALYSIS_STATE = "RESET_ANALYSIS_STATE";

// Simple Actions
export const setZoom = (
  zoom: number,
  refPoint: OpenSeadragon.Point | null = null
) => action(SET_ZOOM, { zoom, refPoint });
export const updateInferenceResultLoaded = (loaded: boolean) =>
  action(UPDATE_INFERENCE_RESULT_LOADED, loaded);
export const resetViewerState = () => action(RESET_VIEWER_STATE);
export const setPhysicalWidthPx = (physicalWidthPx: number) =>
  action(SET_PHYSICAL_WIDTH_PX, physicalWidthPx);
export const resetAnalysisState = () => action(RESET_ANALYSIS_STATE);

/**
 * Action Creators
 * Usage: getAnalysisSourceDetailsById[type: request | success | failure](params)
 */
export const GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES = asyncActionCreator(
  "GET_ANALYSIS_SOURCE_DETAILS_BY_ID"
);
export const getAnalysisSourceDetailsById = asyncAction<
  string,
  AnalysisSourceDetails,
  string
>(GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES);

export const ANALYZE_SINGLE_SOURCE_TYPES = asyncActionCreator(
  "ANALYZE_SINGLE_SOURCE_TYPES"
);
export const analyzeSingleSource = asyncAction<string, any, any>(
  ANALYZE_SINGLE_SOURCE_TYPES
);
