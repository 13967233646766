import { Box, Typography } from "@mui/material";

export default function NoMatchContainer() {
  return (
    <Box
      key="no-result"
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          color="darkGrey.15"
          sx={{
            mt: (theme) => theme.spacing(12.5),
            mb: (theme) => theme.spacing(2),
          }}
        >
          Nothing matches your search
        </Typography>
        <Typography variant="subtitle3" color="darkGrey.15">
          Please try again!
        </Typography>
      </Box>
    </Box>
  );
}
