import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, styled, Tooltip, Typography } from "@mui/material";
import UserTable from "./UserTable";
import { DialogContext } from "components/Dialog/context";
import SearchField from "components/Forms/SearchField";
import ActiveIconButton from "components/common/ActiveIconButton";
import Pagination from "components/common/PageTemplate/Pagination";
import UserForm from "components/Forms/UserForm";
import { FormMode } from "components/Forms/Template";
import useFormTemplate from "components/hooks/useFormTemplate";
import { NoData } from "./component/NoData";
import useDebounce from "components/hooks/useDebounce";
import { subScrollbarWidth } from "utils/styles";
import { DEFAULT_PAGE_SIZE } from "utils/pagination";
import { DeleteIcon, ReloadIcon } from "assets/icons";

import {
  createUser,
  deleteUsers,
  getAllUsers,
  updateUser,
} from "dux/user/actions";
import {
  getAccountMgmtStateSelector,
  getUserListLoadingSelector,
  getUserListPaginationInfoSelector,
  getUserListSelector,
} from "dux/user/selectors";
import {
  APIUserInput,
  ExtendedUser,
  UserUUID,
  userRoleMapper,
  UserListRequestParams,
} from "dux/user/model";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { GridSortModel } from "@mui/x-data-grid";
import { FetchStatus } from "dux/utils/commonEnums";
import LoadingIndicator from "components/analysis/LoadingIndicator";
import useProjectsCRUD from "components/hooks/useProjectsCRUD";

const UserContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  minWidth: "860px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "calc(100% - 240px)",
  }),
}));

const SlidesTopField = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3, subScrollbarWidth(theme.spacing(5)), 0, 5),
  marginBottom: theme.spacing(2),
  minWidth: "1000px",
}));

const SlidesSearchField = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "36px",
  minWidth: "1000px",
  padding: theme.spacing(0, 5, 0, 5),
  marginBottom: theme.spacing(3),
}));

const TooltipField = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  right: subScrollbarWidth(theme.spacing(5)),
}));

const ContentContainer = styled("div")({
  display: "flex",
  height: "100%",
  overflow: "hidden",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
  overflowX: "auto",
}));

const DEFAULT_SORT = "createdAt,desc";

function sortModelQuery(sortModel: GridSortModel): string {
  if (sortModel.length === 0) return DEFAULT_SORT;
  return `${sortModel[0].field},${sortModel[0].sort}`;
}

const INITIAL_USER_PARAMS: UserListRequestParams = {
  word: "",
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
};

const UserList = () => {
  const dispatch = useDispatch();

  const userList = useSelector(getUserListSelector);
  const userListLoading = useSelector(getUserListLoadingSelector);
  const accountMgmtState = useSelector(getAccountMgmtStateSelector);
  const userListPageInfo = useSelector(getUserListPaginationInfoSelector);

  const containerRef = useRef<HTMLDivElement>(null);
  const { handleDialog: openDialog, open: isDialogOpen } =
    useContext(DialogContext);
  const [userQuery, setUserQuery] =
    useState<UserListRequestParams>(INITIAL_USER_PARAMS);
  const [selectedUsers, setSelectedUsers] = useState<UserUUID[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const { projects: totalProjects, projectsMgmtState } = useProjectsCRUD({});
  const [searchUser, setSearchUser] = useState<string>("");
  const debouncedSearchUser = useDebounce(searchUser, 500);
  const {
    state: userFormState,
    dispatch: dispatchUserForm,
    actionCreators: userFormActions,
  } = useFormTemplate<APIUserInput>();

  const dispatchUserList = useCallback(() => {
    dispatch(
      getAllUsers.request({
        word: userQuery.word,
        page: userQuery.page - 1,
        size: userQuery.size,
        sort: userQuery.sort,
      })
    );
  }, [
    dispatch,
    userQuery.page,
    userQuery.size,
    userQuery.sort,
    userQuery.word,
  ]);

  const handleUserFormSubmit = useCallback(
    (data) => {
      const values = {
        ...userFormState.defaultValues,
        email: data.email,
        name: data.name,
        phoneNumber: data.phoneNumber,
        roleId: userRoleMapper(data.roleId),
      };

      if (userFormState.mode === FormMode.Add) {
        const requestAddBody = {
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          roleId: values.roleId,
        };
        dispatch(createUser.request(requestAddBody));
      } else {
        const requestEditBody = {
          id: values.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          roleId: values.roleId,
        };
        dispatch(updateUser.request(requestEditBody));
      }
      dispatchUserForm(userFormActions.closeForm());
      dispatchUserList();
    },
    [
      dispatch,
      dispatchUserForm,
      dispatchUserList,
      userFormState,
      userFormActions,
    ]
  );

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const handleAddClick = useCallback(
    () => dispatchUserForm(userFormActions.openForm(FormMode.Add, null)),
    [dispatchUserForm, userFormActions]
  );

  const handleEditClick = useCallback(
    (user: ExtendedUser) =>
      dispatchUserForm(userFormActions.openForm(FormMode.Edit, user)),
    [dispatchUserForm, userFormActions]
  );

  const handlePerPageChange = useCallback((_, perPage) => {
    setUserQuery((state) => ({
      ...state,
      size: perPage,
    }));
  }, []);

  const handlePageChange = useCallback((_, page) => {
    setUserQuery((state) => ({
      ...state,
      page: page,
    }));
  }, []);

  const handleSearchUser = (event) => {
    setSearchUser(event.target.value);
  };

  const handleResetInput = () => {
    setSearchUser("");
  };

  const handleResetSelect = useCallback(() => {
    setSelectedUsers([]);
  }, []);

  const handleRowSelect = useCallback((items: UserUUID[]) => {
    setSelectedUsers(items);
  }, []);

  const handleDeleteUser = (UserID: UserUUID) => {
    handleDeleteUserList([UserID]);
  };

  const handleDeleteUserList = useCallback(
    (users: UserUUID[]) => {
      openDialog({
        width: 320,
        agree: "Confirm",
        title: "Delete Users",
        content: (
          <>
            <Typography variant="body5">
              Are you sure you want to delete the selected users?
            </Typography>
          </>
        ),
        handleAgreementCallback: () => {
          dispatch(deleteUsers.request(users));
          handleResetSelect();
        },
        disableBackdropClick: true,
        loading: false,
      });
    },
    [openDialog, dispatch, handleResetSelect]
  );

  const handleReload = useCallback(() => {
    dispatchUserList();
    handleResetSelect();
  }, [dispatchUserList, handleResetSelect]);

  useEffect(() => {
    dispatchUserList();
  }, [dispatchUserList]);

  useEffect(() => {
    setUserQuery((state) => ({
      ...state,
      word: debouncedSearchUser,
    }));
  }, [debouncedSearchUser]);

  useEffect(() => {
    setUserQuery((state) => ({
      ...state,
      sort: sortModelQuery(sortModel),
    }));
  }, [sortModel]);

  useEffect(() => {
    let needToRefresh = false;
    needToRefresh ||=
      accountMgmtState.success &&
      accountMgmtState.currentMethod === FetchMethod.Patch;
    needToRefresh ||= accountMgmtState.fetchStatus === FetchStatus.Fulfilled;
    if (needToRefresh) {
      dispatchUserList();
    }
  }, [
    dispatchUserList,
    accountMgmtState.currentMethod,
    accountMgmtState.fetchStatus,
    accountMgmtState.success,
  ]);

  useEffect(() => {
    if (
      isDialogOpen &&
      accountMgmtState.currentMethod === FetchMethod.Delete &&
      accountMgmtState.success
    ) {
      openDialog(null);
    }
  }, [
    openDialog,
    isDialogOpen,
    accountMgmtState.currentMethod,
    accountMgmtState.success,
  ]);

  return (
    <UserContainer ref={containerRef}>
      <SlidesTopField>
        <Typography variant="subtitle1">All Users</Typography>
        <Button onClick={handleAddClick} variant="contained" size="small">
          Create
        </Button>
      </SlidesTopField>
      <SlidesSearchField>
        <SearchField
          placeholder="Search by user email or name"
          value={searchUser}
          onSearch={handleSearchUser}
          onResetInput={handleResetInput}
        />
        <TooltipField>
          <Tooltip title="Delete" key="delete">
            <span>
              <ActiveIconButton
                onClick={() => handleDeleteUserList(selectedUsers)}
                disabled={selectedUsers.length === 0}
                size="large"
              >
                <DeleteIcon />
              </ActiveIconButton>
            </span>
          </Tooltip>
          <Tooltip onClick={handleReload} title="Reload" key="reload">
            <ActiveIconButton size="large">
              <ReloadIcon />
            </ActiveIconButton>
          </Tooltip>
        </TooltipField>
      </SlidesSearchField>
      <ContentContainer>
        <TableContainer>
          {userList.length === 0 ? (
            <NoData />
          ) : (
            <UserTable
              userList={userList}
              sortModel={sortModel}
              selectedUsers={selectedUsers}
              totalProjects={totalProjects}
              handleRowSelect={handleRowSelect}
              handleEditUser={handleEditClick}
              handleDeleteUser={handleDeleteUser}
              handleSortModelChange={handleSortModelChange}
            />
          )}
        </TableContainer>
        {(userListLoading || projectsMgmtState.loading) && (
          <LoadingIndicator message="Loading user list..." />
        )}
      </ContentContainer>
      <Box
        sx={{
          padding: (theme) =>
            `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
        }}
      >
        <Pagination
          page={userQuery.page}
          perPage={userQuery.size}
          onPerPageChange={handlePerPageChange}
          onPageChange={handlePageChange}
          count={userListPageInfo.totalElements}
        />
      </Box>
      <UserForm
        onSubmit={handleUserFormSubmit}
        onCancel={() => dispatchUserForm(userFormActions.closeForm())}
        open={userFormState.open}
        mode={userFormState.mode}
        defaultValues={userFormState.defaultValues}
        loading={accountMgmtState.loading}
      />
    </UserContainer>
  );
};
export default UserList;
