import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import {
  APICancerType,
  APICancerTypeInput,
  APICancerTypeList,
  CancerUUID,
  SearchCancersParams,
} from "dux/cancerTypes/model";
import { action } from "typesafe-actions";

export const RESET_CREATED_CANCER_TYPE = "RESET_CREATED_CANCER_TYPE";
export const resetCreatedCancerType = () => action(RESET_CREATED_CANCER_TYPE);

/**
 * Action Creators
 * Usage: getCancers[type: request | success | failure](params)
 */
export const GET_CANCERS_TYPES = asyncActionCreator("GET_CANCERS");
export const getCancers = asyncAction<
  SearchCancersParams,
  APICancerTypeList,
  string
>(GET_CANCERS_TYPES);

/**
 * Action Creators
 * Usage: createCancerType[type: request | success | failure](params)
 */
export const CREATE_CANCER_TYPE_TYPES =
  asyncActionCreator("CREATE_CANCER_TYPE");
export const createCancerType = asyncAction<
  APICancerTypeInput,
  APICancerType,
  string
>(CREATE_CANCER_TYPE_TYPES);

/**
 * Action Creators
 * Usage: deleteCancerTypes[type: request | success | failure](params)
 */
export const DELETE_CANCER_TYPE_TYPES =
  asyncActionCreator("DELETE_CANCER_TYPE");
export const deleteCancerTypes = asyncAction<CancerUUID, any, string>(
  DELETE_CANCER_TYPE_TYPES
);

/**
 * Action Creators
 * Usage: editCancerType[type: request | success | failure](params)
 */
export const EDIT_CANCER_TYPE_TYPES = asyncActionCreator("EDIT_CANCER_TYPE");
export const editCancerType = asyncAction<APICancerType, APICancerType, string>(
  EDIT_CANCER_TYPE_TYPES
);
