/**
 * User Reducer
 */
import { createReducer, StateType, ActionType } from "typesafe-actions";
import produce from "immer";
import {
  AccountMgmtState,
  SettingsState,
  User,
  UserListState,
  UserRole,
  UserState,
  SettingsAPI,
} from "./model";
import {
  CURRENT_USER_TYPES,
  getCurrentUser,
  GET_ALL_USERS_TYPES,
  getAllUsers,
  RESET_ACCOUNT_MGMT_STATE,
  CREATE_USER_TYPES,
  UPDATE_USER_TYPES,
  DELETE_USERS_TYPES,
  deleteUsers,
  createUser,
  updateUser,
  UPDATE_PROFILE_TYPES,
  updateProfile,
  UPDATE_PASSWORD_TYPES,
  updatePassword,
  RESET_SETTINGS_STATE,
  GET_NOTIFICATION_SETTINGS,
  getNotiSettings,
  UPDATE_NOTIFICATION_SETTINGS,
  USER_LOGIN,
  UPDATE_USER_PROJECT_MAPPINGS_TYPES,
  updateUserProjectMappings,
} from "./actions";
import { combineReducers } from "redux";
import map from "lodash/map";
import { FetchStatus } from "dux/utils/commonEnums";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import accessTokenManager from "auth/accessTokenManager";

const accountInitialState: User = {
  id: "",
  name: "",
  email: "",
  phoneNumber: "",
  type: "",
  roleName: UserRole.Admin,
  isLogin: accessTokenManager.hasAccessTokenInStorage(),
  createdAt: "",
  mfaKeyRegisteredAt: "",
  customer: {
    code: "",
    name: "",
    createdAt: "",
  },
  analysisResultFormat: {
    csv: false,
    excel: false,
    json: false,
  },
  notiAnalysisResult: false,
  notiReportDownload: false,
  notiWsiUpload: false,
};

const accountReducer = createReducer<User>(accountInitialState, {
  [USER_LOGIN]: (state) =>
    produce(state, (draft) => {
      draft.isLogin = true;
    }),
  USER_LOGOUT: (state) => ({
    ...accountInitialState,
    isLogin: false,
  }),
  [CURRENT_USER_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof getCurrentUser.success>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.id = payload.id;
      draft.name = payload.name;
      draft.email = payload.email;
      draft.type = payload.type;
      draft.roleName = payload.roleName;
      draft.customer = payload.customer;
      draft.createdAt = payload.createdAt;
      draft.phoneNumber = payload.phoneNumber;
      draft.mfaKeyRegisteredAt = payload.mfaKeyRegisteredAt;
      draft.analysisResultFormat = payload.analysisResultFormat;
      draft.notiAnalysisResult = payload.notiAnalysisResult;
      draft.notiReportDownload = payload.notiReportDownload;
      draft.notiWsiUpload = payload.notiWsiUpload;
    }),
});
const DEFAULT_ITEM_SIZE = 15;

const userListInitialState: UserListState = {
  fetchStatus: FetchStatus.Idle,
  list: [],
  pagination: {
    first: true,
    last: true,
    size: DEFAULT_ITEM_SIZE,
    totalElements: 0,
    totalPages: 0,
  },
};

const userListReducer = createReducer<UserListState>(userListInitialState, {
  [GET_ALL_USERS_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof getAllUsers.success>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.fetchStatus = FetchStatus.Fulfilled;
      draft.list = payload.contents;
      draft.pagination = {
        ...state.pagination,
        first: payload.first,
        last: payload.last,
        totalElements: payload.totalElements,
        totalPages: payload.totalPages,
      };
    }),
  [GET_ALL_USERS_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [GET_ALL_USERS_TYPES.FAILURE]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
    }),
  [UPDATE_USER_PROJECT_MAPPINGS_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof updateUserProjectMappings.success>
  ) =>
    produce(state, (draft) => {
      const idx = draft.list.findIndex(
        ({ id }) => id === action.payload.userId
      );
      if (idx >= 0) {
        draft.list[idx].projects = map(
          action.payload.mappings,
          ({ projectId }) => projectId
        );
      }
    }),
});

// TODO: add one or more reducers handling network requests for CRUD of users

const accountMgmtInitialState: AccountMgmtState = {
  currentMethod: FetchMethod.Post,
  error: "",
  fetchStatus: FetchStatus.Idle,
};

const accountMgmtReducer = createReducer<AccountMgmtState>(
  accountMgmtInitialState,
  {
    [RESET_ACCOUNT_MGMT_STATE]: (state) => accountMgmtInitialState,
    [CREATE_USER_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Post;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [CREATE_USER_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [CREATE_USER_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof createUser.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [UPDATE_USER_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Put;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [UPDATE_USER_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [UPDATE_USER_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof updateUser.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [DELETE_USERS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [DELETE_USERS_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_USERS_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof deleteUsers.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
  }
);

const settingsInitialState: SettingsState = {
  fetchStatus: FetchStatus.Idle,
  error: null,
  currentAPI: SettingsAPI.UpdateProfile,
  notiSettings: [],
};

const settingsReducer = createReducer<SettingsState>(settingsInitialState, {
  [RESET_SETTINGS_STATE]: (_) => settingsInitialState,
  [UPDATE_PROFILE_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentAPI = SettingsAPI.UpdateProfile;
      draft.fetchStatus = FetchStatus.Pending;
      draft.error = null;
    }),
  [UPDATE_PROFILE_TYPES.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [UPDATE_PROFILE_TYPES.FAILURE]: (
    state,
    action: ReturnType<typeof updateProfile.failure>
  ) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = action.payload;
    }),
  [UPDATE_PASSWORD_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentAPI = SettingsAPI.UpdatePassword;
      draft.fetchStatus = FetchStatus.Pending;
      draft.error = null;
    }),
  [UPDATE_PASSWORD_TYPES.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [UPDATE_PASSWORD_TYPES.FAILURE]: (
    state,
    action: ReturnType<typeof updatePassword.failure>
  ) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = action.payload;
    }),
  [GET_NOTIFICATION_SETTINGS.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentAPI = SettingsAPI.UpdateProfile;
      draft.fetchStatus = FetchStatus.Pending;
      draft.error = null;
    }),
  [GET_NOTIFICATION_SETTINGS.SUCCESS]: (
    state,
    action: ActionType<typeof getNotiSettings.success>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.notiSettings = payload;
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [GET_NOTIFICATION_SETTINGS.FAILURE]: (
    state,
    action: ReturnType<typeof updateProfile.failure>
  ) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = action.payload;
    }),
  [UPDATE_NOTIFICATION_SETTINGS.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentAPI = SettingsAPI.UpdateProfile;
      draft.fetchStatus = FetchStatus.Pending;
      draft.error = null;
    }),
  [UPDATE_NOTIFICATION_SETTINGS.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [UPDATE_NOTIFICATION_SETTINGS.FAILURE]: (
    state,
    action: ReturnType<typeof updateProfile.failure>
  ) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = action.payload;
    }),
});

const userReducer = combineReducers({
  account: accountReducer,
  userList: userListReducer,
  accountMgmt: accountMgmtReducer,
  settings: settingsReducer,
});

export const initialState: UserState = {
  account: accountInitialState,
  userList: userListInitialState,
  accountMgmt: accountMgmtInitialState,
  settings: settingsInitialState,
};

export type UserReducerState = StateType<typeof userReducer>;

export default userReducer;
