import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import { APITag, TagListState, TagMgmtState } from "./model";
import { FetchStatus } from "dux/utils/commonEnums";
import { TagState } from "./reducers";

const tagsStateSelector: Selector<RootState, TagState> = (state) => state.tags;

export const tagsSelector: Selector<RootState, TagListState> = createSelector(
  tagsStateSelector,
  (tag) => tag.tagsTotal
);

export const tagsTotalSelector: Selector<RootState, APITag[]> = createSelector(
  tagsStateSelector,
  (tag) => tag.tagsTotal.list
);

export const tagMgmtStateSelector: Selector<
  RootState,
  TagMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = (state) => ({
  ...state.tags.tagMgmtState,
  loading: state.tags.tagMgmtState.fetchStatus === FetchStatus.Pending,
  success: state.tags.tagMgmtState.fetchStatus === FetchStatus.Fulfilled,
});
