import { FetchMethod, request } from "dux/utils/apiRequestHelper";

export const getAnalysisSourceDetailsById = (sourceId: string) =>
  request({
    method: FetchMethod.Get,
    path: `analysis/sources/${sourceId}/details`,
  });

export const requestSingleSlideAnalyze = (sourceId: string) =>
  request({
    method: FetchMethod.Post,
    path: `analysis/sources/${sourceId}/biomarker`,
    skipJson: true,
  });
