import { useState, MouseEvent, useEffect } from "react";
import { Box, Button, Divider, Menu } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { ArrowIcon } from "assets/icons";
import SelectItem from "../SelectItem";
import _ from "lodash";
import { PAGE_SIZE_LIST } from "utils/pagination";

const PaperProps = {
  style: {
    width: 136,
  },
};

const SidePageBox = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SelectPageBox = styled("div")({});

const SelectButton = styled(Button)(({ theme }) => ({
  border: "1px solid transparent",
  minWidth: "auto",
  width: "67px",
  height: "28px",
  marginLeft: 12,
  padding: "4px 12px",
  ".MuiButton-endIcon": {
    margin: 0,
  },
  "&:hover": {
    background: "transparent",
    borderColor: theme.palette.darkGrey[30],
  },
  "&:focus-visible": {
    background: "transparent",
    borderColor: theme.palette.scope1,
  },
}));

const ArrowButton = styled(Button)(({ theme }) => ({
  border: "1px solid transparent",
  minWidth: "auto",
  height: "28px",
  padding: theme.spacing(0.5),
  color: theme.palette.darkGrey[10],
  ".MuiButton-endIcon": {
    margin: 0,
  },
}));

const divider = {
  height: "20px",
  margin: "0 12px",
} as const;

interface PaginationProps {
  count: number;
  page?: number;
  perPage: number;
  onPageChange?: (
    event:
      | React.MouseEvent<HTMLLIElement>
      | React.MouseEvent<HTMLButtonElement>
      | null,
    page: number
  ) => void;
  onPerPageChange?: (
    event: React.MouseEvent<HTMLLIElement> | null,
    perPage: number
  ) => void;
}

const Pagination = ({
  count: itemCount,
  page: selectedPage,
  perPage: selectedPerPage,
  onPageChange,
  onPerPageChange,
}: PaginationProps) => {
  const theme = useTheme();

  const [perPageAnchorEl, setPerPageAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [pageAnchorEl, setPageAnchorEl] = useState<null | HTMLElement>(null);

  const perPageOpen = Boolean(perPageAnchorEl);
  const pageOpen = Boolean(pageAnchorEl);

  const pageCount = Math.max(Math.ceil(itemCount / selectedPerPage), 1);
  const pages = _.range(1, pageCount + 1);

  const startItemNumber = selectedPerPage * (selectedPage - 1) + 1;
  const endItemNumber = Math.min(
    startItemNumber + selectedPerPage - 1,
    itemCount
  );

  const handlePerPageClick = (event: MouseEvent<HTMLButtonElement>) => {
    setPerPageAnchorEl(event.currentTarget);
  };

  const handlePerPageClose = () => {
    setPerPageAnchorEl(null);
  };

  const handlePageClick = (event: MouseEvent<HTMLButtonElement>) => {
    setPageAnchorEl(event.currentTarget);
  };

  const handlePageClose = () => {
    setPageAnchorEl(null);
  };

  useEffect(() => {
    if (selectedPage > pageCount) {
      onPageChange(null, pageCount);
    }
  }, [pageCount, selectedPage, onPageChange]);

  return (
    <Box
      sx={{
        ...theme.typography.body5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "48px",
        borderTop: (theme) => `1px solid ${theme.palette.darkGrey[80]}`,
        padding: "10px 0",
        color: (theme) => theme.palette.darkGrey[30],
      }}
    >
      <SidePageBox>
        Items per page
        <SelectPageBox>
          {/* @todo 스타일 제거한 Select 컴포넌트로 교체 */}
          <SelectButton
            sx={{ color: (theme) => theme.palette.darkGrey[10] }}
            endIcon={
              <ArrowIcon
                style={{
                  transform: perPageOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            }
            onClick={handlePerPageClick}
          >
            {selectedPerPage}
          </SelectButton>
          <Menu
            anchorEl={perPageAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            PaperProps={PaperProps}
            onClose={handlePerPageClose}
            open={perPageOpen}
          >
            {PAGE_SIZE_LIST.map((perPage) => (
              <SelectItem
                key={perPage}
                selected={perPage === selectedPerPage}
                onClick={(event) => {
                  event.preventDefault();
                  onPerPageChange(event, perPage);
                  handlePerPageClose();
                }}
              >
                {perPage}
              </SelectItem>
            ))}
          </Menu>
        </SelectPageBox>
        <Divider orientation="vertical" sx={divider} />
        {startItemNumber}-{endItemNumber} of {itemCount} items
      </SidePageBox>
      <SidePageBox>
        The page you’re on
        <SelectPageBox>
          {/* @todo 스타일 제거한 Combobox 컴포넌트로 교체 */}
          <SelectButton
            sx={{ color: (theme) => theme.palette.darkGrey[10] }}
            endIcon={
              <ArrowIcon
                style={{
                  transform: pageOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            }
            onClick={handlePageClick}
          >
            {selectedPage}
          </SelectButton>
          <Menu
            anchorEl={pageAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            PaperProps={PaperProps}
            onClose={handlePageClose}
            open={pageOpen}
          >
            {pages.map((page) => (
              <SelectItem
                key={page}
                selected={page === selectedPage}
                onClick={(event) => {
                  event.preventDefault();
                  onPageChange(event, page);
                  handlePageClose();
                }}
              >
                {page}
              </SelectItem>
            ))}
          </Menu>
        </SelectPageBox>
        <Divider orientation="vertical" sx={divider} />
        <ArrowButton
          disabled={selectedPage === 1}
          onClick={(event) => {
            event.preventDefault();
            onPageChange(event, selectedPage - 1);
          }}
          endIcon={<ArrowIcon style={{ transform: "rotate(90deg)" }} />}
        />
        <ArrowButton
          disabled={selectedPage === pageCount}
          onClick={(event) => {
            event.preventDefault();
            onPageChange(event, selectedPage + 1);
          }}
          endIcon={<ArrowIcon style={{ transform: "rotate(-90deg)" }} />}
        />
      </SidePageBox>
    </Box>
  );
};

export default Pagination;
