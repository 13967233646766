import { useDispatch } from "react-redux";
import { putSheet, removeSheet, ISheetContent } from "../Sheets/dux";

interface SheetsActionCreators {
  putSheet: (content: ISheetContent) => void;
  removeSheet: (key: string) => void;
}

const useSheets = (): SheetsActionCreators => {
  const dispatch = useDispatch();
  return {
    putSheet: (content: ISheetContent) => dispatch(putSheet(content)),
    removeSheet: (key: string) => dispatch(removeSheet(key)),
  };
};

export default useSheets;
