import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MuiDivider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import {
  PolygonIcon,
  UndoIcon,
  RedoIcon,
  DeleteIcon,
  ShortcutIcon,
} from "assets/icons";
import HeaderButton, {
  HeaderButtonMenuItem,
} from "components/common/HeaderButton";
import useViewerToolbar, {
  selectTool,
  selectCellClass,
  ViewerToolbarState,
  ViewerToolType,
} from "hooks/useViewerToolbar";
import find from "lodash/find";
import Button from "@mui/material/Button";
import { DetailsSlideInformation } from "dux/analysis/model";
import { useDispatch, useSelector } from "react-redux";
import { analyzeSingleSource } from "dux/analysis/actions";
import {
  CELL_CLASS_MENU_ITEMS,
  CELL_MENU_ITEMS,
  POINTER_MENU_ITEMS,
  REDO_SHORTCUT,
  UNDO_SHORTCUT,
} from "./ToolbarConstants";
import KeyboardShortcutPopover from "./KeyboardShortcutPopover";
import Box from "@mui/material/Box";
import GenerateReportButton from "components/report/GenerateReportButton";
import { useCallback, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { isViewerUserSelector } from "dux/user/selectors";

interface ToolbarProps {
  toolbarState: ViewerToolbarState;
  slideId: string;
  dispatchToolbar: ReturnType<typeof useViewerToolbar>["dispatchToolbar"];
  onUndo?: () => void;
  onRedo?: () => void;
  onDeleteSelected?: () => void;
  onReset?: () => void;
  slideInformation?: DetailsSlideInformation;
}

const Divider = styled(MuiDivider)({
  margin: "14px 8px",
  height: 20,
});

function tooltipForMenuButton(
  menuItems: HeaderButtonMenuItem[],
  selectedMenuId: string
) {
  const selectedMenu = find(
    menuItems,
    (menuItem) => menuItem.id === selectedMenuId
  );
  if (!selectedMenu) return null;
  return tooltipWithShortcut(selectedMenu.title, selectedMenu.shortcut);
}

function tooltipWithShortcut(title: string, shortcut?: string) {
  return (
    <>
      <Typography color="darkGrey.0" variant="body5">
        {title}
      </Typography>
      {shortcut && (
        <Typography color="darkGrey.30" variant="body6" sx={{ marginLeft: 1 }}>
          {shortcut}
        </Typography>
      )}
    </>
  );
}

const Container = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
});

const LeftContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});
const RightContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

function slideAnalyzable(status: string) {
  return status === "PRE_ANALYZED" || status === "ANAL_FAILED";
}

function slideAnalyzing(status: string) {
  return status === "ANALYZING";
}

function slideAnalyzed(status: string) {
  return status === "ANALYZED";
}

export default function ViewerToolbar({
  toolbarState,
  dispatchToolbar,
  onUndo,
  onRedo,
  onReset,
  onDeleteSelected,
  slideId,
  slideInformation,
}: ToolbarProps) {
  const {
    cellEditable,
    activeTool,
    roiDraw,
    pointerType,
    cellSelection,
    cellClass,
    toolsEnabled,
  } = toolbarState;

  const dispatch = useDispatch();
  const isViewer = useSelector(isViewerUserSelector);
  const onClickDrawROI = () => {
    dispatchToolbar(selectTool(ViewerToolType.ROI, "polygon"));
  };
  const handleAnalyzeClick = () => {
    dispatch(analyzeSingleSource.request(slideId));
  };

  const btnRef = useRef();
  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const toggleShortcuts = useCallback(() => {
    setShortcutsOpen((state) => !state);
  }, []);

  useHotkeys(
    "t",
    (evt) => {
      evt.preventDefault();
      toggleShortcuts();
    },
    [toggleShortcuts]
  );

  return (
    <Container>
      <LeftContainer>
        {toolsEnabled &&
          !!slideInformation &&
          !slideAnalyzing(slideInformation.status) && (
            <>
              <Tooltip title={"Region of interest (ROI)"}>
                <span>
                  <HeaderButton
                    disabled
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 1,
                      "&:disabled": {
                        color: (theme) => theme.palette.darkGrey[0],
                      },
                    }}
                  >
                    <Typography variant="body3">ROI</Typography>
                  </HeaderButton>
                </span>
              </Tooltip>
              <HeaderButton
                sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                selected={activeTool === ViewerToolType.ROI}
                onClick={onClickDrawROI}
                onShortcutPress={onClickDrawROI}
                shortcut="R"
                selectedMenuId={roiDraw}
                tooltip={tooltipWithShortcut(`Draw ROI`, "R")}
                hoverMode="bg"
              >
                <PolygonIcon />
              </HeaderButton>
              {cellEditable && (
                <>
                  <Divider
                    orientation="vertical"
                    sx={{
                      marginRight: 0,
                    }}
                  />
                  <HeaderButton
                    disabled
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 1,
                      "&:disabled": {
                        color: (theme) => theme.palette.darkGrey[0],
                      },
                    }}
                  >
                    <Typography variant="body3">Cell</Typography>
                  </HeaderButton>
                  <HeaderButton
                    hasMenu
                    sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                    menuItems={CELL_MENU_ITEMS}
                    selected={activeTool === ViewerToolType.Cell}
                    onMenuClick={(menuId) => {
                      dispatchToolbar(selectTool(ViewerToolType.Cell, menuId));
                    }}
                    selectedMenuId={cellSelection}
                    tooltip={tooltipForMenuButton(
                      CELL_MENU_ITEMS,
                      cellSelection
                    )}
                    hoverMode="bg"
                  />
                  <HeaderButton
                    hasMenu
                    disabled={
                      !(
                        activeTool === ViewerToolType.Cell &&
                        cellSelection === "pointer"
                      )
                    }
                    sx={{
                      color: (theme) => theme.palette.darkGrey[100],
                      backgroundColor:
                        cellClass === "tp-" ? "#1EFCFF" : "#AD1EFF",
                      "&.Mui-disabled": {
                        backgroundColor:
                          cellClass === "tp-" ? "#1EFCFF" : "#AD1EFF",
                        color: (theme) => theme.palette.darkGrey[100],
                        opacity: 0.3,
                      },
                      "&:hover": {
                        backgroundColor:
                          cellClass === "tp-" ? "#1EFCFF" : "#AD1EFF",
                      },
                    }}
                    menuItems={CELL_CLASS_MENU_ITEMS}
                    onMenuClick={(menuId) => {
                      dispatchToolbar(selectCellClass(menuId));
                    }}
                    selectedMenuId={cellClass}
                    tooltip={tooltipForMenuButton(
                      CELL_CLASS_MENU_ITEMS,
                      cellClass
                    )}
                    hoverMode="bg"
                  >
                    {
                      <Typography variant="button2">
                        {cellClass === "tp-" ? "TP-" : "TP+"}
                      </Typography>
                    }
                  </HeaderButton>
                </>
              )}
              <Divider orientation="vertical" />
              <HeaderButton
                hasMenu
                sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                menuItems={POINTER_MENU_ITEMS}
                selected={activeTool === ViewerToolType.Pointer}
                onMenuClick={(menuId) => {
                  dispatchToolbar(selectTool(ViewerToolType.Pointer, menuId));
                }}
                selectedMenuId={pointerType}
                tooltip={tooltipForMenuButton(POINTER_MENU_ITEMS, pointerType)}
                hoverMode="bg"
              />
              <HeaderButton
                sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                shortcut={UNDO_SHORTCUT}
                tooltip={tooltipWithShortcut("Undo", UNDO_SHORTCUT)}
                disabled={!onUndo}
                onClick={onUndo}
                onShortcutPress={onUndo}
              >
                <UndoIcon />
              </HeaderButton>
              <HeaderButton
                sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                shortcut={REDO_SHORTCUT}
                tooltip={tooltipWithShortcut("Redo", REDO_SHORTCUT)}
                disabled={!onRedo}
                onClick={onRedo}
                onShortcutPress={onRedo}
              >
                <RedoIcon />
              </HeaderButton>
              <HeaderButton
                sx={{ color: (theme) => theme.palette.darkGrey[0] }}
                shortcut="Backspace"
                tooltip={tooltipWithShortcut("Delete Selected", "Backspace")}
                disabled={!onDeleteSelected}
                onClick={onDeleteSelected}
                onShortcutPress={onDeleteSelected}
              >
                <DeleteIcon />
              </HeaderButton>
              <Divider orientation="vertical" />
              <HeaderButton
                tooltip={"Reset ROI"}
                disabled={!onReset}
                onClick={onReset}
              >
                <Typography variant="body5">Reset</Typography>
              </HeaderButton>
            </>
          )}
      </LeftContainer>
      <RightContainer>
        {slideInformation && slideAnalyzed(slideInformation.status) && (
          <>
            <GenerateReportButton
              slides={[
                {
                  id: slideId,
                  ...slideInformation,
                },
              ]}
              mode="caption"
            />
            <Divider
              orientation="vertical"
              sx={{ marginLeft: 1, marginRight: 1 }}
            />
          </>
        )}
        {slideInformation &&
          slideAnalyzable(slideInformation.status) &&
          !isViewer && (
            <>
              <Button
                variant="contained"
                sx={{ height: 28, padding: "4px 12px" }}
                onClick={handleAnalyzeClick}
              >
                Analyze
              </Button>
              <Divider
                orientation="vertical"
                sx={{ marginLeft: 1, marginRight: 1 }}
              />
            </>
          )}
        <Box sx={{ position: "relative" }}>
          <Tooltip title={!shortcutsOpen ? "Keyboard Shortcuts" : ""}>
            <HeaderButton onClick={toggleShortcuts} ref={btnRef}>
              <ShortcutIcon />
            </HeaderButton>
          </Tooltip>
          <KeyboardShortcutPopover
            open={shortcutsOpen}
            anchorEl={btnRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={() => setShortcutsOpen(false)}
            toolbarState={toolbarState}
          />
        </Box>
      </RightContainer>
    </Container>
  );
}
