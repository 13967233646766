import { Box, Link } from "@mui/material";
import AuthActionButton from "./AuthActionButton";
import AuthDescription from "./AuthDescription";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { registerMFA } from "dux/user/api";
import { UserTokenOut } from "dux/user/model";
import { FormEvent, useCallback, useState } from "react";
import AuthAlert from "./AuthAlert";
import { useAuthLoadingStateContext } from "./AuthTemplate";

interface RegisterMFAFormProps {
  qrcode?: string;
  onBackToLogin?: () => void;
  onSuccess?: (tokenOut: UserTokenOut) => void;
}

export default function RegisterMFAForm({
  qrcode,
  onBackToLogin,
  onSuccess,
}: RegisterMFAFormProps) {
  const { setLoading } = useAuthLoadingStateContext();
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const actionDisabled = !code.trim();
  const hasError = !!errorMessage.trim();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      registerMFA({ code })
        .then((res: UserTokenOut) => {
          onSuccess?.(res);
        })
        .catch((error) => {
          setErrorMessage(
            error.details === "MFA Code is invalid"
              ? error.details
              : "Something went wrong. Please try again."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [code, onSuccess, setLoading]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>Register MFA device</AuthHeading>
      <Box sx={{ marginBottom: 3 }}>
        <img
          src={qrcode}
          alt="Register QR Code"
          style={{
            display: "block",
            background: "#fff",
            width: 200,
            height: 200,
            margin: "0 auto",
          }}
        />
      </Box>
      {hasError && <AuthAlert severity="error">{errorMessage}</AuthAlert>}
      <AuthDescription>
        If your virtual MFA application supports scanning QR codes, scan the
        following QR code with your smartphone’s camera.
      </AuthDescription>
      <AuthFormField
        id="code"
        aria-label="MFA Code"
        placeholder="MFA Code*"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        autoFocus
      />
      <AuthActionButton disabled={actionDisabled}>Confirm</AuthActionButton>
      <AuthNav>
        <Link component="button" onClick={onBackToLogin}>
          Back to log in
        </Link>
      </AuthNav>
    </AuthForm>
  );
}
