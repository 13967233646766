import { styled } from "@mui/material/styles";

export const SectionContainer = styled("div")({
  padding: "0 0 8px 0",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const SectionHeader = styled("div")(({ theme }) => ({
  width: "100%",
  height: 48,
  paddingTop: 8,
  flex: "0 0 48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 20,
  paddingRight: 16,
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.darkGrey[85],
}));

export const SectionListItem = styled("div")({
  width: "100%",
  height: 32,
  flex: "0 0 32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 20,
  paddingRight: 16,
});

export const SectionContent = styled("div")({
  width: "100%",
  padding: "8px 20px",
});
