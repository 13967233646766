import OpenSeadragon from "openseadragon";
import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import {
  AnalysisResultDetail,
  AnalysisSourceDetails,
  AnalysisState,
  DetailsSlideInformation,
  ResultFilePaths,
  SlideViewerState,
} from "./model";
import { SlideProperties } from "dux/slide/model";

const getAnalysisState: Selector<RootState, AnalysisState> = (state) =>
  state.analysis;

const viewerStateSelector: Selector<RootState, SlideViewerState> =
  createSelector(getAnalysisState, (analysis) => analysis.viewer);

export const zoomSelector: Selector<RootState, number> = createSelector(
  viewerStateSelector,
  (viewer) => viewer.zoom
);

export const refPointSelector: Selector<RootState, OpenSeadragon.Point | null> =
  createSelector(viewerStateSelector, (viewer) => viewer.refPoint);

export const physicalWidthPxSelector: Selector<RootState, number> =
  createSelector(viewerStateSelector, (viewer) => viewer.physicalWidthPx);

export const inferenceResultLoadedSelector: Selector<RootState, boolean> =
  createSelector(viewerStateSelector, (viewer) => viewer.inferenceResultLoaded);

export const sourceDetailsSelector: Selector<RootState, AnalysisSourceDetails> =
  createSelector(viewerStateSelector, (viewer) => viewer.sourceDetails);

export const tiledImageSourceSelector: Selector<
  RootState,
  { dziMetaUrl: string; dziUrl: string }
> = createSelector(sourceDetailsSelector, (details) => ({
  dziMetaUrl: details?.dziMetaUrl || "",
  dziUrl: details?.dziUrl || "",
}));

export const slideInformationSelector: Selector<
  RootState,
  DetailsSlideInformation | null
> = createSelector(
  viewerStateSelector,
  (viewer) => viewer.sourceDetails && viewer.sourceDetails.information
);

export const analysisResultDetailSelector: Selector<
  RootState,
  AnalysisResultDetail | null
> = createSelector(
  viewerStateSelector,
  (viewer) => viewer.sourceDetails && viewer.sourceDetails.analysisResultDetail
);

export const viewOptionsSelector: Selector<
  RootState,
  AnalysisResultDetail["viewOptions"] | null
> = createSelector(
  analysisResultDetailSelector,
  (detail) => detail && detail.viewOptions
);
export const analysisResultMsgpacksSelector: Selector<
  RootState,
  ResultFilePaths
> = createSelector(viewerStateSelector, (viewer) => ({
  histologyResultFilePath:
    viewer.sourceDetails && viewer.sourceDetails.inferenceMsgPackPath,
  biomarkerResultFilePath:
    viewer.sourceDetails &&
    viewer.sourceDetails.analysisResultDetail &&
    viewer.sourceDetails.analysisResultDetail.ioBiomarkerMsgpackFile,
}));

const slidePropertiesSelector: Selector<RootState, SlideProperties | null> =
  createSelector(slideInformationSelector, (info) => info && info.properties);

export const mppSelector: Selector<RootState, number> = createSelector(
  slidePropertiesSelector,
  (props) => (props && props.mppX) || 0.1
);

export const wsiIdSelector: Selector<RootState, string> = createSelector(
  viewerStateSelector,
  (viewer) => viewer.sourceDetails?.wsiId || ""
);
