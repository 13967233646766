import { styled } from "@mui/material/styles";

const DataGridContainer = styled("div")(({ theme }) => ({
  height: "100%",
  ".MuiDataGrid-root": {
    border: "none",
    color: "#fff",
    overflowY: "hidden",
    minWidth: "1200px",
    ".header": {
      "&:focus": {
        outline: "none",
      },
      "&:last-child": {
        borderRight: "none",
      },
    },
    ".MuiDataGrid-main": {
      overflow: "hidden",
      "*::-webkit-scrollbar": {
        width: "12px",
        height: "12px",
      },
      "*::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.darkGrey[40],
        borderRadius: "50px",
      },
      ".MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
      },
    },
    ".MuiDataGrid-columnHeaderTitle": {
      ...theme.typography.body3,
      color: theme.palette.darkGrey[30],
      "&:focus": {
        outline: "none",
      },
    },
    ".MuiDataGrid-row": {
      ...theme.typography.body3,
      color: theme.palette.darkGrey[10],
      "&.Mui-selected": {
        backgroundColor: theme.palette.background.secondary,
        border: "transparent",
        "&:hover": {
          backgroundColor: theme.palette.background.secondary,
        },
      },
      "&:last-child .MuiDataGrid-cell": {
        borderBottom: 0,
      },
      "& .hover-shown": {
        opacity: 0,
      },
      "&:hover .hover-shown": {
        opacity: 1,
      },
    },
    ".MuiDataGrid-cell": {
      padding: theme.spacing(0, 1),
      position: "relative",
      "&:focus, &:focus-within, &:focus-visible": {
        outline: "none",
      },
    },
    ".MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
    ".MuiDataGrid-columnSeparator": {
      position: "relative",
    },
    ".MuiDataGrid-iconSeparator": {
      display: "none",
    },
  },
}));

export default DataGridContainer;
