import { put, takeLatest, call } from "redux-saga/effects";
import {
  createAsyncSaga,
  makeDefaultErrorMessageEffect,
  makeDefaultSuccessMessageEffect,
} from "dux/utils/actionHandlingHelper";
import {
  GET_ALL_PROJECTS_TYPES,
  getAllProjects,
  getProjectsPerPage,
  GET_PROJECTS_PER_PAGE_TYPES,
  createProject,
  CREATE_PROJECT_TYPES,
  updateProject,
  UPDATE_PROJECT_TYPES,
  deleteProjects,
  DELETE_PROJECTS_TYPES,
  resetProjectMgmtState,
  enterProject,
  ENTER_PROJECT_TYPES,
  deleteProjectMappings,
  DELETE_PROJECT_MAPPINGS_TYPES,
} from "./actions";
import * as api from "./api";
import noop from "lodash/noop";

const getAllProjectsSaga = createAsyncSaga(
  getAllProjects,
  api.getProjects,
  noop,
  makeDefaultErrorMessageEffect("Failed to fetch all projects")
);

const getProjectsPerPageSaga = createAsyncSaga(
  getProjectsPerPage,
  api.getProjectsPerPage,
  noop,
  makeDefaultErrorMessageEffect("Failed to fetch project list")
);

const createProjectSaga = createAsyncSaga(
  createProject,
  api.createProject,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully created a project")
    );
    yield put(getAllProjects.request({}));
    yield put(resetProjectMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to create a project")
);

const updateProjectSaga = createAsyncSaga(
  updateProject,
  api.updateProject,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully edited a project")
    );
    yield put(getAllProjects.request({}));
    yield put(resetProjectMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to edit a project")
);

const deleteProjectsSaga = createAsyncSaga(
  deleteProjects,
  api.deleteProjects,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully deleted a project")
    );
    yield put(getAllProjects.request({}));
    yield put(resetProjectMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to delete a project")
);

const enterProjectSaga = createAsyncSaga(
  enterProject,
  api.enterProject,
  undefined,
  function () {
    window.location.replace("/projects");
  }
);

const deleteBulkProjectMappingsSaga = createAsyncSaga(
  deleteProjectMappings,
  api.deleteProjectMappings,
  makeDefaultSuccessMessageEffect(
    "Successfully deleted all mappings to a project"
  ),
  makeDefaultErrorMessageEffect("Failed to delete all mappings to a project")
);

export default function* projectsRootSaga() {
  yield takeLatest(GET_ALL_PROJECTS_TYPES.REQUEST, getAllProjectsSaga);
  yield takeLatest(GET_PROJECTS_PER_PAGE_TYPES.REQUEST, getProjectsPerPageSaga);
  yield takeLatest(CREATE_PROJECT_TYPES.REQUEST, createProjectSaga);
  yield takeLatest(UPDATE_PROJECT_TYPES.REQUEST, updateProjectSaga);
  yield takeLatest(DELETE_PROJECTS_TYPES.REQUEST, deleteProjectsSaga);
  yield takeLatest(ENTER_PROJECT_TYPES.REQUEST, enterProjectSaga);
  yield takeLatest(
    DELETE_PROJECT_MAPPINGS_TYPES.REQUEST,
    deleteBulkProjectMappingsSaga
  );
}
