import { forwardRef, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { TabPanel } from "@mui/lab";
import Paper from "@mui/material/Paper";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { ViewerToolbarState } from "hooks/useViewerToolbar";
import { styled } from "@mui/material/styles";
import {
  PAN_ITEMS,
  TOGGLE_SHORTCUT_ITEM,
  ZOOM_ITEMS,
} from "./ToolbarConstants";
import map from "lodash/map";

const ShortcutListContainer = styled("div")({
  width: "100%",
  padding: "16px 32px",
});

interface ShortcutListItemProps {
  icon: React.ReactNode;
  title: string;
  shortcut: string;
}

const ShortcutTextContainer = styled("div")(({ theme }) => ({
  left: 276,
  position: "absolute",
  "& > .MuiTypography-root": {
    marginRight: 8,
    padding: "4px 8px",
    border: `1px solid ${theme.palette.darkGrey[70]}`,
    borderRadius: "8px",
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const ShortcutListItem = ({ icon, title, shortcut }: ShortcutListItemProps) => {
  const shortcutPieces = shortcut.split("+");
  return (
    <ListItem
      sx={{
        height: 44,
        padding: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        color: (theme) => theme.palette.darkGrey[0],
        position: "relative",
      }}
    >
      <ListItemIcon
        sx={{
          color: "inherit",
          padding: "0 4px",
          marginRight: 1,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "body5",
          color: "inherit",
        }}
      />
      <ShortcutTextContainer>
        {map(shortcutPieces, (piece, index) => (
          <Typography
            variant="body6"
            color="darkGrey.30"
            key={`${piece}_${index}`}
          >
            {piece.replace("=", "+")}
          </Typography>
        ))}
      </ShortcutTextContainer>
    </ListItem>
  );
};

function ViewPanel() {
  return (
    <TabPanel
      value="view"
      sx={{
        maxHeight: 434,
        overflowY: "auto",
      }}
    >
      <ShortcutListContainer>
        <List sx={{ padding: 0 }}>
          {map([TOGGLE_SHORTCUT_ITEM, ...PAN_ITEMS], (item) => (
            <ShortcutListItem
              key={item.id}
              title={item.title}
              icon={item.icon}
              shortcut={item.shortcut}
            />
          ))}
          {map(ZOOM_ITEMS, (item) => (
            <ShortcutListItem
              key={item.id}
              title={item.title}
              icon={item.icon}
              shortcut={item.shortcut}
            />
          ))}
        </List>
      </ShortcutListContainer>
    </TabPanel>
  );
}
interface KeyboardShortcutPopoverProps extends PopoverProps {
  toolbarState: ViewerToolbarState;
}

const KeyboardShortcutPopover = forwardRef<
  HTMLDivElement,
  KeyboardShortcutPopoverProps
>((props, ref) => {
  const { toolbarState, ...popoverProps } = props;
  const [tab, setTab] = useState("view");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  return (
    <Popover
      {...popoverProps}
      ref={ref}
      PaperProps={{
        sx: {
          marginTop: 0.5,
        },
      }}
    >
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 500,
          height: 544,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#fff",
            height: 64,
          }}
        >
          <Typography variant="subtitle3">Keyboard Shortcuts</Typography>
        </Toolbar>
        <Divider />
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="standard"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab label="View" value={"view"} />
          </Tabs>
          <Divider />
          <ViewPanel />
        </TabContext>
      </Paper>
    </Popover>
  );
});

export default KeyboardShortcutPopover;
