import { componentsStateSelector } from "components/utils";
import { RootState } from "dux/rootReducer";
import { Selector } from "react-redux";
import { createSelector } from "reselect";
import {
  AcceptableFileFormat,
  SingleWSIUploadState,
  WSIFileUploadStage,
  WSIUploadState,
} from "./model";
import reduce from "lodash/reduce";
import { FetchStatus } from "dux/utils/commonEnums";

// selectors
export const getWSIUploadStateSelector: Selector<RootState, WSIUploadState> =
  createSelector(componentsStateSelector, (components) => components.wsiUpload);

export const getWSIKeyOrderSelector: Selector<RootState, string[]> =
  createSelector(getWSIUploadStateSelector, (state) => state.keyOrder);

export const getAnyWSIUploading: Selector<RootState, boolean> = createSelector(
  getWSIUploadStateSelector,
  (state) =>
    reduce(
      state.keyOrder,
      (uploading, key) =>
        uploading ||
        state.states[key].loading ||
        state.states[key].stage === WSIFileUploadStage.ISyntaxConverting,
      false
    )
);

export const getNumUploadedWSISelector: Selector<RootState, number> =
  createSelector(getWSIUploadStateSelector, (state) =>
    reduce(
      state.states,
      (n, singleState) =>
        singleState.stage === WSIFileUploadStage.DONE ? n + 1 : n,
      0
    )
  );

export const getSingleWSIUploadStateByWSIKeySelector: (wsiKey) => Selector<
  RootState,
  SingleWSIUploadState & {
    progress: number;
  }
> = (wsiKey) =>
  createSelector(getWSIUploadStateSelector, (state) => ({
    ...state.states[wsiKey],
    progress:
      state.states[wsiKey].xhrPool.length > 0
        ? (state.states[wsiKey].xhrPool.reduce(
            (loadedSum, xhrState) => loadedSum + xhrState.loaded,
            0
          ) /
            state.states[wsiKey].xhrPool.reduce(
              (totalSum, xhrState) => totalSum + xhrState.total,
              0
            )) *
          100
        : 0,
  }));

export const getPresignedURLsByWSIKeySelector: (
  wsiKey
) => Selector<RootState, SingleWSIUploadState["presignedURLs"]> = (wsiKey) =>
  createSelector(
    getWSIUploadStateSelector,
    (state) => state.states[wsiKey].presignedURLs
  );

export const getWSIIdByWSIKeySelector: (wsiKey) => Selector<RootState, string> =
  (wsiKey) =>
    createSelector(
      getWSIUploadStateSelector,
      (state) => state.states[wsiKey].targetWSIId
    );

export const getAllowedFormatFetchStatusSelector: Selector<
  RootState,
  {
    loading: boolean;
    success: boolean;
    failure: boolean;
  }
> = createSelector(getWSIUploadStateSelector, (wsiUpload) => ({
  loading: wsiUpload.formatsFetchStatus === FetchStatus.Pending,
  success: wsiUpload.formatsFetchStatus === FetchStatus.Fulfilled,
  failure: wsiUpload.formatsFetchStatus === FetchStatus.Rejected,
}));

export const getAllowedFormatsSelector: Selector<
  RootState,
  AcceptableFileFormat[]
> = createSelector(
  getWSIUploadStateSelector,
  (wsiUpload) => wsiUpload.allowedFormats
);

export const getSlideLimitSelector: Selector<
  RootState,
  { limitSlideCount: number; currentSlideCount: number }
> = createSelector(getWSIUploadStateSelector, (wsiUpload) => ({
  limitSlideCount: wsiUpload.limitSlideCount,
  currentSlideCount: wsiUpload.currentSlideCount,
}));
