import React, { useCallback, useState, useLayoutEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import accessTokenManager from "auth/accessTokenManager";
import { UserTokenOut } from "dux/user/model";
import LoginForm from "components/auth/LoginForm";
import AuthTemplate from "components/auth/AuthTemplate";
import RegisterMFAForm from "components/auth/RegisterMFAForm";
import VerifyMFAForm from "components/auth/VerifyMFAForm";
import { LoginFormType } from "dux/utils/commonEnums";
import useQueryParams from "components/hooks/useQueryParams";
import { useDispatch } from "react-redux";
import { userLogin } from "dux/user/actions";

export interface VerifyMFAQueryParams {
  verify: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formType, setFormType] = useState<LoginFormType>(LoginFormType.Login);
  const [qrcode, setQrcode] = useState<string>(null);
  const [status, setStatus] = useState<boolean>(false);

  const { verify } = useQueryParams<VerifyMFAQueryParams>();

  const handleLoginSuccess = useCallback(
    (newTokenOut: UserTokenOut) => {
      accessTokenManager.setAccessToken(newTokenOut.accessToken);
      accessTokenManager.setRefreshToken(newTokenOut.refreshToken);
      if (!newTokenOut.mfaRequired) {
        dispatch(userLogin());
        return history.replace("/projects");
      }
      setQrcode(newTokenOut.mfaQrcode);
      setStatus(true);
      setFormType(
        newTokenOut.mfaRegistered
          ? LoginFormType.VerifyMFA
          : LoginFormType.RegisterMFA
      );
    },
    [dispatch, history]
  );

  const handleVerifyMFASuccess = useCallback(
    (newTokenOut: UserTokenOut) => {
      accessTokenManager.setAccessToken(newTokenOut.accessToken);
      accessTokenManager.setRefreshToken(newTokenOut.refreshToken);
      dispatch(userLogin());
      history.replace("/projects");
    },
    [dispatch, history]
  );

  const handleBackToLogin = useCallback(() => {
    setFormType(LoginFormType.Login);
  }, []);

  useLayoutEffect(() => {
    if (!accessTokenManager.hasAccessTokenInStorage()) {
      setFormType(LoginFormType.Login);
    }
  }, [formType]);

  useLayoutEffect(() => {
    if (
      (formType === LoginFormType.RegisterMFA && qrcode === null) ||
      (formType === LoginFormType.VerifyMFA && status === false && !verify)
    ) {
      accessTokenManager.removeAccessToken();
      accessTokenManager.removeRefreshToken();
      setFormType(LoginFormType.Login);
    }
    if (verify && formType === LoginFormType.Login) {
      setStatus(true);
      setFormType(LoginFormType.VerifyMFA);
    }
  }, [formType, qrcode, status, verify]);

  if (verify) {
    return <Redirect to="/login" />;
  }

  const renderForm = () => {
    switch (formType) {
      case LoginFormType.VerifyMFA:
        return (
          <VerifyMFAForm
            onSuccess={handleVerifyMFASuccess}
            onBackToLogin={handleBackToLogin}
          />
        );
      case LoginFormType.RegisterMFA:
        return (
          <RegisterMFAForm
            qrcode={qrcode}
            onSuccess={handleVerifyMFASuccess}
            onBackToLogin={handleBackToLogin}
          />
        );
      default:
        return <LoginForm onSuccess={handleLoginSuccess} />;
    }
  };

  return <AuthTemplate>{renderForm()}</AuthTemplate>;
};

export default Login;
