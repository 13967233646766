import { TextField, TextFieldProps } from "@mui/material";

export default function AuthFormField(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      sx={{
        display: "flex",
        marginBottom: 2,
      }}
      variant="outlined"
      hiddenLabel={true}
    />
  );
}
