import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Loader from "react-loader-spinner";

const LoadingOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(36, 46, 62, 0.5)",
});
const LoadingBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 470,
  height: 280,
  marginLeft: -235,
  marginTop: -140,
  backgroundColor: "#242E3E",
  color: "#fafafb",
});

const LoadingIndicator = ({
  message,
  detail,
}: {
  message?: string;
  detail?: string;
}) => {
  return (
    <LoadingOverlay>
      <LoadingBox>
        <div style={{ marginBottom: 10 }}>
          <Loader type="Bars" color="#7292FD" height={70} width={70} />
        </div>
        <Typography variant="body1">
          {message || "Visualizing Data.."}
        </Typography>
        <Typography variant="body1">
          {detail || "Please wait, loading data may take a minute."}
        </Typography>
      </LoadingBox>
    </LoadingOverlay>
  );
};

export default LoadingIndicator;
