import { useDispatch, useSelector } from "react-redux";
import useSheets from "components/hooks/useSheets";
import { useContext, useEffect } from "react";
import { DialogContext } from "components/Dialog/context";
import { Typography } from "@mui/material";
import { getReportStateSelector } from "dux/WSIAnalysisResult/selectors";
import ReportProgressSheet from "components/report/sheet/ReportProgressSheet";
import {
  closeReportProgress,
  doneGeneratingReport,
} from "dux/WSIAnalysisResult/actions";

const SHEET_KEY = "downloading-report";

export default function DownloadingReportSheetManager() {
  const dispatch = useDispatch();
  const { putSheet, removeSheet } = useSheets();
  const { handleDialog } = useContext(DialogContext);
  const { wsiIds, states, progressClosed } = useSelector(
    getReportStateSelector
  );

  const done = wsiIds.map((id) => states[id]).filter((value) => value.done);

  useEffect(() => {
    if (wsiIds.length > 0 && !progressClosed) {
      putSheet({
        key: SHEET_KEY,
        title: "Downloading report files",
        subtitle: `${done.length}/${wsiIds.length}`,
        paperStyle: {
          width: 500,
        },
        content: <ReportProgressSheet />,
        onClose: () => {
          if (done.length === wsiIds.length) {
            dispatch(doneGeneratingReport());
          } else {
            handleDialog({
              width: 320,
              agree: "Close",
              disagree: "Do Not Close",
              content: (
                <>
                  <Typography variant="subtitle3" sx={{ marginBottom: 1 }}>
                    Close?
                  </Typography>
                  <Typography variant="body5" sx={{ marginBottom: 1 }}>
                    App will continue to download PDF reports even though
                    download progress is closed.
                  </Typography>
                </>
              ),
              handleAgreementCallback: () => {
                dispatch(closeReportProgress());
              },
              disableBackdropClick: true,
            });
          }
          return false;
        },
      });
    } else {
      removeSheet(SHEET_KEY);
    }
  }, [
    dispatch,
    wsiIds,
    states,
    handleDialog,
    putSheet,
    removeSheet,
    done.length,
    progressClosed,
  ]);

  return null;
}
