import { FormEvent, useCallback, useState } from "react";
import { Link } from "@mui/material";
import AuthActionButton from "./AuthActionButton";
import AuthFooter from "./AuthFooter";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { loginUser } from "dux/user/api";
import { UserTokenOut } from "dux/user/model";
import { useAuthLoadingStateContext } from "./AuthTemplate";

interface LoginFormProps {
  onSuccess?: (tokenOut: UserTokenOut) => void;
}

export default function LoginForm({ onSuccess }: LoginFormProps) {
  const { setLoading } = useAuthLoadingStateContext();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const actionDisabled = !email.trim() || !password.trim();
  const hasError = !!errorMessage.trim();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      loginUser({ email, password })
        .then((res: UserTokenOut) => {
          onSuccess?.(res);
        })
        .catch((error) => {
          if (String(error.details).includes("activate")) {
            setErrorMessage(
              "Customer is not yet activated. Please contact your administrator or email cs-scope-io@lunit.io"
            );
          } else {
            setErrorMessage(
              String(error.status).substring(0, 1) === "4"
                ? "Email or password is incorrect."
                : "Something went wrong while logging in. Please try again."
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [email, password, onSuccess, setLoading]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>Log In</AuthHeading>
      <AuthFormField
        id="email"
        type="email"
        aria-label="Email"
        placeholder="Email*"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={hasError}
      />
      <AuthFormField
        id="password"
        type="password"
        aria-label="Password"
        placeholder="Password*"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={hasError}
        helperText={errorMessage}
      />
      <AuthActionButton disabled={actionDisabled}>Log in</AuthActionButton>
      <AuthNav>
        <Link href="/forgot-password">Forgot your password?</Link>
        <Link href="mailto:cs-scope-io@lunit.io">cs-scope-io@lunit.io</Link>
      </AuthNav>
      <AuthFooter />
    </AuthForm>
  );
}
