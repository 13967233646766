import { RootState } from "dux/rootReducer";
import { createSelector, Selector } from "reselect";
import { SnackbarState } from "./model";

const snackbarStateSelector: Selector<RootState, SnackbarState> = (state) =>
  state.snackbar;

export const snackbarsSelector: Selector<
  RootState,
  SnackbarState["snackbars"]
> = createSelector(snackbarStateSelector, (snackbar) => snackbar.snackbars);
