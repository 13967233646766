/**
 * Slide Reducer
 */
import { ActionType, createReducer, StateType } from "typesafe-actions";
import produce from "immer";

import {
  APISlideSummaryList,
  DEFAULT_MPP,
  Slide,
  SlideAnalyzeRequestFetchState,
  SlideErrorInfo,
  SlideMgmtState,
  SlideStatus,
  SlideViewerListParams,
} from "./model";
import { FetchStatus } from "dux/utils/commonEnums";
import { combineReducers } from "redux";
import {
  DELETE_SLIDE_SUMMARIES_TYPES,
  deleteSlideSummaries,
  GET_SLIDE_SUMMARIES_TYPES,
  getSlideSummaries,
  OPEN_SLIDE_VIEWER_TYPE,
  OpenSlideViewerAction,
  REQUEST_SLIDES_ANALYZE_TYPES,
  requestSlidesAnalyze,
  UPDATE_SLIDE_SUMMARY_TYPES,
  updateSlideSummary,
  requestSlideErrorInfo,
  REQUEST_SLIDES_ERROR_INFO_TYPES,
  BULK_UPDATE_CANCER_TYPES,
  RESET_SLIDE_MGMT_STATE,
  SYNC_DRAWER_SLIDE_LIST,
  syncDrawerSlideList,
  bulkUpdateCancerTypes,
  REQUEST_SLIDE_INFERENCES_TYPES,
  requestSlideInferences,
} from "dux/slide/actions";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { EventStatus, EventType } from "hooks/useStompClient";
import {
  getAnalysisSourceDetailsById,
  GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES,
} from "dux/analysis/actions";
import { apiSlideStatusToEnum } from "components/SlideList/common/literals";
import forEach from "lodash/forEach";

export const slideDetailInitialState: Slide = {
  name: "",
  metadata: {
    dziUrl: "",
    globUrl: "",
    tileIndexUrl: "",
  },
  mpp: DEFAULT_MPP,
};

const slideDetailReducer = createReducer<Slide>(slideDetailInitialState, {
  // [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.SUCCESS]: (
  //   state,
  //   action: ActionType<typeof getAnalysisSourceDetailsById.success>
  // ) =>
  //   produce(state, (draft) => {
  //     const { payload } = action;
  //     const slideData = payload.wsiProperties;
  //     draft.metadata.dziUrl = payload.dziUrl;
  //     draft.metadata.globUrl = slideData.globUrl;
  //     draft.metadata.tileIndexUrl = slideData.tileIndexUrl;
  //     draft.mpp = slideData.mppX;
  //     draft.name = payload.wsiFileName;
  //   }),
  // [GET_WSI_DETAIL_TYPES.SUCCESS]: (
  //   state,
  //   action: ActionType<typeof getWSIDetail.success>
  // ) =>
  //   produce(state, (draft) => {
  //     const { payload } = action;
  //     draft.metadata.dziUrl = payload.dziUrl;
  //     draft.mpp = payload.mppX;
  //     draft.name = payload.fileName;
  //   }),
  // [RESET_ANALYSIS_RESULT]: (_) => slideDetailInitialState,
});

export const DEFAULT_ITEM_SIZE = 15;
const slideListInitialState: APISlideSummaryList = {
  contents: [],
  first: true,
  last: true,
  size: DEFAULT_ITEM_SIZE,
  totalElements: 0,
  totalPages: 0,
};

const slideListReducer = createReducer<APISlideSummaryList>(
  slideListInitialState,
  {
    [GET_SLIDE_SUMMARIES_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getSlideSummaries.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.contents = payload.contents;
        draft.totalPages = payload.totalPages;
        draft.first = payload.first;
        draft.last = payload.last;
        draft.size = payload.size;
        draft.totalElements = payload.totalElements;
      }),
    [UPDATE_SLIDE_SUMMARY_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof updateSlideSummary.success>
    ) =>
      produce(state, (draft) => {
        const index = state.contents.findIndex(
          (slide) => slide.wsiId === action.payload.wsiId
        );
        if (index >= 0) {
          draft.contents[index].tagIds = action.payload.mappedTags;
          draft.contents[index].projectIds = action.payload.mappedProjects;
          draft.contents[index].name = action.payload.name;
          if (action.payload.cancerType) {
            draft.contents[index].cancerType = action.payload.cancerType;
          }
        }
      }),
    [BULK_UPDATE_CANCER_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof bulkUpdateCancerTypes.success>
    ) =>
      produce(state, (draft) => {
        forEach(action.payload, (updatedSlide) => {
          const index = state.contents.findIndex(
            (slide) => slide.wsiId === updatedSlide.wsiId
          );
          if (index >= 0) {
            draft.contents[index].cancerType = updatedSlide.cancerType;
          }
        });
      }),
  }
);

const slideMgmtInitialState: SlideMgmtState = {
  currentMethod: FetchMethod.Get,
  fetchStatus: FetchStatus.Idle,
  error: null,
};

const slideMgmtStateReducer = createReducer<SlideMgmtState>(
  slideMgmtInitialState,
  {
    [RESET_SLIDE_MGMT_STATE]: () => slideMgmtInitialState,
    [GET_SLIDE_SUMMARIES_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Get;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_SLIDE_SUMMARIES_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [GET_SLIDE_SUMMARIES_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof getSlideSummaries.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),

    [UPDATE_SLIDE_SUMMARY_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [UPDATE_SLIDE_SUMMARY_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [UPDATE_SLIDE_SUMMARY_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof updateSlideSummary.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),
    [BULK_UPDATE_CANCER_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [BULK_UPDATE_CANCER_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [BULK_UPDATE_CANCER_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
    [DELETE_SLIDE_SUMMARIES_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [DELETE_SLIDE_SUMMARIES_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_SLIDE_SUMMARIES_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof deleteSlideSummaries.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),
  }
);

const slideAnalyzeRequestFetchInitialState: SlideAnalyzeRequestFetchState = {
  fetchStatus: FetchStatus.Idle,
  error: "",
};

const slideAnalyzeRequestFetchStateReducer =
  createReducer<SlideAnalyzeRequestFetchState>(
    slideAnalyzeRequestFetchInitialState,
    {
      [REQUEST_SLIDES_ANALYZE_TYPES.REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.fetchStatus = FetchStatus.Pending;
        }),
      [REQUEST_SLIDES_ANALYZE_TYPES.SUCCESS]: (state) =>
        produce(state, (draft) => {
          draft.fetchStatus = FetchStatus.Fulfilled;
        }),
      [REQUEST_SLIDES_ANALYZE_TYPES.FAILURE]: (
        state,
        action: ActionType<typeof requestSlidesAnalyze.failure>
      ) =>
        produce(state, (draft) => {
          const { payload } = action;
          draft.fetchStatus = FetchStatus.Rejected;
          draft.error = payload;
        }),
      [REQUEST_SLIDE_INFERENCES_TYPES.REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.fetchStatus = FetchStatus.Pending;
        }),
      [REQUEST_SLIDE_INFERENCES_TYPES.SUCCESS]: (state) =>
        produce(state, (draft) => {
          draft.fetchStatus = FetchStatus.Fulfilled;
        }),
      [REQUEST_SLIDE_INFERENCES_TYPES.FAILURE]: (
        state,
        action: ActionType<typeof requestSlideInferences.failure>
      ) =>
        produce(state, (draft) => {
          const { payload } = action;
          draft.fetchStatus = FetchStatus.Rejected;
          draft.error = payload;
        }),
    }
  );

const slideErrorInfoInitialState: SlideErrorInfo = {
  id: "",
  status: "",
  message: {
    version: "",
    code: "",
    message: "",
    details: "",
  },
  fetchStatus: FetchStatus.Idle,
  error: "",
};

const slideErrorInfoReducer = createReducer<SlideErrorInfo>(
  slideErrorInfoInitialState,
  {
    [REQUEST_SLIDES_ERROR_INFO_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof requestSlideErrorInfo.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.id = payload.id;
        draft.message = payload.message;
        draft.status = payload.status;
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [REQUEST_SLIDES_ERROR_INFO_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [REQUEST_SLIDES_ERROR_INFO_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof requestSlideErrorInfo.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),
  }
);

const slideViewerParamsInitialState: SlideViewerListParams = {
  selectedSlides: [],
};

const slideViewerParamsReducer = createReducer<SlideViewerListParams>(
  slideViewerParamsInitialState,
  {
    [OPEN_SLIDE_VIEWER_TYPE]: (state, action: OpenSlideViewerAction) =>
      produce(state, (draft) => {
        const { slide, selectedSlides, projectId, history } = action.payload;
        if (history) {
          draft.selectedSlides =
            selectedSlides && selectedSlides.length > 0
              ? selectedSlides
              : [slide];
          history.push(
            projectId
              ? `/projects/${projectId}/slides/${slide.id}`
              : `/slides/${slide.id}`
          );
        }
      }),
    [UPDATE_SLIDE_SUMMARY_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof updateSlideSummary.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        const index = state.selectedSlides.findIndex(
          (slide) => slide.wsiId === payload.wsiId
        );
        if (index >= 0) {
          draft.selectedSlides[index] = {
            ...state.selectedSlides[index],
            name: payload.name,
            tags: payload.mappedTags,
            projects: payload.mappedProjects,
          };
        }
      }),
    [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getAnalysisSourceDetailsById.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        const index = state.selectedSlides.findIndex(
          (slide) => slide.wsiId === payload.wsiId
        );
        if (index >= 0) {
          draft.selectedSlides[index] = {
            ...state.selectedSlides[index],
            status: apiSlideStatusToEnum(payload.information.status),
          };
        }
      }),
    [SYNC_DRAWER_SLIDE_LIST]: (
      state,
      action: ActionType<typeof syncDrawerSlideList>
    ) =>
      produce(state, (draft) => {
        const { events } = action.payload;
        events.forEach((event) => {
          const index = state.selectedSlides.findIndex(
            (slide) => slide.wsiId === event.slideInfo.id
          );
          if (index >= 0) {
            if (event.status === EventStatus.Fail) {
              draft.selectedSlides[index] = {
                ...state.selectedSlides[index],
                status: SlideStatus.AnalFailed,
              };
            } else if (event.type === EventType.PreAnalysis) {
              draft.selectedSlides[index] = {
                ...state.selectedSlides[index],
                status: SlideStatus.Ready,
              };
            } else if (event.type === EventType.Analysis) {
              draft.selectedSlides[index] = {
                ...state.selectedSlides[index],
                status: SlideStatus.Analyzed,
              };
            }
          }
        });
      }),
  }
);

const slideReducer = combineReducers({
  slideDetail: slideDetailReducer,
  slideSummaries: slideListReducer,
  slideMgmtState: slideMgmtStateReducer,
  slideErrorInfo: slideErrorInfoReducer,
  slideAnalyzeRequestFetchState: slideAnalyzeRequestFetchStateReducer,
  slideViewerListParams: slideViewerParamsReducer,
});

export const initialState = {
  slideDetail: slideDetailInitialState,
  slideSummaries: slideListInitialState,
  slideMgmtState: slideMgmtInitialState,
  slideErrorInfo: slideErrorInfoInitialState,
  slideAnalyzeRequestFetchState: slideAnalyzeRequestFetchInitialState,
  slideViewerListParams: slideViewerParamsInitialState,
};

export type SlideState = StateType<typeof slideReducer>;

export default slideReducer;
