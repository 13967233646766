import {
  createAsyncAction,
  AsyncActionCreatorBuilder,
  action,
} from "typesafe-actions";
import { call, put } from "redux-saga/effects";
import { enqueueSnackbar } from "dux/snackbar/actions";
import { ErrorMessage } from "./apiRequestHelper";

export const logout = () => action("USER_LOGOUT");

type AsyncAction = {
  REQUEST?: string;
  SUCCESS?: string;
  FAILURE?: string;
};

/**
 * A util to handle REQUEST/ SUCCESS/ FAILURE cases at a time
 * using typesafe-action's createAsyncAction API
 * The order of generic in <T, P, J> -> <request, success, failure>
 * Usage: asyncAction[type: request | success | failure](requestParams)
 */
export const asyncAction = <T, P, J>(asyncAction: AsyncAction) =>
  createAsyncAction(
    asyncAction.REQUEST,
    asyncAction.SUCCESS,
    asyncAction.FAILURE
  )<T, P, J>();

/**
 * A util to create a tuple of async action types at a time like
 * ${actionName}_REQUEST / ${actionName}_SUCCESS / ${actionName}_FAILURE
 */
export const asyncActionCreator = (actionName: string): AsyncAction => {
  const asyncTypeAction: Array<string> = ["_REQUEST", "_SUCCESS", "_FAILURE"];
  return {
    REQUEST: `${actionName}${asyncTypeAction[0]}`,
    SUCCESS: `${actionName}${asyncTypeAction[1]}`,
    FAILURE: `${actionName}${asyncTypeAction[2]}`,
  };
};

type PromiseCreatorFunction<P, T> =  // handle both \w & \wo payload
  | ((payload: P) => Promise<T>)
  | (() => Promise<T>);

/**
 * A fully typed async saga action handler
 */
export function createAsyncSaga<
  RequestType,
  RequestPayload,
  SuccessType,
  SuccessPayload,
  FailureType,
  FailurePayload
>(
  asyncAction: AsyncActionCreatorBuilder<
    [RequestType, [RequestPayload, undefined]],
    [SuccessType, [SuccessPayload, undefined]],
    [FailureType, [FailurePayload, undefined]]
  >,
  asyncFunction: PromiseCreatorFunction<RequestPayload, SuccessPayload>,
  successFunc?: any,
  failureFunc?: any
) {
  return function* saga(action: ReturnType<typeof asyncAction.request>) {
    try {
      const result: SuccessPayload = yield call(
        // call API & pass payload
        asyncFunction,
        (action as any).payload
      );
      // Call success function & return API call response
      yield put(asyncAction.success(result));
      if (successFunc) {
        // Execute an additional callback function if necessary
        yield call(successFunc, result, (action as any).payload);
      }
    } catch (e) {
      if (e.message === ErrorMessage.AuthError) {
        yield put({ type: "components/FileUpload/ABORT_ALL_WSI_UPLOAD" });
        yield put(logout());
        alert("Server returned 401 error. Possible expired token. Logging out");
      }
      // Call failure function & return error message
      yield put(asyncAction.failure(e.details || e.message));
      //  Sentry.captureException(e);
      if (failureFunc) {
        // Execute an additional callback function if necessary
        yield call(
          failureFunc,
          e.details || e.message,
          (action as any).payload
        );
      }
    }
  };
}

// @todo createAsynSaga로 이름 변경
export function createAsyncSagaV2<
  RequestType,
  RequestPayload,
  SuccessType,
  SuccessPayload,
  FailureType,
  FailurePayload
>(
  asyncAction: AsyncActionCreatorBuilder<
    [RequestType, [RequestPayload, undefined]],
    [SuccessType, [SuccessPayload, undefined]],
    [FailureType, [FailurePayload, undefined]]
  >,
  asyncFunction: PromiseCreatorFunction<RequestPayload, SuccessPayload>,
  successFunc?: any,
  failureFunc?: any
) {
  return function* saga(action: ReturnType<typeof asyncAction.request>) {
    try {
      const result: SuccessPayload = yield call(
        // call API & pass payload
        asyncFunction,
        (action as any).payload
      );
      // Call success function & return API call response
      yield put(asyncAction.success(result));
      if (successFunc) {
        // Execute an additional callback function if necessary
        yield call(successFunc, result, (action as any).payload);
      }
    } catch (e) {
      // Call failure function & return error message
      if (e.message === ErrorMessage.AuthError) {
        yield put({ type: "components/FileUpload/ABORT_ALL_WSI_UPLOAD" });
        yield put(logout());
        alert("Server returned 401 error. Possible expired token. Logging out");
      }
      yield put(asyncAction.failure(e));
      //  Sentry.captureException(e);
      if (failureFunc) {
        // Execute an additional callback function if necessary
        yield call(failureFunc, e, (action as any).payload);
      }
    }
  };
}

export const makeDefaultSuccessMessageEffect = (msg) =>
  function* () {
    yield put(
      enqueueSnackbar({
        message: msg || "Remote action successful.",
        options: {
          variant: "success",
        },
      })
    );
  };

export const makeDefaultErrorMessageEffect = (baseMsg) =>
  function* (error) {
    yield put(
      enqueueSnackbar({
        message: `${baseMsg}: ${
          error.details || error.message || error || "Unknown server error"
        }`,
        options: {
          variant: "error",
        },
      })
    );
  };
