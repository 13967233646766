import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";

interface ISlideDndZoneProps {
  currentSlideCount: number;
  slideLimit: number;
  noSlideLimit: boolean;
}

const DndContainer = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "100px",
});

export const SlideDndZone = ({
  currentSlideCount,
  slideLimit,
  noSlideLimit,
}: ISlideDndZoneProps) => {
  const theme = useTheme();
  return (
    <DndContainer>
      <Typography
        variant="h3"
        color={theme.palette.darkGrey[15]}
        sx={{ marginBottom: "16px" }}
      >
        Drag and drop your slide files here
      </Typography>
      <Typography variant="subtitle3" color={theme.palette.darkGrey[40]}>
        Support file format
      </Typography>
      <Typography variant="subtitle3" color={theme.palette.darkGrey[15]}>
        {`Leica (.svs), 3DHistech(.mrxs), and Philips(.isyntax, .tiff)`}
      </Typography>
      <br />
      <Typography variant="subtitle3" color={theme.palette.darkGrey[40]}>
        Allowed number of slides
      </Typography>
      <Typography variant="subtitle3" color={theme.palette.darkGrey[15]}>
        {`You have uploaded ${currentSlideCount} slides in Lunit SCOPE Product.`}
      </Typography>
      {!noSlideLimit && (
        <Typography variant="subtitle3" color={theme.palette.darkGrey[15]}>
          {`The number of slides cannot exceed ${slideLimit}.`}
        </Typography>
      )}
    </DndContainer>
  );
};
