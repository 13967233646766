import { FetchStatus } from "dux/utils/commonEnums";
import produce from "immer";
import min from "lodash/min";
import max from "lodash/max";
import { combineReducers } from "redux";
import { ActionType, createReducer, StateType } from "typesafe-actions";
import {
  getAnalysisSourceDetailsById,
  GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES,
  RESET_ANALYSIS_STATE,
  RESET_VIEWER_STATE,
  setPhysicalWidthPx,
  setZoom,
  SET_PHYSICAL_WIDTH_PX,
  SET_ZOOM,
  updateInferenceResultLoaded,
  UPDATE_INFERENCE_RESULT_LOADED,
} from "./actions";
import {
  AnalysisState,
  SlideViewerState,
  ViewOption,
  ViewOptionItem,
} from "./model";
import find from "lodash/find";

const viewerInitialState: SlideViewerState = {
  sourceDetails: null,
  fetchStatus: FetchStatus.Idle,
  inferenceResultLoaded: false,
  refPoint: null,
  zoom: 1,
  // Physical width of the image (slide) in browser px
  // (WsiViewerZoom * ((physicalWidthPx * 10) / viewport.containerSize.x) = ViewportZoom)
  physicalWidthPx: 0,
};

// function getCellItemsFromViewOptions(
//   options?: ViewOption[]
// ): LabelIdWithColor[] {
//   if (!options) return [];
//   const histologicalFeatOption = find(options, (option) =>
//     option.title.includes("Histological Features")
//   );
//   if (!histologicalFeatOption) return [];
//   return (histologicalFeatOption.items as ViewOptionItem[])
//     .filter((item) => item.type === "POINT")
//     .map((mapItem) => ({
//       labelId: mapItem.id,
//       color: mapItem.color,
//     }));
// }

// function getTissueItemsFromViewOptions(
//   options?: ViewOption[]
// ): LabelIdWithColor[] {
//   if (!options) return [];
//   const histologicalFeatOption = find(options, (option) =>
//     option.title.includes("Histological Features")
//   );
//   if (!histologicalFeatOption) return [];
//   return (histologicalFeatOption.items as ViewOptionItem[])
//     .filter((item) => item.type === "MASK")
//     .map((mapItem) => ({
//       labelId: mapItem.id,
//       color: mapItem.color,
//     }));
// }

const DEFAULT_HISTOLOGICAL_FEATURES: ViewOptionItem[] = [
  {
    id: "cancer area",
    type: "MASK",
    title: "Cancer Area",
    color: "#0CA678",
  },
  {
    id: "cancer stroma",
    type: "MASK",
    title: "Cancer Stroma",
    color: "#00F3FF",
  },
  {
    id: "lymphoplasma cell",
    type: "POINT",
    title: "Lymphoplasma Cell",
    color: "#FFF950",
  },
  {
    id: "tumor cell",
    type: "POINT",
    title: "Tumor Cell",
    color: "#F76707",
  },
];

function getHistologicalFeatures(options: ViewOption[]): ViewOptionItem[] {
  const histologicalFeatOption = find(options, (option) =>
    option.title.includes("Histological Features")
  );
  if (!histologicalFeatOption) return DEFAULT_HISTOLOGICAL_FEATURES;
  if (histologicalFeatOption.items.length < 1)
    return DEFAULT_HISTOLOGICAL_FEATURES;
  return histologicalFeatOption.items as ViewOptionItem[];
}

const viewerReducer = createReducer<SlideViewerState>(viewerInitialState, {
  [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.inferenceResultLoaded = false;
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof getAnalysisSourceDetailsById.success>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.sourceDetails = {
        ...payload,
        information: {
          ...payload.information,
          qualityControl: payload.qualityControl,
        },
      };

      if (!!payload.analysisResultDetail) {
        const histologicalFeatures = getHistologicalFeatures(
          payload.analysisResultDetail.viewOptions.items
        );
        draft.sourceDetails.analysisResultDetail.viewOptions.items[1].items =
          histologicalFeatures;
      }
      draft.inferenceResultLoaded = !payload.analysisResultDetail;
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.FAILURE]: (
    state,
    action: ActionType<typeof getAnalysisSourceDetailsById.failure>
  ) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
    }),
  [RESET_VIEWER_STATE]: (_) => viewerInitialState,
  [UPDATE_INFERENCE_RESULT_LOADED]: (
    state,
    action: ActionType<typeof updateInferenceResultLoaded>
  ) =>
    produce(state, (draft) => {
      draft.inferenceResultLoaded = action.payload;
    }),
  [GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.FAILURE]: (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.Rejected;
    }),
  [SET_ZOOM]: (state, action: ActionType<typeof setZoom>) =>
    produce(state, (draft) => {
      let clampedZoom = min([max([action.payload.zoom, 0.1]), 160]);
      draft.zoom = clampedZoom;
      draft.refPoint = action.payload.refPoint;
    }),
  [SET_PHYSICAL_WIDTH_PX]: (
    state,
    action: ActionType<typeof setPhysicalWidthPx>
  ) =>
    produce(state, (draft) => {
      draft.physicalWidthPx = action.payload;
    }),
  [RESET_ANALYSIS_STATE]: () => viewerInitialState,
});

const analysisReducer = combineReducers({
  viewer: viewerReducer,
});

export const initialState: AnalysisState = {
  viewer: viewerInitialState,
};

export type AnalysisReducerState = StateType<typeof analysisReducer>;

export default analysisReducer;
