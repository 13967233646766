import { getCancers } from "dux/cancerTypes/actions";
import { FetchStatus } from "dux/utils/commonEnums";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancerListSelector } from "dux/cancerTypes/selectors";

interface UseCancerTypesProps {
  refetch?: boolean;
}

const useCancerTypes = ({ refetch = false }: UseCancerTypesProps) => {
  const dispatch = useDispatch();
  const { fetchStatus, list } = useSelector(cancerListSelector);
  useEffect(() => {
    if (refetch && fetchStatus === FetchStatus.Idle) {
      dispatch(getCancers.request({}));
    }
  }, [refetch, fetchStatus, dispatch]);
  return {
    fetchStatus,
    cancerTypes: list.map((cancer) => cancer),
  };
};

export default useCancerTypes;
