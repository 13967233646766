import { Box, InputLabel, TextField, Typography } from "@mui/material";

import FormTemplate, { CommonFormProps } from "components/Forms/Template";
import useCancerTypeCRUD from "components/hooks/useCancerTypeCRUD";
import { useEffect, useRef, useState } from "react";
import { SlideItem } from "dux/slide/model";
import SingleAutocomplete from "../common/SingleAutocomplete";
import { APICancerType } from "dux/cancerTypes/model";

interface ICancerTypeDialogContentProps extends CommonFormProps {
  selectedSlides: SlideItem[];
}

export default function CancerTypeForm({
  onCancel,
  onSubmit,
  open,
  loading,
  selectedSlides,
}: ICancerTypeDialogContentProps) {
  const [selectedOption, setSelectedOption] = useState<APICancerType>(null);
  const [defaultValue, setDefaultValue] = useState<APICancerType>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    cancerTypes,
    requestCreateCancerType,
    requestEditCancerType,
    requestDeleteCancerType,
    cancerTypeMgmtState: { loading: cancerTypeLoading },
  } = useCancerTypeCRUD({
    onCreated: (created) => {
      // just put newly created one
      setSelectedOption(created);
      inputRef?.current?.blur && inputRef.current.blur();
    },
    onEdited: (created) => {
      // if user changed previous one, choose new one
      // otherwise keep previous one
      setSelectedOption((prevState) =>
        prevState?.id === created?.id ? created : prevState
      );
    },
    onDelete: (id) => {
      // change it to default if the user deleted previously selected one
      setSelectedOption((prevState) =>
        prevState?.id === id ? defaultValue : prevState
      );
    },
  });

  useEffect(() => {
    if (selectedSlides && selectedSlides.length && open) {
      setSelectedOption((prevState) => {
        if (!prevState) {
          // previously undefined.
          const newlySelected = cancerTypes.find(
            (cancerType) => cancerType.name === selectedSlides[0].cancerType
          );
          // only one slide is selected and there is same cancer type.
          if (selectedSlides.length === 1 && newlySelected) {
            return newlySelected;
          } else {
            // else then just default value.
            return defaultValue;
          }
        }
        // if there is previous value, skip...
        return prevState;
      });
    }
  }, [defaultValue, selectedSlides, cancerTypes, open]);

  useEffect(() => {
    const panCancer = cancerTypes.find(
      (cancerType) => cancerType.name === "Pan cancer"
    );
    setDefaultValue((prevState) =>
      // set pan cancer unless there isn't the same object
      prevState &&
      panCancer &&
      prevState.id === panCancer.id &&
      prevState.name === panCancer.name
        ? prevState
        : panCancer
    );
  }, [cancerTypes]);

  useEffect(() => {
    if (!open) {
      // clean up selected option to set another default value
      setSelectedOption(null);
    }
  }, [open]);

  return (
    <FormTemplate
      open={open}
      onSubmit={(e) => {
        e.preventDefault();
        if (!cancerTypeLoading) {
          onSubmit({ cancerType: selectedOption });
        }
      }}
      onCancel={onCancel}
      loading={loading}
      title="Change Cancer Type"
    >
      <Box
        sx={{
          width: 436,
        }}
      >
        <Typography variant="body5" sx={{ marginBottom: "20px" }}>
          Please choose the cancer type of the selected slides.
        </Typography>
        <InputLabel htmlFor="cancerType" required>
          <Typography variant="body3" color="darkGrey.5">
            Cancer Type
          </Typography>
        </InputLabel>
        <SingleAutocomplete
          totalOptions={cancerTypes}
          selectedOption={selectedOption}
          onChangeValue={(changed) => {
            setSelectedOption(changed);
          }}
          placeholder="Select a cancer type or create one"
          onCreateRequest={requestCreateCancerType}
          onDeleteRequest={requestDeleteCancerType}
          onEditRequest={requestEditCancerType}
          loading={cancerTypeLoading}
          readonlyItems={cancerTypes?.filter(
            (item) => item.name === "Pan cancer"
          )}
          renderInput={(props) => (
            <TextField
              {...props}
              name="cancerType"
              variant="outlined"
              inputRef={(ref) => {
                props.inputRef = ref;
                inputRef.current = ref;
              }}
              inputProps={{
                ...props.inputProps,
                required: !selectedOption,
              }}
              required
            />
          )}
        />
      </Box>
    </FormTemplate>
  );
}
