export enum AnalysisStatus {
  Idle = "IDLE",
  Uploaded = "UPLOADED",
  Prepared = "PREPARED",
  Requested = "REQUESTED",
  Analyzing = "ANALYZING",
  Analyzed = "ANALYZED",
  Failed = "FAILED",
}

export enum FetchStatus {
  Idle = "IDLE",
  Pending = "PENDING",
  Fulfilled = "FULFILLED",
  Rejected = "REJECTED",
}

export enum LoginFormType {
  Login = "Login",
  VerifyMFA = "VerifyMFA",
  RegisterMFA = "RegisterMFA",
}
