import React, { createContext, useEffect, useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import throttle from "lodash/throttle";

type EntryHandler = (entry: Element) => void;

type ObserverEntryHandlers = {
  [key: string]: EntryHandler;
};

interface IResizeObserverContextProps {
  addResizeHandler(target: Element, handler: EntryHandler): void;
  removeResizeHandler(target: Element): void;
}

let ResizeObserverContext: React.Context<IResizeObserverContextProps>;
const { Provider } = (ResizeObserverContext = createContext(
  {} as IResizeObserverContextProps
));

const ResizeObserverProvider = ({ children }) => {
  const observerRef = useRef<ResizeObserver>(null);
  const handlersRef = useRef<ObserverEntryHandlers>({});

  function getObserver(): ResizeObserver {
    if (observerRef.current === null) {
      observerRef.current = new ResizeObserver(
        throttle((entries) => {
          entries.forEach((entry) => {
            const resizeHandlers = handlersRef.current;
            let observerKey =
              entry.target.attributes.getNamedItem("data-resizeobkey");
            if (resizeHandlers[observerKey.value]) {
              resizeHandlers[observerKey.value](entry.target);
            }
          });
        })
      );
    }
    return observerRef.current;
  }

  const addResizeHandler = (target: Element, handler: EntryHandler) => {
    let observerKey = target.attributes.getNamedItem("data-resizeobkey");
    if (observerKey === null) {
      target.setAttribute("data-resizeobkey", uuidV4());
      observerKey = target.attributes.getNamedItem("data-resizeobkey");
    }
    handlersRef.current[observerKey.value] = handler;
    getObserver().observe(target);
  };

  const removeResizeHandler = (target: Element) => {
    const observerKey = target.attributes.getNamedItem("data-resizeobkey");
    if (observerKey !== null) {
      delete handlersRef.current[observerKey.value];
    }
    getObserver().unobserve(target);
  };

  useEffect(
    () => () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    },
    []
  );

  return (
    <Provider
      value={{
        addResizeHandler,
        removeResizeHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { ResizeObserverContext, ResizeObserverProvider };
