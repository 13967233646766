import { useEffect, useState, useMemo } from "react";
import { TabPanel, TabPanelProps } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { SectionContainer, SectionHeader, SectionListItem } from "./common";
import { HiddenIcon, InfoIcon, ShownIcon } from "assets/icons";
import useRightPanelStateContext, {
  HistologicalFeatureOptions,
} from "./context";
import { AnalysisResultDetail, ViewOptionItem } from "dux/analysis/model";
import AnalysisSummary from "./AnalysisSummary";
import ViewOptionsTooltip from "../ViewOptionsTooltip";
import find from "lodash/find";
import map from "lodash/map";
import fromPairs from "lodash/fromPairs";
import { styled } from "@mui/material/styles";

interface AnalysisPanelProps extends TabPanelProps {
  analysisResultDetail?: AnalysisResultDetail;
}

const PointIndicator = styled("div")({
  marginLeft: 2,
  width: 6,
  height: 6,
  marginRight: 6,
  borderRadius: 3,
});

const MaskIndicator = styled("div")({
  width: 10,
  height: 10,
  marginRight: 4,
});

const SubViewOptionLeftContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const SubSectionListItem = styled(SectionListItem)({
  height: 28,
  flex: "0 0 28px",
});

const TreeIndicator = ({ last }: { last?: boolean }) => {
  if (last) {
    return (
      <svg
        width="7"
        height="28"
        viewBox="0 0 7 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0V14H7"
          stroke="#3E3E40"
          fill="none"
          className="fixed-color"
        />
      </svg>
    );
  }
  return (
    <svg
      width="7"
      height="28"
      viewBox="0 0 7 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0V14H7"
        stroke="#3E3E40"
        fill="none"
        className="fixed-color"
      />
      <path d="M1 0V28" stroke="#3E3E40" fill="none" className="fixed-color" />
    </svg>
  );
};

const IP_MAP_OPTIONS_ORDER = ["Inflamed", "Immune-Excluded", "Immune-Desert"];

function useSeparateHistologicalFeatureOptions(
  histologicalOptions: ViewOptionItem[],
  histologicalFeatOptions: HistologicalFeatureOptions,
  setHistologicalFeatureOptions: (
    initialValue: HistologicalFeatureOptions
  ) => void
) {
  const initialHistologicalOptions = useMemo(
    () => fromPairs(map(histologicalOptions, (option) => [option.id, true])),
    [histologicalOptions]
  );
  useEffect(() => {
    if (
      Object.keys(initialHistologicalOptions).length >
      Object.keys(histologicalFeatOptions).length
    ) {
      setHistologicalFeatureOptions(initialHistologicalOptions);
    }
  }, [
    initialHistologicalOptions,
    histologicalFeatOptions,
    setHistologicalFeatureOptions,
  ]);
}

function AnalysisPanel(props: AnalysisPanelProps) {
  const { analysisResultDetail, ...tabProps } = props;
  const [subShowHideShown, setSubShowHideShown] = useState(false);
  const {
    analysisPanelState,
    setViewOptionsOn,
    setHistologicalFeatureOptions,
    setSingleHistologicalFeatureOn,
  } = useRightPanelStateContext();
  const {
    viewOptionsOn,
    ipMapOn,
    histologicalFeatOn,
    histologicalFeatOptions,
  } = analysisPanelState;
  const viewOptions = analysisResultDetail?.viewOptions.items;
  const ipMapOptions = find(viewOptions, (option) =>
    option.title.includes("Immune Phenotype Map")
  );
  const histologicalViewOptions = find(viewOptions, (option) =>
    option.title.includes("Histological Features")
  );
  useSeparateHistologicalFeatureOptions(
    !!histologicalViewOptions
      ? (histologicalViewOptions.items as ViewOptionItem[])
      : [],
    histologicalFeatOptions,
    setHistologicalFeatureOptions
  );

  return (
    <TabPanel
      {...tabProps}
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "240px",
          height: "100%",
        }}
      >
        <SectionContainer
          sx={{
            flex: "0 0 316px",
            maxHeight: 316,
          }}
          onMouseEnter={() => setSubShowHideShown(true)}
          onMouseLeave={() => setSubShowHideShown(false)}
        >
          <SectionHeader
            sx={{
              opacity:
                viewOptionsOn && (ipMapOn || histologicalFeatOn) ? 1 : 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body3">View Options</Typography>
              <Tooltip
                title={
                  <ViewOptionsTooltip
                    viewOptions={analysisResultDetail?.viewOptions.items}
                  />
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: 0.5,
                    padding: 0.5,
                    borderRadius: "8px",
                    color: (theme) => theme.palette.darkGrey[30],
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.darkGrey[80],
                    },
                  }}
                >
                  <InfoIcon />
                </Box>
              </Tooltip>
            </Box>
            <IconButton
              sx={{
                padding: 0.5,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.darkGrey[80],
                },
              }}
              onClick={() => setViewOptionsOn("viewOptionsOn", !viewOptionsOn)}
            >
              {viewOptionsOn ? <ShownIcon /> : <HiddenIcon />}
            </IconButton>
          </SectionHeader>
          <SectionListItem
            sx={{
              opacity: viewOptionsOn && ipMapOn ? 1 : 0.5,
            }}
          >
            <Typography variant="body5">Immune Phenotype Map</Typography>
            <IconButton
              sx={{
                padding: 0.5,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.darkGrey[80],
                },
              }}
              onClick={() => setViewOptionsOn("ipMapOn", !ipMapOn)}
            >
              {ipMapOn ? <ShownIcon /> : <HiddenIcon />}
            </IconButton>
          </SectionListItem>
          {!!ipMapOptions &&
            map(IP_MAP_OPTIONS_ORDER, (optionId, index) => {
              const option = find(
                ipMapOptions.items as ViewOptionItem[],
                (item) => item.id === optionId
              );
              return (
                <SubSectionListItem
                  key={optionId}
                  sx={{
                    opacity: viewOptionsOn && ipMapOn ? 1 : 0.5,
                  }}
                >
                  <SubViewOptionLeftContainer>
                    <TreeIndicator
                      last={index === ipMapOptions.items.length - 1}
                    />
                    <MaskIndicator
                      sx={{
                        backgroundColor: option.color,
                        marginLeft: "6px",
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="body5" color="darkGrey.20">
                      {option.title}
                    </Typography>
                  </SubViewOptionLeftContainer>
                </SubSectionListItem>
              );
            })}
          <SectionListItem
            sx={{
              opacity: viewOptionsOn && histologicalFeatOn ? 1 : 0.5,
            }}
          >
            <Typography variant="body5">Histological Features</Typography>
            <IconButton
              sx={{
                padding: 0.5,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.darkGrey[80],
                },
              }}
              onClick={() =>
                setViewOptionsOn("histologicalFeatOn", !histologicalFeatOn)
              }
            >
              {histologicalFeatOn ? <ShownIcon /> : <HiddenIcon />}
            </IconButton>
          </SectionListItem>
          {!!histologicalViewOptions &&
            map(
              histologicalViewOptions.items as ViewOptionItem[],
              (option, index) => (
                <SubSectionListItem
                  key={option.id}
                  sx={{
                    opacity:
                      viewOptionsOn &&
                      histologicalFeatOn &&
                      histologicalFeatOptions[option.id]
                        ? 1
                        : 0.5,
                  }}
                >
                  <SubViewOptionLeftContainer>
                    <TreeIndicator
                      last={index === histologicalViewOptions.items.length - 1}
                    />
                    {option.type === "MASK" && (
                      <MaskIndicator
                        sx={{
                          backgroundColor: option.color,
                          marginLeft: "6px",
                          marginRight: 1,
                        }}
                      />
                    )}
                    {option.type === "POINT" && (
                      <PointIndicator
                        sx={{
                          backgroundColor: option.color,
                          marginLeft: "6px",
                          marginRight: 1,
                        }}
                      />
                    )}
                    <Typography variant="body5" color="darkGrey.20">
                      {option.title}
                    </Typography>
                  </SubViewOptionLeftContainer>
                  <IconButton
                    sx={{
                      opacity:
                        subShowHideShown || !histologicalFeatOptions[option.id]
                          ? 1
                          : 0,
                      padding: 0.5,
                      ":hover": {
                        backgroundColor: (theme) => theme.palette.darkGrey[80],
                      },
                    }}
                    onClick={() =>
                      setSingleHistologicalFeatureOn(
                        option.id,
                        !histologicalFeatOptions[option.id]
                      )
                    }
                  >
                    {histologicalFeatOptions[option.id] ? (
                      <ShownIcon />
                    ) : (
                      <HiddenIcon />
                    )}
                  </IconButton>
                </SubSectionListItem>
              )
            )}
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <SectionHeader>
            <Typography variant="body3">Analysis Summary</Typography>
          </SectionHeader>
          {!!analysisResultDetail && (
            <AnalysisSummary summary={analysisResultDetail.analysisSummary} />
          )}
        </SectionContainer>
      </Box>
    </TabPanel>
  );
}

export default AnalysisPanel;
