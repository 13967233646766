import { ClickAwayListener, Menu, MenuItem } from "@mui/material";
import { MenuProps } from "@mui/material/Menu";
import { UserMeta, UserRole } from "dux/user/model";

export interface UserContextMenuProps extends MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  onDelete?: (UserID: UserMeta) => void;
  onEdit?: (user: UserMeta) => void;
  user: any;
  role: UserRole;
}

const UserContextMenu = ({
  anchorEl,
  open,
  onClose,
  onDelete,
  onEdit,
  user,
  role,
}: UserContextMenuProps): ReturnType<typeof Menu> => {
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        BackdropProps={{ open: false }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit(user);
            onClose();
          }}
        >
          Edit user
        </MenuItem>
        {role !== UserRole.Admin && (
          <MenuItem
            onClick={() => {
              onDelete(user.id);
              onClose();
            }}
          >
            Delete User
          </MenuItem>
        )}
      </Menu>
    </ClickAwayListener>
  );
};

export default UserContextMenu;
