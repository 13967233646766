import { ReactNode } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseIcon } from "assets/icons";

export enum FormMode {
  Add = "Add",
  Edit = "Edit",
}

export interface CommonFormProps {
  open: boolean;
  onCancel: () => void;
  // We might want to create onSubmit in the children component sometimes
  onSubmit?: (data: any) => void;
  loading?: boolean;
  isValidating?: boolean;
}

interface FormTemplateProps extends CommonFormProps {
  children: ReactNode;
  tab?: ReactNode;
  title: string;
}

function Template({
  children,
  open,
  onSubmit,
  title,
  tab,
  onCancel,
  loading,
  isValidating,
}: FormTemplateProps) {
  return (
    <Dialog
      aria-labelledby="modal-form-title"
      open={open}
      onClose={onCancel}
      PaperProps={{
        elevation: 1,
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          id="modal-form-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle3">{title}</Typography>
          {!loading ? (
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                sx={{
                  padding: 0.5,
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.darkGrey[80],
                  },
                }}
                onClick={onCancel}
                disabled={loading}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress
              sx={{
                "& svg *": {
                  fill: "none",
                },
                position: "absolute",
                right: (theme) => theme.spacing(1.5),
                top: (theme) => theme.spacing(1.5),
              }}
            />
          )}
        </DialogTitle>
        {tab}
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={loading || isValidating}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Template;
