import { ProjectUUID } from "dux/projects/model";
import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import { FileWithPath } from "react-dropzone";
import { Task } from "redux-saga";
import { action } from "typesafe-actions";
import {
  APIAcceptableFileFormat,
  APISlideLimit,
  SingleWSIUploadState,
} from "./model";

// action types
export const RESET_FILE_UPLOAD = "components/FileUpload/RESET_FILE_UPLOAD";
export const RESET_SINGLE_WSI_UPLOAD =
  "components/FileUpload/RESET_SINGLE_WSI_UPLOAD";
export const ADD_FILES_TO_UPLOAD = "components/FileUpload/ADD_FILES_TO_UPLOAD";
export const ADD_WSIS_TO_UPLOAD = "components/FileUpload/ADD_WSIS_TO_UPLOAD";
export const INIT_WSI_UPLOAD_WATCHER_TASK =
  "components/FileUpload/INIT_WSI_UPLOAD_WATCHER_TASK";
export const SET_PRESIGNED_URLS_FOR_WSI =
  "components/FileUpload/SET_PRESIGNED_URLS_FOR_WSI";
export const UPLOAD_WSI_TO_S3 = "components/FileUpload/UPLOAD_WSI_TO_S3";
export const UPLOAD_WSI_TO_S3_REQUEST =
  "components/FileUpload/UPLOAD_WSI_TO_S3_REQUEST";
export const UPLOAD_WSI_TO_S3_FILE_PROGRESS =
  "components/FileUpload/UPLOAD_WSI_TO_S3_PROGRESS";
export const UPLOAD_WSI_TO_S3_FILE_SUCCESS =
  "components/FileUpload/UPLOAD_WSI_TO_S3_FILE_SUCCESS";
export const UPLOAD_WSI_TO_S3_SUCCESS =
  "components/FileUpload/UPLOAD_WSI_TO_S3_SUCCESS";
export const UPLOAD_WSI_TO_S3_FAILURE =
  "components/FileUpload/UPLOAD_WSI_TO_S3_FAILURE";
export const COMPLETE_WSI_UPLOADING_SUCCESS =
  "components/FileUpload/COMPLETE_WSI_UPLOADING_SUCCESS";
export const COMPLETE_WSI_UPLOADING_FAILURE =
  "components/FileUpload/COMPLETE_WSI_UPLOADING_FAILURE";
export const START_ISYNTAX_CONVERSION = "START_ISYNTAX_CONVERSION";
export const ABORT_SINGLE_WSI_UPLOAD =
  "components/FileUpload/ABORT_SINGLE_WSI_UPLOAD";
export const ABORT_ALL_WSI_UPLOAD =
  "components/FileUpload/ABORT_ALL_WSI_UPLOAD";
export const RETRY_SINGLE_WSI_UPLOAD =
  "components/FileUpload/RETRY_SINGLE_WSI_UPLOAD";

export interface ResetFileUploadAction {
  type: typeof RESET_FILE_UPLOAD;
}

export interface AddFilesToUploadAction {
  type: typeof ADD_FILES_TO_UPLOAD;
  payload: { files: FileWithPath[]; projectId: ProjectUUID };
}

export interface AddWSIsToUploadAction {
  type: typeof ADD_WSIS_TO_UPLOAD;
  payload: {
    initialStates: SingleWSIUploadState[];
    wsiKeys: string[];
  };
}

export interface InitWSIUploadWatcherTaskAction {
  type: typeof INIT_WSI_UPLOAD_WATCHER_TASK;
  payload: {
    task: Task;
  };
}

export interface SetPresignedURLsForWSIAction {
  type: typeof SET_PRESIGNED_URLS_FOR_WSI;
  payload: {
    wsiKey: string;
    targetWSIId: string;
    presignedURLs: { object_path: string; url: string }[];
  };
}

export interface UploadWSIToS3Action {
  type: typeof UPLOAD_WSI_TO_S3;
  payload: { wsiKey: string };
}

export interface UploadWSIToS3RequestAction {
  type: typeof UPLOAD_WSI_TO_S3_REQUEST;
  payload: { wsiKey: string };
}

export interface UploadWSIToS3FileProgressAction {
  type: typeof UPLOAD_WSI_TO_S3_FILE_PROGRESS;
  payload: {
    wsiKey: string;
    index: number;
    loaded: number;
    total: number;
  };
}

export interface UploadWSIToS3FileSuccessAction {
  type: typeof UPLOAD_WSI_TO_S3_FILE_SUCCESS;
  payload: {
    wsiKey: string;
    index: number;
  };
}

export interface UploadWSIToS3SuccessAction {
  type: typeof UPLOAD_WSI_TO_S3_SUCCESS;
  payload: {
    wsiKey: string;
  };
}

export interface UploadWSIToS3FailureAction {
  type: typeof UPLOAD_WSI_TO_S3_FAILURE;
  payload: {
    wsiKey: string;
    error: string;
  };
}

export interface CompleteWSIUploadingSuccessAction {
  type: typeof COMPLETE_WSI_UPLOADING_SUCCESS;
  payload: {
    wsiKey: string;
  };
}

export interface CompleteWSIUploadingFailureAction {
  type: typeof COMPLETE_WSI_UPLOADING_FAILURE;
  payload: {
    wsiKey: string;
    error: string;
  };
}

export interface AbortSingleWSIUploadAction {
  type: typeof ABORT_SINGLE_WSI_UPLOAD;
  payload: {
    wsiKey: string;
  };
}

export interface AbortAllWSIUploadAction {
  type: typeof ABORT_ALL_WSI_UPLOAD;
}

export interface RetrySingleWSIUploadAction {
  type: typeof RETRY_SINGLE_WSI_UPLOAD;
  payload: {
    wsiKey: string;
    addlFiles?: FileWithPath[];
  };
}

// FileUpload Action Types
export type WSIUploadActionTypes =
  | AddWSIsToUploadAction
  | ResetFileUploadAction
  | AddFilesToUploadAction
  | InitWSIUploadWatcherTaskAction
  | SetPresignedURLsForWSIAction
  | UploadWSIToS3Action
  | UploadWSIToS3RequestAction
  | UploadWSIToS3FileProgressAction
  | UploadWSIToS3FileSuccessAction
  | UploadWSIToS3SuccessAction
  | UploadWSIToS3FailureAction
  | CompleteWSIUploadingSuccessAction
  | CompleteWSIUploadingFailureAction
  | AbortSingleWSIUploadAction
  | AbortAllWSIUploadAction
  | RetrySingleWSIUploadAction;

// action creators
export const startISyntaxConversion = (wsiKey: string) =>
  action(START_ISYNTAX_CONVERSION, { wsiKey });

export function resetFileUpload(): WSIUploadActionTypes {
  return {
    type: RESET_FILE_UPLOAD,
  };
}

export function addFilesToUpload(
  files: FileWithPath[],
  projectId?: ProjectUUID
): WSIUploadActionTypes {
  return {
    type: ADD_FILES_TO_UPLOAD,
    payload: { files, projectId },
  };
}

export function addWSIsToUpload(
  initialStates: SingleWSIUploadState[],
  wsiKeys: string[]
): WSIUploadActionTypes {
  return {
    type: ADD_WSIS_TO_UPLOAD,
    payload: {
      initialStates,
      wsiKeys,
    },
  };
}

export function initWSIUploadWatcherTask(task: Task): WSIUploadActionTypes {
  return {
    type: INIT_WSI_UPLOAD_WATCHER_TASK,
    payload: {
      task,
    },
  };
}

export function setPresignedURLsForWSI(
  wsiKey: string,
  targetWSIId: string,
  presignedURLs: { object_path: string; url: string }[]
): WSIUploadActionTypes {
  return {
    type: SET_PRESIGNED_URLS_FOR_WSI,
    payload: {
      wsiKey,
      targetWSIId,
      presignedURLs,
    },
  };
}

export function uploadWSIToS3(wsiKey: string): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3,
    payload: { wsiKey },
  };
}

export function uploadWSIToS3Request(wsiKey: string): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3_REQUEST,
    payload: { wsiKey },
  };
}

export function uploadWSIToS3FileProgress(
  wsiKey: string,
  index: number,
  loaded: number,
  total: number
): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3_FILE_PROGRESS,
    payload: {
      wsiKey,
      index,
      loaded,
      total,
    },
  };
}

export function uploadWSIToS3FileSuccess(
  wsiKey: string,
  index: number
): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3_FILE_SUCCESS,
    payload: {
      wsiKey,
      index,
    },
  };
}

export function uploadWSIToS3Failure(
  wsiKey: string,
  error: string
): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3_FAILURE,
    payload: {
      wsiKey,
      error,
    },
  };
}

export function uploadWSIToS3Success(wsiKey: string): WSIUploadActionTypes {
  return {
    type: UPLOAD_WSI_TO_S3_SUCCESS,
    payload: { wsiKey },
  };
}

export function completeWSIUploadingSuccess(
  wsiKey: string
): WSIUploadActionTypes {
  return {
    type: COMPLETE_WSI_UPLOADING_SUCCESS,
    payload: { wsiKey },
  };
}

export function completeWSIUploadingFailure(
  wsiKey: string,
  error: string
): WSIUploadActionTypes {
  return {
    type: COMPLETE_WSI_UPLOADING_FAILURE,
    payload: { wsiKey, error },
  };
}

export function abortSingleWSIUpload(wsiKey: string): WSIUploadActionTypes {
  return {
    type: ABORT_SINGLE_WSI_UPLOAD,
    payload: { wsiKey },
  };
}

export function abortAllWSIUpload(): WSIUploadActionTypes {
  return { type: ABORT_ALL_WSI_UPLOAD };
}

export function retrySingleWSIUpload(
  wsiKey: string,
  addlFiles?: FileWithPath[]
): WSIUploadActionTypes {
  return {
    type: RETRY_SINGLE_WSI_UPLOAD,
    payload: { wsiKey, addlFiles },
  };
}

// async actions
/**
 * Action Creators
 * Usage: getAllowedWSIFormats[type: request | success | failure](params)
 */
export const GET_ALLOWED_WSI_FORMATS_TYPES = asyncActionCreator(
  "GET_ALLOWED_WSI_FORMATS"
);
export const getAllowedWSIFormats = asyncAction<
  any,
  APIAcceptableFileFormat[],
  string
>(GET_ALLOWED_WSI_FORMATS_TYPES);

// async actions
/**
 * Action Creators
 * Usage: getSlideLimit[type: request | success | failure](params)
 */
export const GET_SLIDE_LIMIT_TYPES = asyncActionCreator("GET_SLIDE_LIMIT");
export const getSlideLimit = asyncAction<any, APISlideLimit, string>(
  GET_SLIDE_LIMIT_TYPES
);
