import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { styled } from "@mui/material/styles";
import {
  Dialog as Container,
  DialogTitle as Title,
  DialogContent as Content,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getCustomerCodeSelector,
  getCustomerCreationDateSelector,
} from "dux/user/selectors";
import { format } from "date-fns";

const parseDateFormat = (date) => format(new Date(date), "yyyy년 MM월");

const ListItem = styled("p")(({ theme }) => ({
  ...theme.typography.body5,
  textAlign: "left",
}));

const ListTitle = styled("span")(({ theme }) => ({
  ...theme.typography.body5,
  fontWeight: 700,
  whiteSpace: "pre-wrap",
}));
const LabelDialog = ({ handleClose, open }) => {
  const customerCode = useSelector(getCustomerCodeSelector);
  const createdAt = useSelector(getCustomerCreationDateSelector);

  return (
    open &&
    !!customerCode &&
    createPortal(
      <Container
        maxWidth={false}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: 0,
            width: 504,
            color: "#000",
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 300,
            border: "3px solid #1E1E1E",
          },
        }}
        aria-labelledby="label-dialog-title"
        open
      >
        <Title
          id="label-dialog-title"
          sx={{
            margin: 0,
            padding: 2,
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "#000",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Title>
        <Content sx={{ padding: "16px 30px" }}>
          <ListItem>
            <ListTitle>■ 제조업자 </ListTitle>
            주식회사 루닛 <br />
            서울 특별시 강남구 테헤란로 2길 27, 15층(역삼동, 비젼타워)
          </ListItem>
          <ListItem>
            <ListTitle>■ 품목명 </ListTitle>
            P04010.01 [3] 병리조직진단보조소프트웨어 (Computer aided diagnosis
            software for pathological tissue)
          </ListItem>
          <ListItem>
            <ListTitle>■ 제품명 </ListTitle>
            Lunit SCOPE IO
          </ListItem>
          <ListItem>
            <ListTitle>■ 모델명 </ListTitle>
            LSIO-CT-C
          </ListItem>
          <ListItem>
            <ListTitle>■ 포장단위 </ListTitle>1 unit
          </ListItem>
          <ListItem>
            <ListTitle>■ 사용기한 </ListTitle>
            해당 없음
          </ListItem>
          <ListItem>
            <ListTitle>■ 제조년월 </ListTitle>
            {parseDateFormat(createdAt)}
          </ListItem>
          <ListItem>
            <ListTitle>■ 제조번호(고객코드) </ListTitle>
            {customerCode}
          </ListItem>
          <Divider />
          <ListItem>
            <Typography variant="body6">
              * 본 제품은 임상적 성능 평가용 의료기기임{" "}
            </Typography>
          </ListItem>
        </Content>
      </Container>,
      document.querySelector("#dialog-root")
    )
  );
};

export default LabelDialog;
