import {
  createProject,
  deleteProjects,
  getAllProjects,
  resetCreatedProject,
  updateProject,
} from "dux/projects/actions";
import { Project } from "dux/projects/model";
import {
  getAllProjectsSelector,
  getProjectMgmtStateSelector,
} from "dux/projects/selectors";
import { enqueueSnackbar } from "dux/snackbar/actions";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { FetchStatus } from "dux/utils/commonEnums";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface UseProjectsCRUDProps {
  onCreated?: (project: Project) => void;
}

export default function useProjectsCRUD({ onCreated }: UseProjectsCRUDProps) {
  const projects = useSelector(getAllProjectsSelector);
  const projectsMgmtState = useSelector(getProjectMgmtStateSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      projectsMgmtState.fetchStatus === FetchStatus.Fulfilled &&
      projectsMgmtState.currentMethod === FetchMethod.Post &&
      !!projectsMgmtState.createdProject
    ) {
      dispatch(resetCreatedProject());
      onCreated && onCreated(projectsMgmtState.createdProject);
    }
  }, [projectsMgmtState, onCreated, dispatch]);

  useEffect(() => {
    // check if need to refresh tag TODO: simplify it with selector later...
    let needToLoad = false;
    needToLoad ||=
      projectsMgmtState.fetchStatus === FetchStatus.Idle &&
      projectsMgmtState.currentMethod === FetchMethod.Get;
    if (needToLoad && !projectsMgmtState.loading) {
      dispatch(dispatch(getAllProjects.request({})));
    }
  }, [dispatch, projectsMgmtState]);

  const requestCreateProject = useCallback(
    (input: string) => {
      if (input.length <= 200 && input.length > 0) {
        dispatch(
          createProject.request({
            title: input,
          })
        );
      } else if (input.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (input.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestEditProject = useCallback(
    (input: Project, changed: string) => {
      const { id, description, toDate, fromDate } = input;
      if (changed.length <= 200 && changed.length > 0) {
        dispatch(
          updateProject.request({
            id,
            title: changed,
            description,
            toDate,
            fromDate,
          })
        );
      } else if (changed.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (changed.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Project title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestDeleteProject = useCallback(
    (input: Project) => {
      const { id } = input;
      dispatch(
        deleteProjects.request({
          ids: [id],
        })
      );
    },
    [dispatch]
  );

  return {
    projects,
    projectsMgmtState,
    requestCreateProject,
    requestDeleteProject,
    requestEditProject,
  };
}
