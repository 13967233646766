import { Store, createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootReducer, { initialRootState, RootState } from "dux/rootReducer";
import rootSaga from "dux/rootSaga";
// import * as Sentry from "@sentry/react";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  // Why it's not just window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?
  // https://stackoverflow.com/a/55674136
  (window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
  compose;

// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const configureStore = (initialState: RootState): Store<RootState> => {
  const store = createStore(
    rootReducer,
    initialState,
    // composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  // Run the root saga before returnning the store object
  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configureStore(initialRootState);
export const persistor = persistStore(store);
export default store;
