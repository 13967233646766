/**
 * User information
 */

import { ProjectUUID } from "dux/projects/model";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { FetchStatus } from "dux/utils/commonEnums";
import {
  FetchStatusResult,
  FetchStatusResultV2,
  Paginate,
  Paging,
} from "dux/utils/commonModels";

export type UserUUID = string | number;

export interface AccountProfile {
  email: string;
  name: string;
  phoneNumber?: string;
  customerCode: string;
}

export interface UserTokenOut {
  tokenType: string;
  mfaRequired: boolean;
  accessToken: string;
  refreshToken?: string;
  mfaRegistered: boolean;
  mfaQrcode?: string;
  url?: string;
}

export enum UserRole {
  Admin = "ADMIN",
  Analyzer = "ANALYZER",
  Manager = "MANAGER",
  Viewer = "VIEWER",
}

export function formatUserRole(role: UserRole): string {
  switch (role) {
    case UserRole.Admin:
      return "Admin";
    case UserRole.Analyzer:
      return "◆ Analyzer";
    case UserRole.Manager:
      return "❖ Manager";
    case UserRole.Viewer:
      return "◇ Viewer";
  }
}

export interface AnalysisResultFormat {
  csv: boolean;
  json: boolean;
  excel: boolean;
}

export interface User extends UserMeta {
  email: string;
  customer: APICustomerInformation;
  analysisResultFormat: AnalysisResultFormat;
  notiAnalysisResult: boolean;
  notiReportDownload: boolean;
  notiWsiUpload: boolean;
  isLogin: boolean;
}

export interface ExtendedUser extends APIUser {}

export interface ResetPasswordPathParams {
  key: string;
}

export interface ResetMFAPathParams {
  key: string;
}
export interface ForgotPasswordQueryParams {
  expired: string;
}

export interface ResetMFALocation {
  state: boolean;
}

export interface UserListRequestParams {
  page: number;
  size: number;
  word: string;
  sort: string;
}
export interface UserMeta {
  id: UserUUID;
  name: string;
  email: string;
  phoneNumber: string;
  roleName: UserRole;
  type: string; // need to change enum type
  createdAt: string;
  mfaKeyRegisteredAt: string;
  projects?: ProjectUUID[];
}

export interface APIUserList extends Paginate<UserMeta> {
  fetchStatus?: FetchStatus;
}

export interface UserListState {
  fetchStatus: FetchStatus;
  pagination: Paging;
  list: UserMeta[];
}

export const userRoleMapper = (roleName: UserRole): number => {
  switch (roleName) {
    case UserRole.Admin:
      return 4;
    case UserRole.Manager:
      return 5;
    case UserRole.Analyzer:
      return 6;
    case UserRole.Viewer:
      return 7;
    default:
      return 0;
  }
};

export interface AccountMgmtState extends FetchStatusResult {
  currentMethod: FetchMethod;
}

export enum SettingsAPI {
  UpdateProfile = "UPDATE_PROFILE",
  UpdatePassword = "UPDATE_PASSWORD",
  ToggleMFA = "TOGGLE_MFA",
  RegisterAuthenticator = "REGISTER_AUTHENTICATOR",
  GetNotification = "GET_NOTIFICATION",
  UpdateNotification = "UPDATE_NOTIFICATION",
}
export interface SettingsState extends FetchStatusResultV2 {
  currentAPI: SettingsAPI;
  notiSettings: APINotiSetting[];
}

export interface UserState {
  account: User;
  settings: SettingsState;
  userList: UserListState;
  accountMgmt: AccountMgmtState;
}

export interface APICustomerInformation {
  code: string;
  name: string;
  createdAt: string;
}

export interface APIUser extends UserMeta {
  email: string;
  analysisResultFormat?: AnalysisResultFormat;
  notiAnalysisResult?: boolean;
  notiReportDownload?: boolean;
  notiWsiUpload: boolean;
  createdAt: string;
  customer: APICustomerInformation;
}

export interface APIUserInput {
  id?: UserUUID;
  name: string;
  email?: string;
  phoneNumber: string;
  roleId?: number;
  roleName?: UserRole;
}

export interface APIPasswordParams {
  currentPassword: string;
  newPassword: string;
}

export interface APILoginUserParams {
  email: string;
  password: string;
}

export interface APIRefreshTokenParams {
  accessToken: string;
  refreshToken: string;
  expSec: number;
}

export interface APIForgotPasswordParams {
  email: string;
}

export interface APIResetPasswordParams {
  key: string;
  newPassword: string;
}

export interface APIVerifyValidKeyParmas {
  key: string;
  purpose: string;
}

export interface APIRegisterMFAParams {
  code: string;
}
export interface APIDeregisterMFAParams {
  key: string;
}

export interface APIVerifyMFAParams {
  code: string;
}

export enum NotificationMethod {
  Email = "EMAIL",
  WebPush = "WEB_PUSH",
}

export enum NotificationReceiveRange {
  OnMyActivity = "ON_MY_ACTIVITY",
  OnAllActivity = "ON_ALL_ACTIVITY",
}
export interface APINotiSetting {
  notiSettingId: number;
  notificationName: string;
  method: NotificationMethod;
  receiveRange: NotificationReceiveRange;
  isActive: boolean;
}

export interface UpdateUserProjectMappingsParams {
  userId: string;
  projectIds: number[];
}

export type UpdateUserProjectMappingsResult = {
  mappings: {
    userId: string;
    projectId: ProjectUUID;
  }[];
  userId: string;
};

export interface APIUpdateUsersMFAParams {
  mfaRequired: boolean;
}
export interface NotiSettingsFormValues extends Pick<APINotiSetting, "method"> {
  notiSettings: Omit<APINotiSetting, "method" | "receiveRange">[];
  receiveRange: NotificationReceiveRange | "";
}

export type NotiSettingsRequestValues = APINotiSetting[];

export const createNotiSettingsFormValues = (
  values: APINotiSetting[],
  method: NotificationMethod
): NotiSettingsFormValues => {
  const newNotiSettings: NotiSettingsFormValues["notiSettings"] = [];
  let prevReceiveRange: NotiSettingsFormValues["receiveRange"] = "";
  let isSameReceiveRange = true;
  values.forEach(
    ({ notiSettingId, notificationName, isActive, receiveRange }, index) => {
      newNotiSettings.push({
        notiSettingId,
        notificationName,
        isActive,
      });
      if (index !== 0 && receiveRange !== prevReceiveRange) {
        isSameReceiveRange = false;
      }
      prevReceiveRange = receiveRange;
    }
  );
  return {
    method: method,
    receiveRange: isSameReceiveRange ? prevReceiveRange : "",
    notiSettings: newNotiSettings,
  };
};

export const createNotiSettingsRequestValues = (
  values: NotiSettingsFormValues
): NotiSettingsRequestValues => {
  return values.notiSettings.map(
    ({ notiSettingId, notificationName, isActive }) => ({
      notiSettingId,
      notificationName,
      isActive,
      method: values.method,
      receiveRange:
        values.receiveRange || NotificationReceiveRange.OnMyActivity,
    })
  );
};
