import React, { useCallback, useMemo, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import BaseDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";

import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import { ArrowDownIcon, CloseIcon } from "assets/icons";

import Select from "../../common/Select";
import SelectItem from "../../common/SelectItem";
import { DATE_FORMAT } from "utils/format";
import {
  FilterDrawerParams,
  SlideDateType,
  SlideStatus,
} from "dux/slide/model";
import { Tag } from "dux/tags/model";
import { Project } from "dux/projects/model";
import { useSelector } from "react-redux";
import { tagsTotalSelector } from "dux/tags/selectors";
import SlideAutocomplete from "components/SlideList/common/SlideAutocomplete";
import { IoResultControl } from "./FilterFields";
import useCancerTypes from "hooks/useCancerTypes";
import { APICancerType } from "dux/cancerTypes/model";

interface IDrawerProps {
  drawerOpen: boolean;
  drawerFilter: FilterDrawerParams;
  handleDrawer: () => void;
  handleDrawerFilter: (data: FilterDrawerParams) => void;
  totalProjects: Project[];
}

interface IControlProps {
  drawerFilter: FilterDrawerParams;
  handleDrawerFilter: (data: FilterDrawerParams) => void;
}

const Container = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  marginTop: "48px",
  overflowY: "auto",
});

const HeaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0px 16px",
  height: "44px",
});

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.darkGrey[90],
  borderTop: "1px solid" + theme.palette.darkGrey[80],
  boxShadow: "none",
  ":before": {
    backgroundColor: "transparent",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: "40px",
  height: "40px",
  padding: "0 16px",
});

const StyledAccordionDetail = styled(AccordionDetails)({
  padding: "0 16px",
});

const DateRangeContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "208px",
});

const filterCheckBoxField = [
  //"Created",
  //"PreAnalyzing",
  //"Ready",
  "Analyzing",
  "Failed",
  "Analyzed",
];

const filterCheckBoxLabel = [
  //"Created",
  //"Pre-analyzing",
  //"Ready",
  "Analyzing",
  "Failed",
  "Analyzed",
];

const StatusContorl = React.memo(
  ({ drawerFilter, handleDrawerFilter }: IControlProps) => {
    const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      let checkedArray = [...drawerFilter.selectedStatuses, event.target.name];
      if (
        drawerFilter.selectedStatuses.includes(event.target.name as SlideStatus)
      )
        checkedArray = checkedArray.filter((key) => key !== event.target.name);
      handleDrawerFilter({
        ...drawerFilter,
        selectedStatuses: checkedArray as SlideStatus[],
      });
    };

    return (
      <FormControl>
        <FormGroup>
          {filterCheckBoxField.map((r, index) => (
            <FormControlLabel
              key={r}
              control={
                <Checkbox
                  name={r}
                  checked={drawerFilter.selectedStatuses.includes(
                    r as SlideStatus
                  )}
                  onChange={handleFilterStatus}
                />
              }
              label={
                <Typography variant="body5">
                  {filterCheckBoxLabel[index]}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }
);

const DateControl = ({ drawerFilter, handleDrawerFilter }: IControlProps) => {
  const handleDateTypeSelect = (event: SelectChangeEvent) => {
    handleDrawerFilter({
      ...drawerFilter,
      dateType: event.target.value as SlideDateType,
    });
  };

  const handleDateRange = (value: DateRange<Date>) => {
    handleDrawerFilter({
      ...drawerFilter,
      dateValue: value,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Select
        value={drawerFilter?.dateType}
        displayEmpty
        onChange={handleDateTypeSelect}
        inputProps={{
          "aria-label": "date",
        }}
        fullWidth
        sx={{ marginBottom: "12px" }}
      >
        <SelectItem
          value={"UPLOADED_AT"}
          selected={drawerFilter?.dateType === SlideDateType.UploadedAt}
        >
          {"Uploaded Date"}
        </SelectItem>
        <SelectItem
          value={"STATUS_UPDATED_AT"}
          selected={drawerFilter?.dateType === SlideDateType.UpdatedAt}
        >
          {"Updated Date"}
        </SelectItem>
      </Select>
      <DateRangePicker
        calendars={1}
        value={drawerFilter?.dateValue ?? [null, null]}
        onChange={handleDateRange}
        startText=""
        endText=""
        PopperProps={{
          placement: "bottom-start",
        }}
        inputFormat={DATE_FORMAT}
        renderInput={(startProps, endProps) => (
          <DateRangeContainer>
            <TextField
              variant="outlined"
              {...startProps}
              InputProps={{
                "aria-label": "fromDate",
              }}
              sx={{ marginBottom: "12px" }}
              fullWidth
            />
            <TextField
              variant="outlined"
              {...endProps}
              InputProps={{
                "aria-label": "toDate",
              }}
              // inputProps={{ placeholder: "mm/dd/yyyy (To)", type: "tel" }}
              sx={{ marginBottom: "14px" }}
              fullWidth
            />
          </DateRangeContainer>
        )}
      />
    </LocalizationProvider>
  );
};

type AutocompleteHandlerSet<T> = [T[], T[], (value: T[]) => void];

const getFilterField = (
  drawerFilter: FilterDrawerParams,
  handleDrawerFilter: (data: FilterDrawerParams) => void,
  [
    totalCancerTypes,
    selectedCancerTypes,
    setSelectedCancerTypes,
  ]: AutocompleteHandlerSet<APICancerType>,
  [totalTags, selectedTags, setSelectedTags]: AutocompleteHandlerSet<Tag>,
  [
    totalProjects,
    selectedProjects,
    setSelectedProjects,
  ]: AutocompleteHandlerSet<Project>
) => [
  {
    name: "status",
    header: "Status",
    render: () => {
      return (
        <StatusContorl
          drawerFilter={drawerFilter}
          handleDrawerFilter={handleDrawerFilter}
        />
      );
    },
  },
  {
    name: "date",
    header: "Date",
    render: () => {
      return (
        <DateControl
          drawerFilter={drawerFilter}
          handleDrawerFilter={handleDrawerFilter}
        />
      );
    },
  },
  {
    name: "cancerType",
    header: "Cancer Type",
    render: () => {
      return (
        <SlideAutocomplete
          maxFilteredOptionLength={totalCancerTypes.length}
          totalOptions={totalCancerTypes}
          selectedOption={selectedCancerTypes}
          onChangeValue={setSelectedCancerTypes}
          placeholder={"Select a cancer type"}
          readonlyItems={totalCancerTypes?.filter(
            (item) => item.name === "Pan cancer"
          )}
          sx={{
            "& .MuiTextField-root": {
              "& .MuiInputBase-root": {
                padding: (theme) => theme.spacing(1, 2),
                fontSize: 14,
                "& .MuiInputBase-input": {
                  padding: 0,
                  height: 20,
                },
              },
              marginBottom: 2,
            },
          }}
          popperSx={{
            "& .MuiList-root": {
              maxHeight: 300,
              overflowY: "scroll",
            },
          }}
        />
      );
    },
  },
  {
    name: "ioResult",
    header: "IO Result",
    render: () => {
      return (
        <IoResultControl
          drawerFilter={drawerFilter}
          handleDrawerFilter={handleDrawerFilter}
        />
      );
    },
  },
  {
    name: "projects",
    header: "Projects",
    render: () => {
      return (
        <SlideAutocomplete
          totalOptions={totalProjects}
          selectedOption={selectedProjects}
          onChangeValue={setSelectedProjects}
          placeholder={"Select a project"}
          sx={{
            "& .MuiTextField-root": {
              "& .MuiInputBase-root": {
                padding: (theme) => theme.spacing(1, 2),
                fontSize: 14,
                "& .MuiInputBase-input": {
                  padding: 0,
                  height: 20,
                },
              },
              marginBottom: 2,
            },
          }}
        />
      );
    },
  },
  {
    name: "tags",
    header: "Tags",
    render: () => {
      return (
        <SlideAutocomplete
          totalOptions={totalTags}
          selectedOption={selectedTags}
          onChangeValue={setSelectedTags}
          placeholder={"Select a tag"}
          sx={{
            "& .MuiTextField-root": {
              "& .MuiInputBase-root": {
                padding: (theme) => theme.spacing(1, 2),
                fontSize: 14,
                "& .MuiInputBase-input": {
                  padding: 0,
                  height: 20,
                },
              },
            },
          }}
        />
      );
    },
  },
];

const FilterDrawer = ({
  drawerOpen,
  drawerFilter,
  handleDrawer,
  handleDrawerFilter,
  totalProjects,
}: IDrawerProps) => {
  const theme = useTheme();
  const { cancerTypes } = useCancerTypes({});
  const [isMenuOpen, setMenuOpen] = useState({
    status: true,
    date: true,
    cancerType: true,
    ioResult: true,
    projects: true,
    tags: true,
  });

  const totalTags = useSelector(tagsTotalSelector);

  const onChangeSelectedTags = useCallback(
    (tag: Tag[]) => {
      handleDrawerFilter({
        ...drawerFilter,
        selectedTags: tag,
      });
    },
    [handleDrawerFilter, drawerFilter]
  );

  const onChangeSelectedProjects = useCallback(
    (project: Project[]) => {
      handleDrawerFilter({
        ...drawerFilter,
        selectedProjects: project,
      });
    },
    [handleDrawerFilter, drawerFilter]
  );

  const onChangeSelectedCancerTypes = useCallback(
    (cancerTypes: APICancerType[]) => {
      handleDrawerFilter({
        ...drawerFilter,
        cancerTypes: cancerTypes,
      });
    },
    [handleDrawerFilter, drawerFilter]
  );

  const filterField = useMemo(
    () =>
      getFilterField(
        drawerFilter,
        handleDrawerFilter,
        [cancerTypes, drawerFilter.cancerTypes, onChangeSelectedCancerTypes],
        [totalTags, drawerFilter.selectedTags, onChangeSelectedTags],
        [totalProjects, drawerFilter.selectedProjects, onChangeSelectedProjects]
      ),
    [
      drawerFilter,
      handleDrawerFilter,
      totalTags,
      totalProjects,
      cancerTypes,
      onChangeSelectedCancerTypes,
      onChangeSelectedProjects,
      onChangeSelectedTags,
    ]
  );

  const handleMenu = (target: string) => {
    setMenuOpen({ ...isMenuOpen, [target]: !isMenuOpen[target] });
  };

  return (
    <BaseDrawer
      sx={{
        width: theme.subDrawer.width,
        flexShrink: 0,
      }}
      open={drawerOpen}
      variant="persistent"
      anchor="right"
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          width: theme.subDrawer.width,
          backgroundColor: theme.palette.darkGrey[90],
        },
      }}
    >
      <Container>
        <HeaderContainer>
          <Typography sx={{ fontSize: "14px" }}>Filters</Typography>
          <Tooltip title="Close">
            <IconButton aria-label="close" onClick={handleDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </HeaderContainer>
        {filterField.map((r) => (
          <StyledAccordion
            key={r.header}
            disableGutters
            square
            expanded={isMenuOpen[r.name]}
          >
            <StyledAccordionSummary
              expandIcon={
                <IconButton onClick={() => handleMenu(r.name)}>
                  <ArrowDownIcon />
                </IconButton>
              }
              aria-label="Expand"
              aria-controls={`${r.header}-content`}
              id={`${r.header}-header`}
            >
              <Typography variant="body3">{r.header}</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetail>{r.render()}</StyledAccordionDetail>
          </StyledAccordion>
        ))}
      </Container>
    </BaseDrawer>
  );
};

export default FilterDrawer;
