import { ListItemIcon, ListItemText } from "@mui/material";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { CheckIcon } from "assets/icons";
import clsx from "clsx";

export interface SelectItemProps extends MenuItemProps {
  checked?: boolean;
}

const SelectItem = ({
  checked,
  children,
  className: rawClassName,
  ...props
}: SelectItemProps): ReturnType<typeof MenuItem> => {
  const className: string = !checked
    ? rawClassName
    : clsx(rawClassName, "Mui-selected");

  return (
    <MenuItem {...props} className={className}>
      <ListItemIcon
        sx={{
          marginRight: 1,
          opacity: 0,
          ".Mui-selected &": {
            opacity: 1,
          },
        }}
      >
        <CheckIcon />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </MenuItem>
  );
};

export default SelectItem;
