import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects";
import { createAsyncSaga } from "dux/utils/actionHandlingHelper";
import {
  GET_WSI_LIST_TYPES,
  getWSIList,
  GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES,
  getWSIAnalysisResultSummaryById,
  GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES,
  getWSIAnalysisResultErrorMsgById,
  GET_WSI_ANALYSIS_RESULT_REPORT_TYPES,
  getWSIAnalysisResultReportById,
  UPDATE_WSI_META_INFO_TYPES,
  updateWSIMetaInfo,
  UPDATE_CANCER_NAME_TYPES,
  updateCancerName,
  requestAnalysis,
  REQUEST_ANALYSIS_TYPES,
  setNeedToRefreshWSIList,
  doneGeneratingReport,
  updateReportState,
  UPDATE_REPORT_STATE,
  getAnalyzedSlides,
  GET_ANALYZED_SLIDE_LIST_TYPES,
  deleteSlides,
  DELETE_SLIDES_TYPES,
  resetDeleteStatus,
} from "./actions";
import * as api from "./api";
import { ActionType } from "typesafe-actions";
import { getReportStateSelector } from "./selectors";
import { WSIReportState } from "./model";
import { reduce } from "lodash";

const getWSIListSaga = createAsyncSaga(getWSIList, api.getWSIList);

function* getWSIAnalysisResultSummarySaga(action) {
  const { payload } = action;
  try {
    const result = yield call(api.getAnalysisSummaryById, payload);
    yield put(
      getWSIAnalysisResultSummaryById.success({
        ...result,
        wsiId: payload,
      })
    );
  } catch (error) {
    yield put(getWSIAnalysisResultSummaryById.failure(payload));
  }
}

function* getWSIAnalysisResultErrorMsgSaga(action) {
  const { payload } = action;
  try {
    const result = yield call(api.getAnalysisErrorMsgById, payload);
    yield put(
      getWSIAnalysisResultErrorMsgById.success({
        ...result,
        wsiId: payload,
      })
    );
  } catch (error) {
    yield put(getWSIAnalysisResultErrorMsgById.failure(payload));
  }
}

function* getWSIAnalysisResultReportSaga(action) {
  const { payload } = action;
  try {
    // const importPromise = new Promise((resolve) =>
    //   import("./generatePDF").then((module) => resolve(module))
    // );
    // const generateModule = yield call(() => Promise.resolve(importPromise));
    const result = yield call(api.getAnalysisReportById, payload);
    // yield call(generateModule.default, result);
    yield put(getWSIAnalysisResultReportById.success(result));
  } catch (error) {
    yield put(getWSIAnalysisResultReportById.failure(payload));
  }
}

const updateWSIMetaInfoSaga = createAsyncSaga(
  updateWSIMetaInfo,
  api.updateWSIMetaInfo
);

const updateCancerNameSaga = createAsyncSaga(
  updateCancerName,
  api.updateCancerName
);

function* requestAnalysisSaga(action) {
  const { payload } = action;
  try {
    yield call(api.requestAnalysis, payload);
    yield put(requestAnalysis.success(payload));
  } catch (error) {
    yield put(requestAnalysis.failure(payload));
  } finally {
    yield put(setNeedToRefreshWSIList({ needToRefresh: true }));
  }
}

function allReportsDoneGenerating(reportState: WSIReportState) {
  return reduce(
    reportState.states,
    (done, singleState) => done && singleState.done,
    true
  );
}

const updateReportStateSaga = function* (
  action: ActionType<typeof updateReportState>
) {
  const reportState = yield select(getReportStateSelector);
  if (allReportsDoneGenerating(reportState)) {
    yield put(doneGeneratingReport());
  }
};
const getAnalyzedSlidesSaga = createAsyncSaga(
  getAnalyzedSlides,
  api.getAnalyzedSlides
);

const deleteSlidesSaga = createAsyncSaga(
  deleteSlides,
  api.deleteSlides,
  function* () {
    yield put(resetDeleteStatus());
    yield put(setNeedToRefreshWSIList({ needToRefresh: true }));
  }
);

export default function* WSIAnalysisResultRootSaga() {
  yield takeLatest(GET_WSI_LIST_TYPES.REQUEST, getWSIListSaga);
  yield takeLatest(
    GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES.REQUEST,
    getWSIAnalysisResultSummarySaga
  );
  yield takeLatest(
    GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES.REQUEST,
    getWSIAnalysisResultErrorMsgSaga
  );
  yield takeLatest(UPDATE_WSI_META_INFO_TYPES.REQUEST, updateWSIMetaInfoSaga);
  yield takeLatest(UPDATE_CANCER_NAME_TYPES.REQUEST, updateCancerNameSaga);
  yield takeLatest(
    GET_WSI_ANALYSIS_RESULT_REPORT_TYPES.REQUEST,
    getWSIAnalysisResultReportSaga
  );
  yield takeLatest(REQUEST_ANALYSIS_TYPES.REQUEST, requestAnalysisSaga);
  yield takeEvery(UPDATE_REPORT_STATE, updateReportStateSaga);
  yield takeLatest(
    GET_ANALYZED_SLIDE_LIST_TYPES.REQUEST,
    getAnalyzedSlidesSaga
  );
  yield takeLatest(DELETE_SLIDES_TYPES.REQUEST, deleteSlidesSaga);
}
