import { Link } from "@mui/material";
import { FormEvent, useCallback, useState } from "react";
import AuthActionButton from "./AuthActionButton";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import AuthDescription from "./AuthDescription";
import { forgotPassword } from "dux/user/api";
import AuthAlert from "./AuthAlert";
import { ForgotPasswordQueryParams } from "dux/user/model";
import useQueryParams from "components/hooks/useQueryParams";

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [firstrun, setFirstrun] = useState<boolean>(false);

  const actionDisabled = !email.trim() || submitting;

  const { expired: rawExpired } = useQueryParams<ForgotPasswordQueryParams>();
  const isExpired = rawExpired != null;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubmitting(true);
      setSuccess(false);
      setFirstrun(false);
      forgotPassword({ email })
        .then(() => {
          setSuccess(true);
          setEmail("");
        })
        .catch((error) => {
          setErrorMessage(
            error.details === `${email} is not found.`
              ? "Please enter correct email address."
              : "Something went wrong. Please try again."
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
      setFirstrun(true);
    },
    [email]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>Forgot password?</AuthHeading>
      {success && (
        <AuthAlert severity="success">
          We have successfully sent you an email. Please check your email now.
        </AuthAlert>
      )}
      {!success && firstrun && errorMessage !== "" ? (
        <AuthAlert severity="error">{errorMessage}</AuthAlert>
      ) : undefined}
      {isExpired && !firstrun && (
        <AuthAlert severity="error">
          The password setting link is expired, please request to set password
          again.
        </AuthAlert>
      )}
      <AuthDescription>
        Please enter below your email registered with us.
        <br />
        We will send you an email for password reset.
        <br />
        <br />
        *Please be aware of caps
        <br />
        *If you don't remember the registered email address, contact support.
        <br />
        *If you can't see the email in your inbox, check junk/spam or other
        folders.
      </AuthDescription>
      <AuthFormField
        id="email"
        type="email"
        aria-label="Email"
        placeholder="Email*"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
      />
      <AuthActionButton disabled={actionDisabled}>Confirm</AuthActionButton>
      <AuthNav>
        <Link href="/login">Back to log in</Link>
      </AuthNav>
    </AuthForm>
  );
}
