import produce from "immer";
import { useReducer, useState } from "react";
import constate from "constate";

export interface HistologicalFeatureOptions {
  [id: string]: boolean;
}

export interface AnalysisPanelState {
  viewOptionsOn: boolean;
  ipMapOn: boolean;
  histologicalFeatOn: boolean;
  histologicalFeatOptions: HistologicalFeatureOptions;
}

const initialState: AnalysisPanelState = {
  viewOptionsOn: true,
  ipMapOn: true,
  histologicalFeatOn: true,
  histologicalFeatOptions: {},
};

interface SetViewOptionsOnAction {
  type: "SET_VIEW_OPTIONS_ON";
  payload: {
    key: "viewOptionsOn" | "ipMapOn" | "histologicalFeatOn";
    value: boolean;
  };
}

interface SetSingleHistologicalFeatureOnAction {
  type: "SET_SINGLE_HISTOLOGICAL_FEATURE_ON";
  payload: {
    id: string;
    value: boolean;
  };
}

interface SetHistologicalFeatureOptions {
  type: "SET_HISTOLOGICAL_FEATURE_OPTIONS";
  payload: {
    initialValue: HistologicalFeatureOptions;
  };
}

type AnalysisPanelActions =
  | SetViewOptionsOnAction
  | SetSingleHistologicalFeatureOnAction
  | SetHistologicalFeatureOptions;

const analysisPanelReducer = (
  state: AnalysisPanelState,
  action: AnalysisPanelActions
): AnalysisPanelState => {
  switch (action.type) {
    case "SET_VIEW_OPTIONS_ON":
      return produce(state, (draft) => {
        draft[action.payload.key] = action.payload.value;
      });
    case "SET_HISTOLOGICAL_FEATURE_OPTIONS":
      return produce(state, (draft) => {
        draft.histologicalFeatOptions = action.payload.initialValue;
      });
    case "SET_SINGLE_HISTOLOGICAL_FEATURE_ON":
      return produce(state, (draft) => {
        draft.histologicalFeatOptions[action.payload.id] = action.payload.value;
      });
    default:
      return state;
  }
};

function useAnalysisPanelState() {
  const [analysisPanelState, dispatch] = useReducer(
    analysisPanelReducer,
    initialState
  );
  const setViewOptionsOn = (
    key: SetViewOptionsOnAction["payload"]["key"],
    value: boolean
  ) =>
    dispatch({
      type: "SET_VIEW_OPTIONS_ON",
      payload: {
        key,
        value,
      },
    });
  const setHistologicalFeatureOptions = (
    initialValue: HistologicalFeatureOptions
  ) =>
    dispatch({
      type: "SET_HISTOLOGICAL_FEATURE_OPTIONS",
      payload: { initialValue },
    });
  const setSingleHistologicalFeatureOn = (id: string, value: boolean) =>
    dispatch({
      type: "SET_SINGLE_HISTOLOGICAL_FEATURE_ON",
      payload: { id, value },
    });
  return {
    analysisPanelState,
    setViewOptionsOn,
    setHistologicalFeatureOptions,
    setSingleHistologicalFeatureOn,
  };
}

export enum RightPanelTabType {
  INFORMATION = "information",
  ANALYSIS = "analysis",
}

function useRightPanelState() {
  const [tab, setTab] = useState<RightPanelTabType>(
    RightPanelTabType.INFORMATION
  );
  return {
    tab,
    setTab,
    ...useAnalysisPanelState(),
  };
}

const [RightPanelStateProvider, useRightPanelStateContext] =
  constate(useRightPanelState);

export { RightPanelStateProvider };
export default useRightPanelStateContext;
