import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import { SlideUUID } from "dux/slide/model";
import { requestSlideErrorInfo } from "dux/slide/actions";
import { slideErrorInfoSelector } from "dux/slide/selectors";
import { FetchStatus } from "dux/utils/commonEnums";
interface IFailedInfoDialogProps {
  id: SlideUUID;
}

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

const DetailGrid = styled(Grid)({
  overflowY: "scroll",
  height: "300px",
  marginTop: "8px",
  padding: "0 12px 0 8px !important",
});

const PlainButton = styled("a")(({ theme }) => ({
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: 700,
  color: theme.palette.darkGrey[30],
  marginBottom: 24,
  "& a": {
    color: "inherit",
  },
}));

export default function FailedInfo({ id }: IFailedInfoDialogProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const errorInfo = useSelector(slideErrorInfoSelector);

  useEffect(() => {
    dispatch(requestSlideErrorInfo.request(id));
  }, [dispatch, id]);

  if (errorInfo.fetchStatus === FetchStatus.Pending)
    return (
      <Box
        sx={{
          display: "flex",
          height: "429px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container spacing={1} columns={10}>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Error code
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{errorInfo.message.code}</Typography>
      </Grid>
      <StyledGrid item xs={3}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Error message
        </Typography>
      </StyledGrid>
      <Grid item xs={7}>
        <Typography variant="body5">{errorInfo.message.message}</Typography>
      </Grid>
      <Grid item xs={3} sx={{ alignItems: "flex-start" }}>
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Detail
        </Typography>
      </Grid>
      <DetailGrid item xs={7}>
        <Typography variant="body5">{errorInfo.message.details}</Typography>
      </DetailGrid>
      <StyledGrid
        item
        xs={10}
        sx={{ flexDirection: "column", alignItems: "flex-start", gap: "4px" }}
      >
        <Typography variant="body5" color={theme.palette.darkGrey[30]}>
          Please contact
        </Typography>
        <PlainButton
          href="mailto:cs-scope-io@lunit.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          cs-scope-io@lunit.io
        </PlainButton>
      </StyledGrid>
    </Grid>
  );
}
