import { FetchMethod, request } from "dux/utils/apiRequestHelper";
import {
  RequestSlidesAnalyzeParams,
  SearchSlideSummariesParams,
  SlideStatus,
  SlideUUID,
  UpdateCancerTypePayload,
  UpdateSlideSummaryParams,
} from "dux/slide/model";
import qs from "qs";
import { decamelize } from "@ridi/object-case-converter";
import { SortOrder } from "components/Forms/SortField";

export const checkEmpty = (val, s) => (Boolean(val) ? s : "");
const encode = encodeURIComponent;

const queryString = (obj: any) =>
  qs.stringify(decamelize(obj), {
    encode: true,
    indices: false,
    arrayFormat: "repeat",
    skipNulls: true,
    filter: (prefix, value) => value && value,
  });

/*
Created ->
CREATED, CONVERTING

PreAnalyzing ->
PRE_ANALYZING

Ready ->
PRE_ANALYZED

Failed ->
PRE_ANAL_FAILED, ANAL_FAILED

Analyzing ->
ANALYZING

Analyzed ->
ANALYZED
 */
function ConvSlideStatusList(slideStatuses: SlideStatus[]) {
  const ret = [];
  slideStatuses.forEach((slideStatus) => {
    switch (slideStatus) {
      case SlideStatus.PreAnalyzing:
        ret.push("PRE_ANALYZING");
        break;
      case SlideStatus.Ready:
        ret.push("PRE_ANALYZED");
        break;
      case SlideStatus.Analyzing:
        ret.push("ANALYZING");
        break;
      case SlideStatus.AnalFailed:
        ret.push("PRE_ANAL_FAILED");
        ret.push("ANAL_FAILED");
        break;
      case SlideStatus.Analyzed:
        ret.push("ANALYZED");
        break;
      case SlideStatus.Created: // not used
        ret.push("CREATED");
        break;
    }
  });
  return ret;
}

export const getSlideSummaries = (payload: SearchSlideSummariesParams) =>
  request({
    method: FetchMethod.Get,
    path: `analysis/summaries?${queryString({
      ...payload,
      sort:
        payload.sort !== "score"
          ? `${payload.sort},${payload.sortOrder}`
          : [
              `quality_control,${
                payload.sortOrder === SortOrder.Ascending
                  ? SortOrder.Descending
                  : SortOrder.Ascending
              }`,
              `${payload.sort},${payload.sortOrder}`,
            ],
      statusList: ConvSlideStatusList(payload.statusList),
    })}`,
  });

export const updateSlideSummary = (payload: UpdateSlideSummaryParams) =>
  request({
    method: FetchMethod.Patch,
    path: `wsi/${encode(payload.wsiId)}`,
    payload,
  });

export const updateCancerType = (payload: UpdateCancerTypePayload) =>
  request({
    method: FetchMethod.Patch,
    path: `analysis/sources/${encode(payload.id)}`,
    payload: {
      cancerType: payload.cancerType,
    },
  });

export const deleteSlideSummaries = (payload: SlideUUID[]) =>
  request({
    method: FetchMethod.Delete,
    path: `wsi?${queryString({
      ids: payload,
    })}`,
    skipJson: true,
  });

export const requestSlidesAnalyze = (payload: RequestSlidesAnalyzeParams) =>
  request({
    method: FetchMethod.Post,
    path: "analysis/sources/biomarker",
    payload,
  });

export const requestSingleSlideAnalyze = (sourceId: string) =>
  request({
    method: FetchMethod.Post,
    path: `analysis/sources/${sourceId}/biomarker`,
  });

export const requestSlideErrorInfo = (id: SlideUUID) =>
  request({
    method: FetchMethod.Get,
    path: `analysis/sources/${id}/fail-message`,
  });

export const requestSlideInferences = (id: SlideUUID) =>
  request({
    method: FetchMethod.Post,
    path: `analysis/sources/${id}/inferences`,
    skipJson: true,
  });
