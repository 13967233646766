/**
 * User Reducer
 */
import { createReducer, StateType, ActionType } from "typesafe-actions";
import produce from "immer";
import { ProjectListState, ProjectMgmtState, AllProjectsState } from "./model";
import { FetchStatus } from "dux/utils/commonEnums";
import {
  GET_ALL_PROJECTS_TYPES,
  getAllProjects,
  GET_PROJECTS_PER_PAGE_TYPES,
  getProjectsPerPage,
  RESET_PROJECT_MGMT_STATE,
  CREATE_PROJECT_TYPES,
  UPDATE_PROJECT_TYPES,
  DELETE_PROJECTS_TYPES,
  createProject,
  updateProject,
  deleteProjects,
  DELETE_PROJECT_MAPPINGS_TYPES,
  RESET_CREATED_PROJECT,
} from "./actions";
import { combineReducers } from "redux";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { DEFAULT_PAGE_SIZE } from "utils/pagination";

const allProjectsInitialState: AllProjectsState = {
  fetchStatus: FetchStatus.Idle,
  list: [],
};

const allProjectsReducer = createReducer<AllProjectsState>(
  allProjectsInitialState,
  {
    [GET_ALL_PROJECTS_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getAllProjects.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.list = payload;
      }),
    [GET_ALL_PROJECTS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_ALL_PROJECTS_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
  }
);

const projectListInitialState: ProjectListState = {
  fetchStatus: FetchStatus.Idle,
  list: [],
  pagination: {
    first: true,
    last: true,
    size: DEFAULT_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0,
  },
};

const projectListReducer = createReducer<ProjectListState>(
  projectListInitialState,
  {
    [GET_PROJECTS_PER_PAGE_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getProjectsPerPage.success>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.list = payload.contents;
        draft.pagination = {
          ...state.pagination,
          first: payload.first,
          last: payload.last,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        };
      }),
    [GET_PROJECTS_PER_PAGE_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_PROJECTS_PER_PAGE_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
  }
);

const projectMgmtInitialState: ProjectMgmtState = {
  currentMethod: FetchMethod.Post,
  fetchStatus: FetchStatus.Idle,
  error: "",
};

const projectMgmtReducer = createReducer<ProjectMgmtState>(
  projectMgmtInitialState,
  {
    [RESET_PROJECT_MGMT_STATE]: (state) => ({
      ...projectMgmtInitialState,
      createdProject: state.createdProject,
    }),
    [GET_PROJECTS_PER_PAGE_TYPES.REQUEST]: (state) => ({
      ...projectMgmtInitialState,
      createdProject: state.createdProject,
    }),
    [RESET_CREATED_PROJECT]: (state) =>
      produce(state, (draft) => {
        delete draft.createdProject;
      }),
    [CREATE_PROJECT_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Post;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [CREATE_PROJECT_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof createProject.success>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.createdProject = action.payload;
      }),
    [CREATE_PROJECT_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof createProject.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [UPDATE_PROJECT_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Put;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [UPDATE_PROJECT_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [UPDATE_PROJECT_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof updateProject.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [DELETE_PROJECTS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [DELETE_PROJECTS_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_PROJECTS_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof deleteProjects.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
    [DELETE_PROJECT_MAPPINGS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Pending;
        draft.error = "";
      }),
    [DELETE_PROJECT_MAPPINGS_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_PROJECT_MAPPINGS_TYPES.FAILURE]: (
      state,
      action: ReturnType<typeof updateProject.failure>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = action.payload;
      }),
  }
);

const projectsReducer = combineReducers({
  allProjects: allProjectsReducer,
  projectList: projectListReducer,
  projectMgmt: projectMgmtReducer,
});

export const initialState = {
  allProjects: allProjectsInitialState,
  projectList: projectListInitialState,
  projectMgmt: projectMgmtInitialState,
};

export type ProjectsReducerState = StateType<typeof projectsReducer>;

export default projectsReducer;
