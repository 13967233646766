import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import BaseDrawer from "@mui/material/Drawer";

import { isAdminUserSelector } from "dux/user/selectors";
import EllipsizedTypography from "components/EllipsizedTypography";
import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { getAllProjectsStateSelector } from "dux/projects/selectors";
import { getAllProjects } from "dux/projects/actions";
import { ArrowIcon, ProjectsIcon, SlidesIcon, UsersIcon } from "assets/icons";
import useToggleState from "hooks/useToggleState";
import useFectchCurrentUser from "components/hooks/useFetchCurrentUser";
import { slideViewerListParamsSelector } from "dux/slide/selectors";
import { slideStatusColorProps } from "components/SlideList/common/literals";

interface IDrawerProps {
  drawerOpen: boolean;
}

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  "&.colorHover": {
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));

const DrawerList = styled(List)({
  paddingTop: 1,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
});

const DotIcon = styled("span")({
  width: 5,
  height: 5,
  borderRadius: 5,
});

const Drawer = ({ drawerOpen }: IDrawerProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const homeMatch = useRouteMatch({
    path: "/projects",
    exact: true,
  });
  const projectDashboardMatch = useRouteMatch({
    path: "/projects/:projectId/slides",
    exact: true,
  });

  const SlideViewerMatch = useRouteMatch({
    path: ["/slides/:wsiId", "/projects/:projectId/slides/:wsiId"],
    exact: true,
  });

  const projectId =
    SlideViewerMatch &&
    SlideViewerMatch.params["projectId"] &&
    SlideViewerMatch.params["projectId"];

  const [projectListExpanded, toggleProjectListExpanded] = useToggleState(
    !!projectDashboardMatch || !!projectId
  );

  const { list: allProjects } = useSelector(getAllProjectsStateSelector);
  const isAdminUser = useSelector(isAdminUserSelector);
  const selectedSlides = useSelector(slideViewerListParamsSelector);
  const dispatch = useDispatch();

  useFectchCurrentUser();

  useEffect(() => {
    dispatch(getAllProjects.request({}));
  }, [dispatch, pathname]);

  return (
    <BaseDrawer
      sx={{
        width: theme.appDrawer.width,
        flexShrink: 0,
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      PaperProps={{
        sx: {
          color: theme.palette.text.secondary,
          width: theme.appDrawer.width,
          borderRight: "none",
          overflowX: "hidden",
        },
      }}
    >
      <Toolbar />
      <DrawerList>
        <ListItem
          sx={{
            "&:hover": {
              color: (theme) => theme.palette.primary.main,
            },
          }}
          secondaryAction={
            <IconButton
              color="inherit"
              sx={{
                padding: 0.5,
                color: (theme) => !!homeMatch && theme.palette.primary.main,
                ":hover": {
                  backgroundColor: theme.palette.darkGrey[80],
                },
              }}
              onClick={toggleProjectListExpanded}
            >
              <ArrowIcon
                style={{
                  transform: projectListExpanded
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              />
            </IconButton>
          }
        >
          <ListItemButton
            to="/projects"
            component={StyledNavLink}
            className="colorHover"
            activeClassName="colorActive"
            disableRipple
          >
            <ListItemIcon>
              <ProjectsIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItemButton>
        </ListItem>
        <Collapse
          sx={{
            maxHeight: `calc(100% - ${isAdminUser ? 120 : 80}px)`,
            overflow: "auto",
          }}
          in={projectListExpanded}
        >
          <>
            {allProjects.map((project) => (
              <ListItem key={project.id}>
                <ListItemButton
                  to={`/projects/${project.id}/slides`}
                  component={StyledNavLink}
                  activeClassName="bgActive"
                  sx={{
                    width: "100%",
                  }}
                >
                  <EllipsizedTypography
                    sx={{
                      ml: 0.5,
                    }}
                    variant="body5"
                  >
                    {`- ${project.title}`}
                  </EllipsizedTypography>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        </Collapse>
        <ListItem
          sx={{
            "&:hover": {
              color: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <ListItemButton
            to="/slides"
            component={StyledNavLink}
            className="colorHover"
            activeClassName="colorActive"
            disableRipple
          >
            <ListItemIcon>
              <SlidesIcon />
            </ListItemIcon>
            <ListItemText primary="Slides" />
          </ListItemButton>
        </ListItem>
        {isAdminUser && (
          <ListItem>
            <ListItemButton
              to="/users"
              component={StyledNavLink}
              className="colorHover"
              activeClassName="colorActive"
              disableRipple
              exact
            >
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="User Management" />
            </ListItemButton>
          </ListItem>
        )}
      </DrawerList>
      {SlideViewerMatch && <Divider />}
      {SlideViewerMatch && (
        <DrawerList>
          <ListItemText
            sx={{
              flex: 0,
              ml: (theme) => theme.spacing(2),
              mt: (theme) => theme.spacing(2.25),
              mb: (theme) => theme.spacing(1),
            }}
          >
            <Typography variant="body3" color="darkGrey.0">
              Slide List
            </Typography>
          </ListItemText>
          {selectedSlides.map((slide) => (
            <ListItem key={slide.id}>
              <ListItemButton
                to={
                  Boolean(projectId)
                    ? `/projects/${projectId}/slides/${slide.id}`
                    : `/slides/${slide.id}`
                }
                component={StyledNavLink}
                activeClassName="bgActive"
                exact
                sx={{
                  width: "100%",
                }}
              >
                <DotIcon
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.slideStatus[
                        slideStatusColorProps(slide.status)
                      ]?.dot,
                    m: (theme) => theme.spacing("auto", 1, "auto", 0.5),
                  }}
                />
                <EllipsizedTypography
                  sx={{
                    alignItems: "center",
                    flex: 1,
                  }}
                  variant="body5"
                >
                  {slide.name}
                </EllipsizedTypography>
              </ListItemButton>
            </ListItem>
          ))}
        </DrawerList>
      )}
    </BaseDrawer>
  );
};

export default Drawer;
