import { ComponentType } from "react";
import { useHistory } from "react-router";
import { renderNull } from "utils/react";
import accessTokenManager from "../auth/accessTokenManager";

export default function withMFAGuard(Component: ComponentType) {
  return () => {
    const history = useHistory();
    if (!accessTokenManager.hasAccessTokenInStorage()) {
      return renderNull(() => history.push("/login"));
    }
    if (
      !accessTokenManager.getMFARequiredFromToken() ||
      accessTokenManager.getMFAVerifiedFromToken()
    ) {
      return renderNull(() => history.push("/"));
    }
    if (!accessTokenManager.getMFACertifiedFromToken()) {
      return renderNull(() => history.push("/login"));
    }
    return <Component />;
  };
}
