import { useState } from "react";

type Toggle = () => void;

export default function useToggleState(
  defaultState: boolean
): [boolean, Toggle] {
  const [state, setState] = useState(defaultState);
  const toggleState = () => setState((s) => !s);
  return [state, toggleState];
}
