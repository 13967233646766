import { FormEvent, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import AuthActionButton from "./AuthActionButton";
import AuthAlert from "./AuthAlert";
import AuthDescription from "./AuthDescription";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { requestResetMFA } from "dux/user/api";
import { UserTokenOut } from "dux/user/model";
import accessTokenManager from "auth/accessTokenManager";
interface LostMFAFormProps {
  onSuccess?: (tokenOut: UserTokenOut) => void;
}

export default function LostMFAForm({ onSuccess }: LostMFAFormProps) {
  const [success, setSuccess] = useState<boolean>(false);

  const userEmail = accessTokenManager.getCustomerEmailFromToken();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      requestResetMFA()
        .then((res: UserTokenOut) => {
          onSuccess?.(res);
          setSuccess(true);
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    [onSuccess]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>Lost MFA device?</AuthHeading>
      {success && (
        <AuthAlert severity="success">
          We have successfully sent you an email. Please check your email now.
        </AuthAlert>
      )}
      <AuthDescription>
        We will send you an email to deregister your current device.
        <br /> You can register a new device when you try to login after
        deregistering your current device.
        <br /> Do you want to proceed?
        <br />
        <br />
        *If you can't see the email in your inbox, check junk/spam or other
        folders.
      </AuthDescription>
      <AuthFormField
        id="email"
        aria-label="Email"
        placeholder={userEmail}
        inputProps={{ readOnly: true }}
      />
      <AuthActionButton>Confirm</AuthActionButton>
      <AuthNav>
        <Link to="/login?verify=true" style={{ color: "#7292FD" }}>
          Back to MFA
        </Link>
      </AuthNav>
    </AuthForm>
  );
}
