import React, { useState } from "react";
import { Container, AppBar } from "@mui/material";
import AuthSection from "./AuthSection";
import constate from "constate";
import LoadingIndicator from "components/analysis/LoadingIndicator";

const useAuthLoadingState = () => {
  const [loading, setLoading] = useState(false);
  return { loading, setLoading };
};

const [AuthLoadingStateProvider, useAuthLoadingStateContext] =
  constate(useAuthLoadingState);

const LoadingOverlay = () => {
  const { loading } = useAuthLoadingStateContext();
  return loading && <LoadingIndicator message="Loading..." />;
};

const AuthTemplate: React.FC = ({ children }) => {
  return (
    <AuthLoadingStateProvider>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.darkGrey[90],
          padding: (theme) => theme.spacing(4, 10),
        }}
      >
        <a href="/login">
          <img
            src={"/logos/logo-SCOPE-IO-dark-big.svg"}
            width="180"
            alt="Lunit SCOPE IO-login"
          />
        </a>
      </AppBar>
      <Container
        disableGutters
        sx={{
          display: "flex",
          height: "100%",
          maxWidth: "inherit",
        }}
      >
        <AuthSection>{children}</AuthSection>
      </Container>
      <LoadingOverlay />
    </AuthLoadingStateProvider>
  );
};

export { useAuthLoadingStateContext };

export default AuthTemplate;
