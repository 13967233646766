import { enqueueSnackbar } from "dux/snackbar/actions";
import {
  createTag,
  deleteTags,
  editTag,
  getTags,
  resetCreatedTag,
} from "dux/tags/actions";
import { Tag } from "dux/tags/model";
import { tagMgmtStateSelector, tagsTotalSelector } from "dux/tags/selectors";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { FetchStatus } from "dux/utils/commonEnums";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface UseTagsCRUDProps {
  onCreated?: (tag: Tag) => void;
}

export default function useTagsCRUD({ onCreated }: UseTagsCRUDProps) {
  const tags = useSelector(tagsTotalSelector);
  const tagMgmtState = useSelector(tagMgmtStateSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    // check if need to refresh tag TODO: simplify it with selector later...
    let needToLoad = false;
    needToLoad ||=
      tagMgmtState.fetchStatus === FetchStatus.Idle &&
      tagMgmtState.currentMethod === FetchMethod.Get;
    if (needToLoad && !tagMgmtState.loading) {
      dispatch(
        getTags.request({
          title: "",
        })
      );
    }
  }, [dispatch, tagMgmtState]);

  useEffect(() => {
    if (
      tagMgmtState.fetchStatus === FetchStatus.Fulfilled &&
      tagMgmtState.currentMethod === FetchMethod.Post &&
      !!tagMgmtState.createdTag
    ) {
      dispatch(resetCreatedTag());
      onCreated && onCreated(tagMgmtState.createdTag);
    }
  }, [tagMgmtState, onCreated, dispatch]);

  const requestCreateTag = useCallback(
    (input: string) => {
      if (input.length <= 200 && input.length > 0) {
        dispatch(
          createTag.request({
            title: input,
          })
        );
      } else if (input.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (input.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const requestDeleteTag = useCallback(
    (input: Tag) => {
      dispatch(deleteTags.request([input.id]));
    },
    [dispatch]
  );

  const requestEditTag = useCallback(
    (input: Tag, changed: string) => {
      const { id, description } = input;
      if (changed.length <= 200 && changed.length > 0) {
        dispatch(
          editTag.request({
            id,
            title: changed,
            description,
          })
        );
      } else if (changed.length > 200) {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be longer than 200 characters.",
            options: {
              variant: "error",
            },
          })
        );
      } else if (changed.length === 0) {
        dispatch(
          enqueueSnackbar({
            message: "Tag title cannot be empty.",
            options: {
              variant: "error",
            },
          })
        );
      }
    },
    [dispatch]
  );

  return {
    tags,
    tagMgmtState,
    requestCreateTag,
    requestEditTag,
    requestDeleteTag,
  };
}
