import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import {
  Project,
  ProjectMgmtState,
  ProjectsState,
  ProjectUUID,
  AllProjectsState,
} from "./model";
import { Paging } from "dux/utils/commonModels";
import { FetchStatus } from "dux/utils/commonEnums";

const ProjectsStateSelector: Selector<RootState, ProjectsState> = (state) =>
  state.projects;

export const getAllProjectsSelector: Selector<RootState, Project[]> =
  createSelector(
    ProjectsStateSelector,
    (projects) => projects.allProjects.list
  );

export const getAllProjectsStateSelector: Selector<
  RootState,
  AllProjectsState
> = createSelector(ProjectsStateSelector, (projects) => projects.allProjects);

export const getProjectTitleByIdSelector: (
  id: ProjectUUID
) => Selector<RootState, string> = (id: ProjectUUID) =>
  createSelector(ProjectsStateSelector, (projects) => {
    const filtered = projects.allProjects.list.filter(
      (project) => project.id === id
    );
    return filtered.length === 1 ? filtered[0].title : "";
  });

export const getProjectListSelector: Selector<RootState, Project[]> =
  createSelector(
    ProjectsStateSelector,
    (projects) => projects.projectList.list
  );

export const getProjectListLoadingSelector: Selector<RootState, boolean> =
  createSelector(
    ProjectsStateSelector,
    (projects) => projects.projectList.fetchStatus === FetchStatus.Pending
  );

export const getProjectListPaginationInfoSelector: Selector<RootState, Paging> =
  createSelector(
    ProjectsStateSelector,
    (projects) => projects.projectList.pagination
  );

export const getProjectMgmtStateSelector: Selector<
  RootState,
  ProjectMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = createSelector(ProjectsStateSelector, (projects) => ({
  ...projects.projectMgmt,
  loading: projects.projectMgmt.fetchStatus === FetchStatus.Pending,
  success: projects.projectMgmt.fetchStatus === FetchStatus.Fulfilled,
}));
