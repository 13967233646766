/**
 * Tag Reducer
 */
import { ActionType, createReducer, StateType } from "typesafe-actions";
import produce from "immer";

import { TagListState, TagMgmtState } from "./model";
import { FetchStatus } from "dux/utils/commonEnums";
import { combineReducers } from "redux";
import {
  CREATE_TAG_TYPES,
  createTag,
  DELETE_TAGS_TYPES,
  deleteTags,
  EDIT_TAG_TYPES,
  editTag,
  GET_TAGS_TYPES,
  getTags,
  RESET_CREATED_TAG,
} from "./actions";
import { FetchMethod } from "dux/utils/apiRequestHelper";

const tagListInitialState: TagListState = {
  fetchStatus: FetchStatus.Idle,
  list: [],
};

const tagMgmtInitialState: TagMgmtState = {
  currentMethod: FetchMethod.Get,
  fetchStatus: FetchStatus.Idle,
  error: "",
};

const tagListReducer = createReducer<TagListState>(tagListInitialState, {
  [GET_TAGS_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof getTags.success>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.list = payload;
    }),
});

const tagMgmtStateReducer = createReducer<TagMgmtState>(tagMgmtInitialState, {
  [RESET_CREATED_TAG]: (state) =>
    produce(state, (draft) => {
      delete draft.createdTag;
    }),
  [GET_TAGS_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Get;
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [GET_TAGS_TYPES.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Get;
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [GET_TAGS_TYPES.FAILURE]: (
    state,
    action: ActionType<typeof createTag.failure>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.currentMethod = FetchMethod.Get;
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = payload;
    }),

  [CREATE_TAG_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Post;
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [CREATE_TAG_TYPES.SUCCESS]: (
    state,
    action: ActionType<typeof createTag.success>
  ) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Post;
      draft.fetchStatus = FetchStatus.Fulfilled;
      draft.createdTag = action.payload;
    }),
  [CREATE_TAG_TYPES.FAILURE]: (
    state,
    action: ActionType<typeof createTag.failure>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.currentMethod = FetchMethod.Post;
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = payload;
    }),

  [DELETE_TAGS_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Delete;
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [DELETE_TAGS_TYPES.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Delete;
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [DELETE_TAGS_TYPES.FAILURE]: (
    state,
    action: ActionType<typeof deleteTags.failure>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.currentMethod = FetchMethod.Delete;
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = payload;
    }),

  [EDIT_TAG_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Patch;
      draft.fetchStatus = FetchStatus.Pending;
    }),
  [EDIT_TAG_TYPES.SUCCESS]: (state) =>
    produce(state, (draft) => {
      draft.currentMethod = FetchMethod.Patch;
      draft.fetchStatus = FetchStatus.Fulfilled;
    }),
  [EDIT_TAG_TYPES.FAILURE]: (
    state,
    action: ActionType<typeof editTag.failure>
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.currentMethod = FetchMethod.Patch;
      draft.fetchStatus = FetchStatus.Rejected;
      draft.error = payload;
    }),
});

const tagReducer = combineReducers({
  tagsTotal: tagListReducer,
  tagMgmtState: tagMgmtStateReducer,
});

export const initialState = {
  tagsTotal: tagListInitialState,
  tagMgmtState: tagMgmtInitialState,
};

export type TagState = StateType<typeof tagReducer>;

export default tagReducer;
