import { useDispatch, useSelector } from "react-redux";
import { FileProgress } from "./FileProgress";
import { getWSIKeyOrderSelector } from "components/WSIUpload/dux/selectors";
import { abortSingleWSIUpload } from "components/WSIUpload/dux/actions";

export default function FileProgressSheet() {
  const dispatch = useDispatch();
  const wsiKeyOrder = useSelector(getWSIKeyOrderSelector);
  return (
    <div
      style={{
        maxHeight: 448,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {wsiKeyOrder.map((key) => (
        <FileProgress
          key={key}
          wsiKey={key}
          onCancel={() => dispatch(abortSingleWSIUpload(key))}
        />
      ))}
    </div>
  );
}
