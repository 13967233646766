import {
  APICancerType,
  APICancerTypeInput,
  CancerUUID,
  SearchCancersParams,
} from "dux/cancerTypes/model";
import { FetchMethod, request } from "dux/utils/apiRequestHelper";

const encode = encodeURIComponent;
export const getCancers = (payload: SearchCancersParams) =>
  request({
    method: FetchMethod.Get,
    path: `cancers${payload.title ? `?title=${encode(payload.title)}` : ""}`,
  });

export const createCancerType = (payload: APICancerTypeInput) =>
  request({
    method: FetchMethod.Post,
    path: `cancers`,
    payload,
  });

export const deleteCancerTypes = (payload: CancerUUID) =>
  request({
    method: FetchMethod.Delete,
    path: `cancers/${payload}`,
    skipJson: true,
  });

export const editCancerType = (payload: APICancerType) =>
  request({
    method: FetchMethod.Patch,
    path: `cancers/${payload.id}`,
    payload: {
      name: payload.name,
      description: payload.description,
    },
  });
