import concat from "lodash/concat";
import includes from "lodash/includes";
import { FileWithPath } from "react-dropzone";
import {
  actionChannel,
  put,
  select,
  take,
  takeEvery,
  call,
  all,
  spawn,
  cancel,
  flush,
  takeLatest,
} from "redux-saga/effects";
import * as api from "./api";
import { v4 as uuidV4 } from "uuid";
import {
  ADD_FILES_TO_UPLOAD,
  AddFilesToUploadAction,
  addWSIsToUpload,
  UPLOAD_WSI_TO_S3,
  ABORT_SINGLE_WSI_UPLOAD,
  UploadWSIToS3Action,
  ADD_WSIS_TO_UPLOAD,
  AddWSIsToUploadAction,
  initWSIUploadWatcherTask,
  uploadWSIToS3Failure,
  setPresignedURLsForWSI,
  uploadWSIToS3FileSuccess,
  uploadWSIToS3FileProgress,
  uploadWSIToS3,
  uploadWSIToS3Success,
  ABORT_ALL_WSI_UPLOAD,
  AbortSingleWSIUploadAction,
  resetFileUpload,
  uploadWSIToS3Request,
  completeWSIUploadingSuccess,
  completeWSIUploadingFailure,
  RETRY_SINGLE_WSI_UPLOAD,
  RetrySingleWSIUploadAction,
  getAllowedWSIFormats,
  GET_ALLOWED_WSI_FORMATS_TYPES,
  getSlideLimit,
  GET_SLIDE_LIMIT_TYPES,
  startISyntaxConversion,
} from "./actions";
import { buffers, Channel, END, eventChannel } from "redux-saga";
import {
  AcceptableFileFormat,
  DEFAULT_ACCEPTABLE_FILE_TYPES,
  SingleWSIUploadState,
  WSIFileUploadError,
  WSIFileUploadStage,
  WSIUploadState,
} from "./model";
import {
  getAllowedFormatsSelector,
  getPresignedURLsByWSIKeySelector,
  getWSIIdByWSIKeySelector,
  getWSIUploadStateSelector,
} from "./selectors";
import { ErrorMessage, FetchMethod } from "dux/utils/apiRequestHelper";
import { ProjectUUID } from "dux/projects/model";
import { createAsyncSaga } from "dux/utils/actionHandlingHelper";
import { FileName } from "dux/WSIAnalysisResult/model";
import toUpper from "lodash/toUpper";
import { validateMRXSDataFiles } from "./utils";

function* watchAddFilesToUpload() {
  const addChannel = yield actionChannel(ADD_FILES_TO_UPLOAD);
  while (true) {
    const { payload }: AddFilesToUploadAction = yield take(addChannel);
    const fileFormats: AcceptableFileFormat[] = yield select(
      getAllowedFormatsSelector
    );
    // identify every WSI and init upload states for them
    const initialWSIStates = yield call(
      fileListToWSIUploadStates,
      payload.files,
      payload.projectId,
      fileFormats.length > 0 ? fileFormats : DEFAULT_ACCEPTABLE_FILE_TYPES
    );
    yield put(
      addWSIsToUpload(
        initialWSIStates,
        initialWSIStates.map(() => uuidV4())
      )
    );
  }
}

function fileListToWSIUploadStates(
  files: FileWithPath[],
  projectId: ProjectUUID,
  fileFormats: AcceptableFileFormat[]
): SingleWSIUploadState[] {
  // identify individual WSI files
  let individualWSIFiles: FileWithPath[] = [];
  let possibleMRXSSubFiles: FileWithPath[] = [];

  const acceptableExtensions: string[] = fileFormats.reduce(
    (extensions, fileType) => extensions.concat(fileType.formats),
    []
  );
  files.forEach((file) => {
    const namePieces = file.name.split(".");
    const extension = `.${namePieces[namePieces.length - 1]}`;
    if (acceptableExtensions.indexOf(extension) >= 0) {
      individualWSIFiles.push(file);
    } else {
      possibleMRXSSubFiles.push(file);
    }
  });
  // identify mrxs sub-files
  let allMrxs: FileWithPath[] = individualWSIFiles.filter((file) =>
    file.name.endsWith(".mrxs")
  );
  let mrxsNames: string[] = allMrxs.map((mrxs) => mrxs.name.split(".")[0]);
  let mrxsDats: { [mrxsName: string]: FileWithPath[] } = mrxsNames.reduce(
    (dats, mrxsName) => ({ ...dats, [mrxsName]: [] }),
    {}
  );
  possibleMRXSSubFiles.forEach((file) => {
    const pathPieces = file.path.split("/").slice(-2);
    const dirName = pathPieces[0];
    const isFileInDir = pathPieces.length > 1;
    if (isFileInDir && mrxsNames.indexOf(dirName) >= 0) {
      mrxsDats[dirName].push(file);
    }
  });
  return individualWSIFiles.map((mainFile) => {
    if (mainFile.name.endsWith(".mrxs")) {
      return createSingleWSIUploadStateFromFiles(
        concat([mainFile], mrxsDats[mainFile.name.split(".")[0]] || []),
        projectId
      );
    }
    return createSingleWSIUploadStateFromFiles([mainFile], projectId);
  });
}

function createSingleWSIUploadStateFromFiles(
  files: FileWithPath[],
  projectId: ProjectUUID
): SingleWSIUploadState {
  return {
    targetFiles: files,
    projectId,
    stage: WSIFileUploadStage.INIT,
    totalSize: files.reduce((sizeSum, file) => sizeSum + file.size, 0),
    presignedURLs: {},
    xhrPool: [],
    loading: false,
  };
}

function* watchAddWSIToUploadSaga() {
  const addChannel = yield actionChannel(ADD_WSIS_TO_UPLOAD);
  while (true) {
    const { payload }: AddWSIsToUploadAction = yield take(addChannel);
    const currentState: WSIUploadState = yield select(
      getWSIUploadStateSelector
    );
    const { wsiKeys } = payload;
    // spawn root uploadWatcherTask. This can be used to cancel all uploads at once.
    if (!currentState.uploadWatcherTask) {
      const uploadWatcherTask = yield spawn(watchUploadWSIToS3Saga);
      yield put(initWSIUploadWatcherTask(uploadWatcherTask));
    }
    // put UPLOAD_WSI_TO_S3 actions for every WSIs added
    yield all(wsiKeys.map((wsiKey) => put(uploadWSIToS3(wsiKey))));
  }
}
let uploadProcessStarterTask;
// this saga starts up an actionChannel (i.e. queue) watching UPLOAD_WSI_TO_S3 actions
function* watchUploadWSIToS3Saga() {
  const uploadChannel = yield actionChannel(UPLOAD_WSI_TO_S3);
  // spawn task that consumes the actionChannel created above
  // this can be later used to cancel the upload process that is currently running
  uploadProcessStarterTask = yield spawn(uploadProcessStarter, uploadChannel);
  yield takeEvery(
    ABORT_SINGLE_WSI_UPLOAD,
    function* (action: AbortSingleWSIUploadAction) {
      const state: WSIUploadState = yield select(getWSIUploadStateSelector);
      const canceledKey = action.payload.wsiKey;
      if (state.states[canceledKey].xhrPool.length > 0) {
        // uploading already started, so restart the starter task
        yield cancel(uploadProcessStarterTask);
        uploadProcessStarterTask = yield spawn(
          uploadProcessStarter,
          uploadChannel
        );
      } else {
        // uploading didn't start yet, so filter out action in queue
        const actions: UploadWSIToS3Action[] = yield flush(uploadChannel);
        const filteredActions = actions.filter(
          (qAction) => qAction.payload.wsiKey !== canceledKey
        );
        yield all(filteredActions.map((qAction) => put(qAction)));
      }
    }
  );
}

// This saga consumes actionChannel created from watchUploadWSIToS3Saga and starts the actual upload process for WSI
// Note this uses call() effect to only upload one WSI at a time
function* uploadProcessStarter(uploadChannel: Channel<UploadWSIToS3Action>) {
  while (true) {
    const { payload }: UploadWSIToS3Action = yield take(uploadChannel);
    const state: WSIUploadState = yield select(getWSIUploadStateSelector);
    if (state.states[payload.wsiKey].stage !== WSIFileUploadStage.DONE) {
      yield call(WSIUploadSaga, payload.wsiKey, state.states[payload.wsiKey]);
    }
  }
}

// This saga contains actual logic for the WSI upload process
function* WSIUploadSaga(wsiKey: string, thisWSI: SingleWSIUploadState) {
  try {
    const { targetFiles, stage, projectId } = thisWSI;
    // just get renewed presigned URLs every try unless files are already uploaded to S3
    switch (stage) {
      case WSIFileUploadStage.INIT:
      case WSIFileUploadStage.GetPresignedURL:
      //@ts-ignore
      // eslint-disable-next-line no-fallthrough
      case WSIFileUploadStage.UploadFileToS3:
        yield call(getPresignedURLsSaga, wsiKey, targetFiles, projectId);
        yield put(uploadWSIToS3Request(wsiKey));
        const presignedURLs: SingleWSIUploadState["presignedURLs"] =
          yield select(getPresignedURLsByWSIKeySelector(wsiKey));
        if (!isMRXS(thisWSI.targetFiles)) {
          yield all(
            thisWSI.targetFiles.map((file, index) =>
              call(
                uploadWSIFileSaga,
                wsiKey,
                index,
                file,
                presignedURLs[file.name]
              )
            )
          );
        } else {
          const filenames = getFilenamesForMRXSFileGroup(thisWSI.targetFiles);
          yield all(
            thisWSI.targetFiles.map((file, index) =>
              call(
                uploadWSIFileSaga,
                wsiKey,
                index,
                file,
                presignedURLs[filenames[index]]
              )
            )
          );
        }
        yield put(uploadWSIToS3Success(wsiKey));
      /* fall through */
      case WSIFileUploadStage.CompleteUploadAPI:
        const targetWSIId: string = yield select(
          getWSIIdByWSIKeySelector(wsiKey)
        );
        yield call(
          completeWSIUploadAPISaga,
          wsiKey,
          targetWSIId,
          thisWSI.targetFiles
        );
        break;
      case WSIFileUploadStage.ISyntaxConverting:
        // just wait for upload success message for isyntax file
        break;
      case WSIFileUploadStage.DONE:
        console.log(
          "UPLOAD_WSI_TO_S3 dispatched for WSI that's already done uploading"
        );
    }
  } catch (e) {
    // Call failure function & return error message
    console.log(e.message);
    yield put(uploadWSIToS3Failure(wsiKey, e.message));
  }
}

function* getPresignedURLsSaga(wsiKey, targetFiles, projectId?) {
  // get presigned url
  const targetIsMRXS = isMRXS(targetFiles);
  if (targetFiles.length === 1 && !targetIsMRXS) {
    if (!validateSlideFilename(targetFiles[0].name)) {
      throw new Error(WSIFileUploadError.InvalidFilename);
    }
    try {
      // valid non-MRXS WSI upload
      const results = yield call(
        api.getPresignedURLs,
        [targetFiles[0].name],
        getFormatFromFilename(targetFiles[0].name),
        projectId
      );
      yield put(
        setPresignedURLsForWSI(
          wsiKey,
          results.wsiId,
          results.presigned_url_info
        )
      );
      return;
    } catch (e) {
      if (e.message === ErrorMessage.FetchError) {
        throw new Error(WSIFileUploadError.UploadFailedNetwork);
      }
      if (e.json && includes(e.json.details, "limited count is")) {
        throw new Error(WSIFileUploadError.FileLimitReached);
      }
      throw new Error(WSIFileUploadError.UploadFailedServer);
    }
  }
  if (targetFiles.length > 1 && targetIsMRXS) {
    try {
      // validate mrxs dat file index
      const allDataFilesExist = yield call(validateMRXSDataFiles, targetFiles);
      if (!allDataFilesExist) {
        throw new Error(WSIFileUploadError.MissingSlideDat);
      }
      // valid MRXS WSI upload
      const filenames = getFilenamesForMRXSFileGroup(targetFiles);
      const results = yield call(
        api.getPresignedURLs,
        filenames,
        "MRXS",
        projectId
      );
      yield put(
        setPresignedURLsForWSI(
          wsiKey,
          results.wsiId,
          results.presigned_url_info
        )
      );
      return;
    } catch (e) {
      console.log(e.message);
      if (e.message === WSIFileUploadError.MissingSlideDat) {
        throw e;
      }
      if (e.message === ErrorMessage.FetchError) {
        throw new Error(WSIFileUploadError.UploadFailedNetwork);
      }
      if (e.json && includes(e.json.details, "limited count is")) {
        throw new Error(WSIFileUploadError.FileLimitReached);
      }
      throw new Error(WSIFileUploadError.UploadFailedServer);
    }
  }
  // invalid targetFiles throw error accordingly
  if (!targetIsMRXS && targetFiles.length > 1) {
    throw new Error(WSIFileUploadError.NoMatchingMRXS);
  }
  if (targetIsMRXS && targetFiles.length === 1) {
    throw new Error(WSIFileUploadError.NoDAT);
  }
}

function isMRXS(files: FileWithPath[]): boolean {
  return files.findIndex((file) => file.name.includes(".mrxs")) >= 0;
}

function isISyntax(files: FileWithPath[]): boolean {
  return files.findIndex((file) => file.name.includes(".isyntax")) >= 0;
}

function getFormatFromFilename(filename: FileName): "SVS" | "ISYNTAX" | "TIFF" {
  const namePieces = filename.split(".");
  const extension = toUpper(`${namePieces[namePieces.length - 1]}`);
  if (extension === "SVS" || extension === "ISYNTAX" || extension === "TIFF")
    return extension;
  throw new Error(WSIFileUploadError.InvalidFile);
}

function validateSlideFilename(filename: FileName): boolean {
  if (filename.includes(":")) {
    return false;
  }
  return true;
}

function getFilenamesForMRXSFileGroup(files: FileWithPath[]) {
  const dirName = files[0].name.split(".")[0];
  const filenames = files.map((file) =>
    file.name.includes(".mrxs") ? file.name : `${dirName}/${file.name}`
  );
  return filenames;
}

function* completeWSIUploadAPISaga(
  wsiKey: string,
  wsiId: string,
  targetFiles: FileWithPath[]
) {
  try {
    yield call(api.completeWSIFileUploading, { wsiId });
    yield put(getSlideLimit.request(""));
    if (!isISyntax(targetFiles)) {
      yield put(completeWSIUploadingSuccess(wsiKey));
    } else {
      yield put(startISyntaxConversion(wsiKey));
    }
  } catch (e) {
    if (e.message === "Failed to fetch") {
      yield put(
        completeWSIUploadingFailure(
          wsiKey,
          WSIFileUploadError.UploadFailedNetwork
        )
      );
    }
    if (includes(e.message, "Too Many Requests")) {
      yield put(
        completeWSIUploadingFailure(wsiKey, WSIFileUploadError.FileLimitReached)
      );
    } else {
      yield put(
        completeWSIUploadingFailure(
          wsiKey,
          WSIFileUploadError.UploadFailedServer
        )
      );
    }
  }
}

function* uploadWSIFileSaga(
  wsiKey: string,
  index: number,
  file: FileWithPath,
  presignedURL: string
) {
  const uploadEventChannel = yield call(
    createUploadFileChannel,
    presignedURL,
    file
  );
  try {
    while (true) {
      const {
        loaded = 0,
        total = 1,
        err,
        success,
      } = yield take(uploadEventChannel);
      if (err) {
        yield put(uploadWSIToS3Failure(wsiKey, err));
        throw new Error(err);
      }
      if (success) {
        yield put(uploadWSIToS3FileSuccess(wsiKey, index));
        break;
      }
      yield put(uploadWSIToS3FileProgress(wsiKey, index, loaded, total));
    }
  } finally {
    uploadEventChannel.close();
  }
}

function createUploadFileChannel(presignedURL: string, file: FileWithPath) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        emitter({ loaded: e.loaded, total: e.total });
      }
    };
    const onFailure = (e: ProgressEvent) => {
      emitter({ err: WSIFileUploadError.UploadFailedNetwork });
      emitter(END);
    };
    const onCancel = (e: ProgressEvent) => {
      emitter({ cancelled: true });
      emitter(END);
    };
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onCancel);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === XMLHttpRequest.DONE) {
        if (status === 200) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open(FetchMethod.Put, presignedURL, true);
    xhr.send(file);
    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onCancel);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
    // Using a sliding buffer to keep the latest XHR event in the event channel.
    // Buffer size is set to an arbitrarily small value (2),
    // as we don't need to buffer that many out-of-date XHR events.
  }, buffers.sliding(2));
}

// function getUploadingWSIState(
//   wsiUploadStates: WSIUploadState["states"]
// ): SingleWSIUploadState | null {
//   const uploadingWSIStates = filter(
//     wsiUploadStates,
//     (state: SingleWSIUploadState) => state.loading
//   );
//   if (uploadingWSIStates.length > 0) return uploadingWSIStates[0];
//   return null;
// }

function* cancelWSIUploadSaga() {
  const abortChannel = yield actionChannel(ABORT_ALL_WSI_UPLOAD);
  while (true) {
    yield take(abortChannel);
    const wsiUploadState: WSIUploadState = yield select(
      getWSIUploadStateSelector
    );
    yield cancel(wsiUploadState.uploadWatcherTask.ref);
    yield cancel(uploadProcessStarterTask);
    yield put(resetFileUpload());
  }
}

function* retryWSIUploadSaga() {
  const retryChannel = yield actionChannel(RETRY_SINGLE_WSI_UPLOAD);
  while (true) {
    const action: RetrySingleWSIUploadAction = yield take(retryChannel);
    const { wsiKey } = action.payload;
    yield put(uploadWSIToS3(wsiKey));
  }
}

const getAllowedWSIFormatsSaga = createAsyncSaga(
  getAllowedWSIFormats,
  api.getAllowedWSIFormats
);

const getSlideLimitSaga = createAsyncSaga(getSlideLimit, api.getSlideLimit);

export default function* WSIUploadRootSaga() {
  const sagas = [
    watchAddFilesToUpload,
    watchAddWSIToUploadSaga,
    cancelWSIUploadSaga,
    retryWSIUploadSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
  yield takeLatest(
    GET_ALLOWED_WSI_FORMATS_TYPES.REQUEST,
    getAllowedWSIFormatsSaga
  );
  yield takeLatest(GET_SLIDE_LIMIT_TYPES.REQUEST, getSlideLimitSaga);
}
