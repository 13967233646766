import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { DialogContext } from "./Dialog/context";

const Router = ({ children }) => {
  const { handleDialog } = useContext(DialogContext);
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        handleDialog({
          width: 504,
          agree: "Confirm",
          content: <Typography variant="body2">{message}</Typography>,
          handleAgreementCallback: () => {
            callback(true);
          },
          handleDisagreementCallback: () => {
            callback(false);
          },
          disableBackdropClick: true,
        });
      }}
    >
      {children}
    </BrowserRouter>
  );
};

export default Router;
