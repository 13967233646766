import React from "react";
import AuthTemplate from "components/auth/AuthTemplate";
import ResetMFAContent from "components/auth/ResetMFAContent";

const ResetMFA: React.FC = () => {
  return (
    <AuthTemplate>
      <ResetMFAContent />
    </AuthTemplate>
  );
};

export default ResetMFA;
