import { ReactNode } from "react";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import { Selector } from "react-redux";
import { RootState } from "dux/rootReducer";
import { createSelector } from "reselect";
import { componentsStateSelector } from "components/utils";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export interface ISheetContent {
  title: string;
  paperStyle?: SxProps<Theme>;
  subtitle?: string;
  content: ReactNode;
  key: string;
  onClose?: () => boolean;
  expanded?: boolean;
}

export type SheetsState = ISheetContent[];

export const initialState: SheetsState = [];

// action types
const PUT_SHEET = "components/Sheets/PUT_SHEET";
const REMOVE_SHEET = "components/Sheets/REMOVE_SHEET";

interface PutSheetAction {
  type: typeof PUT_SHEET;
  payload: {
    content: ISheetContent;
  };
}

interface RemoveSheetAction {
  type: typeof REMOVE_SHEET;
  payload: {
    key: string;
  };
}

export type SheetsActionTypes = PutSheetAction | RemoveSheetAction;

// action creators

export function putSheet(content: ISheetContent): SheetsActionTypes {
  return {
    type: PUT_SHEET,
    payload: { content },
  };
}

export function removeSheet(key: string): SheetsActionTypes {
  return {
    type: REMOVE_SHEET,
    payload: { key },
  };
}

// reducer
const sheetsReducer = createReducer<SheetsState>(initialState, {
  [PUT_SHEET]: (state, action: PutSheetAction) =>
    produce(state, (draft) => {
      // update if key exists
      const { content } = action.payload;
      const contentIndex = draft.findIndex(
        (aContent) => aContent.key === content.key
      );
      if (contentIndex >= 0) {
        draft[contentIndex] = content;
        return;
      }
      draft.push(content);
    }),
  [REMOVE_SHEET]: (state, action: RemoveSheetAction) =>
    produce(state, (draft) => {
      const { key } = action.payload;
      const contentIndex = draft.findIndex((aContent) => aContent.key === key);
      if (contentIndex >= 0) {
        draft.splice(contentIndex, 1);
      }
    }),
});

export default sheetsReducer;

// selectors
export const getSheetsSelector: Selector<RootState, SheetsState> =
  createSelector(componentsStateSelector, (components) => components.sheets);
