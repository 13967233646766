import { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { TabContext } from "@mui/lab";
import Divider from "@mui/material/Divider";
import InformationPanel from "./InformationPanel";
import AnalysisPanel from "./AnalysisPanel";
import {
  AnalysisResultDetail,
  DetailsSlideInformation,
} from "dux/analysis/model";
import useRightPanelStateContext, { RightPanelTabType } from "./context";

interface SlideRightPanelProps {
  slideId: string;
  slideInformation?: DetailsSlideInformation;
  analysisResultDetail?: AnalysisResultDetail;
}

function SlideRightPanel({
  slideId,
  slideInformation,
  analysisResultDetail,
}: SlideRightPanelProps) {
  const { tab, setTab } = useRightPanelStateContext();
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: RightPanelTabType
  ) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (!analysisResultDetail || !slideInformation?.qualityControl) {
      setTab(RightPanelTabType.INFORMATION);
    } else {
      setTab(RightPanelTabType.ANALYSIS);
    }
  }, [slideInformation, analysisResultDetail, setTab]);
  return (
    <Drawer
      sx={{
        width: (theme) => theme.subDrawer.width,
        flexShrink: 0,
      }}
      variant="permanent"
      PaperProps={{
        sx: {
          borderLeft: "none",
          width: (theme) => theme.subDrawer.width,
          color: "#fff",
          display: "flex",
          flexDirection: "column",
        },
      }}
      anchor="right"
    >
      <Toolbar />
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Tab label="Information" value={RightPanelTabType.INFORMATION} />
          <Tab
            label="Analysis"
            value={RightPanelTabType.ANALYSIS}
            disabled={
              !analysisResultDetail || !slideInformation?.qualityControl
            }
          />
        </Tabs>
        <Divider />
        <InformationPanel
          slideId={slideId}
          slide={slideInformation}
          value={RightPanelTabType.INFORMATION}
        />
        <AnalysisPanel
          analysisResultDetail={analysisResultDetail}
          value={RightPanelTabType.ANALYSIS}
        />
      </TabContext>
    </Drawer>
  );
}

export default SlideRightPanel;
