import {
  AccountProfile,
  APINotiSetting,
  APIPasswordParams,
  APIUser,
  APIUserInput,
  APIUserList,
  UpdateUserProjectMappingsParams,
  UpdateUserProjectMappingsResult,
  UserListRequestParams,
  UserUUID,
} from "./model";
import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import { action } from "typesafe-actions";
import { APIError } from "dux/utils/apiRequestHelper";

/**
 * Action Creators
 * Usage: getCurrentUser[type: request | success | failure](params)
 */
export const CURRENT_USER_TYPES = asyncActionCreator("GET_CURRENT_USER");
export const getCurrentUser = asyncAction<string, APIUser, string>(
  CURRENT_USER_TYPES
);

/**
 * Action Creators
 * Usage: getAllUsers[type: request | success | failure](params)
 */
export const GET_ALL_USERS_TYPES = asyncActionCreator("GET_ALL_USERS");
export const getAllUsers = asyncAction<
  UserListRequestParams,
  APIUserList,
  string
>(GET_ALL_USERS_TYPES);

export const SET_USERS_FILTER = "SET_USERS_FILTER";
export const setUsersFilter = (name: string) => action(SET_USERS_FILTER, name);

export const RESET_ACCOUNT_MGMT_STATE = "RESET_ACCOUNT_MGMT_STATE";
export const resetAccountMgmtState = () => action(RESET_ACCOUNT_MGMT_STATE);

/**
 * Action Creators
 * Usage: forgotPassword[type: request | success | failure](params)
 */
export interface ForgotPasswordPayload {
  email: string;
  resolve: (resolveParam?) => void;
  reject: (rejectParam?) => void;
}
export const FORGOT_PASSWORD_TYPES = asyncActionCreator("FORGOT_PASSWORD");
export const forgotPassword = asyncAction<
  ForgotPasswordPayload,
  string,
  string
>(FORGOT_PASSWORD_TYPES);

/**
 * Action Creators
 * Usage: createUser[type: request | success | failure](params)
 */
export const CREATE_USER_TYPES = asyncActionCreator("CREATE_USER");
export const createUser = asyncAction<APIUserInput, any, string>(
  CREATE_USER_TYPES
);

/**
 * Action Creators
 * Usage: updateUser[type: request | success | failure](params)
 */
export const UPDATE_USER_TYPES = asyncActionCreator("UPDATE_USER");
export const updateUser = asyncAction<APIUserInput, any, string>(
  UPDATE_USER_TYPES
);

/**
 * Action Creators
 * Usage: deleteUsers[type: request | success | failure](params)
 */
export const DELETE_USERS_TYPES = asyncActionCreator("DELETE_USERS");
export const deleteUsers = asyncAction<UserUUID[], any, string>(
  DELETE_USERS_TYPES
);

export const RESET_SETTINGS_STATE = "RESET_SETTINGS_STATE";
export const resetSettingsState = () => ({
  type: RESET_SETTINGS_STATE,
});

/**
 * Action Creators
 * Usage: updatePassword[type: request | success | failure](params)
 */
export const UPDATE_PASSWORD_TYPES = asyncActionCreator("UPDATE_PASSWORD");
export const updatePassword = asyncAction<APIPasswordParams, any, APIError>(
  UPDATE_PASSWORD_TYPES
);

/**
 * Action Creators
 * Usage: updateProfile[type: request | success | failure](params)
 */
export const UPDATE_PROFILE_TYPES = asyncActionCreator("UPDATE_PROFILE");
export const updateProfile = asyncAction<
  Partial<AccountProfile>,
  any,
  APIError
>(UPDATE_PROFILE_TYPES);

/**
 * Action Creators
 * Usage: getNotiSettings[type: request | success | failure](params)
 */
export const GET_NOTIFICATION_SETTINGS = asyncActionCreator("GET_NOTIFICATION");
export const getNotiSettings = asyncAction<string, APINotiSetting[], APIError>(
  GET_NOTIFICATION_SETTINGS
);

/**
 * Action Creators
 * Usage: updateNotiSettings[type: request | success | failure](params)
 */
export const UPDATE_NOTIFICATION_SETTINGS = asyncActionCreator(
  "UPDATE_NOTIFICATION"
);
export const updateNotiSettings = asyncAction<APINotiSetting[], any, APIError>(
  UPDATE_NOTIFICATION_SETTINGS
);

/**
 * Action Creators
 * Usage: updateUserProjectMappings[type: request | success | failure](params)
 */
export const UPDATE_USER_PROJECT_MAPPINGS_TYPES = asyncActionCreator(
  "UPDATE_USER_PROJECT_MAPPINGS"
);
export const updateUserProjectMappings = asyncAction<
  UpdateUserProjectMappingsParams,
  UpdateUserProjectMappingsResult,
  string
>(UPDATE_USER_PROJECT_MAPPINGS_TYPES);

export const USER_LOGIN = "USER_LOGIN";
export const userLogin = () => action(USER_LOGIN);
