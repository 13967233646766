import { useCallback, useContext, useState, MouseEvent } from "react";
import {
  TextField,
  DialogContent,
  Button,
  Typography,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SwitchSlider from "components/SwitchSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountProfileSelector,
  getMFARegisteredAt,
  isAdminUserSelector,
} from "dux/user/selectors";
import { deleteMFA, refreshToken, updateUsersMFA } from "dux/user/api";
import { DialogContext } from "components/Dialog/context";
import { useHistory } from "react-router";
import accessTokenManager from "auth/accessTokenManager";
import { DEFAULT_TOKEN_EXP_SEC } from "dux/utils/apiRequestHelper";
import { DateFormatter, SLIDE_DATE_FORMAT } from "utils/format";
import { logout } from "dux/utils/actionHandlingHelper";
import { abortAllWSIUpload } from "components/WSIUpload/dux/actions";
import { enqueueSnackbar } from "dux/snackbar/actions";

interface MFAFormProps {
  loading?: boolean;
}
const AdminMFAContent = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6.5),
}));

const MFAContent = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(4.5),
  "& > button": {
    width: "148px",
    height: 36,
    padding: (theme) => theme.spacing(1, 2),
  },
}));

const SettingsFormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:not(:first-of-type)": {
    marginTop: "inherit",
  },
}));

export default function MFAForm({ loading }: MFAFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector(getAccountProfileSelector);
  const isAdmin = useSelector(isAdminUserSelector);
  const mfaRegisteredAt = useSelector(getMFARegisteredAt);

  const { handleDialog: openDialog } = useContext(DialogContext);

  const [mfaRequired, setMFARequired] = useState(
    accessTokenManager.getMFARequiredFromToken()
  );

  const handleMFASwitchChange = useCallback(() => {
    if (!mfaRequired) {
      openDialog({
        width: 320,
        height: 184,
        agree: "Confirm",
        title: "Warning",
        content: (
          <Typography variant="body5">
            Are you sure you want to turn on MFA? When you turn on, all users
            should login using MFA.
          </Typography>
        ),
        handleAgreementCallback: async () => {
          await updateUsersMFA({ mfaRequired: true });
          setMFARequired(true);
          const newTokenOut = await refreshToken({
            accessToken: accessTokenManager.getAccessToken(),
            refreshToken: accessTokenManager.getRefreshTokenFromStorage(),
            expSec: DEFAULT_TOKEN_EXP_SEC,
          });
          accessTokenManager.setAccessToken(newTokenOut.accessToken);
          accessTokenManager.setRefreshToken(newTokenOut.refreshToken);
          if (accessTokenManager.getMFACertifiedFromToken())
            history.replace("/login?verify=true");
          else {
            dispatch(abortAllWSIUpload());
            dispatch(logout());
          }
        },
      });
    } else {
      openDialog({
        width: 320,
        height: 184,
        agree: "Confirm",
        title: "Warning",
        content: (
          <Typography variant="body5">
            Are you sure you want to turn off MFA? When you turn off, all users
            can login without MFA step.
          </Typography>
        ),
        handleAgreementCallback: async () => {
          await updateUsersMFA({ mfaRequired: false });
          setMFARequired(false);
          const newTokenOut = await refreshToken({
            accessToken: accessTokenManager.getAccessToken(),
            refreshToken: accessTokenManager.getRefreshTokenFromStorage(),
            expSec: DEFAULT_TOKEN_EXP_SEC,
          });
          accessTokenManager.setAccessToken(newTokenOut.accessToken);
          accessTokenManager.setRefreshToken(newTokenOut.refreshToken);
        },
      });
    }
  }, [mfaRequired, openDialog, history, dispatch]);

  const handleDeleteMFAClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      openDialog({
        width: 320,
        height: 204,
        agree: "Confirm",
        title: "Warning",
        content: (
          <Typography variant="body5">
            Are you sure you want to deregister your current MFA device?
            <br /> Once you deregister, you need to login again and regiser new
            MFA device.
          </Typography>
        ),
        handleAgreementCallback: () => {
          deleteMFA()
            .then(() => {
              history.replace({
                pathname: `/reset-mfa/reset`,
                state: true,
              });
            })
            .catch((error) => {
              dispatch(
                enqueueSnackbar({
                  message: `Failed to deregister your current MFA device: ${
                    error.details ||
                    error.message ||
                    error ||
                    "Unknown server error"
                  }`,
                  options: {
                    variant: "error",
                    onEnter: () => {
                      openDialog(null);
                    },
                  },
                })
              );
            });
        },
      });
    },
    [history, openDialog]
  );

  return (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      }}
    >
      {isAdmin && (
        <AdminMFAContent>
          <Typography variant="body5" sx={{ marginBottom: 2 }}>
            For enhanced security, we recommend that you configure Multi-Factor
            Authentication (MFA) to protect your resources. When you enable MFA,
            it will be applied to all user under your account.
          </Typography>
          <SwitchSlider
            label="Activate MFA on all accounts"
            onChange={handleMFASwitchChange}
            checked={mfaRequired}
            disabled={loading}
          />
        </AdminMFAContent>
      )}
      <MFAContent>
        <Typography variant="body5" sx={{ marginTop: 0, marginBottom: 2 }}>
          We only support virtual MFA device. This is a software application
          that runs on a phone or other device that emulates a physical device.
          The virtual MFA device generates a six-digit numeric code, based on a
          time-synchronized, one-time password algorithm. The user should check
          the code from the device and type on the pop-up page that appears
          during the log-in process.
        </Typography>
        <Button
          variant="contained"
          color="scope2"
          disabled={mfaRegisteredAt === ""}
          onClick={handleDeleteMFAClick}
        >
          <Typography variant="body5">Deregistration</Typography>
        </Button>
      </MFAContent>
      <SettingsFormRow>
        <InputLabel htmlFor="email">
          <Typography color="#fff" variant="body3">
            MFA application ID
          </Typography>
        </InputLabel>
        <TextField
          id="email"
          name="email"
          type="email"
          variant="outlined"
          disabled
          defaultValue={profile.email}
        />
      </SettingsFormRow>
      <SettingsFormRow>
        <InputLabel htmlFor="registeredAt">
          <Typography color="#fff" variant="body3">
            Registered at
          </Typography>
        </InputLabel>
        <TextField
          id="registeredAt"
          name="registeredAt"
          variant="outlined"
          disabled
          defaultValue={
            mfaRegisteredAt !== ""
              ? DateFormatter(mfaRegisteredAt, SLIDE_DATE_FORMAT)
              : ""
          }
        />
      </SettingsFormRow>
    </DialogContent>
  );
}
