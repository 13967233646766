import { useEffect, useState } from "react";
import useStompClient, { NotificationMessage } from "./useStompClient";

export default function useStompMessageWatch(
  predicate?: (message: NotificationMessage) => boolean
) {
  const { messages: notificationMessages } = useStompClient();
  const [lastNotificationCount, setLastNotificationCount] = useState(
    notificationMessages.length
  );
  const [newMessages, setNewMessages] = useState([]);
  useEffect(() => {
    if (notificationMessages.length > lastNotificationCount) {
      setLastNotificationCount(notificationMessages.length);
      let filteredMessages = notificationMessages.slice(
        0,
        notificationMessages.length - lastNotificationCount
      );
      if (predicate) {
        filteredMessages = filteredMessages.filter(predicate);
      }
      if (filteredMessages.length > 0) {
        setNewMessages(filteredMessages);
      }
    }
  }, [
    lastNotificationCount,
    notificationMessages,
    notificationMessages.length,
    predicate,
  ]);

  return newMessages;
}
