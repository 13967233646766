import { styled } from "@mui/material/styles";

const AuthNav = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(3),
  "& > *:only-child": {
    marginLeft: "auto",
  },
}));

export default AuthNav;
