import { RootState } from "dux/rootReducer";
import { Selector } from "react-redux";
import forEach from "lodash/forEach";
import split from "lodash/split";
import { SlideItem } from "dux/slide/model";

export const componentsStateSelector: Selector<
  RootState,
  RootState["components"]
> = (state) => state.components;

export function dataURItoBlob(dataURI) {
  var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mime });
}

export type RGB = {
  r: number;
  g: number;
  b: number;
};
/**
 * Converts Hex Color String -> RGB object format
 * @param HexColorString e.g. '#000000'
 * @param alpha 0 - 1
 * @returns RGB
 */
export const hexToRgba = (hex: string): RGB => ({
  r: Number.parseInt(hex.substr(1, 2), 16),
  g: Number.parseInt(hex.substr(3, 2), 16),
  b: Number.parseInt(hex.substr(5, 2), 16),
});

export const roundedRect = function (x, y, width, height, radius) {
  return (context: CanvasRenderingContext2D) => {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    return context;
  };
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatTps = (totalTps: number) =>
  `${(Math.floor(totalTps * 10) / 10).toFixed(1)}`;

export const roundToFirstDecimal = (num: number) =>
  `${(Math.round(num * 10) / 10).toFixed(1)}`;

export const formatAITPS = (totalAITPS: number) => `${Math.floor(totalAITPS)}`;

export function isPhoneNumberValid(value: string): boolean {
  if (value.length === 0) return true;
  if (value.length > 13 && value.length < 8) return false;
  const charset = "1234567890-";
  let validCharset = true;
  forEach(value, (char) => {
    validCharset = validCharset && charset.includes(char);
  });
  if (!validCharset) return false;
  const pieces = split(value, "-");
  if (pieces.length > 3 || pieces.length < 2) return false;
  // ###-###-#### / ###-####-#### / ##-###-#### / ##-####-####
  if (pieces.length === 3) {
    return (
      pieces[0].length >= 2 &&
      pieces[0].length <= 3 &&
      pieces[1].length >= 3 &&
      pieces[1].length <= 4 &&
      pieces[2].length === 4
    );
  }
  // ###-#### / ####-####
  return (
    pieces[0].length >= 3 && pieces[0].length <= 4 && pieces[1].length === 4
  );
}

export function supportsOffscreenCanvas(): boolean {
  const canvas = document.createElement("canvas");
  if (
    canvas.transferControlToOffscreen &&
    typeof canvas.transferControlToOffscreen === "function"
  ) {
    return true;
  }
  return false;
}

export function getSlideBulkActionTooltip(
  selectedSlides: SlideItem[],
  predicate: (slide: SlideItem) => boolean,
  defaultTooltip: string
): string {
  const filtered = selectedSlides.filter(predicate);
  if (
    getSlideBulkActionDisabled(selectedSlides, predicate) &&
    filtered.length > 0
  ) {
    return (
      `"${defaultTooltip}" disabled because following slides are selected: ` +
      filtered.reduce(
        (prev, slide) => `${prev}${prev && ", "}${slide.name}`,
        ""
      )
    );
  }
  return defaultTooltip;
}

export function getSlideBulkActionDisabled(
  selectedSlides: SlideItem[],
  predicate: (slide: SlideItem) => boolean
): boolean {
  const filtered = selectedSlides.filter(predicate);
  return filtered.length > 0 || selectedSlides.length === 0;
}
