import { Link } from "@mui/material";
import AuthActionButton from "./AuthActionButton";
import AuthDescription from "./AuthDescription";
import AuthForm from "./AuthForm";
import AuthFormField from "./AuthFormField";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { refreshToken, verifyMFA } from "dux/user/api";
import { UserTokenOut } from "dux/user/model";
import { FormEvent, useCallback, useState } from "react";
import { DEFAULT_TOKEN_EXP_SEC } from "dux/utils/apiRequestHelper";
import AuthAlert from "./AuthAlert";
import { useAuthLoadingStateContext } from "./AuthTemplate";

interface VerifyMFAFormProps {
  onBackToLogin?: () => void;
  onSuccess?: (tokenOut: UserTokenOut) => void;
}

export default function VerifyMFAForm({
  onBackToLogin,
  onSuccess,
}: VerifyMFAFormProps) {
  const { setLoading } = useAuthLoadingStateContext();
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const actionDisabled = !code.trim();
  const hasError = !!errorMessage.trim();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      verifyMFA({ code })
        .then(async (res: UserTokenOut) => {
          return {
            ...(await refreshToken({
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
              expSec: DEFAULT_TOKEN_EXP_SEC,
            })),
            refreshToken: res.refreshToken,
          };
        })
        .then((res: UserTokenOut) => {
          onSuccess?.(res);
        })
        .catch((error) => {
          setErrorMessage(
            error.details === "MFA Code is invalid"
              ? error.details
              : "Something went wrong. Please try again."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [code, onSuccess, setLoading]
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthHeading>
        Multi-factor
        <br />
        Authentication (MFA)
      </AuthHeading>
      {hasError && <AuthAlert severity="error">{errorMessage}</AuthAlert>}
      <AuthDescription>
        Please enter MFA code which shows in your registed device
      </AuthDescription>
      <AuthFormField
        id="code"
        aria-label="MFA Code"
        placeholder="MFA Code*"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        autoFocus
      />
      <AuthActionButton disabled={actionDisabled}>Confirm</AuthActionButton>
      <AuthNav>
        <Link href="/lost-mfa">Lost MFA device?</Link>
        <Link component="button" onClick={onBackToLogin}>
          Back to log in
        </Link>
      </AuthNav>
    </AuthForm>
  );
}
