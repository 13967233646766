import { styled } from "@mui/material/styles";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import cloneDeep from "lodash/cloneDeep";
import { useMemo } from "react";
import { ArrowIcon } from "assets/icons";

const ArrorIconComponent = styled(ArrowIcon)({
  "&.MuiSelect-icon": {
    top: "auto",
    right: 16,
  },
});

export interface SelectProps<T = unknown>
  extends Exclude<MuiSelectProps<T>, "IconComponent"> {}

const Select = Object.assign(
  function Select<T = unknown>(
    props: SelectProps<T>
  ): ReturnType<typeof MuiSelect> {
    const MenuProps = useMemo(() => {
      const clonedMenuProps = props.MenuProps ? cloneDeep(props.MenuProps) : {};
      if (!clonedMenuProps.anchorOrigin) {
        clonedMenuProps.anchorOrigin = {
          vertical: "bottom",
          horizontal: "left",
        };
      }
      if (!clonedMenuProps.transformOrigin) {
        clonedMenuProps.transformOrigin = {
          vertical: "top",
          horizontal: "left",
        };
      }
      if (!clonedMenuProps.MenuListProps) {
        clonedMenuProps.MenuListProps = {};
      }
      return clonedMenuProps;
    }, [props.MenuProps]);
    return (
      <MuiSelect
        {...props}
        MenuProps={MenuProps}
        IconComponent={ArrorIconComponent}
      />
    );
  },
  { muiName: MuiSelect.muiName }
);

export default Select;
