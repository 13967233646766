import { enqueueSnackbar } from "dux/snackbar/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import {
  GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES,
  getAnalysisSourceDetailsById,
  analyzeSingleSource,
  ANALYZE_SINGLE_SOURCE_TYPES,
} from "./actions";
import * as api from "./api";

function* getAnalysisSourceDetailsByIdSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(api.getAnalysisSourceDetailsById, payload);
    yield put(getAnalysisSourceDetailsById.success(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: `Failed to fetch slide details: ${
          error.details || error.message || error
        }`,
        options: {
          variant: "error",
        },
      })
    );
    yield put(getAnalysisSourceDetailsById.failure(error));
  }
}

function* analyzeSingleSourceSaga(
  action: ActionType<typeof analyzeSingleSource.request>
) {
  const { payload } = action;
  try {
    const response = yield call(api.requestSingleSlideAnalyze, payload);
    yield put(analyzeSingleSource.success(response));
    yield put(
      enqueueSnackbar({
        message: "Successfully requested analysis for current slide",
        options: {
          variant: "success",
        },
      })
    );
    yield put(getAnalysisSourceDetailsById.request(payload));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: `Failed to requst analysis for this slide: ${
          error.details || error.message || error
        }`,
        options: {
          variant: "error",
        },
      })
    );
    yield put(analyzeSingleSource.failure(error));
  }
}

export default function* analysisRootSaga() {
  yield takeLatest(
    GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES.REQUEST,
    getAnalysisSourceDetailsByIdSaga
  );
  yield takeLatest(
    ANALYZE_SINGLE_SOURCE_TYPES.REQUEST,
    analyzeSingleSourceSaga
  );
}
