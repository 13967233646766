import {
  createAsyncSaga,
  makeDefaultErrorMessageEffect,
  makeDefaultSuccessMessageEffect,
} from "dux/utils/actionHandlingHelper";
import {
  DELETE_SLIDE_SUMMARIES_TYPES,
  deleteSlideSummaries,
  GET_SLIDE_SUMMARIES_TYPES,
  getSlideSummaries,
  REQUEST_SLIDES_ANALYZE_TYPES,
  requestSlidesAnalyze,
  UPDATE_SLIDE_SUMMARY_TYPES,
  updateSlideSummary,
  requestSlideErrorInfo,
  REQUEST_SLIDES_ERROR_INFO_TYPES,
  bulkUpdateCancerTypes,
  BULK_UPDATE_CANCER_TYPES,
  requestSlideInferences,
  REQUEST_SLIDE_INFERENCES_TYPES,
} from "./actions";
import * as api from "./api";
import { call, put, takeLatest, all } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import noop from "lodash/noop";
import { SlideItem } from "./model";
import { enqueueSnackbar } from "dux/snackbar/actions";

const getSlideSummariesSaga = createAsyncSaga(
  getSlideSummaries,
  api.getSlideSummaries,
  noop,
  makeDefaultErrorMessageEffect("Failed to fetch slide list")
);

const updateSlideSummarySaga = function* (
  action: ActionType<typeof updateSlideSummary.request>
) {
  try {
    const { cancerType, id, ...updateSlideSummaryPayload } = action.payload;
    const updateSlideSummaryResult = yield call(
      // call API & pass payload
      api.updateSlideSummary,
      updateSlideSummaryPayload
    );
    if (id && cancerType) {
      yield call(api.updateCancerType, {
        id,
        cancerType,
      });
    }
    yield put(
      updateSlideSummary.success({ ...updateSlideSummaryResult, cancerType })
    );
    yield call(
      makeDefaultSuccessMessageEffect("Successfully edited slide information")
    );
  } catch (e) {
    yield call(
      makeDefaultErrorMessageEffect("Failed to edit slide information"),
      e
    );
    yield put(updateSlideSummary.failure(e));
  }
};

const bulkUpdateCancerTypeSaga = function* (
  action: ActionType<typeof bulkUpdateCancerTypes.request>
) {
  try {
    const { slideIds, cancerType } = action.payload;
    const results = yield all(
      slideIds.map((id) =>
        call(api.updateCancerType, {
          id,
          cancerType,
        })
      )
    );
    yield put(bulkUpdateCancerTypes.success(results));
    yield call(
      makeDefaultSuccessMessageEffect(
        "Successfully updated cancer type of selected slides"
      )
    );
  } catch (e) {
    yield put(bulkUpdateCancerTypes.failure(e));
    yield call(
      makeDefaultErrorMessageEffect(
        "Failed to update cancer type of selected slides"
      ),
      e
    );
  }
};

const deleteSlideSummariesSaga = createAsyncSaga(
  deleteSlideSummaries,
  api.deleteSlideSummaries,
  makeDefaultSuccessMessageEffect("Successfully deleted selected slides"),
  makeDefaultErrorMessageEffect("Failed to delete selected slides")
);

const requestSlideAnalyzeSaga = createAsyncSaga(
  requestSlidesAnalyze,
  api.requestSlidesAnalyze,
  makeDefaultSuccessMessageEffect(
    "Successfully requested IO analysis for selected slides"
  ),
  makeDefaultErrorMessageEffect(
    "Failed to request IO analysis for selected slides"
  )
);

const requestSlideInferencesSaga = function* (
  action: ActionType<typeof requestSlideInferences.request>
) {
  try {
    const slides = action.payload;
    const response = yield all(
      slides.map((slide: SlideItem) =>
        call(api.requestSlideInferences, slide.id)
      )
    );
    yield put(requestSlideInferences.success(response));
    yield put(
      enqueueSnackbar({
        message: "Successfully requested analysis for selected slide",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(requestSlideInferences.failure(error));
    yield put(
      enqueueSnackbar({
        message: `Failed to requst analysis for selected slides: ${
          error.details || error.message || error
        }`,
        options: {
          variant: "error",
        },
      })
    );
  }
};

const requestSlideErrorInfoSaga = createAsyncSaga(
  requestSlideErrorInfo,
  api.requestSlideErrorInfo
  // noop,
  // makeDefaultErrorMessageEffect("Failed to get slide error info")
);

export default function* slidesRootSaga() {
  yield takeLatest(GET_SLIDE_SUMMARIES_TYPES.REQUEST, getSlideSummariesSaga);
  yield takeLatest(UPDATE_SLIDE_SUMMARY_TYPES.REQUEST, updateSlideSummarySaga);
  yield takeLatest(
    DELETE_SLIDE_SUMMARIES_TYPES.REQUEST,
    deleteSlideSummariesSaga
  );
  yield takeLatest(
    REQUEST_SLIDES_ANALYZE_TYPES.REQUEST,
    requestSlideAnalyzeSaga
  );
  yield takeLatest(
    REQUEST_SLIDES_ERROR_INFO_TYPES.REQUEST,
    requestSlideErrorInfoSaga
  );
  yield takeLatest(BULK_UPDATE_CANCER_TYPES.REQUEST, bulkUpdateCancerTypeSaga);
  yield takeLatest(
    REQUEST_SLIDE_INFERENCES_TYPES.REQUEST,
    requestSlideInferencesSaga
  );
}
