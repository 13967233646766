import React from "react";
import AuthTemplate from "components/auth/AuthTemplate";
import ResetPasswordForm from "components/auth/ResetPasswordForm";

const ResetPassword: React.FC = () => {
  return (
    <AuthTemplate>
      <ResetPasswordForm />
    </AuthTemplate>
  );
};

export default ResetPassword;
