import { put, call, takeLatest } from "redux-saga/effects";
import {
  createAsyncSaga,
  createAsyncSagaV2,
  makeDefaultErrorMessageEffect,
  makeDefaultSuccessMessageEffect,
} from "dux/utils/actionHandlingHelper";
import {
  CURRENT_USER_TYPES,
  getCurrentUser,
  getAllUsers,
  GET_ALL_USERS_TYPES,
  createUser,
  CREATE_USER_TYPES,
  updateUser,
  resetAccountMgmtState,
  deleteUsers,
  UPDATE_USER_TYPES,
  DELETE_USERS_TYPES,
  updatePassword,
  updateProfile,
  UPDATE_PROFILE_TYPES,
  UPDATE_PASSWORD_TYPES,
  FORGOT_PASSWORD_TYPES,
  forgotPassword,
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  getNotiSettings,
  updateNotiSettings,
  updateUserProjectMappings,
  UPDATE_USER_PROJECT_MAPPINGS_TYPES,
} from "./actions";
import * as api from "./api";
import noop from "lodash/noop";
import forEach from "lodash/forEach";
import map from "lodash/map";
import { APIUserList, UpdateUserProjectMappingsResult } from "./model";
import { ActionType } from "typesafe-actions";

const getCurrentUserSaga = createAsyncSaga(getCurrentUser, api.getCurrentUser);

const getAllUsersSaga = function* (
  action: ActionType<typeof getAllUsers.request>
) {
  try {
    const result: APIUserList = yield call(api.getAllUsers, action.payload);
    const userIds = map(result.contents, (user) => user.id);
    const userProjects = yield call(api.getUserProjects, userIds);
    forEach(result.contents, (userMeta, index) => {
      result.contents[index].projects = map(
        userProjects[userMeta.id],
        ({ project_id }) => project_id
      );
    });
    yield put(getAllUsers.success(result));
  } catch (e) {
    yield call(
      makeDefaultErrorMessageEffect("Failed to fetch list of users"),
      e
    );
    yield put(getAllUsers.failure(e));
  }
};

const updateUserProjectMappingsSaga = function* (
  action: ActionType<typeof updateUserProjectMappings.request>
) {
  try {
    const result: UpdateUserProjectMappingsResult["mappings"] = yield call(
      api.updateUserProjectMappings,
      action.payload
    );
    yield put(
      updateUserProjectMappings.success({
        userId: action.payload.userId,
        mappings: result,
      })
    );
    yield call(
      makeDefaultSuccessMessageEffect(
        "Successfully edited user's accessible project list"
      )
    );
  } catch (e) {
    yield call(
      makeDefaultErrorMessageEffect(
        "Failed to edit user's accessible project list"
      ),
      e
    );
    yield put(updateUserProjectMappings.failure(e));
  }
};

const createUserSaga = createAsyncSaga(
  createUser,
  api.createUser,
  function* () {
    yield call(makeDefaultSuccessMessageEffect("Successfully created a user"));
    yield put(resetAccountMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to create a user")
);

const updateUserSaga = createAsyncSaga(
  updateUser,
  api.updateUser,
  function* () {
    yield call(makeDefaultSuccessMessageEffect("Successfully edited a user"));
    yield put(resetAccountMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to edit a user")
);

const deleteUsersSaga = createAsyncSaga(
  deleteUsers,
  api.deleteUsers,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully deleted selected users")
    );
    yield put(resetAccountMgmtState());
  },
  makeDefaultErrorMessageEffect("Failed to deleted selected users")
);

const updatePasswordSaga = createAsyncSagaV2(
  updatePassword,
  api.updatePassword,
  makeDefaultSuccessMessageEffect("Successfully updated password"),
  makeDefaultErrorMessageEffect("Failed to update password")
);

const updateProfileSaga = createAsyncSagaV2(
  updateProfile,
  api.updateProfile,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect(
        "Successfully updated profile information"
      )
    );
    yield put(getCurrentUser.request(""));
  },
  makeDefaultErrorMessageEffect("Failed to update profile information")
);

const getNotiSettingsSaga = createAsyncSagaV2(
  getNotiSettings,
  api.getNotiSettings,
  noop,
  makeDefaultErrorMessageEffect("Failed to fetch notification settings")
);

const updateNotiSettingsSaga = createAsyncSagaV2(
  updateNotiSettings,
  api.updateNotiSettings,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect(
        "Successfully updated notification settings"
      )
    );
    yield put(getNotiSettings.request(""));
  },
  makeDefaultErrorMessageEffect("Failed to update notification settings")
);

function* forgotPasswordSaga(
  action: ReturnType<typeof forgotPassword.request>
) {
  try {
    yield call(api.forgotPassword, action.payload);
    yield put(forgotPassword.success(""));
    yield call(action.payload.resolve);
  } catch (e) {
    yield put(forgotPassword.failure(e.message));
    yield call(action.payload.reject, e.message);
  }
}

export default function* userRootSaga() {
  yield takeLatest(CURRENT_USER_TYPES.REQUEST, getCurrentUserSaga);
  yield takeLatest(GET_ALL_USERS_TYPES.REQUEST, getAllUsersSaga);
  yield takeLatest(CREATE_USER_TYPES.REQUEST, createUserSaga);
  yield takeLatest(UPDATE_USER_TYPES.REQUEST, updateUserSaga);
  yield takeLatest(DELETE_USERS_TYPES.REQUEST, deleteUsersSaga);
  yield takeLatest(UPDATE_PASSWORD_TYPES.REQUEST, updatePasswordSaga);
  yield takeLatest(FORGOT_PASSWORD_TYPES.REQUEST, forgotPasswordSaga);
  yield takeLatest(UPDATE_PROFILE_TYPES.REQUEST, updateProfileSaga);
  yield takeLatest(GET_NOTIFICATION_SETTINGS.REQUEST, getNotiSettingsSaga);
  yield takeLatest(
    UPDATE_NOTIFICATION_SETTINGS.REQUEST,
    updateNotiSettingsSaga
  );
  yield takeLatest(
    UPDATE_USER_PROJECT_MAPPINGS_TYPES.REQUEST,
    updateUserProjectMappingsSaga
  );
}
