import { isNil } from "ramda";
import {
  request,
  arrayBufferRequest,
  FetchMethod,
} from "dux/utils/apiRequestHelper";
import { ProjectUUID } from "dux/projects/model";
import { WSIId } from "./model";
import { DEFAULT_PAGE_SIZE } from "../../utils/pagination";
import {
  WSIListRequestParam,
  UpdateWSIMetaInfoPayload,
  UpdateCancerNamePayload,
  RequestAnalysisPayload,
  DeleteSlidesPayload,
} from "./actions";
import reduce from "lodash/reduce";

export const getWSIList = (params: WSIListRequestParam) => {
  const parsedParams = reduce(
    params,
    (acc, param, key: string) => {
      if (key === "keyword") {
        return !isNil(param) && param !== ""
          ? `${acc}&${key}=${encodeURIComponent(param)}`
          : acc;
      }
      return !isNil(param) ? `${acc}&${key}=${encodeURIComponent(param)}` : acc;
    },
    ""
  );
  return request({
    method: FetchMethod.Get,
    path: `wsi/pages?&sort=uploadedAt,desc&size=${DEFAULT_PAGE_SIZE}${parsedParams}`,
  });
};

export const getAnalysisSummaryById = (id: WSIId) =>
  request({
    method: FetchMethod.Get,
    path: `wsi/${id}/results/latest/summary`,
  });

export const getAnalysisSourceDetailsById = (sourceId: string) =>
  request({
    method: FetchMethod.Get,
    path: `analysis/sources/${sourceId}/details`,
  });

export const getAnalysisErrorMsgById = (id: WSIId) =>
  request({
    method: FetchMethod.Get,
    path: `wsi/${id}/results/latest/error`,
  });

export const getAnalysisReportById = (id: WSIId) =>
  request({
    method: FetchMethod.Get,
    path: `analysis/sources/reports?ids=${id}`,
  });

export const updateWSIMetaInfo = (payload: UpdateWSIMetaInfoPayload) =>
  request({
    method: FetchMethod.Patch,
    path: `wsi/${payload.wsiId}`,
    payload: payload.wsiMetaInfo,
  });

export const updateCancerName = (payload: UpdateCancerNamePayload) =>
  request({
    method: FetchMethod.Put,
    path: `wsi/cancers?cancer_name=${payload.cancerName}&proj_id=${payload.projectId}`,
    payload: payload.wsiIds,
  });

export const requestAnalysis = (payload: RequestAnalysisPayload) =>
  request({
    method: FetchMethod.Post,
    path: `wsi/analysis`,
    payload: payload.wsiIds,
    skipJson: true,
  });

export const getDecodedMsgPackResult = (filePath: string) =>
  arrayBufferRequest({
    method: FetchMethod.Get,
    path: filePath,
  });

export const getAnalyzedSlides = (projectId: ProjectUUID) =>
  request({
    method: FetchMethod.Get,
    path: `wsi/analyzed-slides?proj_id=${projectId}`,
  });

export const deleteSlides = ({ projectId, ids }: DeleteSlidesPayload) => {
  const slideIdsParam = reduce(
    ids,
    (param, id) => (param ? `${param},${id}` : id),
    ""
  );
  return request({
    method: FetchMethod.Delete,
    path: `wsi?proj_id=${projectId}&ids=${encodeURIComponent(slideIdsParam)}`,
    skipJson: true,
  });
};
