import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { CloseIcon, ErrorIcon, SuccessIcon } from "assets/icons";
import {
  EventData,
  EventStatus,
  eventTypeToTitle,
  getEventTypePaletteKey,
} from "hooks/useStompClient";
import IconButton from "@mui/material/IconButton";
import map from "lodash/map";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ClassNames } from "@emotion/react";
import React from "react";

interface MultiEventTooltipProps {
  events: EventData[];
}

const EventTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "event",
})<TypographyProps & { event: EventData }>(({ theme, event }) => ({
  margin: "12px 0",
  whiteSpace: "normal",
  "& b": {
    ...theme.typography.body3,
    color: theme.palette.slideStatus[getEventTypePaletteKey(event.type)].main,
  },
  "& strong": {
    ...theme.typography.body3,
    display: "inline",
    color: theme.palette.darkGrey[0],
  },
}));

function formatProjectNames(projectNames: string[]): React.ReactNode {
  if (projectNames.length === 1) {
    return <strong>{projectNames[0]}</strong>;
  } else if (projectNames.length === 2) {
    return (
      <>
        <strong>{projectNames[0]}</strong>
        {" and "}
        <strong>{projectNames[1]}</strong>
      </>
    );
  }
  return (
    <>
      {map(projectNames, (name, index) =>
        index === 0 ? (
          <strong key={`${name}_index`}>{name}</strong>
        ) : index === projectNames.length - 1 ? (
          <React.Fragment key={`${name}_index`}>
            {", and "}
            <strong>{name}</strong>
          </React.Fragment>
        ) : (
          <React.Fragment key={`${name}_index`}>
            {", "}
            <strong>{name}</strong>
          </React.Fragment>
        )
      )}
    </>
  );
}

const EventTooltipRow = styled("div")({
  minHeight: 44,
  display: "flex",
});

const StatusContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: 20,
  flex: "0 0 20px",
  alignItems: "center",
  marginRight: 8,
});

const TopDivider = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.darkGrey[70],
  width: 2,
  height: 4,
  flexShrink: 0,
}));

const BottomDivider = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.darkGrey[70],
  width: 2,
  flexGrow: 1,
}));

const MultiEventTooltip = ({ events }: MultiEventTooltipProps) => {
  return (
    <Box
      sx={{
        padding: "12px 16px",
        height: "100%",
        maxHeight: 340,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {map(events, (event, index) => (
        <EventTooltipRow key={event.id}>
          <StatusContainer>
            {index > 0 && <TopDivider />}
            <Box
              sx={{
                color: (theme) =>
                  event.status && event.status === EventStatus.Fail
                    ? theme.palette.error.main
                    : theme.palette.success.main,
                marginBottom: 1,
                marginTop: index > 0 ? 1 : 1.5,
                maxHeight: 20,
              }}
            >
              {event.status && event.status === EventStatus.Fail ? (
                <ErrorIcon />
              ) : (
                <SuccessIcon />
              )}
            </Box>
            <BottomDivider />
          </StatusContainer>
          <EventTypography variant="body5" color="darkGrey.30" event={event}>
            <b>{`[${eventTypeToTitle(event.type)}] `}</b>
            <strong>{event.slideInfo.name}</strong>
            {event.slideInfo.projectNames.length > 0 && (
              <>
                {" from "}
                {formatProjectNames(event.slideInfo.projectNames)}
              </>
            )}
          </EventTypography>
        </EventTooltipRow>
      ))}
    </Box>
  );
};

interface MultiEventListItemProps extends MultiEventTooltipProps {
  onRemove: () => void;
}

const MultiEventListItem = ({ events, onRemove }: MultiEventListItemProps) => {
  return (
    <ClassNames>
      {({ css }) => {
        const tooltip = css`
          padding: 0;
          max-width: 468px;
          max-height: 340px;
        `;
        return (
          <Tooltip
            classes={{ tooltip }}
            title={<MultiEventTooltip events={events} />}
            placement="left-start"
          >
            <ListItem
              sx={{
                height: 64,
                padding: "22px 71px 22px 32px",
                width: 500,
                "& .MuiListItemSecondaryAction-root": {
                  right: 28,
                },
              }}
              secondaryAction={
                <IconButton onClick={onRemove} color="inherit">
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemText
                sx={{
                  flexWrap: "nowrap",
                  display: "flex",
                }}
                primary={`Notifications for ${events.length} results`}
                primaryTypographyProps={{
                  variant: "body3",
                }}
              />
            </ListItem>
          </Tooltip>
        );
      }}
    </ClassNames>
  );
};

export default MultiEventListItem;
