import { ComponentType } from "react";
import { Redirect } from "react-router-dom";
import accessTokenManager from "../auth/accessTokenManager";
import findIndex from "lodash/findIndex";

export default function withAdminGuard(
  Component: ComponentType<any>
): ComponentType<any> {
  return () => {
    if (
      !accessTokenManager.hasAccessTokenInStorage() ||
      (accessTokenManager.getMFARequiredFromToken() &&
        !accessTokenManager.getMFAVerifiedFromToken())
    ) {
      return <Redirect to="/login" />;
    }
    const roles = accessTokenManager.getRolesFromToken();
    if (findIndex(roles, (role) => role === "ADMIN") < 0) {
      return <Redirect to="/projects" />;
    }
    return <Component />;
  };
}
