import { useCallback, useEffect, useRef, useState } from "react";
import OSDViewer, {
  OSDViewerRef,
  ScalebarLocation,
  TooltipOverlayProps,
} from "@lunit/osd-react-renderer";
import OpenSeadragon from "openseadragon";
import { useDispatch, useSelector } from "react-redux";
import {
  setPhysicalWidthPx,
  setZoom,
  updateInferenceResultLoaded,
} from "dux/analysis/actions";
import {
  analysisResultMsgpacksSelector,
  mppSelector,
  physicalWidthPxSelector,
  refPointSelector,
  tiledImageSourceSelector,
  zoomSelector,
} from "dux/analysis/selectors";
import useOffscreenVisualization from "./useOffscreenVisualization";
import useRightPanelStateContext, {
  RightPanelTabType,
} from "./SlideRightPanel/context";
import {
  hexToRgba,
  numberWithCommas,
  roundToFirstDecimal,
} from "components/utils";
import { GridResult } from "./workers/AnalysisResultWorker/loader";
import { useHotkeys } from "react-hotkeys-hook";
import { styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "dux/snackbar/actions";
import useWheelButtonPanning from "components/analysis/useWheelButtonPanning";

interface TooltipMsg extends GridResult {
  client: OpenSeadragon.Point;
  bounds: OpenSeadragon.Point;
  gridPixelSizeX: number;
  gridPixelSizeY: number;
  cellCounts: {
    counts: number;
    id: string;
    title: string;
  }[];
}

const OSD_OPTIONS: OpenSeadragon.Options = {
  imageLoaderLimit: 8,
  smoothTileEdgesMinZoom: Infinity,
  showNavigator: true,
  timeout: 60000,
  navigatorAutoResize: false,
  preserveImageSizeOnResize: true,
  zoomPerScroll: 1.3,
  showZoomControl: false,
  showHomeControl: false,
  showFullPageControl: false,
  showRotationControl: false,
  animationTime: 0.3,
  constrainDuringPan: true,
  visibilityRatio: 0.8,
  loadTilesWithAjax: true, // for Glob tiling
  showNavigationControl: false,
  gestureSettingsMouse: {
    clickToZoom: false,
    dblClickToZoom: false,
  },
  gestureSettingsTouch: {
    flickEnabled: false,
    clickToZoom: false,
    dblClickToZoom: false,
  },
  overlays: [
    {
      px: 0,
      py: 0,
      class: "drawing",
    },
  ],
};
const MICRONS_PER_METER = 1e6;
const DEFAULT_MAX_ZOOM = 160;

function getViewportZoomFromZoom(
  zoom: number,
  microscopeWidth1x: number,
  viewer?: OpenSeadragon.Viewer
) {
  if (viewer) {
    const viewportWidth = viewer.viewport.getContainerSize().x;
    const scaleFactor = microscopeWidth1x / viewportWidth;
    return zoom * scaleFactor;
  }
  return zoom;
}

function getZoomFromViewportZoom(
  zoom: number,
  microscopeWidth1x: number,
  viewer?: OpenSeadragon.Viewer
) {
  if (viewer) {
    const viewportWidth = viewer.viewport.getContainerSize().x;
    const scaleFactor = microscopeWidth1x / viewportWidth;
    return zoom / scaleFactor;
  }
  return zoom;
}

function isNotPanKeyboardEvent(event: KeyboardEvent) {
  const NAV_KEYS = "wasdWASD";
  return !(
    !event.altKey &&
    !event.ctrlKey &&
    !event.metaKey &&
    !event.shiftKey &&
    NAV_KEYS.includes(event.key)
  );
}

declare global {
  interface HTMLEventMap {
    "polygon-enter": CustomEvent;
    "polygon-leave": CustomEvent;
  }
}

const OSDContainer = styled("div")({
  height: "100%",
  ".navigator": {
    width: "160px !important",
    height: "160px !important",
    border: "solid 1px #3E3E40 !important",
    marginTop: "16px !important",
    marginRight: "16px !important",
    borderRadius: "8px",
  },
});

const PAN_RATE = 160;

export default function SlideViewer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const viewerRef = useRef<OSDViewerRef>(null);
  const canvasOverlayRef = useRef<any>(null);
  const tooltipOverlayRef = useRef<any>(null);
  const [viewerLoaded, setViewerLoaded] = useState(false);
  const mpp = useSelector(mppSelector);
  const zoom = useSelector(zoomSelector);
  const refPoint = useSelector(refPointSelector);
  const physicalWidthPx = useSelector(physicalWidthPxSelector);
  const tiledImageSource = useSelector(tiledImageSourceSelector);
  const microscopeWidth1x = physicalWidthPx * 10;
  const aiMsgpacks = useSelector(analysisResultMsgpacksSelector);
  const [
    prepareAIResult,
    drawAIResult,
    getAIResultData,
    canPrepareAIVisualization,
  ] = useOffscreenVisualization(aiMsgpacks);
  const {
    tab,
    analysisPanelState: {
      viewOptionsOn,
      ipMapOn,
      histologicalFeatOn,
      histologicalFeatOptions,
    },
  } = useRightPanelStateContext();

  useHotkeys(
    "w,a,s,d,left,right,down,up,ㅁ,ㄴ,ㅇ,ㅈ,shift+*",
    (keyboardEvent, hotkeysEvent) => {
      if (!viewerLoaded) return;
      if (!viewerRef.current?.viewer?.viewport) return;
      const { shiftKey, code } = keyboardEvent;
      const { viewport } = viewerRef.current.viewer;
      switch (code) {
        case "KeyD":
        case "ArrowRight":
          viewport.panBy(
            viewport.deltaPointsFromPixels(new OpenSeadragon.Point(PAN_RATE, 0))
          );
          viewport.applyConstraints();
          break;
        case "KeyA":
        case "ArrowLeft":
          viewport.panBy(
            viewport.deltaPointsFromPixels(
              new OpenSeadragon.Point(-PAN_RATE, 0)
            )
          );
          viewport.applyConstraints();
          break;
        case "KeyW":
        case "ArrowUp":
          if (shiftKey) {
            viewport.zoomBy(1.1);
          } else {
            viewport.panBy(
              viewport.deltaPointsFromPixels(
                new OpenSeadragon.Point(0, -PAN_RATE)
              )
            );
          }
          break;
        case "KeyS":
        case "ArrowDown":
          if (shiftKey) {
            viewport.zoomBy(0.9);
          } else {
            viewport.panBy(
              viewport.deltaPointsFromPixels(
                new OpenSeadragon.Point(0, PAN_RATE)
              )
            );
          }
          break;
      }
    },
    [viewerRef.current?.viewer?.viewport, viewerLoaded]
  );

  useEffect(() => {
    if (canPrepareAIVisualization) {
      prepareAIResult()
        .then(() => {
          canvasOverlayRef.current &&
            canvasOverlayRef.current.overlay &&
            canvasOverlayRef.current.overlay.forceRedraw();
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: `Failed to prepare analysis result visualization: ${error.message}`,
              options: {
                variant: "error",
              },
            })
          );
        })
        .finally(() => {
          dispatch(updateInferenceResultLoaded(true));
        });
    }
  }, [prepareAIResult, canPrepareAIVisualization, dispatch]);

  // viewer event handlers
  const onSlideOpen = useCallback(
    (event) => {
      // onViewerInit(() => viewer.forceRedraw());
      // viewerRef.current = viewer;
      dispatch(setZoom(1));
      setViewerLoaded(true);
    },
    [dispatch]
  );

  useEffect(() => {
    setViewerLoaded(false);
  }, [tiledImageSource.dziMetaUrl, tiledImageSource.dziUrl]);

  const onHome = (event) => {
    const viewer = event.eventSource;
    const imageSize = viewer.world.getItemAt(0).getContentSize();
    const physWidthPx = ((imageSize.x * mpp) / 25400) * 96;
    dispatch(setPhysicalWidthPx(physWidthPx));
  };

  const onZoom = useCallback(
    (event) => {
      const {
        eventSource: viewer,
        zoom: viewportZoom,
        refPoint,
        immediately,
      } = event;
      if (viewer == null || viewportZoom == null || immediately) {
        return;
      }
      const viewportWidth = viewer.viewport.getContainerSize().x;
      const scaleFactor = microscopeWidth1x / viewportWidth;
      viewer.viewport.maxZoomLevel = DEFAULT_MAX_ZOOM * scaleFactor;
      viewer.viewport.minZoomLevel = 0.1 * scaleFactor;
      dispatch(setZoom(viewportZoom / scaleFactor, refPoint));
    },
    [dispatch, microscopeWidth1x]
  );

  const drawBiomarkerInfoTooltip = useCallback(
    (
      context: CanvasRenderingContext2D,
      viewer: OpenSeadragon.Viewer,
      tooltipMsg: TooltipMsg
    ) => {
      const resultData = getAIResultData();
      if (!resultData.resultRenderingProperties) return;
      const {
        minX,
        minY,
        client,
        ipType,
        intraTilDensity,
        stromalTilDensity,
        gridPixelSizeX,
        gridPixelSizeY,
        cellCounts,
      } = tooltipMsg;
      const bounds = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.getBounds(true)
      );
      // Draw grid border
      const sizeRect = new OpenSeadragon.Rect(0, 0, 2, 2);
      const strokeWidth = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.viewerElementToViewportRectangle(sizeRect)
      ).width;
      context.save();
      context.lineWidth = strokeWidth;
      context.strokeStyle = "black";
      context.fillStyle = "transparent";
      context.beginPath();
      context.rect(minX, minY, gridPixelSizeX, gridPixelSizeY);
      context.stroke();
      // tooltip box
      const lineNum = 3 + cellCounts.length;
      const tooltipRect = new OpenSeadragon.Rect(0, 28, 230, 21 * lineNum);
      const tooltipBounds = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.viewerElementToViewportRectangle(tooltipRect)
      );
      const textHeight = (18 / 21) * tooltipBounds.height;
      const lineHeight = textHeight / lineNum;
      const fontSize = (14 / 18) * lineHeight;
      context.font = `400 ${fontSize}px/${lineHeight}px Proxima Nova`;
      // measureText to get the box width
      const longestTextWidth =
        context.measureText("Intratumoral TIL density(mm^2) : 0.000").width +
        (16 / 230) * tooltipBounds.width;
      const x = client.x;
      const y = client.y + (tooltipBounds.y - bounds.y);
      const width = Math.max(tooltipBounds.width, longestTextWidth);
      const height = tooltipBounds.height;
      context.fillStyle = "rgba(21,30,45,0.8)";
      context.beginPath();
      context.rect(x, y, width, height);
      context.closePath();
      context.fill();
      // tooltip text
      context.fillStyle = "#FFFFFF";
      context.textBaseline = "top";
      context.textAlign = "start";
      const textOffsetX = client.x + tooltipBounds.width * (8 / 230);
      let textOffsetY =
        client.y +
        (tooltipBounds.y - bounds.y) +
        tooltipBounds.height * (6 / 63);
      context.fillText(`Type: ${ipType}`, textOffsetX, textOffsetY);
      textOffsetY += lineHeight;
      context.fillText(
        `Intratumoral TIL density(mm²) : ${roundToFirstDecimal(
          intraTilDensity
        )}`,
        textOffsetX,
        textOffsetY
      );
      textOffsetY += lineHeight;
      context.fillText(
        `Stromal TIL density(mm²) : ${roundToFirstDecimal(stromalTilDensity)}`,
        textOffsetX,
        textOffsetY
      );
      cellCounts.forEach((cellCount) => {
        textOffsetY += lineHeight;
        context.fillText(
          `# of ${cellCount.title}s: ${numberWithCommas(cellCount.counts)}`,
          textOffsetX,
          textOffsetY
        );
      });
    },
    [getAIResultData]
  );

  const drawGridOnSlide = useCallback(
    (context: CanvasRenderingContext2D, viewer: OpenSeadragon.Viewer) => {
      const bounds = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.getBounds(true)
      );
      context.save();

      const { indexedGridData } = getAIResultData();

      indexedGridData.forEach(
        ({ grid, color, gridPixelSizeX, gridPixelSizeY }, idx) => {
          context.fillStyle = color;
          grid
            .range(
              bounds.x - gridPixelSizeX,
              bounds.y - gridPixelSizeY,
              bounds.x + bounds.width,
              bounds.y + bounds.height
            )
            .forEach((id) => {
              const data = indexedGridData[idx];
              const { r, g, b } = hexToRgba(data.color);
              const { minX, minY } = data.grid.points[id];
              context.save();
              context.fillStyle = `rgba(${r}, ${g}, ${b}, 0.4)`;
              context.fillRect(minX, minY, gridPixelSizeX, gridPixelSizeY);
              context.restore();
            });
        }
      );
      context.restore();
    },
    [getAIResultData]
  );

  const drawPointsOnSlide = useCallback(
    (context: CanvasRenderingContext2D, viewer: OpenSeadragon.Viewer) => {
      const resultData = getAIResultData();
      const { indexedCellData } = resultData;
      const bounds = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.getBounds(true)
      );
      const radius = 6 * Math.sqrt(zoom / DEFAULT_MAX_ZOOM);
      const sizeRect = new OpenSeadragon.Rect(0, 0, radius, radius);
      const pointSize = viewer.viewport.viewportToImageRectangle(
        viewer.viewport.viewerElementToViewportRectangle(sizeRect)
      ).width;
      context.save();
      context.globalAlpha = 1;
      const colorIndex = {};
      indexedCellData.forEach(({ color, id }) => {
        colorIndex[id] = color;
      });
      indexedCellData.forEach(({ coordinates, color, id }, idx) => {
        coordinates
          .range(
            bounds.x - pointSize * 2,
            bounds.y - pointSize * 2,
            bounds.x + bounds.width + pointSize * 2,
            bounds.y + bounds.height + pointSize * 2
          )
          .forEach((coordId) => {
            const { x, y } = indexedCellData[idx].coordinates.points[coordId];
            if (histologicalFeatOptions[id]) {
              context.beginPath();
              context.arc(x, y, pointSize, 0, 2 * Math.PI);
              context.fillStyle = color;
              context.fill();
              context.closePath();
            }
          });
      });
      context.restore();
    },
    [getAIResultData, zoom, histologicalFeatOptions]
  );

  // canvasOverlay
  const onCanvasOverlayRedraw = useCallback(
    (overlayCanvasEl: HTMLCanvasElement, viewer: OpenSeadragon.Viewer) => {
      const currentZoom = getZoomFromViewportZoom(
        viewer.viewport.getZoom(true),
        microscopeWidth1x,
        viewer
      );
      const context = overlayCanvasEl.getContext("2d");
      if (
        viewOptionsOn &&
        histologicalFeatOn &&
        tab === RightPanelTabType.ANALYSIS
      ) {
        drawAIResult(context, histologicalFeatOptions);
      }
      if (
        viewOptionsOn &&
        histologicalFeatOn &&
        zoom >= 10 &&
        Math.abs(currentZoom - zoom) < 0.1 &&
        tab === RightPanelTabType.ANALYSIS
      ) {
        drawPointsOnSlide(context, viewer);
      }
      if (
        viewOptionsOn &&
        ipMapOn &&
        zoom < 10 &&
        tab === RightPanelTabType.ANALYSIS
      ) {
        drawGridOnSlide(context, viewer);
      }
    },
    [
      drawPointsOnSlide,
      drawAIResult,
      drawGridOnSlide,
      viewOptionsOn,
      ipMapOn,
      histologicalFeatOptions,
      histologicalFeatOn,
      tab,
      microscopeWidth1x,
      zoom,
    ]
  );

  // tooltipOverlay
  const onTooltipOverlayRedraw: TooltipOverlayProps["onRedraw"] = useCallback(
    ({ overlayCanvasEl, viewer, originalEvent, tooltipCoord }) => {
      // window.canvas = overlayCanvasEl;
      const context = overlayCanvasEl.getContext("2d");
      const resultData = getAIResultData();
      const { indexedCellData } = resultData;
      if (
        indexedCellData.length > 0 &&
        zoom >= 10 &&
        ipMapOn &&
        viewOptionsOn &&
        tab === RightPanelTabType.ANALYSIS
      ) {
        let found = false;
        const { indexedGridData, indexedCellData } = getAIResultData();
        let tooltipMsg: TooltipMsg;
        indexedGridData.forEach(
          ({ grid, gridPixelSizeX, gridPixelSizeY }, idx) => {
            const targetGrid = grid.range(
              tooltipCoord.x - gridPixelSizeX,
              tooltipCoord.y - gridPixelSizeY,
              tooltipCoord.x,
              tooltipCoord.y
            );
            if (targetGrid.length) {
              const id = targetGrid[0];
              const data = indexedGridData[idx].grid.points[id];
              found = true;
              const cellCounts = indexedCellData.map((cellList) => ({
                id: cellList.id,
                title: cellList.title,
                counts: cellList.coordinates.range(
                  data.minX,
                  data.minY,
                  data.minX + gridPixelSizeX,
                  data.minY + gridPixelSizeY
                ).length,
              }));
              // draw
              tooltipMsg = {
                // Biomarker info
                ...data,
                // Mouse position
                client: tooltipCoord,
                gridPixelSizeX,
                gridPixelSizeY,
                cellCounts,
              };
            }
          }
        );
        if (found) drawBiomarkerInfoTooltip(context, viewer, tooltipMsg);
      }
    },
    [
      tab,
      getAIResultData,
      drawBiomarkerInfoTooltip,
      viewOptionsOn,
      ipMapOn,
      zoom,
    ]
  );

  const { onNonPrimaryPress, onMove, onNonPrimaryRelease, cancelPanning } =
    useWheelButtonPanning({
      throttle: 150,
      viewer: viewerRef.current?.viewer,
    });

  const onExit = useCallback(() => {
    // temporary fix about malfunction(?) of mouseup and onNonPrimaryRelease event
    cancelPanning && cancelPanning();
  }, [cancelPanning]);

  return (
    <OSDContainer>
      {tiledImageSource.dziMetaUrl && tiledImageSource.dziUrl && (
        <OSDViewer
          style={{ width: "100%", height: "100%" }}
          ref={viewerRef}
          options={OSD_OPTIONS}
        >
          <viewport
            rotation={0}
            zoom={getViewportZoomFromZoom(
              zoom,
              microscopeWidth1x,
              viewerRef.current && viewerRef.current.viewer
            )}
            refPoint={refPoint}
            onOpenFailed={(e) => {
              console.log("open failed");
            }}
            onOpen={onSlideOpen}
            onZoom={onZoom}
            onHome={onHome}
            onCanvasKey={(event) => {
              event.preventDefaultAction = isNotPanKeyboardEvent(
                event.originalEvent
              );
            }}
            onCanvasDoubleClick={(e) => {
              if (zoom < 40) {
                const refPoint =
                  e?.eventSource?.viewport?.pointFromPixel &&
                  e?.position &&
                  e.eventSource.viewport.pointFromPixel(e.position);
                dispatch(setZoom(40, refPoint));
              }
            }}
          />
          <tiledImage
            url={tiledImageSource.dziMetaUrl}
            tileUrlBase={tiledImageSource.dziUrl}
          />
          <mouseTracker
            onExit={onExit}
            onNonPrimaryPress={onNonPrimaryPress}
            onNonPrimaryRelease={onNonPrimaryRelease}
            onMove={onMove}
          />
          <canvasOverlay
            ref={canvasOverlayRef}
            onRedraw={onCanvasOverlayRedraw}
          />
          <tooltipOverlay
            ref={tooltipOverlayRef}
            onRedraw={onTooltipOverlayRedraw}
          />
          <scalebar
            pixelsPerMeter={MICRONS_PER_METER / mpp}
            xOffset={10}
            yOffset={30}
            barThickness={2}
            color={theme.palette.darkGrey[80]}
            fontColor={theme.palette.darkGrey[80]}
            backgroundColor={"rgba(255,255,255,0.5)"}
            location={ScalebarLocation.BOTTOM_RIGHT}
            stayInsideImage={false}
          />
        </OSDViewer>
      )}
    </OSDContainer>
  );
}
