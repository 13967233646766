import Typography from "@mui/material/Typography";
import { AnalysisSummaryItem } from "dux/analysis/model";
import { SectionContent, SectionListItem } from "./common";
import Box from "@mui/material/Box";
import map from "lodash/map";
import { styled } from "@mui/material/styles";
import { roundToFirstDecimal } from "components/utils";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useToggleState from "hooks/useToggleState";
import IconButton from "@mui/material/IconButton";
import { ArrowDownIcon } from "assets/icons";
import Divider from "@mui/material/Divider";
import find from "lodash/find";
import reverse from "lodash/reverse";

interface AnalysisSummaryProps {
  summary: AnalysisSummaryItem[];
}

const DenseSectionListItem = styled(SectionListItem)({
  padding: 0,
  flex: "0 0 20px",
  height: 20,
  marginTop: 4,
  "&:first-of-type": {
    marginTop: 0,
  },
});

const BarListItem = styled("div")({
  width: "100%",
  padding: "0 20px",
  height: 36,
  position: "relative",
});

const BarContainer = styled("div")({
  width: 200,
  height: 4,
  position: "absolute",
  top: 9,
  display: "flex",
});

const Bar = styled("div")({
  height: 4,
  borderRadius: 2,
  flexGrow: 0,
  flexShrink: 0,
});

const BarStopText = styled("span")(({ theme }) => ({
  position: "absolute",
  width: 16,
  textAlign: "center",
  fontWeight: 500,
  fontSize: "8px",
  lineHeight: "12px",
  color: theme.palette.darkGrey[30],
  opacity: 0.9,
}));

const DOT_INNER_WIDTH = 4;
const DOT_OUTER_WIDTH = 7;

const ScoreDot = styled("div")(({ theme }) => ({
  width: DOT_OUTER_WIDTH,
  height: DOT_OUTER_WIDTH,
  borderRadius: 3.5,
  border: `1.5px solid ${theme.palette.darkGrey[90]}`,
  position: "absolute",
  top: -1.5,
  backgroundColor: theme.palette.darkGrey[0],
}));

const getScoreDotLeft = (score) =>
  ((200 - DOT_OUTER_WIDTH) * score) / 100 +
  DOT_INNER_WIDTH / 2 -
  DOT_OUTER_WIDTH / 2;

const SCORE_TEXT_WIDTH = 24;
const ScoreText = styled("span")(({ theme }) => ({
  position: "absolute",
  width: SCORE_TEXT_WIDTH,
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "10px",
  lineHeight: "12px",
  color: theme.palette.darkGrey[30],
  backgroundColor: theme.palette.darkGrey[85],
}));

const BarTextContainer = styled("div")({
  position: "absolute",
  top: 15,
  width: 200,
});

const getScoreTextLeft = (score) =>
  getScoreDotLeft(score) + DOT_OUTER_WIDTH / 2 - SCORE_TEXT_WIDTH / 2;

const CUTOFF = 20;

function convertUnit(unit: string): string {
  switch (unit) {
    case "sq_mm":
      return "mm²";
    case "percent":
      return "%";
    default:
      return unit;
  }
}

function convertMinAvgMax(str: string): string {
  switch (str) {
    case "Min":
      return "Minimum";
    case "Avg":
      return "Average";
    case "Max":
      return "Maximum";
    default:
      return str;
  }
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  ":before": {
    backgroundColor: "transparent",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: "36px",
  padding: "0 16px 0 20px",
  "& .MuiAccordionSummary-content": {
    marginTop: 8,
    marginBottom: 8,
  },
});

const StyledAccordionDetail = styled(AccordionDetails)({
  padding: 0,
});

const SummaryDivider = styled(Divider)({
  margin: "8px 16px",
});

const BiomarkerFindings = ({ summary }: AnalysisSummaryProps) => {
  const [isOpen, handleToggleOpen] = useToggleState(true);
  const summaryItem = summary[0];
  return (
    <StyledAccordion disableGutters square expanded={isOpen}>
      <StyledAccordionSummary
        expandIcon={
          <IconButton
            sx={{
              padding: 0.5,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.darkGrey[80],
              },
            }}
            onClick={handleToggleOpen}
          >
            <ArrowDownIcon />
          </IconButton>
        }
        aria-label="Expand"
        aria-controls={`BiomarkerFindings-content`}
        id={`BiomarkerFindings-header`}
      >
        <Typography variant="body4">Biomarker Findings</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetail>
        <SectionListItem>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">Score</Typography>
            <Typography
              sx={{
                marginLeft: 1,
                color: summaryItem.color,
                whiteSpace: "pre-wrap",
              }}
              variant="subtitle3"
            >{`${summaryItem.title} `}</Typography>
            <Typography variant="subtitle3">{summaryItem.value}%</Typography>
          </Box>
        </SectionListItem>
        <BarListItem>
          <BarContainer>
            <Bar sx={{ width: `${CUTOFF}%`, backgroundColor: "#7292FD" }} />
            <Bar
              sx={{
                width: `${100 - CUTOFF}%`,
                backgroundColor: "#EE5140",
              }}
            />
            <ScoreDot sx={{ left: getScoreDotLeft(summaryItem.value) }} />
          </BarContainer>
          <BarTextContainer>
            <BarStopText sx={{ left: -5 }}>0</BarStopText>
            <BarStopText sx={{ left: `calc(20% - 8px)` }}>20</BarStopText>
            <BarStopText sx={{ right: 0 }}>100</BarStopText>
            <ScoreText sx={{ left: getScoreTextLeft(summaryItem.value) }}>
              {summaryItem.value}
            </ScoreText>
          </BarTextContainer>
        </BarListItem>
      </StyledAccordionDetail>
    </StyledAccordion>
  );
};

const findSummaryItemByTitle = (
  summary: AnalysisSummaryItem[],
  title
): AnalysisSummaryItem | null =>
  find(summary, (item) => item.title === title) || null;

const REGION_TITLES = [
  "Total Tissue Region",
  "Cancer Region",
  "Analyzable Region",
];

const IP_PROPORTION_TITLES = ["Inflamed", "Immune-Excluded", "Immune-Desert"];

const IPFindings = ({ summary }: AnalysisSummaryProps) => {
  const [isOpen, handleToggleOpen] = useToggleState(true);
  return (
    <StyledAccordion disableGutters square expanded={isOpen}>
      <StyledAccordionSummary
        expandIcon={
          <IconButton
            sx={{
              padding: 0.5,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.darkGrey[80],
              },
            }}
            onClick={handleToggleOpen}
          >
            <ArrowDownIcon />
          </IconButton>
        }
        aria-label="Expand"
        aria-controls={`IPFindings-content`}
        id={`IPFindings-header`}
      >
        <Typography sx={{ whiteSpace: "pre-line" }} variant="body4">
          {"Immune Phenotype\nFindings"}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetail>
        {map(REGION_TITLES, (title) => {
          const summaryItem = findSummaryItemByTitle(summary, title);
          return (
            summaryItem && (
              <SectionListItem key={`${summaryItem.title}`}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body5">{summaryItem.title}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body4">{`${roundToFirstDecimal(
                    Number(summaryItem.value)
                  )}${
                    summaryItem.unit && summaryItem.unit !== "Integer"
                      ? `${convertUnit(summaryItem.unit)}`
                      : ""
                  }`}</Typography>
                </Box>
              </SectionListItem>
            )
          );
        })}
        <SectionListItem>
          <Typography variant="body5">Immune Phenotype Proportion</Typography>
        </SectionListItem>
        <SectionContent sx={{ padding: "6px 20px" }}>
          {map(IP_PROPORTION_TITLES, (title) => {
            const summaryItem = findSummaryItemByTitle(summary, title);
            return (
              summaryItem && (
                <DenseSectionListItem key={`${summaryItem.title}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: summaryItem.color,
                        marginRight: 1,
                      }}
                    />
                    <Typography color="darkGrey.20" variant="body5">
                      {summaryItem.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body4">{`${roundToFirstDecimal(
                      Number(summaryItem.value)
                    )}${
                      summaryItem.unit && summaryItem.unit !== "Integer"
                        ? `${convertUnit(summaryItem.unit)}`
                        : ""
                    }`}</Typography>
                  </Box>
                </DenseSectionListItem>
              )
            );
          })}
        </SectionContent>
        <SectionContent sx={{ padding: "9px 20px", display: "flex" }}>
          {map(IP_PROPORTION_TITLES, (title) => {
            const summaryItem = findSummaryItemByTitle(summary, title);
            return (
              summaryItem && (
                <Box
                  key={`${title}_bar`}
                  sx={{
                    width: `${roundToFirstDecimal(Number(summaryItem.value))}%`,
                    backgroundColor: summaryItem.color,
                    height: 10,
                  }}
                />
              )
            );
          })}
        </SectionContent>
      </StyledAccordionDetail>
    </StyledAccordion>
  );
};

const TIL_DENSITY_TITLES = ["Intratumoral TIL Density", "Stromal TIL Density"];

const TILDensities = ({ summary }: AnalysisSummaryProps) => {
  const [isOpen, handleToggleOpen] = useToggleState(true);
  return (
    <StyledAccordion disableGutters square expanded={isOpen}>
      <StyledAccordionSummary
        expandIcon={
          <IconButton
            sx={{
              padding: 0.5,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.darkGrey[80],
              },
            }}
            onClick={handleToggleOpen}
          >
            <ArrowDownIcon />
          </IconButton>
        }
        aria-label="Expand"
        aria-controls={`TILDensities-content`}
        id={`TILDensities-header`}
      >
        <Typography sx={{ whiteSpace: "pre-line" }} variant="body4">
          {"Tumor Infiltrating\nLymphocyte Density"}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetail>
        {map(TIL_DENSITY_TITLES, (title) => {
          const summaryItem = findSummaryItemByTitle(summary, title);
          const subItems = reverse(Array.from(summaryItem.items));
          return (
            summaryItem && (
              <SectionContent
                key={`${summaryItem.title}`}
                sx={{ padding: "6px 20px" }}
              >
                <DenseSectionListItem>
                  <Typography variant="body5">{summaryItem.title}</Typography>
                </DenseSectionListItem>
                <>
                  {!!summaryItem.items &&
                    map(subItems, (item) => (
                      <DenseSectionListItem key={`${item.title}`}>
                        <Typography color="darkGrey.20" variant="body5">
                          {convertMinAvgMax(item.title)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body4">{`${roundToFirstDecimal(
                            Number(item.value)
                          )}${
                            summaryItem.unit && summaryItem.unit !== "Integer"
                              ? `/${convertUnit(summaryItem.unit)}`
                              : ""
                          }`}</Typography>
                        </Box>
                      </DenseSectionListItem>
                    ))}
                </>
              </SectionContent>
            )
          );
        })}
      </StyledAccordionDetail>
    </StyledAccordion>
  );
};

const AnalysisSummary = ({ summary }: AnalysisSummaryProps) => {
  return (
    <>
      <BiomarkerFindings summary={summary} />
      <SummaryDivider orientation="horizontal" />
      <IPFindings summary={summary} />
      <SummaryDivider orientation="horizontal" />
      <TILDensities summary={summary} />
    </>
  );
};
export default AnalysisSummary;
