import { ProjectUUID } from "dux/projects/model";
import { FetchMethod, request } from "dux/utils/apiRequestHelper";
import map from "lodash/map";
import {
  CancelUploadPayload,
  CompleteUploadingPayload,
  FileName,
} from "./model";

export const getPresignedURLs = (
  fileNames: FileName[],
  format: "MRXS" | "SVS" | "ISYNTAX" | "TIFF",
  projectId?: ProjectUUID
) => {
  const [fileName, ...subFileNames] = map(fileNames, (name) =>
    name.normalize("NFC")
  );

  return request({
    method: FetchMethod.Post,
    path: `wsi`,
    payload: {
      fileName,
      format,
      subFileNames,
      projectId,
    },
  });
};

export const completeWSIFileUploading = (payload: CompleteUploadingPayload) =>
  request({
    method: FetchMethod.Patch,
    path: `wsi/${payload.wsiId}/upload/complete`,
  });

export const getAllowedWSIFormats = () =>
  request({
    method: FetchMethod.Get,
    path: `wsi/format`,
  });

export const getSlideLimit = () =>
  request({
    method: FetchMethod.Get,
    path: `wsi/limit`,
  });

export const cancelWSIUpload = ({
  customerId,
  projectId,
  wsiId,
}: CancelUploadPayload) =>
  request({
    method: FetchMethod.Delete,
    path: `storage/customer/${customerId}/project/${projectId}/wsi/${wsiId}`,
  });
