import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Theme } from "@mui/material/styles";
import { Switch as MuiSwitch } from "@mui/material";
import { SwitchClassKey, SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      top: 1,
      padding: 1,
      color: "#fff",
      "&$checked": {
        color: "#fff",
        transform: "translateX(14px)",
        "& + $track": {
          backgroundColor: "#7292FD",
          opacity: 1,
          border: "none",
        },
      },
      "&$disabled": {
        color: "#fff",
        transform: "translateX(14px)",
        "& + $track": {
          backgroundColor: "rgba(134,148,177,0.4)",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#7292FD",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "rgba(134,148,177,0.4)",
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
    disbled: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface SwitchSliderProps {
  label?: string;
  onChange: () => void;
  checked: boolean;
  disabled?: boolean;
}

const SwitchSlider = ({
  onChange,
  label,
  checked,
  disabled,
}: SwitchSliderProps) => {
  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          variant: "body3",
        },
      }}
      control={
        <Switch
          checked={checked}
          onClick={(e) => e.stopPropagation()}
          onChange={onChange}
          name={label}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};

export default SwitchSlider;
