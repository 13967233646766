import React, { useCallback, useState } from "react";
import {
  Collapse,
  Divider,
  Grow,
  IconButton,
  Paper,
  Portal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ISheetContent, getSheetsSelector } from "./dux";
import useSheets from "components/hooks/useSheets";
import { useSelector } from "react-redux";
import { CloseIcon, ArrowDownIcon } from "assets/icons";

interface SheetProps extends ISheetContent {
  id: string;
}

const ButtonField = styled("div")({
  position: "absolute",
  display: "flex",
  height: "28px",
  right: 28,
});

function Sheet(props: SheetProps) {
  const theme = useTheme();
  const {
    title,
    subtitle,
    content,
    onClose,
    id,
    expanded: initialExpanded,
  } = props;
  const [expanded, setExpanded] = useState<boolean>(
    initialExpanded !== undefined ? initialExpanded : true
  );
  const { removeSheet } = useSheets();

  const handleDismiss = useCallback(() => {
    if (onClose && onClose()) {
      removeSheet(id);
    } else if (!onClose) {
      removeSheet(id);
    }
  }, [id, onClose, removeSheet]);

  const handleExpandClick = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);
  return (
    <Grow in>
      <Paper
        elevation={1}
        sx={{
          ...props.paperStyle,
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#fff",
            height: 64,
          }}
        >
          <Typography
            variant="subtitle3"
            sx={{ marginLeft: "8px", marginRight: "16px" }}
          >
            {title}
          </Typography>
          <Typography variant="body2">{subtitle}</Typography>
          <ButtonField>
            <Tooltip title={expanded ? "Minimize" : "Maximize"}>
              <IconButton
                sx={{
                  transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                  transition: (theme) =>
                    theme.transitions.create("transform", {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                aria-label={expanded ? "minimize" : "maximize"}
                onClick={handleExpandClick}
                size="large"
              >
                <ArrowDownIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                sx={{
                  padding: 0.5,
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.darkGrey[80],
                  },
                }}
                onClick={handleDismiss}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonField>
        </Toolbar>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <>
            <Divider sx={{ background: theme.palette.darkGrey[80] }} />
            {content}
          </>
        </Collapse>
      </Paper>
    </Grow>
  );
}

const SheetCollectionRoot = styled("div")({
  width: "auto",
  height: "auto",
  display: "flex",
  zIndex: 1400,
  position: "fixed",
  maxWidth: "100%",
  minWidth: "288px",
  maxHeight: "100%",
  transition:
    "top 300ms ease 0ms,right 300ms ease 0ms,bottom 300ms ease 0ms,left 300ms ease 0ms",
  flexDirection: "column-reverse",
  right: 24,
  bottom: 55,
});

export default function SheetCollection() {
  const sheets = useSelector(getSheetsSelector);
  return (
    <Portal>
      <SheetCollectionRoot>
        {sheets.map((sheet) => (
          <Sheet key={sheet.key} id={sheet.key} {...sheet} />
        ))}
      </SheetCollectionRoot>
    </Portal>
  );
}
