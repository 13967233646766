import { AlertColor } from "@mui/material";
import AlertSnackBar from "components/common/AlertSnackbar";
import { removeSnackbar, closeSnackbar } from "dux/snackbar/actions";
import { EnqueueSnackbarPayload } from "dux/snackbar/model";
import { snackbarsSelector } from "dux/snackbar/selectors";
import { useSnackbar, VariantType } from "notistack";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

const getSeverityFromVariant = (variant?: VariantType): AlertColor => {
  switch (variant) {
    case "error":
      return "error";
    case "success":
      return "success";
    case "warning":
      return "warning";
    default:
      return "info";
  }
};

let displayed = [];

const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

const useNotifier = () => {
  const dispatch = useDispatch();
  const slideViewerMatch = useRouteMatch([
    "/slides/:slideId",
    "/projects/:projectId/slides/:slideId",
  ]);
  const isLight = !!slideViewerMatch;
  const { enqueueSnackbar, closeSnackbar: closeNotiSnackbar } = useSnackbar();
  const snackbars = useSelector(snackbarsSelector);

  const handleClose = useCallback(
    (key) => dispatch(closeSnackbar(key)),
    [dispatch]
  );

  useEffect(
    () => () => {
      closeNotiSnackbar();
    },
    // Disable for onUnmount handling
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (snackbars.length === 0) {
      closeNotiSnackbar();
      return;
    }
    snackbars.forEach(({ message, options }: EnqueueSnackbarPayload) => {
      const { key, dismissed = false, ...remainingOptions } = options;
      if (dismissed) {
        closeNotiSnackbar(key);
        return;
      }
      if (displayed.includes(key)) return;
      enqueueSnackbar("", {
        key,
        ...remainingOptions,
        content:
          remainingOptions.content ||
          ((key) => {
            return (
              <AlertSnackBar
                id={key}
                isLight={isLight}
                customCloseSnackbar={handleClose}
                severity={getSeverityFromVariant(remainingOptions.variant)}
                action={remainingOptions.action}
                message={message}
              />
            );
          }),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          if (options.onExited) {
            options.onExited(event, myKey);
          }
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });
      storeDisplayed(key);
    });
  }, [
    snackbars,
    closeNotiSnackbar,
    enqueueSnackbar,
    dispatch,
    handleClose,
    isLight,
  ]);
};

export default useNotifier;
