import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CloseIcon, ErrorIcon, SuccessIcon } from "assets/icons";
import {
  EventData,
  EventStatus,
  eventTypeToTitle,
  getEventTypePaletteKey,
} from "hooks/useStompClient";
import IconButton from "@mui/material/IconButton";
import reduce from "lodash/reduce";
import map from "lodash/map";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ClassNames } from "@emotion/react";
import React from "react";

interface SingleEventTooltipProps {
  event: EventData;
}

const ellipsisText = (input: string, max: number): string =>
  input.length > max ? `${input.substring(0, max)}...` : input;

const EventTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "event",
})<TypographyProps & { event: EventData }>(({ theme, event }) => ({
  whiteSpace: "normal",
  lineBreak: "anywhere",
  "& b": {
    ...theme.typography.body3,
    color: theme.palette.slideStatus[getEventTypePaletteKey(event.type)].main,
  },
  "& strong": {
    ...theme.typography.body3,
    display: "inline",
    color: theme.palette.darkGrey[0],
  },
}));

function formatProjectNames(projectNames: string[]): React.ReactNode {
  if (projectNames.length === 1) {
    return <strong>{projectNames[0]}</strong>;
  } else if (projectNames.length === 2) {
    return (
      <>
        <strong>{projectNames[0]}</strong>
        {" and "}
        <strong>{projectNames[1]}</strong>
      </>
    );
  }
  return (
    <>
      {map(projectNames, (name, index) =>
        index === 0 ? (
          <strong key={`${name}_index`}>{name}</strong>
        ) : index === projectNames.length - 1 ? (
          <React.Fragment key={`${name}_index`}>
            {", and "}
            <strong>{name}</strong>
          </React.Fragment>
        ) : (
          <React.Fragment key={`${name}_index`}>
            {", "}
            <strong>{name}</strong>
          </React.Fragment>
        )
      )}
    </>
  );
}

const SingleEventTooltip = ({ event }: SingleEventTooltipProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        minWidth: 436,
        maxWidth: 436,
      }}
    >
      <Box
        sx={{
          color: (theme) =>
            event.status && event.status === EventStatus.Fail
              ? theme.palette.error.main
              : theme.palette.success.main,
          marginRight: 1,
          maxHeight: 20,
        }}
      >
        {event.status && event.status === EventStatus.Fail ? (
          <ErrorIcon />
        ) : (
          <SuccessIcon />
        )}
      </Box>
      <EventTypography variant="body5" color="darkGrey.30" event={event}>
        <b>{`[${eventTypeToTitle(event.type)}] `}</b>
        <strong>{ellipsisText(event.slideInfo.name, 45)}</strong>
        {event.slideInfo.projectNames &&
          event.slideInfo.projectNames.length > 0 && (
            <>
              {" from "}
              {formatProjectNames(event.slideInfo.projectNames)}
            </>
          )}
      </EventTypography>
    </Box>
  );
};

interface SingleEventListItemProps extends SingleEventTooltipProps {
  onRemove: () => void;
}

const SingleEventListItem = ({ event, onRemove }: SingleEventListItemProps) => {
  return (
    <ClassNames>
      {({ css }) => {
        const tooltip = css`
          max-width: 468px;
          padding: 16px;
        `;
        return (
          <Tooltip
            classes={{ tooltip }}
            title={<SingleEventTooltip event={event} />}
            placement="left"
          >
            <ListItem
              sx={{
                height: 64,
                padding: "22px 71px 22px 32px",
                width: 500,
                "& .MuiListItemSecondaryAction-root": {
                  right: 28,
                },
              }}
              secondaryAction={
                <IconButton onClick={onRemove} color="inherit">
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemIcon
                sx={{
                  color: (theme) =>
                    event.status && event.status === EventStatus.Fail
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                  marginRight: 1,
                }}
              >
                {event.status && event.status === EventStatus.Fail ? (
                  <ErrorIcon />
                ) : (
                  <SuccessIcon />
                )}
              </ListItemIcon>
              <ListItemText
                sx={{
                  flexWrap: "nowrap",
                  display: "flex",
                }}
                primary={`[${eventTypeToTitle(event.type)}]`}
                primaryTypographyProps={{
                  variant: "body3",
                  sx: {
                    color: (theme) =>
                      theme.palette.slideStatus[
                        getEventTypePaletteKey(event.type)
                      ].main,
                    display: "flex",
                    flexShrink: 0,
                  },
                }}
                secondary={
                  <>
                    &nbsp;•&nbsp;
                    <span>{event.slideInfo.name}</span>
                    {event.slideInfo.projectNames.length > 0 && (
                      <>&nbsp;/&nbsp;</>
                    )}
                    <span>
                      {reduce(
                        event.slideInfo.projectNames,
                        (str, projectName, index) =>
                          index > 0
                            ? `${str}, - ${projectName}`
                            : ` - ${projectName}`,
                        ""
                      )}
                    </span>
                  </>
                }
                secondaryTypographyProps={{
                  variant: "body3",
                  sx: {
                    color: "darkGrey.0",
                    display: "flex",
                    flex: "1 1 auto",
                    minWidth: 0,
                    "& span": {
                      textOverflow: "ellipsis",
                      flex: "0 1 auto",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    },
                  },
                }}
              />
            </ListItem>
          </Tooltip>
        );
      }}
    </ClassNames>
  );
};

export default SingleEventListItem;
