import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import {
  APISlideSummaryList,
  convertPaginatedAPISlideSummariesToItems,
  Slide,
  SlideAnalyzeRequestFetchState,
  SlideErrorInfo,
  SlideItem,
  SlideItemList,
  SlideMeta,
  SlideMgmtState,
  SlideName,
} from "./model";
import { FetchStatus } from "dux/utils/commonEnums";

export const slideStateSelector: Selector<RootState, Slide> = (state) =>
  state.slide.slideDetail;

export const mppSelector: Selector<RootState, number> = createSelector(
  slideStateSelector,
  (slide) => slide.mpp
);

export const slideNameSelector: Selector<RootState, SlideName> = createSelector(
  slideStateSelector,
  (slide) => slide.name
);

export const slideMetadataSelector: Selector<RootState, SlideMeta> =
  createSelector(slideStateSelector, (slide) => slide.metadata);

export const slideSummarySelector: Selector<RootState, APISlideSummaryList> = (
  state
) => state.slide.slideSummaries;

export const slideItemSelector: Selector<RootState, SlideItemList> =
  createSelector(slideSummarySelector, (slides) =>
    convertPaginatedAPISlideSummariesToItems(slides)
  );

export const slideMgmtStateSelector: Selector<
  RootState,
  SlideMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = (state) => ({
  ...state.slide.slideMgmtState,
  loading: state.slide.slideMgmtState.fetchStatus === FetchStatus.Pending,
  success: state.slide.slideMgmtState.fetchStatus === FetchStatus.Fulfilled,
});

export const slideAnalyzeFetchStateSelector: Selector<
  RootState,
  SlideAnalyzeRequestFetchState
> = (state) => state.slide.slideAnalyzeRequestFetchState;

export const slideViewerListParamsSelector: Selector<RootState, SlideItem[]> = (
  state
) => state.slide.slideViewerListParams.selectedSlides;

export const slideErrorInfoSelector: Selector<RootState, SlideErrorInfo> = (
  state
) => state.slide.slideErrorInfo;
