import { EventData } from "hooks/useStompClient";
import MultiEventListItem from "./MultiEventListItem";
import SingleEventListItem from "./SingleEventListItem";

interface NotificationListItemProps {
  events: EventData[];
  onRemove: () => void;
}

const NotificationListItem = ({
  events,
  onRemove,
}: NotificationListItemProps) => {
  if (events.length > 1) {
    return <MultiEventListItem events={events} onRemove={onRemove} />;
  }
  return <SingleEventListItem event={events[0]} onRemove={onRemove} />;
};

export default NotificationListItem;
