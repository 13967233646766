export default function useQueryParams<
  Params extends { [K in keyof Params]?: string | string[] | undefined } = {}
>(): Params {
  const params = {} as Params;
  const searchParams = new URLSearchParams(
    new URL(window.location.href).search
  );
  for (const [key, value] of searchParams.entries()) {
    if (typeof params[key] === "undefined") {
      params[key] = value;
      continue;
    }
    if (Array.isArray(params[key])) {
      params[key].push(value);
      continue;
    }
    params[key] = [params[key], value];
  }
  return params;
}
