import { Typography } from "@mui/material";

export default function AuthHeading({ children }) {
  return (
    <Typography
      variant="h2"
      textAlign="center"
      sx={{
        marginBottom: 5,
      }}
    >
      {children}
    </Typography>
  );
}
