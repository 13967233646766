import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import { Paging } from "dux/utils/commonModels";
import { FetchStatus } from "dux/utils/commonEnums";
import {
  WSIItem,
  WSIListState,
  WSIAnalysisResultState,
  WSIId,
  WSIMetaInfoMgmtState,
  WSIAnalysisResultViewerState,
  ControlPanelItem,
  WSIReportState,
  SuccessSummary,
  FailureSummary,
  Controller,
  LabelById,
  AnalyzedSlidesState,
} from "./model";

const getWSIAnalysisResultState: Selector<RootState, WSIAnalysisResultState> = (
  state
) => state.WSIAnalysisResult;

const getWSIListState: Selector<RootState, WSIListState> = createSelector(
  getWSIAnalysisResultState,
  (WSIAnalysisResult) => WSIAnalysisResult.wsiList
);

const getWSIMetaInfoState: Selector<RootState, WSIMetaInfoMgmtState> =
  createSelector(
    getWSIAnalysisResultState,
    (WSIAnalysisResult) => WSIAnalysisResult.wsiMetaInfoMgmt
  );

export const getReportStateSelector: Selector<RootState, WSIReportState> =
  createSelector(
    getWSIAnalysisResultState,
    (WSIAnalysisResult) => WSIAnalysisResult.report
  );

export const getReportWSIIdsSelector: Selector<RootState, WSIId[]> =
  createSelector(getReportStateSelector, (report) => report.wsiIds);

export const getDeleteSlidesLoadingStatusSelector: Selector<
  RootState,
  {
    loading: boolean;
    success: boolean;
    failure: boolean;
  }
> = createSelector(getWSIListState, (wsiList) => ({
  loading: wsiList.deleteStatus === FetchStatus.Pending,
  success: wsiList.deleteStatus === FetchStatus.Fulfilled,
  failure: wsiList.deleteStatus === FetchStatus.Rejected,
}));

export const getWSIListLoadingStatusSelector: Selector<
  RootState,
  {
    loading: boolean;
    success: boolean;
    failure: boolean;
  }
> = createSelector(getWSIListState, (wsiList) => ({
  loading: wsiList.fetchStatus === FetchStatus.Pending,
  success: wsiList.fetchStatus === FetchStatus.Fulfilled,
  failure: wsiList.fetchStatus === FetchStatus.Rejected,
}));

export const getWSIAnalysisResultListSelector: Selector<RootState, WSIItem[]> =
  createSelector(getWSIListState, (wsiList) => wsiList.list);

export const getRefreshWSIListSelector: Selector<
  RootState,
  { needToRefresh: boolean; resetSearch: boolean }
> = createSelector(getWSIListState, (wsiList) => ({
  needToRefresh: wsiList.needToRefresh,
  resetSearch: wsiList.resetSearch,
}));

export const getWSIAnalysisSummaryByIdSelector: (
  wsiId: WSIId
) => Selector<RootState, SuccessSummary[]> = (id) =>
  createSelector(
    getWSIAnalysisResultListSelector,
    (WSIAnalysisResultList) =>
      WSIAnalysisResultList.find((item) => item.id === id)?.summary
  );

export const getWSIItemByIdSelector: (
  wsiId: WSIId
) => Selector<RootState, WSIItem> = (id) =>
  createSelector(getWSIAnalysisResultListSelector, (WSIAnalysisResultList) =>
    WSIAnalysisResultList.find((item) => item.id === id)
  );

export const getWSIAnalysisErrorMsgByIdSelector: (
  wsiId: WSIId
) => Selector<RootState, FailureSummary> = (id) =>
  createSelector(
    getWSIAnalysisResultListSelector,
    (WSIAnalysisResultList) =>
      WSIAnalysisResultList.find((item) => item.id === id)?.errorMsg
  );

export const getPaginationInfoSelector: Selector<RootState, Paging> =
  createSelector(
    getWSIListState,
    (WSIAnalysisResult) => WSIAnalysisResult.pagination
  );

export const getWSIMetaInfoMgmtStateSelector: Selector<
  RootState,
  WSIMetaInfoMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = createSelector(getWSIMetaInfoState, (wsiMetaInfoMgmt) => ({
  ...wsiMetaInfoMgmt,
  loading: wsiMetaInfoMgmt.fetchStatus === FetchStatus.Pending,
  success: wsiMetaInfoMgmt.fetchStatus === FetchStatus.Fulfilled,
}));

const getWSIAnalysisResultViewerState: Selector<
  RootState,
  WSIAnalysisResultViewerState
> = createSelector(
  getWSIAnalysisResultState,
  (WSIAnalysisResult) => WSIAnalysisResult.viewer
);

export const WSIAnalysisResultPanelContentReady: Selector<RootState, boolean> =
  createSelector(
    getWSIAnalysisResultViewerState,
    (state) => state.fetchStatus === FetchStatus.Fulfilled
  );

export const getWSIAnalysisResultViewerSummary: Selector<
  RootState,
  SuccessSummary[]
> = createSelector(getWSIAnalysisResultViewerState, (viewer) => viewer.summary);

export const getWSIAnalysisComment: Selector<RootState, string> =
  createSelector(getWSIAnalysisResultViewerState, (viewer) => viewer.comment);

export const getControlPanelItems: Selector<RootState, ControlPanelItem[]> =
  createSelector(getWSIAnalysisResultViewerState, (viewer) =>
    viewer.controlPanel.length
      ? viewer.controlPanel
      : [
          { id: "", title: "", items: [] }, // map items
          { id: "", title: "", items: [] }, // feature items
        ]
  );

const getCellPanelItemList: Selector<RootState, Controller[]> = createSelector(
  getControlPanelItems,
  (items) => items[1]?.items
);

export const getInferenceResultFilePathSelector: Selector<RootState, string> =
  createSelector(
    getWSIAnalysisResultViewerState,
    (state) => state.inferenceResultFilePath
  );

export const getHistologyPanelItemsWithColor: Selector<RootState, LabelById[]> =
  createSelector(getCellPanelItemList, (itemList) =>
    itemList.map((item) => ({
      labelId: item.id,
      color: item.color,
    }))
  );

export const getAnalyzedSlidesState: Selector<RootState, AnalyzedSlidesState> =
  createSelector(
    getWSIAnalysisResultState,
    (WSIAnalysisResult) => WSIAnalysisResult.analyzedSlides
  );

export const getInferenceResultLoaded: Selector<RootState, boolean> =
  createSelector(
    getWSIAnalysisResultViewerState,
    (viewerState) => viewerState.inferenceResultLoaded
  );
