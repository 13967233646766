import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import { action } from "typesafe-actions";
import {
  APITagInput,
  APITagList,
  SearchTagsParams,
  Tag,
  TagUUID,
} from "./model";

export const RESET_CREATED_TAG = "RESET_CREATED_TAG";
export const resetCreatedTag = () => action(RESET_CREATED_TAG);

/**
 * Action Creators
 * Usage: getTags[type: request | success | failure](params)
 */
export const GET_TAGS_TYPES = asyncActionCreator("GET_TAGS");
export const getTags = asyncAction<SearchTagsParams, APITagList, string>(
  GET_TAGS_TYPES
);

/**
 * Action Creators
 * Usage: createTag[type: request | success | failure](params)
 */
export const CREATE_TAG_TYPES = asyncActionCreator("CREATE_TAG");
export const createTag = asyncAction<APITagInput, Tag, string>(
  CREATE_TAG_TYPES
);

/**
 * Action Creators
 * Usage: deleteTags[type: request | success | failure](params)
 */
export const DELETE_TAGS_TYPES = asyncActionCreator("DELETE_TAG");
export const deleteTags = asyncAction<TagUUID[], any, string>(
  DELETE_TAGS_TYPES
);

/**
 * Action Creators
 * Usage: editTag[type: request | success | failure](params)
 */
export const EDIT_TAG_TYPES = asyncActionCreator("EDIT_TAG");
export const editTag = asyncAction<Tag, Tag, string>(EDIT_TAG_TYPES);
