import accessTokenManager from "auth/accessTokenManager";
import constate from "constate";
import { closeSnackbar, enqueueSnackbar } from "dux/snackbar/actions";
import concat from "lodash/concat";
import noop from "lodash/noop";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
/* eslint import/no-webpack-loader-syntax:0 */
import StompClientWorker from "worker-loader!./stompClient.worker";

export enum EventType {
  Analysis = "ANALYSIS",
  PreAnalysis = "PRE_ANALYSIS",
  SlideUpload = "SLIDE_UPLOAD",
  AnalysisResultReview = "ANALYSIS_RESULT_REVIEW",
}

declare global {
  interface Window {
    stompClientWorker: Worker;
  }
}

export function eventTypeToTitle(type: EventType) {
  switch (type) {
    case EventType.Analysis:
      return "Analysis";
    case EventType.PreAnalysis:
      return "Analysis";
    case EventType.SlideUpload:
      return "Upload";
    case EventType.AnalysisResultReview:
      return "Analysis";
    default:
      return type;
  }
}

export function getEventTypePaletteKey(type: EventType) {
  switch (type) {
    case EventType.Analysis:
      return "analyzed";
    case EventType.PreAnalysis:
      return "analyzed";
    case EventType.SlideUpload:
      return "preanalyzing";
    case EventType.AnalysisResultReview:
      return "analyzed";
    default:
      return "analyzed";
  }
}

export enum EventStatus {
  Success = "SUCCESS",
  Fail = "FAIL",
}

export interface EventData {
  id: string;
  type: EventType;
  status?: EventStatus;
  slideInfo: {
    id: string;
    name: string;
    projectNames: string[];
  };
  analysisInfo?: {
    analysisSourceId: string;
    biomarkerAnalysisId: string;
  };
  userInfo: {
    userId: string;
    customerId: string;
    customerCode: string;
  };
  eventTime: string;
}

export interface NotificationMessage {
  eventData: EventData[];
  receivedAt: Date;
  checked: boolean;
}

function useStompClientState({ url, topic }: { url: string; topic: string }) {
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState<NotificationMessage[]>([]);
  const [unread, setUnread] = useState(false);
  const retryInterval = useRef(5);
  const dispatch = useDispatch();
  const checkRead = useCallback(() => {
    setUnread(false);
  }, []);
  const stompClientWorker = useMemo(
    () =>
      (window.stompClientWorker =
        window.stompClientWorker || new StompClientWorker()),
    []
  );
  stompClientWorker.onmessage = useCallback(
    (evt) => {
      const { action, data } = evt.data;
      switch (action) {
        case "message":
          const filteredEventData = data.filter(
            (event) =>
              !(
                event.type === EventType.PreAnalysis &&
                event.status === "SUCCESS"
              )
          );
          if (filteredEventData.length > 0) {
            setMessages((messages) =>
              concat(
                [
                  {
                    eventData: filteredEventData,
                    receivedAt: new Date(),
                    checked: false,
                  },
                ],
                messages
              )
            );
            setUnread(true);
          }
          break;
        case "connected":
          setConnected(true);
          dispatch(closeSnackbar());
          retryInterval.current = 5;
          break;
        case "stomperror":
        case "wsclose":
        case "wserror":
        case "disconnected":
          setConnected(false);
          dispatch(
            enqueueSnackbar({
              message: `Failed to establish a websocket connection. Retrying in ${retryInterval.current} seconds`,
              options: {
                key: `retry-websocket-${retryInterval.current}`,
                autoHideDuration: retryInterval.current * 1000,
                variant: "error",
                disableWindowBlurListener: true,
              },
            })
          );
          retryInterval.current *= 2;
          break;
        default:
          console.log(data);
      }
    },
    [dispatch]
  );
  useEffect(
    () => {
      if (connected) return;
      // need to inspect new STOMP client SDK spec... check out https://stomp-js.github.io/api-docs/latest/classes/StompConfig.html
      // activate stomp client
      stompClientWorker.postMessage({
        action: "activate",
        url,
        initialRetryInterval: retryInterval.current,
        accessToken: accessTokenManager.getAccessToken(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url, connected, stompClientWorker]
  );

  useEffect(
    () => () => {
      // deactivate stomp client
      console.log("deactivate");
      stompClientWorker.onmessage = noop;
      stompClientWorker.postMessage({
        action: "deactivate",
      });
      stompClientWorker.terminate();
      window.stompClientWorker = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!connected || !topic) return;
    // subscribe
    stompClientWorker.postMessage({
      action: "subscribe",
      topic,
    });
  }, [topic, connected, stompClientWorker]);

  const removeMessage = useCallback(
    (message: NotificationMessage) => {
      setMessages([...messages.filter((value) => value !== message)]);
    },
    [messages]
  );

  const removeAllMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return { messages, unread, checkRead, removeMessage, removeAllMessages };
}

const [StompClientProvider, useStompClient] = constate(useStompClientState);

export { useStompClient as default, StompClientProvider };
