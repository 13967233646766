import { useCallback, useState } from "react";
import { IDialogContent } from "./context";

export default function useDialog() {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent>({
    content: "",
  });

  const handleDialog = useCallback((content?: IDialogContent) => {
    if (content) {
      setDialogContent(content);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  return { open, handleDialog, dialogContent };
}
