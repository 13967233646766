import { Button, ButtonProps } from "@mui/material";

interface AuthActionButtonProps
  extends Partial<
    Pick<ButtonProps, "children" | "color" | "disabled" | "type">
  > {}
export default function AuthActionButton({
  children,
  color = "primary",
  disabled = false,
  type = "submit",
}: AuthActionButtonProps) {
  return (
    <Button
      sx={{
        display: "flex",
        marginTop: 3,
        width: "100%",
      }}
      type={type}
      variant="contained"
      size="large"
      color={color}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
