import { Reducer, useMemo, useReducer } from "react";
import { FormMode } from "components/Forms/Template";
import produce, { Draft } from "immer";

interface FormTemplateState<ValueType> {
  open: boolean;
  defaultValues: ValueType | null;
  mode: FormMode;
}

function makeInitialFormTemplateState<
  ValueType
>(): FormTemplateState<ValueType> {
  return {
    open: false,
    defaultValues: null,
    mode: FormMode.Add,
  };
}

const OPEN_FORM = "components/FormTemplate/OPEN_FORM";
const CLOSE_FORM = "components/FormTemplate/CLOSE_FORM";

interface OpenFormAction<ValueType> {
  type: typeof OPEN_FORM;
  payload: {
    mode: FormMode;
    defaultValues: ValueType | null;
  };
}

interface CloseFormAction {
  type: typeof CLOSE_FORM;
}

type FormTemplateActions<ValueType> =
  | OpenFormAction<ValueType>
  | CloseFormAction;

function makeFormTemplateActionCreators<ValueType>() {
  return {
    openForm: function <ValueType>(
      mode: FormMode,
      defaultValues: ValueType | null
    ): FormTemplateActions<ValueType> {
      return {
        type: OPEN_FORM,
        payload: { mode, defaultValues },
      };
    },
    closeForm: function (): FormTemplateActions<ValueType> {
      return {
        type: CLOSE_FORM,
      };
    },
  };
}

function makeFormTemplateStateReducer<ValueType>(): Reducer<
  FormTemplateState<ValueType>,
  FormTemplateActions<ValueType>
> {
  return (
    state: FormTemplateState<ValueType>,
    action: FormTemplateActions<ValueType>
  ) => {
    switch (action.type) {
      case OPEN_FORM:
        return produce(state, (draft) => {
          const { mode, defaultValues } = action.payload;
          draft.mode = mode;
          draft.defaultValues = defaultValues as Draft<ValueType>;
          draft.open = true;
        });
      case CLOSE_FORM:
        return produce(state, (draft) => {
          draft.open = false;
          // draft.mode = FormMode.Add;
          draft.defaultValues = null;
        });
      default:
        return state;
    }
  };
}

function useFormTemplate<ValueType>() {
  const [state, dispatch] = useReducer(
    makeFormTemplateStateReducer<ValueType>(),
    makeInitialFormTemplateState<ValueType>()
  );
  const actionCreators = useMemo(
    () => makeFormTemplateActionCreators<ValueType>(),
    []
  );
  return {
    state,
    dispatch,
    actionCreators,
  };
}

export default useFormTemplate;
