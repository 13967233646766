import {
  HeatmapOptionItem,
  ViewOption,
  ViewOptionItem,
} from "dux/analysis/model";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import map from "lodash/map";
import reduce from "lodash/reduce";

interface ViewOptionsTooltipProps {
  viewOptions?: ViewOption[];
}

const Container = styled("div")({
  padding: "6px 8px",
  display: "flex",
  flexDirection: "column",
});

const OptionContainer = styled("div")({
  paddingBottom: 4,
  minWidth: 188,
  maxWidth: 188,
  display: "flex",
  flexDirection: "column",
});

const OptionTitle = styled("div")({
  height: 28,
  display: "flex",
  alignItems: "center",
});

const OptionItem = styled("div")({
  marginTop: 4,
  display: "flex",
  alignItems: "center",
});

const PointIndicator = styled("div")({
  marginLeft: 2,
  width: 6,
  height: 6,
  marginRight: 6,
  borderRadius: 3,
});

const MaskIndicator = styled("div")({
  width: 10,
  height: 10,
  marginRight: 4,
});

const ColorMapContainer = styled("div")({
  height: 28,
  marginTop: 4,
  marginBottom: 4,
  position: "relative",
});

const ColorMap = styled("div")({
  position: "absolute",
  width: "100%",
  height: 4,
  borderRadius: 2,
  top: 4,
});

const STOP_WIDTH = 54;

const ColorMapStop = styled("span")({
  position: "absolute",
  top: 10,
  opacity: 0.9,
  width: STOP_WIDTH,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "8px",
  lineHeight: "12px",
});

function instanceOfViewOptionItemArray(
  items: any[]
): items is ViewOptionItem[] {
  return "type" in items[0];
}

function instanceOfHeatmapOptionItemArray(
  items: any[]
): items is HeatmapOptionItem[] {
  return "colors" in items[0];
}

const getGradientFromColors = (colors: string[]): string => {
  return `${reduce(
    colors,
    (gradient, color, index) =>
      `${gradient}, ${color} ${(index / colors.length) * 100}% ${
        index + (1 / colors.length) * 100
      }%`,
    "linear-gradient(to right"
  )})`;
};

function ViewOptionComponent({
  viewOption,
  divider,
}: {
  viewOption: ViewOption;
  divider?: boolean;
}) {
  const { items, title } = viewOption;
  if (instanceOfViewOptionItemArray(items)) {
    return (
      <OptionContainer>
        <OptionTitle>
          <Typography variant="body3">{title}</Typography>
        </OptionTitle>
        {map(items, (item) => (
          <OptionItem key={item.id || item.title}>
            {item.type === "POINT" && (
              <PointIndicator sx={{ backgroundColor: item.color }} />
            )}
            {(item.type === "MASK" || title === "Immune Phenotype Map") && (
              <MaskIndicator sx={{ backgroundColor: item.color }} />
            )}
            <Typography variant="body5">{item.title}</Typography>
          </OptionItem>
        ))}
        {title === "Cell Detection" && (
          <OptionItem>
            <Typography variant="body6" color="darkGrey.30">
              Cells show when over x10.
            </Typography>
          </OptionItem>
        )}
        {divider && (
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        )}
      </OptionContainer>
    );
  }
  if (instanceOfHeatmapOptionItemArray(items)) {
    const gradientFromColors = getGradientFromColors(items[0].colors);
    return (
      <OptionContainer sx={{ marginTop: "20px" }}>
        <OptionTitle>
          <Typography variant="body3">{title}</Typography>
        </OptionTitle>
        <ColorMapContainer>
          <ColorMap sx={{ background: gradientFromColors }} />
          <ColorMapStop
            sx={{
              textAlign: "left",
              left: 0,
            }}
          >
            0%
          </ColorMapStop>
          <ColorMapStop
            sx={{
              textAlign: "center",
              left: `calc(50% - ${STOP_WIDTH / 2}px)`,
            }}
          >
            Local TPS
          </ColorMapStop>
          <ColorMapStop
            sx={{
              textAlign: "right",
              right: 0,
            }}
          >
            100%
          </ColorMapStop>
        </ColorMapContainer>
        {divider && (
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        )}
      </OptionContainer>
    );
  }
  return (
    <OptionContainer>
      <OptionTitle>
        <Typography variant="body3">{title}</Typography>
      </OptionTitle>
      <OptionItem>
        <Typography variant="body5">{items[0].title}</Typography>
      </OptionItem>
      {divider && (
        <Divider
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />
      )}
    </OptionContainer>
  );
}

export default function ViewOptionsTooltip({
  viewOptions,
}: ViewOptionsTooltipProps) {
  return (
    !!viewOptions && (
      <Container>
        {map(viewOptions, (viewOption, index) => (
          <ViewOptionComponent
            key={index}
            viewOption={viewOption}
            divider={
              index < viewOptions.length - 1 &&
              !viewOption.title.includes("TPS Map")
            }
          />
        ))}
      </Container>
    )
  );
}
