import {
  asyncAction,
  asyncActionCreator,
} from "dux/utils/actionHandlingHelper";
import {
  APISlideSummary,
  APIUpdatedSlideSummary,
  BulkUpdateCancerTypesParams,
  OpenSlideViewerParams,
  RequestSlidesAnalyzeParams,
  SearchSlideSummariesParams,
  SlideItem,
  SlideUUID,
  UpdateSlideSummaryParams,
} from "./model";
import { Paginate } from "dux/utils/commonModels";
import { action } from "typesafe-actions";
import { EventData } from "hooks/useStompClient";

export const RESET_SLIDE_MGMT_STATE = "RESET_SLIDE_MGMT_STATE";
export const resetSlideMgmtState = () => action(RESET_SLIDE_MGMT_STATE);
export const SYNC_DRAWER_SLIDE_LIST = "SYNC_DRAWER_SLIDE_LIST";
export const syncDrawerSlideList = (events: EventData[]) =>
  action(SYNC_DRAWER_SLIDE_LIST, { events });

/**
 * Action Creators
 * Usage: getSlideSummaries[type: request | success | failure](params)
 */
export const GET_SLIDE_SUMMARIES_TYPES = asyncActionCreator(
  "GET_SLIDE_SUMMARIES"
);
export const getSlideSummaries = asyncAction<
  SearchSlideSummariesParams,
  Paginate<APISlideSummary>,
  string
>(GET_SLIDE_SUMMARIES_TYPES);

/**
 * Action Creators
 * Usage: updateSlideSummary[type: request | success | failure](params)
 */
export const UPDATE_SLIDE_SUMMARY_TYPES = asyncActionCreator(
  "UPDATE_SLIDE_SUMMARY"
);
export const updateSlideSummary = asyncAction<
  UpdateSlideSummaryParams,
  APIUpdatedSlideSummary,
  string
>(UPDATE_SLIDE_SUMMARY_TYPES);

// TODO: integrate with WSIAnalysis? @lunit-msseo
/**
 * Action Creators
 * Usage: deleteSlideSummaries[type: request | success | failure](params)
 */
export const DELETE_SLIDE_SUMMARIES_TYPES = asyncActionCreator(
  "DELETE_SLIDE_SUMMARIES"
);
export const deleteSlideSummaries = asyncAction<SlideUUID[], any, string>(
  DELETE_SLIDE_SUMMARIES_TYPES
);

/**
 * Action Creators
 * Usage: requestSlidesAnalyze[type: request | success | failure](params)
 */
export const REQUEST_SLIDES_ANALYZE_TYPES = asyncActionCreator(
  "REQUEST_SLIDES_ANALYZE"
);
export const requestSlidesAnalyze = asyncAction<
  RequestSlidesAnalyzeParams,
  any,
  string
>(REQUEST_SLIDES_ANALYZE_TYPES);

/**
 * Action Creators
 * Usage: requestSlideErrorInfo[type: request | success | failure](params)
 */
export const REQUEST_SLIDES_ERROR_INFO_TYPES = asyncActionCreator(
  "REQUEST_SLIDES_ERROR_INFO_TYPES"
);
export const requestSlideErrorInfo = asyncAction<SlideUUID, any, string>(
  REQUEST_SLIDES_ERROR_INFO_TYPES
);

/**
 * Action Creators
 * Usage: openSlideViewer[type: request | success | failure](params)
 */
export const OPEN_SLIDE_VIEWER_TYPE = "OPEN_SLIDE_VIEWER_TYPE";
export interface OpenSlideViewerAction {
  type: typeof OPEN_SLIDE_VIEWER_TYPE;
  payload: OpenSlideViewerParams;
}

export function openSlideViewerActionCreator(
  payload: OpenSlideViewerParams
): OpenSlideViewerAction {
  return {
    type: OPEN_SLIDE_VIEWER_TYPE,
    payload,
  };
}

/**
 * Action Creators
 * Usage: updateSlideSummary[type: request | success | failure](params)
 */
export const BULK_UPDATE_CANCER_TYPES = asyncActionCreator(
  "BULK_UPDATE_CANCER_TYPES"
);
export const bulkUpdateCancerTypes = asyncAction<
  BulkUpdateCancerTypesParams,
  APISlideSummary[],
  string
>(BULK_UPDATE_CANCER_TYPES);

/**
 * Action Creators
 * Usage: requestSlideInferences[type: request | success | failure](params)
 */
export const REQUEST_SLIDE_INFERENCES_TYPES = asyncActionCreator(
  "REQUEST_SLIDE_INFERENCES"
);
export const requestSlideInferences = asyncAction<SlideItem[], any, string>(
  REQUEST_SLIDE_INFERENCES_TYPES
);
