import Drawer from "./Drawer";
import Header from "./Header";
import { styled } from "@mui/material/styles";
import useToggleState from "hooks/useToggleState";
import { ReactNode, useLayoutEffect, useState } from "react";

interface ContainerProps {
  leftShifted: boolean;
}

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "leftShifted",
})<ContainerProps>(({ leftShifted, theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "auto",
  paddingTop: theme.toolbar.height,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(leftShifted && {
    width: `calc(100% - ${theme.appDrawer.width}px)`,
    marginLeft: theme.appDrawer.width,
  }),
}));

const PageRoot = ({ children }: { children: ReactNode }) => {
  const [drawerOpen, toggleDrawerOpen] = useToggleState(true);
  const [childrenToRender, setChildrenToRender] = useState(null);
  useLayoutEffect(() => {
    setChildrenToRender(children);
  }, [children]);
  return (
    <Container
      id="page-root"
      className={drawerOpen ? "drawer-open" : "drawer-close"}
      leftShifted={drawerOpen}
    >
      <Header drawerOpen={drawerOpen} onDrawerToggle={toggleDrawerOpen} />
      <Drawer drawerOpen={drawerOpen} />
      {childrenToRender}
    </Container>
  );
};

export default PageRoot;
