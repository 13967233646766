export enum API_TARGETS {
  "production" = "production",
  "dev" = "dev",
  "qe" = "qe",
  "demo" = "demo",
}

export const DEFAULT_API_TARGET = (() => {
  switch (process.env.REACT_APP_VERCEL_ENV) {
    case "production":
      return API_TARGETS.production;
    case "dev":
      return API_TARGETS.dev;
    case "qe":
      return API_TARGETS.qe;
    case "demo":
      return API_TARGETS.demo;
    default:
      return API_TARGETS.dev;
  }
})();

const env = {
  isProduction: process.env.NODE_ENV === "production",
  isOnPrem: process.env.REACT_APP_IS_ON_PREM === "true",
  SENTRY_CONFIG_URL: process.env.REACT_APP_SENTRY_DSN,
  API_URLS: {
    [API_TARGETS.production]: "https://kosmos-io.api.scope.lunit.io",
    [API_TARGETS.dev]: "https://staging.kosmos-io.api.scope.lunit.io", // "https://dev-scope-io.lunit.io", "https://io.api.dev.scope.lunit.io",
    [API_TARGETS.qe]: "https://seoul.qe.io.api.scope.lunit.io", // "https://io.api.qe.scope.lunit.io",
    [API_TARGETS.demo]: "https://staging.kosmos-io.api.scope.lunit.io",
  },
  // TODO: need to change websocket api path
  SOCKET_URLS: {
    [API_TARGETS.production]:
      "https://webpush.kosmos-io.api.scope.lunit.io/fast-push-websocket",
    [API_TARGETS.qe]:
      "https://webpush.seoul.qe.io.api.scope.lunit.io/fast-push-websocket",
    [API_TARGETS.dev]:
      "https://webpush.staging.kosmos-io.api.scope.lunit.io/fast-push-websocket",
  },
  // npm expects versions following the SemVer 60 syntax,
  // and that syntax doesn’t allow for 4 digits.
  // So the last digits connected with '-' should be repalce \w '.' to make it 4 digits of format.
  version: process.env.REACT_APP_VERSION.replace("-", "."),
  ifuLink:
    "https://scope-public.s3.ap-northeast-2.amazonaws.com/io/%5BSCOPE%20IO%5D%20IFU.EN.0.3.2.0.pdf",
  reportLink: process.env.REACT_APP_REPORT_LINK || "https://scope-io.lunit.io/",
};

export default env;
