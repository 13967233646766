// This file composes dux together by combining sagas
import { fork } from "redux-saga/effects";
import projectsRootSaga from "./projects/saga";
import userRootSaga from "./user/saga";
import WSIAnalysisResultRootSaga from "./WSIAnalysisResult/saga";
import WSIUploadRootSaga from "../components/WSIUpload/dux/saga";
import slidesRootSaga from "dux/slide/saga";
import tagsRootSaga from "dux/tags/saga";
import analysisRootSaga from "./analysis/saga";
import canerTypesRootSaga from "dux/cancerTypes/saga";

export default function* rootSaga() {
  yield fork(userRootSaga);
  yield fork(WSIAnalysisResultRootSaga);
  yield fork(WSIUploadRootSaga);
  yield fork(analysisRootSaga);
  yield fork(projectsRootSaga);
  yield fork(slidesRootSaga);
  yield fork(tagsRootSaga);
  yield fork(canerTypesRootSaga);
}
