import { createSelector, Selector } from "reselect";
import { RootState } from "dux/rootReducer";
import {
  AccountMgmtState,
  AccountProfile,
  SettingsState,
  User,
  UserUUID,
  UserRole,
  APINotiSetting,
  NotificationMethod,
  UserMeta,
  UserState,
} from "./model";
import { FetchStatus } from "dux/utils/commonEnums";
import { Paging } from "dux/utils/commonModels";

export const getAccountStateSelector: Selector<RootState, User> = (state) =>
  state.user.account;

export const getAccountIdSelector: Selector<RootState, UserUUID> =
  createSelector(getAccountStateSelector, (user) => user.id);

export const getAccountNameSelector: Selector<RootState, string> =
  createSelector(getAccountStateSelector, (user) => user.name);

export const getIsLoginSelector: Selector<RootState, boolean> = createSelector(
  getAccountStateSelector,
  (user) => user.isLogin
);

export const getAccountProfileSelector: Selector<RootState, AccountProfile> =
  createSelector(getAccountStateSelector, (user) => ({
    name: user.name,
    email: user.email,
    customerCode: user.customer.code,
    phoneNumber: user.phoneNumber,
  }));

export const getCustomerCodeSelector: Selector<RootState, string> =
  createSelector(getAccountStateSelector, (user) => user.customer.code);

export const getCustomerCreationDateSelector: Selector<RootState, string> =
  createSelector(getAccountStateSelector, (user) => user.customer.createdAt);

const UserListStateSelector: Selector<RootState, UserState> = (state) =>
  state.user;

export const getUserListSelector: Selector<RootState, UserMeta[]> =
  createSelector(UserListStateSelector, (users) => users.userList.list);

export const getUserListLoadingSelector: Selector<RootState, boolean> =
  createSelector(
    UserListStateSelector,
    (users) => users.userList.fetchStatus === FetchStatus.Pending
  );

export const getUserListPaginationInfoSelector: Selector<RootState, Paging> =
  createSelector(UserListStateSelector, (users) => users.userList.pagination);

export const getAccountMgmtStateSelector: Selector<
  RootState,
  AccountMgmtState & {
    loading: boolean;
    success: boolean;
  }
> = createSelector(
  (state) => state.user.accountMgmt,
  (accountMgmt) => ({
    ...accountMgmt,
    loading: accountMgmt.fetchStatus === FetchStatus.Pending,
    success: accountMgmt.fetchStatus === FetchStatus.Fulfilled,
  })
);

export const getSettingsStateSelector: Selector<
  RootState,
  SettingsState & {
    loading: boolean;
    success: boolean;
  }
> = createSelector(
  (state) => state.user.settings,
  (settings) => ({
    ...settings,
    loading: settings.fetchStatus === FetchStatus.Pending,
    success: settings.fetchStatus === FetchStatus.Fulfilled,
  })
);

export const isAdminUserSelector: Selector<RootState, boolean> = createSelector(
  getAccountStateSelector,
  (user) => user.id && user.roleName === UserRole.Admin
);

export const isAnalyzerUserSelector: Selector<RootState, boolean> =
  createSelector(
    getAccountStateSelector,
    (user) => user.id && user.roleName === UserRole.Analyzer
  );

export const isViewerUserSelector: Selector<RootState, boolean> =
  createSelector(
    getAccountStateSelector,
    (user) => user.id && user.roleName === UserRole.Viewer
  );

export const getMFARegisteredAt: Selector<RootState, string> = createSelector(
  getAccountStateSelector,
  (user) => user.mfaKeyRegisteredAt
);

export const getEmailNotiSettingsSelector: Selector<
  RootState,
  APINotiSetting[]
> = createSelector(
  (state) => state.user.settings.notiSettings,
  (notiSettings) =>
    notiSettings.filter((setting) => {
      return setting.method === NotificationMethod.Email;
    })
);
