import { ActionType, createReducer } from "typesafe-actions";
import produce from "immer";
import {
  TaskRef,
  WSIFileUploadError,
  WSIFileUploadStage,
  WSIUploadState,
} from "./model";
import {
  AbortSingleWSIUploadAction,
  ABORT_SINGLE_WSI_UPLOAD,
  AddWSIsToUploadAction,
  ADD_WSIS_TO_UPLOAD,
  CompleteWSIUploadingFailureAction,
  CompleteWSIUploadingSuccessAction,
  COMPLETE_WSI_UPLOADING_FAILURE,
  COMPLETE_WSI_UPLOADING_SUCCESS,
  getAllowedWSIFormats,
  getSlideLimit,
  GET_ALLOWED_WSI_FORMATS_TYPES,
  GET_SLIDE_LIMIT_TYPES,
  InitWSIUploadWatcherTaskAction,
  INIT_WSI_UPLOAD_WATCHER_TASK,
  RESET_FILE_UPLOAD,
  RetrySingleWSIUploadAction,
  RETRY_SINGLE_WSI_UPLOAD,
  SetPresignedURLsForWSIAction,
  SET_PRESIGNED_URLS_FOR_WSI,
  startISyntaxConversion,
  START_ISYNTAX_CONVERSION,
  UploadWSIToS3Action,
  UploadWSIToS3FailureAction,
  UploadWSIToS3FileProgressAction,
  UploadWSIToS3SuccessAction,
  UPLOAD_WSI_TO_S3_FAILURE,
  UPLOAD_WSI_TO_S3_FILE_PROGRESS,
  UPLOAD_WSI_TO_S3_REQUEST,
  UPLOAD_WSI_TO_S3_SUCCESS,
} from "./actions";
import { FetchStatus } from "dux/utils/commonEnums";
import map from "lodash/map";
import { reduce } from "lodash";

export const initialState: WSIUploadState = {
  states: {},
  keyOrder: [],
  allowedFormats: [],
  formatsFetchStatus: FetchStatus.Idle,
  limitFetchStatus: FetchStatus.Idle,
  limitSlideCount: 0,
  currentSlideCount: 0,
};

const wsiUploadReducer = createReducer<WSIUploadState>(initialState, {
  [RESET_FILE_UPLOAD]: (state) =>
    produce(state, (draft) => {
      draft.states = initialState.states;
      draft.keyOrder = initialState.keyOrder;
      draft.uploadWatcherTask = null;
    }),
  [ADD_WSIS_TO_UPLOAD]: (state, action: AddWSIsToUploadAction) =>
    produce(state, (draft) => {
      const { payload } = action;
      payload.wsiKeys.forEach((key, index) => {
        draft.states[key] = payload.initialStates[index];
        draft.keyOrder.push(key);
      });
    }),
  [INIT_WSI_UPLOAD_WATCHER_TASK]: (
    state,
    action: InitWSIUploadWatcherTaskAction
  ) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.uploadWatcherTask = new TaskRef(payload.task);
    }),
  [SET_PRESIGNED_URLS_FOR_WSI]: (state, action: SetPresignedURLsForWSIAction) =>
    produce(state, (draft) => {
      const { payload } = action;
      const presignedURLs = reduce(
        payload.presignedURLs,
        (tempIdx, { object_path, url }) => {
          const filename = object_path.split("/").slice(2).join("/");
          return { ...tempIdx, [filename]: url };
        },
        {}
      );
      draft.states[payload.wsiKey].targetWSIId = payload.targetWSIId;
      draft.states[payload.wsiKey].presignedURLs = presignedURLs;
      draft.states[payload.wsiKey].stage = WSIFileUploadStage.GetPresignedURL;
    }),
  [UPLOAD_WSI_TO_S3_REQUEST]: (state, action: UploadWSIToS3Action) =>
    produce(state, (draft) => {
      const { payload } = action;
      const thisWSI = draft.states[payload.wsiKey];
      draft.states[payload.wsiKey].stage = WSIFileUploadStage.UploadFileToS3;
      draft.states[payload.wsiKey].loading = true;
      draft.states[payload.wsiKey].xhrPool = thisWSI.targetFiles.map(() => ({
        isUploading: false,
        loaded: 0,
        total: 1,
        uploadFailed: false,
      }));
    }),
  [UPLOAD_WSI_TO_S3_FAILURE]: (state, action: UploadWSIToS3FailureAction) =>
    produce(state, (draft) => {
      const { payload } = action;
      draft.states[payload.wsiKey].loading = false;
      draft.states[payload.wsiKey].error = payload.error;
    }),
  [UPLOAD_WSI_TO_S3_FILE_PROGRESS]: (
    state,
    action: UploadWSIToS3FileProgressAction
  ) =>
    produce(state, (draft) => {
      const { wsiKey, index, loaded, total } = action.payload;
      draft.states[wsiKey].xhrPool[index].loaded = loaded;
      draft.states[wsiKey].xhrPool[index].total = total;
    }),
  [UPLOAD_WSI_TO_S3_SUCCESS]: (state, action: UploadWSIToS3SuccessAction) =>
    produce(state, (draft) => {
      const { wsiKey } = action.payload;
      draft.states[wsiKey].stage = WSIFileUploadStage.CompleteUploadAPI;
    }),
  [START_ISYNTAX_CONVERSION]: (
    state,
    action: ActionType<typeof startISyntaxConversion>
  ) =>
    produce(state, (draft) => {
      const { wsiKey } = action.payload;
      draft.states[wsiKey].stage = WSIFileUploadStage.ISyntaxConverting;
      draft.states[wsiKey].loading = false;
    }),
  [COMPLETE_WSI_UPLOADING_SUCCESS]: (
    state,
    action: CompleteWSIUploadingSuccessAction
  ) =>
    produce(state, (draft) => {
      const { wsiKey } = action.payload;
      draft.states[wsiKey].stage = WSIFileUploadStage.DONE;
      draft.states[wsiKey].loading = false;
    }),
  [COMPLETE_WSI_UPLOADING_FAILURE]: (
    state,
    action: CompleteWSIUploadingFailureAction
  ) =>
    produce(state, (draft) => {
      const { wsiKey, error } = action.payload;
      draft.states[wsiKey].loading = false;
      draft.states[wsiKey].error = error;
    }),
  [ABORT_SINGLE_WSI_UPLOAD]: (state, action: AbortSingleWSIUploadAction) =>
    produce(state, (draft) => {
      const { wsiKey } = action.payload;
      draft.states[wsiKey].loading = false;
      draft.states[wsiKey].error = WSIFileUploadError.UploadCancelled;
    }),
  [RETRY_SINGLE_WSI_UPLOAD]: (state, action: RetrySingleWSIUploadAction) =>
    produce(state, (draft) => {
      const { wsiKey, addlFiles } = action.payload;
      delete draft.states[wsiKey].error;
      if (addlFiles && addlFiles.length > 0) {
        draft.states[wsiKey].targetFiles =
          draft.states[wsiKey].targetFiles.concat(addlFiles);
      }
    }),
  [GET_ALLOWED_WSI_FORMATS_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.formatsFetchStatus = FetchStatus.Pending;
    }),
  [GET_ALLOWED_WSI_FORMATS_TYPES.SUCCESS]: (
    state,
    action: ReturnType<typeof getAllowedWSIFormats.success>
  ) =>
    produce(state, (draft) => {
      draft.formatsFetchStatus = FetchStatus.Fulfilled;
      draft.allowedFormats = map(action.payload, (apiFormat) => ({
        type: apiFormat.name,
        formats: [apiFormat.ext],
      }));
    }),
  [GET_ALLOWED_WSI_FORMATS_TYPES.FAILURE]: (state) =>
    produce(state, (draft) => {
      draft.formatsFetchStatus = FetchStatus.Rejected;
    }),
  [GET_SLIDE_LIMIT_TYPES.REQUEST]: (state) =>
    produce(state, (draft) => {
      draft.limitFetchStatus = FetchStatus.Pending;
    }),
  [GET_SLIDE_LIMIT_TYPES.SUCCESS]: (
    state,
    action: ReturnType<typeof getSlideLimit.success>
  ) =>
    produce(state, (draft) => {
      draft.limitFetchStatus = FetchStatus.Fulfilled;
      draft.limitSlideCount = action.payload.limitSlideCount;
      draft.currentSlideCount = action.payload.currentSlideCount;
    }),
  [GET_SLIDE_LIMIT_TYPES.FAILURE]: (state) =>
    produce(state, (draft) => {
      draft.limitFetchStatus = FetchStatus.Rejected;
    }),
});

export default wsiUploadReducer;
