import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";
import { persistReducer } from "redux-persist";
import projectsReducer, {
  initialState as projectsInitialState,
} from "./projects/reducers";
import userReducer, { initialState as userInitialState } from "./user/reducers";
import WSIAnalysisResultReducer, {
  initialState as WSIAnalysisResultInitialState,
} from "./WSIAnalysisResult/reducers";
import slideReducer, {
  initialState as slideInitialState,
} from "./slide/reducers";
import sheetsReducer, {
  initialState as sheetsInitialState,
} from "components/Sheets/dux";
import wsiUploadReducer, {
  initialState as wsiUploadInitialState,
} from "components/WSIUpload/dux/reducers";
import tagReducer, { initialState as tagInitialState } from "dux/tags/reducers";
import analysisReducer, {
  initialState as analysisInitialState,
} from "./analysis/reducers";
import snackbarReducer, {
  initialState as snackbarInitialState,
} from "./snackbar/reducers";
import { IO_STORAGE_KEY, persistConfig } from "./utils/persistHelper";
import storage from "redux-persist/lib/storage/session";
import accessTokenManager from "auth/accessTokenManager";
import cancerTypeReducer, {
  initialState as cancerTypeInitialState,
} from "dux/cancerTypes/reducers";

const componentsInitialState = {
  sheets: sheetsInitialState,
  wsiUpload: wsiUploadInitialState,
};

export const initialRootState: RootState = {
  user: userInitialState,
  analysis: analysisInitialState,
  WSIAnalysisResult: WSIAnalysisResultInitialState,
  projects: projectsInitialState,
  slide: slideInitialState,
  components: componentsInitialState,
  tags: tagInitialState,
  snackbar: snackbarInitialState,
  cancerType: cancerTypeInitialState,
};
const componentsReducer = combineReducers({
  sheets: sheetsReducer,
  wsiUpload: wsiUploadReducer,
});

const appReducer = combineReducers({
  user: userReducer,
  analysis: analysisReducer,
  WSIAnalysisResult: WSIAnalysisResultReducer,
  projects: projectsReducer,
  slide: slideReducer,
  components: componentsReducer,
  tags: tagReducer,
  snackbar: snackbarReducer,
  cancerType: cancerTypeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem(IO_STORAGE_KEY);
    accessTokenManager.removeAccessToken();
    accessTokenManager.removeRefreshToken();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = StateType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
