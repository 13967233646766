import { CancerListState, CancerTypesMgmtState } from "dux/cancerTypes/model";
import { FetchStatus } from "dux/utils/commonEnums";
import { ActionType, createReducer, StateType } from "typesafe-actions";
import produce from "immer";
import { combineReducers } from "redux";
import {
  CREATE_CANCER_TYPE_TYPES,
  createCancerType,
  DELETE_CANCER_TYPE_TYPES,
  deleteCancerTypes,
  EDIT_CANCER_TYPE_TYPES,
  editCancerType,
  GET_CANCERS_TYPES,
  getCancers,
  RESET_CREATED_CANCER_TYPE,
} from "./actions";
import { FetchMethod } from "dux/utils/apiRequestHelper";

const cancerListInitialState: CancerListState = {
  fetchStatus: FetchStatus.Idle,
  list: [], //DEFAULT_CANCER_TYPES.map((cancer) => cancer.id),
};

const cancerMgmtInitialState: CancerTypesMgmtState = {
  fetchStatus: FetchStatus.Idle,
  currentMethod: FetchMethod.Get,
  error: "",
};

export const cancerListReducer = createReducer<CancerListState>(
  cancerListInitialState,
  {
    [GET_CANCERS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_CANCERS_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof getCancers.success>
    ) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.list = action.payload;
      }),
    [GET_CANCERS_TYPES.FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.fetchStatus = FetchStatus.Rejected;
      }),
    [EDIT_CANCER_TYPE_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof editCancerType.success>
    ) =>
      produce(state, (draft) => {
        const idx = state.list.findIndex(
          (cancer) => cancer.id === action.payload.id
        );
        if (idx >= 0) {
          draft.list[idx] = action.payload;
        }
      }),
  }
);

export const cancerMgmtStateReducer = createReducer<CancerTypesMgmtState>(
  cancerMgmtInitialState,
  {
    [RESET_CREATED_CANCER_TYPE]: (state) =>
      produce(state, (draft) => {
        if (state.createdCancerType) {
          delete draft.createdCancerType;
        }
        if (state.requestedToDeleteCancerUUID) {
          delete draft.requestedToDeleteCancerUUID;
        }
      }),
    [GET_CANCERS_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Get;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [GET_CANCERS_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Get;
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [GET_CANCERS_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof getCancers.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.currentMethod = FetchMethod.Get;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),
    [CREATE_CANCER_TYPE_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Post;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [CREATE_CANCER_TYPE_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof createCancerType.success>
    ) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Post;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.createdCancerType = action.payload;
      }),
    [CREATE_CANCER_TYPE_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof createCancerType.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.currentMethod = FetchMethod.Post;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),

    [DELETE_CANCER_TYPE_TYPES.REQUEST]: (
      state,
      action: ActionType<typeof deleteCancerTypes.request>
    ) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Pending;
        draft.requestedToDeleteCancerUUID = action.payload;
      }),
    [DELETE_CANCER_TYPE_TYPES.SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Fulfilled;
      }),
    [DELETE_CANCER_TYPE_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof deleteCancerTypes.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.currentMethod = FetchMethod.Delete;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),

    [EDIT_CANCER_TYPE_TYPES.REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Pending;
      }),
    [EDIT_CANCER_TYPE_TYPES.SUCCESS]: (
      state,
      action: ActionType<typeof editCancerType.success>
    ) =>
      produce(state, (draft) => {
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Fulfilled;
        draft.createdCancerType = action.payload;
      }),
    [EDIT_CANCER_TYPE_TYPES.FAILURE]: (
      state,
      action: ActionType<typeof editCancerType.failure>
    ) =>
      produce(state, (draft) => {
        const { payload } = action;
        draft.currentMethod = FetchMethod.Patch;
        draft.fetchStatus = FetchStatus.Rejected;
        draft.error = payload;
      }),
  }
);

const cancerTypeReducer = combineReducers({
  cancers: cancerListReducer,
  cancerMgmtState: cancerMgmtStateReducer,
});

export const initialState = {
  cancers: cancerListInitialState,
  cancerMgmtState: cancerMgmtInitialState,
};

export type CancerTypeState = StateType<typeof cancerTypeReducer>;

export default cancerTypeReducer;
