import React, { useMemo } from "react";
import { Theme, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { SlideItem, SlideStatus, SlideUUID } from "dux/slide/model";
import { Tag } from "dux/tags/model";
import { Project } from "dux/projects/model";
import { slideStatusColorProps, slideStatusLabels } from "./common/literals";
import SlideTagChip, {
  GetChipProps,
} from "components/SlideList/common/SlideTagChip";
import { SlideContextMenuProps } from "components/common/SlideContextMenu";
import {
  CancerType,
  IOResultCell,
  ProjectChips,
  SlideMenu,
  SlideNameCell,
  TagChips,
} from "./component/Columns";
import { DateFormatter, DATETIME_FORMAT } from "utils/format";
import DataGridContainer from "components/common/DataGridContainer";
import { APICancerType } from "dux/cancerTypes/model";

interface ISlideTableProps {
  slides: SlideItem[];
  selectedSlides: SlideItem[];
  handleEditSlide?: SlideContextMenuProps["onEdit"];
  handleDeleteSlide?: SlideContextMenuProps["onDelete"];
  handleSlideInfo?: SlideContextMenuProps["handleInfo"];
  handleRowSelect?: (items: SlideItem[]) => void;
  handleFailedInfo?: (id: SlideUUID) => void;
  totalProjects: Project[];
  totalTags: Tag[];
  totalCancerTypes: APICancerType[];
  pageSize: number;
  handleSelectedList: () => void;
  handleOpenSlideViewer: (item: SlideItem) => void;
  handleEditCancerType: (targetSlides: SlideItem[]) => void;
}

const getColumns = ({
  handleDeleteSlide,
  handleEditSlide,
  handleSlideInfo,
  handleFailedInfo,
  totalProjects,
  totalTags,
  totalCancerTypes,
  handleOpenSlideViewer,
  handleSelectedList,
  theme,
  handleEditCancerType,
}: Pick<
  ISlideTableProps,
  | "handleDeleteSlide"
  | "handleEditSlide"
  | "handleSlideInfo"
  | "totalProjects"
  | "totalTags"
  | "totalCancerTypes"
  | "handleFailedInfo"
  | "handleOpenSlideViewer"
  | "handleSelectedList"
  | "handleEditCancerType"
> & { theme: Theme }): GridColDef[] => [
  {
    field: "status",
    headerName: "Status",
    headerClassName: "header",
    width: 130,
    sortable: false,
    renderCell: (params) => {
      return (
        <SlideTagChip
          onClick={() =>
            (params.value === SlideStatus.PreAnalFailed ||
              params.value === SlideStatus.AnalFailed) &&
            handleFailedInfo(params.row.id)
          }
          clickable={
            params.value === SlideStatus.PreAnalFailed ||
            params.value === SlideStatus.AnalFailed
          }
          label={slideStatusLabels(params.value)}
          {...GetChipProps(params.value, theme)}
          color={slideStatusColorProps(params.value)}
        />
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "header",
    sortable: false,
    flex: 3,
    renderCell: (params) => {
      return (
        <SlideNameCell
          value={params.value}
          slide={params.row}
          handleSelectedList={handleSelectedList}
          handleOpenSlideViewer={handleOpenSlideViewer}
        />
      );
    },
  },
  {
    field: "cancerType",
    headerName: "Cancer Type",
    headerClassName: "header",
    sortable: false,
    flex: 1,
    renderCell: ({ value, row, api }: GridRenderCellParams) => {
      return (
        <CancerType
          value={value}
          slide={row}
          api={api}
          cancerTypes={totalCancerTypes}
          handleSelectedList={handleSelectedList}
          handleEditCancerType={handleEditCancerType}
        />
      );
    },
  },
  {
    field: "ioResult",
    headerName: "IO Result",
    headerClassName: "header",
    headerAlign: "right",
    align: "right",
    width: 135,
    sortable: false,
    renderCell: (params) => {
      return <IOResultCell value={params.value} />;
    },
  },
  {
    field: "projects",
    headerName: "Projects",
    headerClassName: "header",
    width: 159,
    sortable: false,
    renderCell: ({ value, row, api }) => {
      return (
        <ProjectChips
          value={value}
          slide={row}
          api={api}
          totalProjects={totalProjects}
        />
      );
    },
  },
  {
    field: "tags",
    headerName: "Tags",
    headerClassName: "header",
    width: 159,
    sortable: false,
    renderCell: ({ value, row, api }) => {
      return (
        <TagChips value={value} slide={row} api={api} totalTags={totalTags} />
      );
    },
  },
  {
    field: "statusUpdatedAt",
    headerName: "Status updated Date",
    headerClassName: "header",
    width: 140,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body6" color="darkGrey.30">
          {DateFormatter(params.value, DATETIME_FORMAT)}
        </Typography>
      );
    },
  },
  {
    field: "uploadedAt",
    headerName: "Slide upload Date",
    headerClassName: "header",
    width: 168,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <SlideMenu
          row={params.row}
          value={params.value}
          handleInfo={handleSlideInfo}
          onDelete={handleDeleteSlide}
          onEdit={handleEditSlide}
        />
      );
    },
  },
];

function SlideTable({
  handleDeleteSlide,
  handleEditSlide,
  handleSlideInfo,
  handleRowSelect,
  handleFailedInfo,
  slides,
  totalProjects,
  totalTags,
  totalCancerTypes,
  pageSize,
  selectedSlides,
  handleOpenSlideViewer,
  handleSelectedList,
  handleEditCancerType,
}: ISlideTableProps) {
  const theme = useTheme();
  const columns = useMemo(
    () =>
      getColumns({
        handleEditSlide,
        handleDeleteSlide,
        handleSlideInfo,
        handleFailedInfo,
        totalProjects,
        totalTags,
        totalCancerTypes,
        handleOpenSlideViewer,
        handleSelectedList,
        theme,
        handleEditCancerType,
      }),
    [
      handleEditSlide,
      handleDeleteSlide,
      handleSlideInfo,
      handleFailedInfo,
      totalProjects,
      totalTags,
      totalCancerTypes,
      handleOpenSlideViewer,
      handleSelectedList,
      theme,
      handleEditCancerType,
    ]
  );
  return (
    <DataGridContainer>
      <DataGrid
        rows={slides}
        columns={columns}
        headerHeight={40}
        rowHeight={40}
        pageSize={pageSize}
        showColumnRightBorder={true}
        showCellRightBorder={true}
        disableColumnMenu={true}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedSlides.map((slide) => slide.id)}
        onSelectionModelChange={(selectedSlides) => {
          const ids = new Set(selectedSlides);
          handleRowSelect(slides.filter((value) => ids.has(value.id)));
        }}
        components={{
          NoRowsOverlay: () => <></>,
        }}
        hideFooter
      />
    </DataGridContainer>
  );
}

export default React.memo(SlideTable);
