import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { zoomSelector } from "dux/analysis/selectors";
import { setZoom } from "dux/analysis/actions";
import { Button, PopperProps, Tooltip, Typography } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import PlusIcon from "@mui/icons-material/AddOutlined";
import MinusIcon from "@mui/icons-material/RemoveOutlined";
import { useHotkeys } from "react-hotkeys-hook";

interface ZoomControllerProps {
  noSubdrawer?: boolean;
}

const ZoomWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "noSubdrawer",
})<ZoomControllerProps>(({ noSubdrawer, theme }) => ({
  position: "absolute",
  bottom: 70,
  right: `calc(16px + ${theme.subDrawer.width}px)`,
  ...(noSubdrawer && {
    right: "20px",
  }),
}));

const SlideContainer = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette.darkGrey[80],
  color: theme.palette.darkGrey[10],
}));

const LabelController = styled("div")({
  color: "#7292FD",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

const ZoomButton = styled(Button)({
  width: "42px",
  height: "32px",
  borderRadius: "8px",
  minWidth: "inherit",
  color: "white",
  "&:hover": {
    backgroundColor: "#587EFC",
  },
});

const ZoomToggleButton = styled(ToggleButton)({
  height: "32px",
  border: "none",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  "&:hover": {
    backgroundColor: "#587EFC",
  },
  "&.Mui-selected": {
    backgroundColor: "#587EFC",
  },
});

const zoomLevelLabels = [0.1, 0.5, 1, 2, 5, 10, 20, 40, 160];

const POPPER_PROPS: Partial<PopperProps> = {
  popperOptions: {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-10, 0],
        },
      },
    ],
  },
};

const ZoomController = ({ noSubdrawer }: ZoomControllerProps) => {
  const zoomState = useSelector(zoomSelector);
  const dispatch = useDispatch();
  const [tmpZoomValue, setTmpZoomValue] = useState(1);
  const [levelLabelHidden, setLevelLabelHidden] = useState(false);
  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: number) => {
      if (newValue === null) return;
      dispatch(setZoom(newValue || 0.1));
    },
    [dispatch]
  );

  const getClosestZoomLevel = useCallback(
    (target: number) =>
      zoomLevelLabels.reduce((prev, curr) =>
        Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
      ),
    []
  );

  useEffect(() => {
    // To subscribe the updated zoom state and update tmpZoomValue correspondingly.
    // Mostly used when user tries to zoom in/out
    // \w a device such as a mouse wheel or trackpad
    const closest = getClosestZoomLevel(zoomState);
    setTmpZoomValue(closest);
  }, [zoomState, getClosestZoomLevel]);

  const handleZoomIn = useCallback(() => {
    const currentIdx = zoomLevelLabels.findIndex(
      (level) => level === tmpZoomValue
    );
    if (currentIdx === zoomLevelLabels.length - 1) {
      return;
    }
    const nextIdx = currentIdx + 1;
    return dispatch(setZoom(zoomLevelLabels[nextIdx]));
  }, [tmpZoomValue, dispatch]);

  const handleZoomOut = useCallback(() => {
    const currentIdx = zoomLevelLabels.findIndex(
      (level) => level === tmpZoomValue
    );
    if (currentIdx === 0) {
      return;
    }
    const nextIdx = currentIdx - 1;
    return dispatch(setZoom(zoomLevelLabels[nextIdx]));
  }, [tmpZoomValue, dispatch]);

  useHotkeys(
    "=,shift+=,num_add",
    (keyboardEvent) => {
      keyboardEvent.preventDefault();
      handleZoomIn();
    },
    [handleZoomIn]
  );

  useHotkeys(
    "-,num_subtract",
    (keyboardEvent) => {
      keyboardEvent.preventDefault();
      handleZoomOut();
    },
    [handleZoomOut]
  );

  useHotkeys(
    "1,2,3,4,5,6,7,num_1,num_2,num_3,num_4,num_5,num_6,num_7",
    (keyboardEvent) => {
      keyboardEvent.preventDefault();
      const pressedNum = Number(keyboardEvent.key);
      if (pressedNum % 8 >= 0) {
        dispatch(setZoom(zoomLevelLabels[pressedNum % 8]));
      }
    },
    [setTmpZoomValue, dispatch]
  );

  return (
    <ZoomWrapper noSubdrawer={noSubdrawer}>
      <SlideContainer>
        <ToggleButtonGroup
          size="small"
          orientation="vertical"
          value={tmpZoomValue.toString()}
          exclusive
          onChange={handleChange}
        >
          <Tooltip
            placement="left"
            PopperProps={POPPER_PROPS}
            title={
              <div>
                Zoom{" "}
                <LabelController
                  onClick={() => setLevelLabelHidden(!levelLabelHidden)}
                >
                  {levelLabelHidden ? "Show" : "Hide"} labels
                </LabelController>
              </div>
            }
            aria-label="zoom-in"
          >
            <ZoomButton
              aria-label="zoom-in"
              onClick={handleZoomIn}
              color="inherit"
            >
              <PlusIcon />
            </ZoomButton>
          </Tooltip>
          {!levelLabelHidden &&
            zoomLevelLabels
              // https://stackoverflow.com/questions/5024085/whats-the-point-of-slice0-here
              .slice(0)
              .reverse()
              .map((level, index) => (
                <ZoomToggleButton
                  key={`zoom-level-${level}`}
                  onClick={() => setTmpZoomValue(level)}
                  value={level.toString()}
                  aria-label={level.toString()}
                >
                  <Typography variant="caption">
                    {level <= 40 && index < zoomLevelLabels.length - 1
                      ? `x${level}`
                      : level > 40
                      ? "Max"
                      : "Min"}
                  </Typography>
                </ZoomToggleButton>
              ))}
          <Tooltip
            placement="left"
            PopperProps={POPPER_PROPS}
            title={
              <div>
                Zoom{" "}
                <LabelController
                  onClick={() => setLevelLabelHidden(!levelLabelHidden)}
                >
                  {levelLabelHidden ? "Show" : "Hide"} labels
                </LabelController>
              </div>
            }
            aria-label="zoom-out"
          >
            <ZoomButton
              aria-label="zoom-out"
              onClick={handleZoomOut}
              color="inherit"
            >
              <MinusIcon />
            </ZoomButton>
          </Tooltip>
        </ToggleButtonGroup>
      </SlideContainer>
    </ZoomWrapper>
  );
};

export default ZoomController;
