import { FetchMethod, request } from "dux/utils/apiRequestHelper";
import { APITagInput, SearchTagsParams, Tag, TagUUID } from "dux/tags/model";

const encode = encodeURIComponent;
export const getTags = (payload: SearchTagsParams) =>
  request({
    method: FetchMethod.Get,
    path: "tags" + (payload.title ? `?title=${encode(payload.title)}&` : ""),
  });

export const createTag = (payload: APITagInput) =>
  request({
    method: FetchMethod.Post,
    path: `tags`,
    payload,
  });

export const deleteTags = (payload: TagUUID[]) =>
  request({
    method: FetchMethod.Delete,
    path: `tags`,
    skipJson: true,
    payload,
  });

export const editTag = (payload: Tag) =>
  request({
    method: FetchMethod.Put,
    path: `tags/${payload.id}`,
    payload: {
      title: payload.title,
      description: payload.description,
    },
  });
