import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import HeaderButton from "./HeaderButton";
import toUpper from "lodash/toUpper";
import SettingsDialog from "components/SettingsDialog";
import { useState } from "react";

const AvatarBackground = styled("div")(({ theme }) => ({
  width: 28,
  height: 28,
  borderRadius: 14,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.darkGrey[0],
  fontSize: 16,
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface AvatarButtonProps {
  name: string;
}

const Avatar = ({ name }: AvatarButtonProps) => {
  return <AvatarBackground>{toUpper(name[0])}</AvatarBackground>;
};

const AvatarButton = ({ name }: AvatarButtonProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  return (
    <>
      <Tooltip title={name}>
        <HeaderButton
          sx={{ paddingRight: "18px" }}
          hasMenu
          onClick={() => setSettingsOpen(true)}
        >
          <Avatar name={name} />
        </HeaderButton>
      </Tooltip>
      <SettingsDialog
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
    </>
  );
};

export default AvatarButton;
