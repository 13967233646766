import { checkEmpty } from "dux/slide/api";
import {
  request,
  FetchMethod,
  DEFAULT_TOKEN_EXP_SEC,
} from "dux/utils/apiRequestHelper";
import reduce from "lodash/reduce";
import {
  AccountProfile,
  APIDeregisterMFAParams,
  APIForgotPasswordParams,
  APILoginUserParams,
  APIPasswordParams,
  APIRegisterMFAParams,
  APIResetPasswordParams,
  APIUserInput,
  APIVerifyValidKeyParmas,
  APIVerifyMFAParams,
  UserListRequestParams,
  UserUUID,
  APIRefreshTokenParams,
  APINotiSetting,
  APIUpdateUsersMFAParams,
  UpdateUserProjectMappingsParams,
} from "./model";

export interface LoginRequestParams {
  id: string;
  password: string;
}
const encode = encodeURIComponent;

export const getCurrentUser = () =>
  request({
    method: FetchMethod.Get,
    path: "users/me",
  });

export const getAllUsers = (payload: UserListRequestParams) =>
  request({
    method: FetchMethod.Get,
    path:
      `users/members/pages?` +
      checkEmpty(payload.word, `word=${encode(payload.word)}&`) +
      `page=${payload.page}&size=${payload.size}&` +
      `sort=${payload.sort}`,
  });

export const getEmailExists = (email: string) =>
  request({
    method: FetchMethod.Get,
    path: `users/exists/email?user_email=${encodeURIComponent(email)}`,
  });

export const createUser = (payload: APIUserInput) => {
  return request({
    method: FetchMethod.Post,
    skipJson: true,
    path: `users/members`,
    payload,
  });
};

export const updateUser = (payload: APIUserInput) =>
  request({
    method: FetchMethod.Put,
    skipJson: true,
    path: `users/members`,
    payload,
  });

export const deleteUsers = (ids: UserUUID[]) =>
  request({
    method: FetchMethod.Delete,
    skipJson: true,
    path: `users/members?ids=${ids}`,
  });

export const updatePassword = (payload: APIPasswordParams) =>
  request({
    method: FetchMethod.Post,
    path: "users/me/password",
    skipJson: true,
    payload,
  });

export const updateProfile = (payload: Partial<AccountProfile>) =>
  request({
    method: FetchMethod.Put,
    path: "users/me",
    skipJson: true,
    payload,
  });

export const loginUser = (payload: APILoginUserParams) =>
  request({
    method: FetchMethod.Post,
    path: "users/login",
    payload: {
      ...payload,
      client_id: "d65377c1-aecb-4b46-82a2-d07a7a0d8f66",
      client_secret: "user",
      exp_sec: DEFAULT_TOKEN_EXP_SEC,
    },
    skipAuth: true,
  });

export const refreshToken = (payload: APIRefreshTokenParams) =>
  request({
    method: FetchMethod.Post,
    path: "users/token/refresh",
    payload,
    skipAuth: true,
  });

export const forgotPassword = (payload: APIForgotPasswordParams) =>
  request({
    method: FetchMethod.Post,
    path: `users/reset-password?email=${payload.email}`,
    skipAuth: true,
    skipJson: true,
  });

export const resetPassword = (payload: APIResetPasswordParams) =>
  request({
    method: FetchMethod.Put,
    path: `account/reset-password?key=${encodeURIComponent(payload.key)}`,
    payload,
    skipJson: true,
    skipAuth: true,
  });

export const verifyValidKey = (payload: APIVerifyValidKeyParmas) =>
  request({
    method: FetchMethod.Get,
    path: `account/key/verify?key=${encodeURIComponent(payload.key)}&purpose=${
      payload.purpose
    }`,
    skipJson: true,
    skipAuth: true,
  });

export const requestMFAQRCode = () =>
  request({
    method: FetchMethod.Post,
    path: "users/mfa",
  });

export const registerMFA = (payload: APIRegisterMFAParams) =>
  request({
    method: FetchMethod.Post,
    path: "auth/mfa",
    payload,
  });

export const requestResetMFA = () =>
  request({
    method: FetchMethod.Delete,
    path: "users/deregister-mfa",
    skipJson: true,
  });

export const deregisterMFA = (payload: APIDeregisterMFAParams) =>
  request({
    method: FetchMethod.Put,
    path: `account/deregister-mfa?key=${encodeURIComponent(payload.key)}`,
    payload,
    skipJson: true,
    skipAuth: true,
  });

export const verifyMFA = (payload: APIVerifyMFAParams) =>
  request({
    method: FetchMethod.Post,
    path: "auth/mfa/verify",
    payload,
  });

export const getNotiSettings = () =>
  request<APINotiSetting[]>({
    method: FetchMethod.Get,
    path: "notification-settings",
  });

export const updateNotiSettings = (payload: APINotiSetting[]) =>
  request<{}>({
    method: FetchMethod.Put,
    path: "notification-settings",
    payload,
  });

export const deleteMFA = () =>
  request({
    method: FetchMethod.Delete,
    path: "users/mfa",
    skipJson: true,
  });

export const updateUsersMFA = (payload: APIUpdateUsersMFAParams) =>
  request({
    method: FetchMethod.Put,
    path: "users/customer/mfa",
    payload,
    skipJson: true,
  });

export const getUserProjects = (payload: UserUUID[]) => {
  const userIdsParam = reduce(
    payload,
    (param, id) => (param ? `${param},${id}` : id),
    ""
  );
  return request({
    method: FetchMethod.Get,
    path: `projects/mapping/users?user_ids=${encodeURIComponent(userIdsParam)}`,
    skipCamelize: true,
  });
};

export const updateUserProjectMappings = (
  payload: UpdateUserProjectMappingsParams
) =>
  request({
    method: FetchMethod.Post,
    path: `projects/mapping/users`,
    payload: payload,
  });
