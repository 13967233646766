import { useEffect, useState } from "react";
import { Link } from "@mui/material";
import AuthAlert from "./AuthAlert";
import AuthForm from "./AuthForm";
import AuthHeading from "./AuthHeading";
import AuthNav from "./AuthNav";
import { deregisterMFA, verifyValidKey } from "dux/user/api";
import { ResetMFALocation } from "dux/user/model";
import useQueryParams from "components/hooks/useQueryParams";
import { AccountProfile, ResetMFAPathParams } from "dux/user/model";
import { useParams, useLocation } from "react-router-dom";

export const MFA = "MFA";

export default function ResetMFAContent() {
  const location = useLocation<ResetMFALocation>();
  const [success, setSuccess] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const byPassState = location === undefined ? false : location.state;
  const { key } = useParams<ResetMFAPathParams>();

  const { email: userEmail } = useQueryParams<AccountProfile>();

  useEffect(() => {
    if (key === "reset" && byPassState) {
      setSuccess(true);
      setCompleted(true);
      return;
    }
    (async () => {
      try {
        await verifyValidKey({ key, purpose: MFA });
        await deregisterMFA({ key });
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
      } finally {
        setCompleted(true);
      }
    })();
  }, [key, byPassState]);

  return (
    <AuthForm>
      <AuthHeading>MFA device deregistration notification</AuthHeading>
      {completed && success && (
        <AuthAlert severity="success">
          We have successfully deregistered MFA device for {userEmail}. Please
          try login again and register your new MFA device.
        </AuthAlert>
      )}
      {completed && !success && (
        <AuthAlert severity="error">
          We have failed to de-register MFA device for {userEmail}. Please
          contact customer service.
        </AuthAlert>
      )}
      <AuthNav>
        {completed && !success && (
          <Link href="mailto:cs-scope-io@lunit.io">
            Contact customer service
          </Link>
        )}
        <Link href="/login">Back to log in</Link>
      </AuthNav>
    </AuthForm>
  );
}
