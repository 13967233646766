import { useCallback, useEffect, useState } from "react";
import {
  TextField,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "dux/user/actions";
import { getSettingsStateSelector } from "dux/user/selectors";
import { FetchStatus } from "dux/utils/commonEnums";
import DialogActionsMessage from "components/Dialog/DialogActionsMessage";

interface PasswordFormProps {
  onCancel: () => void;
}

const SettingsFormRow = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:not(:first-of-type)": {
    marginTop: "inherit",
  },
}));

export default function PasswordForm({ onCancel }: PasswordFormProps) {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, getValues, reset, setError } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const settingsState = useSelector(getSettingsStateSelector);

  // SettingsDailog의 Form별로 Idle 상태를 분리 (settingsState.fetchStatus는 모든 Form에서 공유됨)
  const [firstrun, setFirstrun] = useState<boolean>(true);

  const isSuccess =
    !firstrun && settingsState.fetchStatus === FetchStatus.Fulfilled;
  const hasError =
    !firstrun && settingsState.fetchStatus === FetchStatus.Rejected;
  const error = settingsState.error;

  const onSubmit = useCallback(
    (data) => {
      dispatch(updatePassword.request(data));
      setFirstrun(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (!hasError) {
      return;
    }
    if (error.details && error.details.includes("password is invalid")) {
      setError("currentPassword", {
        type: "manual",
        message: "Incorrect password",
      });
    }
    if (
      error.details &&
      error.details.includes("New password should contain")
    ) {
      setError("newPassword", {
        type: "manual",
        message:
          "New password should contain an alphabet letter, a number, and a special character($@%^&*!#), and it should be 8-20 characters long.",
      });
    }
  }, [hasError, error, setError]);

  useEffect(() => {
    if (settingsState.success) {
      reset();
    }
  }, [reset, settingsState]);

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderTop: "none",
          width: "100%",
        }}
      >
        <SettingsFormRow>
          <InputLabel htmlFor="currentPassword" required>
            <Typography color="#fff" variant="body3">
              Current password
            </Typography>
          </InputLabel>
          <TextField
            id="currentPassword"
            name="currentPassword"
            type="password"
            variant="outlined"
            autoComplete="off"
            inputRef={register({
              required: "Please fill out this field",
            })}
            error={!!formState.errors.currentPassword}
            helperText={
              formState.errors.currentPassword &&
              formState.errors.currentPassword.message
            }
          />
        </SettingsFormRow>
        <SettingsFormRow>
          <InputLabel htmlFor="newPassword" required>
            <Typography color="#fff" variant="body3">
              New password
            </Typography>
          </InputLabel>
          <TextField
            id="newPassword"
            name="newPassword"
            type="password"
            variant="outlined"
            autoComplete="new-password"
            inputRef={register({
              required: "Please fill out this field",
              validate: {
                newPasswordNotMatch: (value) => {
                  const currentPassword = getValues("currentPassword");
                  return (
                    value !== currentPassword ||
                    "New password must not match the current password"
                  );
                },
              },
            })}
            error={!!formState.errors.newPassword}
            helperText={
              formState.errors.newPassword &&
              formState.errors.newPassword.message
            }
          />
        </SettingsFormRow>
        <SettingsFormRow>
          <InputLabel htmlFor="confirmNewPassword" required>
            <Typography color="#fff" variant="body3">
              Confirm new password
            </Typography>
          </InputLabel>
          <TextField
            id="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            variant="outlined"
            autoComplete="new-password"
            inputRef={register({
              required: "Please fill out this field",
              validate: {
                confirmPasswordMatches: (value) => {
                  const newPassword = getValues("newPassword");
                  return (
                    value === newPassword ||
                    "Confirm password must match the new password"
                  );
                },
              },
            })}
            error={!!formState.errors.confirmNewPassword}
            helperText={
              formState.errors.confirmNewPassword &&
              formState.errors.confirmNewPassword.message
            }
          />
        </SettingsFormRow>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          "& > button": {
            padding: (theme) => theme.spacing(1, 2),
            width: 96,
            height: 36,
          },
        }}
      >
        {isSuccess && <DialogActionsMessage>Change saved</DialogActionsMessage>}
        {hasError && (
          <DialogActionsMessage error={true}>
            Failed to save change. Please try again
          </DialogActionsMessage>
        )}
        <Button
          color="primary"
          onClick={onCancel}
          disabled={settingsState.loading}
        >
          <Typography variant="button">Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={settingsState.loading || !formState.isDirty}
        >
          <Typography variant="button">Confirm</Typography>
        </Button>
      </DialogActions>
    </form>
  );
}
