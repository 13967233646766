/**
 * Slide information
 */
import { Project, ProjectUUID } from "dux/projects/model";
import { FetchStatusResult, Paginate } from "dux/utils/commonModels";
import { apiSlideStatusToEnum } from "components/SlideList/common/literals";
import { Tag, TagUUID } from "dux/tags/model";
import { FetchMethod } from "dux/utils/apiRequestHelper";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { SortOrder } from "components/Forms/SortField";
import { useHistory } from "react-router-dom";
import { FetchStatus } from "dux/utils/commonEnums";
import { APICancerType } from "dux/cancerTypes/model";

export type SlideName = string;

type Mpp = number;
export const DEFAULT_MPP = 0.12126736;

export interface SlideMeta {
  dziUrl?: string;
  globUrl?: string;
  tileIndexUrl?: string;
}

export interface APISlideMeta extends SlideMeta {
  mppX: Mpp;
  fileName: SlideName;
}

export interface Slide {
  name: SlideName;
  mpp: Mpp;
  metadata: SlideMeta;
}

export enum SlideStatus {
  Created = "Created",
  PreAnalyzing = "PreAnalyzing",
  Ready = "Ready",
  Analyzing = "Analyzing",
  PreAnalFailed = "PreAnalFailed",
  AnalFailed = "Failed",
  Analyzed = "Analyzed",
}

export interface FilterDrawerParams {
  slideName: string;
  fromDate?: string;
  toDate?: string;
  cancerTypes?: APICancerType[];
  ioResult?: IoResultType;
  dateValue: DateRange<Date>;
  selectedStatuses: SlideStatus[]; // at least an empty array
  selectedTags: Tag[]; //at least an empty array
  selectedProjects: Project[]; //at least an empty array
  dateType: SlideDateType; //NEED UPPER CASED STRING
  sort: string;
  sortOrder: SortOrder;
  selectedPage: number;
  selectedPerPage: number;
}

export enum SlideDateType {
  UploadedAt = "UPLOADED_AT",
  UpdatedAt = "UPDATED_AT",
}

export enum IoResultType {
  Positive = "Positive",
  Negative = "Negative",
  QCFailed = "QC failed",
}

export type SlideUUID = string;

export enum SlideTpsType {
  AI,
  User,
}

export interface IOResult {
  ioType?: IoResultType;
  score?: number;
}

export interface SlideItem {
  id: SlideUUID;
  wsiId?: SlideUUID;
  status: SlideStatus;
  name: string;
  fileName: string;
  filePath?: string;
  cancerType: string;
  uploadedBy?: string;
  uploadedAt: string;
  ioResult?: IOResult;
  projects: ProjectUUID[];
  tags: TagUUID[];
  properties: SlideProperties;
  thumbnailPath?: string;
  statusUpdatedAt?: string;
}

export interface APISlideSummary {
  id: SlideUUID;
  wsiId: SlideUUID;
  customerId?: string;
  customerCode?: string;
  fileName: string;
  name: string;
  filePath?: string;
  uploadedBy?: string;
  uploadedAt?: string;
  properties?: SlideProperties;
  status?: string;
  ttr?: string;
  statusUpdatedAt?: string;
  projectIds?: ProjectUUID[];
  tagIds?: TagUUID[];
  thumbnailPath?: string;
  cancerType: string;
  io?: boolean;
  score?: number;
  qualityControl?: boolean;
}

export interface APIUpdatedSlideSummary extends APISlideSummary {
  updatedAt?: string;
  mappedProjects: ProjectUUID[];
  mappedTags: TagUUID[];
}

export interface SlideProperties {
  id: string;
  mppX: number;
  mppY: number;
  objectivePower: number;
  backgroundColor: number;
  comment: string;
  vendor: string;
  quickhash_1: string;
  boundsX: number;
  boundsY: number;
  boundsWidth: number;
  boundsHeight: number;
  thumbnailFilePath: string;
}

const convertAPISlideSummaryToItem = (
  slideSummary: APISlideSummary
): SlideItem => ({
  id: slideSummary.id,
  wsiId: slideSummary.wsiId,
  status: apiSlideStatusToEnum(slideSummary.status),
  name: slideSummary.name,
  fileName: slideSummary.fileName,
  filePath: slideSummary.filePath,
  uploadedAt: slideSummary.uploadedAt,
  uploadedBy: slideSummary.uploadedBy,
  cancerType: slideSummary.cancerType,
  ioResult: {
    ioType:
      slideSummary.qualityControl === false
        ? IoResultType.QCFailed
        : slideSummary.io === true
        ? IoResultType.Positive
        : slideSummary.io === false
        ? IoResultType.Negative
        : null,
    score: slideSummary.score,
  },
  projects: slideSummary.projectIds ? slideSummary.projectIds : [],
  tags: slideSummary.tagIds ? slideSummary.tagIds : [],
  properties: slideSummary.properties,
  thumbnailPath: slideSummary.thumbnailPath,
  statusUpdatedAt: slideSummary.statusUpdatedAt,
});

export const convertPaginatedAPISlideSummariesToItems = (
  slideSummaries: APISlideSummaryList
): SlideItemList => ({
  totalPages: slideSummaries.totalPages,
  totalElements: slideSummaries.totalElements,
  last: slideSummaries.last,
  first: slideSummaries.first,
  size: slideSummaries.size,
  contents: slideSummaries.contents.map((slide) =>
    convertAPISlideSummaryToItem(slide)
  ),
});

export interface SearchSlideSummariesParams {
  slideName?: string;
  dateType?: string;
  fromDate?: string;
  toDate?: string;
  tagIds?: TagUUID[];
  projectIds?: ProjectUUID[];
  statusList?: SlideStatus[];
  cancerType?: number[];
  ioResult?: boolean;
  qualityControl?: boolean;
  page?: number;
  size?: number;
  sort: string;
  sortOrder: SortOrder;
}

export interface APISlideSummaryList extends Paginate<APISlideSummary> {}
export interface SlideItemList extends Paginate<SlideItem> {}

export interface UpdateSlideSummaryParams {
  wsiId: SlideUUID;
  name: string;
  id?: string;
  tagIds?: TagUUID[];
  projectIds?: ProjectUUID[];
  cancerType?: string;
}

export interface BulkUpdateCancerTypesParams {
  cancerType: string;
  slideIds: SlideUUID[];
}

export interface SlideSummaryUpdateState {
  updated?: APIUpdatedSlideSummary;
}

export interface SlideMgmtState extends FetchStatusResult {
  currentMethod: FetchMethod;
}

export interface RequestSlidesAnalyzeParams {
  sourceIds: SlideUUID[];
}

export interface SlideAnalyzeRequestFetchState extends FetchStatusResult {}

export interface SlideViewerListParams {
  selectedSlides?: SlideItem[];
}

export interface OpenSlideViewerParams extends SlideViewerListParams {
  history: ReturnType<typeof useHistory>;
  slide: SlideItem;
  projectId?: ProjectUUID;
}

export interface UpdateCancerTypePayload {
  id: string;
  cancerType: string;
}

export interface SlideErrorInfo {
  id: SlideUUID;
  status: string;
  message: {
    version: string;
    code: string;
    message: string;
    details: string;
  };
  fetchStatus: FetchStatus;
  error?: string;
}
