import { action } from "typesafe-actions";
import {
  asyncActionCreator,
  asyncAction,
} from "dux/utils/actionHandlingHelper";
import { ProjectUUID } from "dux/projects/model";
import {
  APIWSIList,
  APIWSIItem,
  AnalysisId,
  APIWSIAnaysisSummary,
  APIWSIAnaysisErrorMsg,
  APIWSIReport,
  FileName,
  WSIId,
  APIWSIMetaInfo,
  CancerName,
  APIDetailedAnalysisResult,
  APISimpleSlideItem,
  SlideSummaryItem,
} from "./model";

/**
 * Action Creators
 * Usage: getWSIList[type: request | success | failure](params)
 */
export interface WSIListRequestParam {
  proj_id: ProjectUUID;
  page?: number;
  keyword?: string;
  from?: string;
  to?: string;
}
export const GET_WSI_LIST_TYPES = asyncActionCreator("GET_WSI_LIST");
export const getWSIList = asyncAction<WSIListRequestParam, APIWSIList, string>(
  GET_WSI_LIST_TYPES
);

/**
 * Action Creators
 * Usage: getWSIAnalysisResultSummaryById[type: request | success | failure](params)
 */
export const GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES = asyncActionCreator(
  "GET_WSI_ANALYSIS_RESULT_SUMMARY"
);

interface SuccessSummayPayload extends APIWSIAnaysisSummary {
  wsiId: WSIId;
}
export const getWSIAnalysisResultSummaryById = asyncAction<
  WSIId,
  SuccessSummayPayload,
  string
>(GET_WSI_ANALYSIS_RESULT_SUMMARY_TYPES);

/**
 * Action Creators
 * Usage: getWSIAnalysisResultErrorMsgById[type: request | success | failure](params)
 */
export const GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES = asyncActionCreator(
  "GET_WSI_ANALYSIS_RESULT_ERROR_MSG"
);

interface ErrorMsgPayload extends APIWSIAnaysisErrorMsg {
  wsiId: WSIId;
}
export const getWSIAnalysisResultErrorMsgById = asyncAction<
  WSIId,
  ErrorMsgPayload,
  string
>(GET_WSI_ANALYSIS_RESULT_ERROR_MSG_TYPES);

/**
 * Action Creators
 * Usage: getDetailedAnalysisResultById[type: request | success | failure](params)
 */
export const GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES = asyncActionCreator(
  "GET_ANALYSIS_SOURCE_DETAILS_BY_ID"
);
export const getAnalysisSourceDetailsById = asyncAction<
  WSIId,
  APIDetailedAnalysisResult,
  string
>(GET_ANALYSIS_SOURCE_DETAILS_BY_ID_TYPES);

/**
 * Action Creators
 * Usage: getWSIAnalysisResultReportById[type: request | success | failure](params)
 */
export const GET_WSI_ANALYSIS_RESULT_REPORT_TYPES = asyncActionCreator(
  "GET_WSI_ANALYSIS_RESULT_REPORT"
);
export const getWSIAnalysisResultReportById = asyncAction<
  AnalysisId,
  APIWSIReport,
  AnalysisId
>(GET_WSI_ANALYSIS_RESULT_REPORT_TYPES);

/**
 * Action Creators
 * Usage: completeWSIFileUploading[type: request | success | failure](params)
 */
export interface CompleteUploadingPayload {
  fileName: FileName;
  wsiId: WSIId;
}
export const COMPLETE_WSI_FILE_UPLOADING_TYPES = asyncActionCreator(
  "COMPLETE_WSI_FILE_UPLOADING"
);
export const completeWSIFileUploading = asyncAction<
  CompleteUploadingPayload,
  APIWSIItem,
  string
>(COMPLETE_WSI_FILE_UPLOADING_TYPES);

/**
 * Action Creators
 * Usage: updateWSIMetaInfo[type: request | success | failure](params)
 * Can be used to update any WSI meta informations e.g. Cancer Name / Tags ...
 */
export interface UpdateWSIMetaInfoPayload {
  wsiId: WSIId;
  projectId: ProjectUUID;
  wsiMetaInfo: APIWSIMetaInfo;
}
export const UPDATE_WSI_META_INFO_TYPES = asyncActionCreator(
  "UPDATE_WSI_META_INFO"
);
export const updateWSIMetaInfo = asyncAction<
  UpdateWSIMetaInfoPayload,
  APIWSIItem,
  string
>(UPDATE_WSI_META_INFO_TYPES);

/**
 * Action Creators
 * Usage: updateCancerName[type: request | success | failure](params)
 * Can be used to update multiple WSI's cancer name at once
 */
export interface UpdateCancerNamePayload {
  wsiIds: WSIId[];
  projectId: ProjectUUID;
  cancerName: CancerName;
}
export const UPDATE_CANCER_NAME_TYPES =
  asyncActionCreator("UPDATE_CANCER_NAME");
export const updateCancerName = asyncAction<
  UpdateCancerNamePayload,
  APIWSIItem[],
  string
>(UPDATE_CANCER_NAME_TYPES);

export const UPDATE_IMAGE_MASK_LOADING_STATUS =
  "UPDATE_IMAGE_MASK_LOADING_STATUS";
export const updateImageMaskLoadingStatus = (payload: boolean) =>
  action(UPDATE_IMAGE_MASK_LOADING_STATUS, payload);

/**
 * Action Creators
 * Usage: requestAnalysis[type: request | success | failure](params)
 * Request analysis on multiple WSIs
 */
export interface RequestAnalysisPayload {
  wsiIds: WSIId[];
}
export const REQUEST_ANALYSIS_TYPES = asyncActionCreator("REQUEST_ANALYSIS");
export const requestAnalysis = asyncAction<
  RequestAnalysisPayload,
  any,
  RequestAnalysisPayload
>(REQUEST_ANALYSIS_TYPES);

export const SET_NEED_TO_REFRESH_WSI_LIST = "SET_NEED_TO_REFRESH_WSI_LIST";
export const setNeedToRefreshWSIList = (payload: {
  needToRefresh: boolean;
  resetSearch?: boolean;
}) => action(SET_NEED_TO_REFRESH_WSI_LIST, payload);

export const GENERATE_REPORTS = "GENERATE_REPORTS";
export const generateReports = (payload: SlideSummaryItem[]) =>
  action(GENERATE_REPORTS, payload);

export const UPDATE_REPORT_STATE = "UPDATE_REPORT_STATE";
export const updateReportState = (payload: {
  wsiId: WSIId;
  loading: boolean;
  done: boolean;
  error?: string;
}) => action(UPDATE_REPORT_STATE, payload);

export const DONE_GENERATING_REPORT = "DONE_GENERATING_REPORT";
export const doneGeneratingReport = () => action(DONE_GENERATING_REPORT);

export const CLOSE_REPORT_PROGRESS = "CLOSE_REPORT_PROGRESS";
export const closeReportProgress = () => action(CLOSE_REPORT_PROGRESS);

export const CANCEL_REPORT_GENERATION = "CANCEL_REPORT_GENERATION";
export const cancelReportGeneration = (wsiId: string) =>
  action(CANCEL_REPORT_GENERATION, { wsiId });

/**
 * Action Creators
 * Usage: getAnalyzedSlides[type: request | success | failure](params)
 * Get analyzed slides list
 */
export const GET_ANALYZED_SLIDE_LIST_TYPES = asyncActionCreator(
  "GET_ANALYZED_SLIDE_LIST"
);
export const getAnalyzedSlides = asyncAction<
  ProjectUUID,
  APISimpleSlideItem[],
  string
>(GET_ANALYZED_SLIDE_LIST_TYPES);

export const RESET_ANALYZED_SLIDES = "RESET_ANALYZED_SLIDES";
export const resetAnalyzedSlides = () => action(RESET_ANALYZED_SLIDES);

/**
 * Action Creators
 * Usage: deleteSlides[type: request | success | failure](params)
 * Delete slides
 */
export interface DeleteSlidesPayload {
  projectId: ProjectUUID;
  ids: string[];
}
export const DELETE_SLIDES_TYPES = asyncActionCreator("DELETE_SLIDES");
export const deleteSlides = asyncAction<DeleteSlidesPayload, any, string>(
  DELETE_SLIDES_TYPES
);
export const RESET_DELETE_STATUS = "RESET_DELETE_STATUS";
export const resetDeleteStatus = () => action(RESET_DELETE_STATUS);

export const RESET_ANALYSIS_RESULT = "RESET_ANALYSIS_RESULT";
export const resetAnalysisResult = () => action(RESET_ANALYSIS_RESULT);
