import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ResizeObserverProvider } from "components/ResizeObserverContext";
import RootReportGenerator from "components/report/RootReportGenerator";
import useIdleDetector from "components/hooks/useIdleDetector";
import PageRoot from "components/common/PageTemplate/PageRoot";
import UploadingSheetManager from "components/WSIUpload/UploadingSheetManager";
import { RightPanelStateProvider } from "components/analysis/SlideRightPanel/context";
import Sheets from "components/Sheets/Sheets";
import Home from "pages/Home";
import Users from "pages/Users";
import Login from "pages/Login";
import Slides from "pages/Slides";
import Slide from "pages/Slide";
import ResetMFA from "pages/ResetMFA";
import LostMFA from "pages/LostMFA";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import withAuthGuard from "hoc/withAuthGuard";
import { getAccountIdSelector } from "dux/user/selectors";
import { StompClientProvider } from "hooks/useStompClient";
import { WEB_SOCKET_URL_BASE } from "dux/utils/apiRequestHelper";
import { QueuedThumbnailRenderingProvider } from "components/report/useQueuedThumbnailRenderer";

import { ViewerToolbarProvider } from "hooks/useViewerToolbar";
import DownloadingReportSheetManager from "components/report/sheet/DownloadingReportSheetManager";
import useNotifier from "hooks/useNotifier";
import AlertSnackBar from "components/common/AlertSnackbar";
import { useSnackbar } from "notistack";
import { supportsOffscreenCanvas } from "components/utils";
import { Link } from "@mui/material";

const MainRouteComponent = () => {
  useIdleDetector();
  useNotifier();
  const userId = useSelector(getAccountIdSelector);
  return (
    <ResizeObserverProvider>
      <StompClientProvider
        url={WEB_SOCKET_URL_BASE}
        topic={userId && `/user/${userId}/queue/notification`}
      >
        <PageRoot>
          <UploadingSheetManager />
          <DownloadingReportSheetManager />
          <Switch>
            <Route exact path="/projects">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Home />
              </LocalizationProvider>
            </Route>
            <Route
              exact
              path={[
                "/slides/:slideId",
                "/projects/:projectId/slides/:slideId",
              ]}
            >
              <RightPanelStateProvider>
                <ViewerToolbarProvider>
                  <Slide />
                </ViewerToolbarProvider>
              </RightPanelStateProvider>
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path={["/slides", "/projects/:projectId/slides"]}>
              <Slides />
            </Route>
            <Route>
              <Redirect to="/projects" />
            </Route>
          </Switch>
          <Sheets />
          <QueuedThumbnailRenderingProvider>
            <RootReportGenerator />
          </QueuedThumbnailRenderingProvider>
        </PageRoot>
      </StompClientProvider>
    </ResizeObserverProvider>
  );
};

const MainRoute = withAuthGuard(MainRouteComponent);

export const App: React.FC = () => {
  useEffect(() => {
    console.log(`
    ███████╗ ██████╗ ██████╗ ██████╗ ███████╗    ██╗ ██████╗
    ██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██║██╔═══██╗
    ███████╗██║     ██║   ██║██████╔╝█████╗      ██║██║   ██║
    ╚════██║██║     ██║   ██║██╔═══╝ ██╔══╝      ██║██║   ██║
    ███████║╚██████╗╚██████╔╝██║     ███████╗    ██║╚██████╔╝
    ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚══════╝    ╚═╝ ╚═════╝

    v${process.env.REACT_APP_COMPONENT_VERSION}
    `);
  }, []);

  const checkedOffscreen = useMemo(() => supportsOffscreenCanvas(), []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!checkedOffscreen) {
      enqueueSnackbar("", {
        persist: true,
        anchorOrigin: { vertical: "top", horizontal: "center" },
        content: (key) => {
          return (
            <AlertSnackBar
              id={key}
              severity="info"
              noAction={true}
              message="Please use your Google Chrome browser on PC with 8GB memory (RAM)"
              children={
                <Link
                  variant="body5"
                  color="inherit"
                  sx={{ textDecoration: "underline !important" }}
                  href="https://www.google.com/chrome/?brand=BNSD&gclid=Cj0KCQjwktKFBhCkARIsAJeDT0jQQUll7N8dzF5yValx0o3UIBAAXsX52h__0fwCQAD8XRSKXvAiZLoaAjlSEALw_wcB&gclsrc=aw.ds"
                >
                  Download Google Chrome
                </Link>
              }
            />
          );
        },
      });
    }
  }, [checkedOffscreen, enqueueSnackbar, closeSnackbar]);

  if (!checkedOffscreen) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:key" component={ResetPassword} />
      <Route exact path="/lost-mfa" component={LostMFA} />
      <Route exact path="/reset-mfa/:key" component={ResetMFA} />
      <Route component={MainRoute} />
    </Switch>
  );
};
