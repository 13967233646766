import { v4 as uuidV4 } from "uuid";
import { action } from "typesafe-actions";
import { EnqueueSnackbarPayload } from "./model";
import { SnackbarKey } from "notistack";

export const ENQUEUE_SNACKBAR = "ENQUEU_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = (payload: EnqueueSnackbarPayload) =>
  action(ENQUEUE_SNACKBAR, {
    ...payload,
    options: {
      ...payload.options,
      key: payload.options.key || uuidV4(),
    },
  });
export const closeSnackbar = (key?: SnackbarKey) =>
  action(CLOSE_SNACKBAR, { key, dismissAll: !key });
export const removeSnackbar = (key: SnackbarKey) =>
  action(REMOVE_SNACKBAR, { key });
