import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { MenuOpenIcon, MenuIcon, LabelIcon } from "assets/icons";
import Notification from "../Notification";
import Tooltip from "@mui/material/Tooltip";
import HeaderButton from "../HeaderButton";
import AvatarButton from "../AvatarButton";
import { useSelector } from "react-redux";
import { getAccountNameSelector } from "dux/user/selectors";
import { useContext, useEffect, useRef, useState } from "react";
import LabelDialog from "components/LabelDialog";
import { ResizeObserverContext } from "components/ResizeObserverContext";
import { Link } from "react-router-dom";

interface IHeaderProps {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  justifyContent: "flex-start",
  padding: theme.spacing(0, 2),
  minHeight: "inherit",
}));

const LeftContainer = styled("div")({
  display: "flex",
  position: "relative",
  alignItems: "center",
  minHeight: "inherit",
  flex: 1,
});

const RightContainer = styled("div")({
  display: "flex",
  flexShrink: 0,
  flexDirection: "row-reverse",
});

const Header = ({ drawerOpen, onDrawerToggle }: IHeaderProps) => {
  const name = useSelector(getAccountNameSelector);
  const [labelDialogOpen, setLabelDialogOpen] = useState(false);
  const appBarRef = useRef();
  const prevWidthRef = useRef(0);
  const { addResizeHandler, removeResizeHandler } = useContext(
    ResizeObserverContext
  );
  useEffect(() => {
    const target = appBarRef.current;
    const resizeHandler = (target) => {
      if (
        prevWidthRef.current >= 1920 &&
        target.clientWidth < 1920 &&
        drawerOpen
      ) {
        onDrawerToggle();
      }
      prevWidthRef.current = target.clientWidth;
    };
    addResizeHandler(target, resizeHandler);
    return () => removeResizeHandler(target);
  }, [addResizeHandler, removeResizeHandler, drawerOpen, onDrawerToggle]);
  return (
    <AppBar
      position="fixed"
      color="primary"
      ref={appBarRef}
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) =>
          theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Toolbar disableGutters>
        <Container>
          <Link to="/projects">
            <img
              src={"/logos/logo-SCOPE-IO-dark-big.svg"}
              width="153"
              alt="Lunit SCOPE IO-drawer"
              style={{ marginRight: "4px" }}
            />
          </Link>
          <Tooltip title={drawerOpen ? "Close side bar" : "Open side bar"}>
            <HeaderButton onClick={onDrawerToggle} id="drawer-toggle">
              {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </HeaderButton>
          </Tooltip>
          <LeftContainer id="toolbar-container" />
          <RightContainer>
            <AvatarButton name={name} />
            <Notification />
            <Tooltip title="Label">
              <HeaderButton onClick={() => setLabelDialogOpen(true)}>
                <LabelIcon />
              </HeaderButton>
            </Tooltip>
          </RightContainer>
        </Container>
      </Toolbar>
      {labelDialogOpen && (
        <LabelDialog
          open={labelDialogOpen}
          handleClose={() => setLabelDialogOpen(false)}
        />
      )}
    </AppBar>
  );
};

export default Header;
