import { SlideStatus } from "dux/slide/model";

/*
Status Mapping Table
  CREATED -> Created
  CONVERTING ->  Created or Converting?
  PRE_ANALYZING -> PreAnalyzing
  PRE_ANALYZED -> Ready
  PRE_ANAL_FAILED -> Failed
  ANALYZING -> Analyzing
  ANALYZED -> Analyzed
  ANAL_FAILED -> Failed
*/

export const slideStatusLabels = (status: SlideStatus) => {
  switch (status) {
    case SlideStatus.Analyzed:
      return "Analyzed";
    case SlideStatus.PreAnalyzing:
    case SlideStatus.Analyzing:
      return "Analyzing";
    case SlideStatus.PreAnalFailed:
    case SlideStatus.AnalFailed:
      return "Failed";
    default:
      return "Analyzing";
  }
};

/*
Created ->
CREATED, CONVERTING

PreAnalyzing ->
PRE_ANALYZING

Ready ->
PRE_ANALYZED

Failed ->
PRE_ANAL_FAILED, ANAL_FAILED

Analyzing ->
ANALYZING

Analyzed ->
ANALYZED
*/
export const apiSlideStatusToEnum = (status: string) => {
  switch (status) {
    case "ANALYZED":
      return SlideStatus.Analyzed;
    case "PRE_ANALYZING":
    case "ANALYZING":
    case "PRE_ANALYZED":
      return SlideStatus.Analyzing;
    case "PRE_ANAL_FAILED":
      return SlideStatus.PreAnalFailed;
    case "ANAL_FAILED":
      return SlideStatus.AnalFailed;
    default:
      return SlideStatus.Analyzing;
  }
};

export const slideStatusColorProps = (status: SlideStatus) => {
  switch (status) {
    case SlideStatus.PreAnalyzing:
      return "preanalyzing";
    case SlideStatus.Analyzed:
      return "analyzed";
    case SlideStatus.Analyzing:
      return "analyzing";
    case SlideStatus.AnalFailed:
    case SlideStatus.PreAnalFailed:
      return "failed";
    default:
      return "ready";
  }
};
