import ProjectList from "../components/ProjectList/ProjectList";
import PageTemplate from "components/common/PageTemplate";
import withAuthGuard from "hoc/withAuthGuard";

const Home = () => {
  return (
    <PageTemplate>
      <ProjectList />
    </PageTemplate>
  );
};

export default withAuthGuard(Home);
