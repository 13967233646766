import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ViewOption } from "dux/analysis/model";
import useOffscreenVisualization from "components/analysis/useOffscreenVisualization";
import { PresignedURL } from "dux/WSIAnalysisResult/model";
import { hexToRgba } from "components/utils";

enum RenderingTaskStatus {
  Pending = "pending",
  Rendering = "rendering",
  Done = "done",
  Reject = "reject",
}

interface RenderingTaskParam {
  image: HTMLImageElement;
  canvas: HTMLCanvasElement;
  dziMetaUrl: PresignedURL;
  biomarkerResultFilePath: PresignedURL | null;
  histologyResultFilePath?: PresignedURL | null;
  viewOptions: ViewOption[];
}

interface RenderingTask extends RenderingTaskParam {
  renderId: number; // internally managed param
  renderingStatus: RenderingTaskStatus;
  resultValue?: string;
}

interface RenderingResult {
  renderId: number;
  blobUri?: string;
  failed: boolean;
}

function convertCanvasToURI(canvas: HTMLCanvasElement) {
  return new Promise((resolve) => {
    canvas &&
      canvas.toBlob((blob) => {
        blob && resolve(URL.createObjectURL(blob));
      });
  });
}

const DEFAULT_IPMAP_FEATURES = [
  {
    id: "Inflamed",
    title: "Inflamed",
    color: "#C65F6E",
  },
  {
    id: "Immune-Excluded",
    title: "Immune-Excluded",
    color: "#91C4A3",
  },
  {
    id: "Immune-Desert",
    title: "Immune-Desert",
    color: "#143DDE",
  },
];

async function getFullWidth(url: string) {
  const body = await (await fetch(url)).text();
  const parser = new DOMParser();
  const dziMetaDoc = parser.parseFromString(body, "application/xml");
  return Number(
    dziMetaDoc.getElementsByTagName("Size")[0].getAttribute("Width")
  );
}

const tmpRenderingContext = createContext({
  pushQueue: null,
  getTaskStatus: null,
  getTaskResult: null,
  currentTask: null,
});

function QueuedThumbnailRenderingProvider({ children }) {
  const [queue, setQueue] = useState<RenderingTask[]>([]);
  const [doneTask, setDoneTask] = useState<RenderingResult[]>([]);
  const [currentTask, setCurrentTask] = useState<RenderingTask>(null);
  const [lastId, setLastId] = useState(0);

  const pushQueue = useCallback(
    (param: RenderingTaskParam) => {
      let generatedKey = lastId + 1;
      const genTask = {
        ...param,
        renderId: generatedKey,
        renderingStatus: RenderingTaskStatus.Pending,
      };
      if (!currentTask) {
        setCurrentTask(genTask);
      } else {
        setQueue([...queue, genTask]);
      }
      setLastId(generatedKey);
      console.log("added new object, last queue len : ", queue.length);
      return generatedKey;
    },
    [queue, lastId, currentTask]
  );

  const getTaskStatus = useCallback(
    (requestId: number) => {
      const foundInQueue = queue.find((value) => value.renderId === requestId);
      if (foundInQueue) {
        return foundInQueue.renderingStatus;
      }
      const foundInDone = doneTask.find(
        (value) => value.renderId === requestId
      );
      if (foundInDone) {
        return foundInDone.failed
          ? RenderingTaskStatus.Reject
          : RenderingTaskStatus.Done;
      }
      if (currentTask) {
        return RenderingTaskStatus.Pending;
      }
      return null;
    },
    [queue, doneTask, currentTask]
  );

  const getTaskResult = useCallback(
    (requestId: number) => {
      const found = doneTask.find((value) => value.renderId === requestId);
      if (!found) {
        return null;
      }
      setDoneTask([
        ...doneTask.filter((value) => value.renderId !== requestId),
      ]);
      return found.blobUri;
    },
    [doneTask]
  );

  const [prepare, , getResultData, canPrepareVisualization] =
    useOffscreenVisualization({
      biomarkerResultFilePath: currentTask?.biomarkerResultFilePath,
      histologyResultFilePath: currentTask?.histologyResultFilePath,
      viewOptions: {
        title: "View Option",
        items: [
          {
            title: "Immune Phenotype Map",
            items: currentTask?.viewOptions
              ? currentTask.viewOptions
              : DEFAULT_IPMAP_FEATURES,
          },
        ],
      },
    });

  useEffect(() => {
    if (
      getResultData &&
      canPrepareVisualization &&
      currentTask &&
      currentTask.renderingStatus === RenderingTaskStatus.Pending
    ) {
      // not to render twice at the same time.
      (async () => {
        try {
          setCurrentTask({
            ...currentTask,
            renderingStatus: RenderingTaskStatus.Rendering,
          });
          const { dziMetaUrl, canvas, image } = currentTask;
          console.log("Currently rendering :", currentTask.renderId);
          const fullWidth = await getFullWidth(dziMetaUrl);
          await prepare();
          const { indexedGridData } = getResultData();
          const { naturalWidth: width, naturalHeight: height } = image;
          canvas.width = width;
          canvas.height = height;
          const scale = fullWidth / width;
          const context = canvas.getContext("2d");
          console.log("before draw thumbnail");
          // draw thumbnail image
          context.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
          );
          console.log("after draw thumbnail");

          console.log("before draw IP Map overlay");
          indexedGridData.forEach(
            ({ grid, color, gridPixelSizeX, gridPixelSizeY }, idx) => {
              context.fillStyle = color;
              grid.points.forEach(({ minX, minY }) => {
                const data = indexedGridData[idx];
                const { r, g, b } = hexToRgba(data.color);
                context.save();
                context.fillStyle = `rgba(${r}, ${g}, ${b}, 0.4)`;
                context.fillRect(
                  minX / scale,
                  minY / scale,
                  gridPixelSizeX / scale,
                  gridPixelSizeY / scale
                );
                context.restore();
              });
            }
          );
          context.restore();
          console.log("after draw IP Map overlay");
          setCurrentTask({
            ...currentTask,
            renderingStatus: RenderingTaskStatus.Done,
            resultValue: (await convertCanvasToURI(canvas)) as string,
          });
          console.log("set done blob");
        } catch (e) {
          setCurrentTask({
            ...currentTask,
            renderingStatus: RenderingTaskStatus.Reject,
          });
          console.log("mark as failed");
        }
      })();
    }
  }, [prepare, canPrepareVisualization, currentTask, getResultData]);

  useEffect(() => {
    console.log("load on stage", queue.length, !!currentTask);
    if (!currentTask && queue.length > 0) {
      console.log("new rendering request is on stage");
      setCurrentTask(queue[0]);
      setQueue([...queue.slice(1)]);
    }
  }, [queue, currentTask]);

  useEffect(() => {
    console.log("done check");
    if (
      currentTask &&
      (currentTask.renderingStatus === RenderingTaskStatus.Done ||
        currentTask.renderingStatus === RenderingTaskStatus.Reject)
    ) {
      console.log("set done");
      setDoneTask([
        ...doneTask,
        {
          renderId: currentTask.renderId,
          blobUri: currentTask.resultValue,
          failed: currentTask.renderingStatus === RenderingTaskStatus.Reject,
        },
      ]);
      setCurrentTask(null);
    }
  }, [doneTask, currentTask]);

  return (
    <tmpRenderingContext.Provider
      value={{
        pushQueue,
        getTaskStatus,
        getTaskResult,
        currentTask,
      }}
    >
      {children}
    </tmpRenderingContext.Provider>
  );
}

const useQueuedThumbnailRenderer = () => useContext(tmpRenderingContext);

export {
  QueuedThumbnailRenderingProvider,
  RenderingTaskStatus,
  useQueuedThumbnailRenderer,
};
