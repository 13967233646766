import { useDispatch, useSelector } from "react-redux";
import { getReportStateSelector } from "dux/WSIAnalysisResult/selectors";
import { ReportProgress } from "./ReportProgress";
import { cancelReportGeneration } from "dux/WSIAnalysisResult/actions";

export default function ReportProgressSheet() {
  const dispatch = useDispatch();
  const { wsiIds, states } = useSelector(getReportStateSelector);
  return (
    <div
      style={{
        maxHeight: 448,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {wsiIds.map((key) => (
        <ReportProgress
          key={key}
          wsiId={key}
          state={states[key]}
          onCancel={() => dispatch(cancelReportGeneration(key))}
        />
      ))}
    </div>
  );
}
