import React from "react";
import LostMFAForm from "components/auth/LostMFAForm";
import AuthTemplate from "components/auth/AuthTemplate";
import withMFAGuard from "hoc/withMFAGuard";

const LostMFA: React.FC = () => {
  return (
    <AuthTemplate>
      <LostMFAForm />
    </AuthTemplate>
  );
};

export default withMFAGuard(LostMFA);
