import {
  createAsyncSaga,
  makeDefaultErrorMessageEffect,
  makeDefaultSuccessMessageEffect,
} from "dux/utils/actionHandlingHelper";
import {
  CREATE_CANCER_TYPE_TYPES,
  createCancerType,
  DELETE_CANCER_TYPE_TYPES,
  deleteCancerTypes,
  EDIT_CANCER_TYPE_TYPES,
  editCancerType,
  GET_CANCERS_TYPES,
  getCancers,
} from "dux/cancerTypes/actions";
import * as api from "dux/cancerTypes/api";
import noop from "lodash/noop";
import { call, put, takeLatest } from "redux-saga/effects";

const getCancersSaga = createAsyncSaga(getCancers, api.getCancers, noop);

const createCancerTypeSaga = createAsyncSaga(
  createCancerType,
  api.createCancerType,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully created a cancer type")
    );
    yield put(getCancers.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to create a cancer type")
);
const deleteCancerTypeSaga = createAsyncSaga(
  deleteCancerTypes,
  api.deleteCancerTypes,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully deleted a cancer type")
    );
    yield put(getCancers.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to delete a cancer type")
);
const editCancerTypeSaga = createAsyncSaga(
  editCancerType,
  api.editCancerType,
  function* () {
    yield call(
      makeDefaultSuccessMessageEffect("Successfully edited a cancer type")
    );
    yield put(getCancers.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to edit a cancer type")
);

export default function* canerTypesRootSaga() {
  yield takeLatest(GET_CANCERS_TYPES.REQUEST, getCancersSaga);
  yield takeLatest(CREATE_CANCER_TYPE_TYPES.REQUEST, createCancerTypeSaga);
  yield takeLatest(DELETE_CANCER_TYPE_TYPES.REQUEST, deleteCancerTypeSaga);
  yield takeLatest(EDIT_CANCER_TYPE_TYPES.REQUEST, editCancerTypeSaga);
}
