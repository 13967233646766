import { ClickAwayListener, Menu, MenuItem } from "@mui/material";
import { MenuProps } from "@mui/material/Menu";
import { SlideItem } from "dux/slide/model";
import { isViewerUserSelector } from "dux/user/selectors";
import { useSelector } from "react-redux";

export interface SlideContextMenuProps extends MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  onDelete?: (slideItem: SlideItem[]) => void;
  onEdit?: (slideItem: SlideItem) => void;
  handleInfo?: (slideItem: SlideItem) => void;
  slide: SlideItem;
}

const SlideContextMenu = ({
  anchorEl,
  open,
  onClose,
  onDelete,
  onEdit,
  handleInfo,
  slide,
}: SlideContextMenuProps): ReturnType<typeof Menu> => {
  //const isAnalyzer = useSelector(isAnalyzerUserSelector);
  const isViewer = useSelector(isViewerUserSelector);
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        BackdropProps={{ open: false }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ margin: (theme) => theme.spacing(0.5, 0) }}
      >
        {!isViewer && (
          <MenuItem
            onClick={() => {
              onEdit(slide);
              onClose();
            }}
          >
            Edit slide
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleInfo(slide);
            onClose();
          }}
        >
          Information
        </MenuItem>
        {!isViewer && (
          <MenuItem
            onClick={() => {
              onDelete([slide]);
              onClose();
            }}
          >
            Delete slide
          </MenuItem>
        )}
      </Menu>
    </ClickAwayListener>
  );
};

export default SlideContextMenu;
