import {
  createAsyncSaga,
  makeDefaultErrorMessageEffect,
  makeDefaultSuccessMessageEffect,
} from "dux/utils/actionHandlingHelper";
import {
  CREATE_TAG_TYPES,
  createTag,
  DELETE_TAGS_TYPES,
  deleteTags,
  EDIT_TAG_TYPES,
  editTag,
  GET_TAGS_TYPES,
  getTags,
} from "./actions";
import * as api from "./api";
import { call, put, takeLatest } from "redux-saga/effects";
import noop from "lodash/noop";

const getTagsSaga = createAsyncSaga(getTags, api.getTags, noop);
const createTagSaga = createAsyncSaga(
  createTag,
  api.createTag,
  function* () {
    yield call(makeDefaultSuccessMessageEffect("Successfully created a tag"));
    yield put(getTags.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to create a tag")
);
const deleteTagsSaga = createAsyncSaga(
  deleteTags,
  api.deleteTags,
  function* () {
    yield call(makeDefaultSuccessMessageEffect("Successfully deleted a tag"));
    yield put(getTags.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to delete a tag")
);
const editTagSaga = createAsyncSaga(
  editTag,
  api.editTag,
  function* () {
    yield call(makeDefaultSuccessMessageEffect("Successfully edited a tag"));
    yield put(getTags.request({ title: "" }));
  },
  makeDefaultErrorMessageEffect("Failed to edit a tag")
);

export default function* tagsRootSaga() {
  yield takeLatest(GET_TAGS_TYPES.REQUEST, getTagsSaga);
  yield takeLatest(CREATE_TAG_TYPES.REQUEST, createTagSaga);
  yield takeLatest(DELETE_TAGS_TYPES.REQUEST, deleteTagsSaga);
  yield takeLatest(EDIT_TAG_TYPES.REQUEST, editTagSaga);
}
