import { Button, IconButton, Tooltip, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { generateReports } from "dux/WSIAnalysisResult/actions";
import { PDFDownIcon } from "assets/icons";
import { SlideSummaryItem } from "dux/WSIAnalysisResult/model";
import { format } from "date-fns";
import { REPORT_TIME_FORMAT } from "utils/format";

interface ButtonProps {
  slides: SlideSummaryItem[];
  disabled?: boolean;
  tooltip?: string;
  mode?: "default" | "caption";
  onClick?: () => any;
}

const GenerateReportButton = ({
  slides,
  disabled,
  tooltip,
  mode = "default",
  onClick,
}: ButtonProps) => {
  const dispatch = useDispatch();
  const createTime = format(new Date(), REPORT_TIME_FORMAT);
  const reportSlides = slides.map((slide) => ({
    ...slide,
    createTime: createTime,
  }));

  const handleGenerateReports = () => {
    dispatch(generateReports(reportSlides));
    if (onClick) onClick();
  };

  if (mode === "caption") {
    return (
      <Button
        variant="contained"
        sx={{ height: 28, padding: "4px 12px" }}
        onClick={handleGenerateReports}
      >
        <Box
          sx={{
            width: 20,
            height: 20,
            marginRight: 1,
          }}
        >
          <PDFDownIcon />
        </Box>
        Report
      </Button>
    );
  }
  return (
    <Tooltip title={tooltip || "PDF download"} key="pdf-download">
      <span>
        <IconButton
          onClick={handleGenerateReports}
          disabled={slides.length < 1 || disabled}
          size="large"
        >
          <PDFDownIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default GenerateReportButton;
