import { IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CloseIcon, SuccessIcon } from "assets/icons";
import { SingleReportState } from "dux/WSIAnalysisResult/model";
import EllipsizedTypography from "components/EllipsizedTypography";

const ProgressContainer = styled("div")({
  position: "absolute",
  bottom: 0,
  width: "100%",
});

const ProgressIndicatorForStage = () => {
  return (
    <ProgressContainer>
      <LinearProgress />
    </ProgressContainer>
  );
};

interface ReportProgressProps {
  wsiId: string;
  state: SingleReportState;
  onCancel: () => void;
}

const RootContainer = styled("div")({
  display: "flex",
  width: 500,
  height: 64,
  alignItems: "center",
  position: "relative",
  "&:not(:last-child)": {
    boxShadow: "inset 0px -1px 0px rgba(134, 148, 177, 0.16)",
  },
});

const RightButton = styled(IconButton)({
  flexGrow: 0,
  flexShrink: 0,
  marginRight: "28px",
  height: "28px",
  width: "28px",
  padding: "4px",
  ":hover": {
    backgroundColor: (theme) => theme.palette.darkGrey[80],
  },
});

const filenameSx = {
  marginLeft: "32px",
  marginRight: "16px",
  flexGrow: 1,
  flexBasis: "82px",
  overflow: "hidden",
  textOverflow: "ellipsis",
} as const;

export const ReportProgress = ({ state, onCancel }: ReportProgressProps) => {
  const { name, loading, error, done, createTime } = state;
  if (loading && !error) {
    return (
      <RootContainer>
        <EllipsizedTypography variant="body5" sx={filenameSx}>
          {`lunit-scope-io-${name}-${createTime}`}
        </EllipsizedTypography>
        <ProgressIndicatorForStage />
      </RootContainer>
    );
  }
  if (error) {
    return (
      <RootContainer>
        <EllipsizedTypography variant="body5" sx={filenameSx}>
          {`lunit-scope-io-${name}-${createTime}`}
        </EllipsizedTypography>
        <Typography
          color="error"
          variant="body4"
          sx={{
            marginRight: "30px",
            flexGrow: 1,
            flexBasis: "290px",
            fontWeight: "400 !important",
          }}
        >
          {error}
        </Typography>
        {
          <Tooltip title="Retry download later">
            <span>
              <RightButton disabled size="large">
                <ErrorOutlineIcon />
              </RightButton>
            </span>
          </Tooltip>
        }
      </RootContainer>
    );
  }
  return (
    <RootContainer>
      <EllipsizedTypography variant="body5" sx={filenameSx}>
        {`lunit-scope-io-${name}-${createTime}`}
      </EllipsizedTypography>
      {!done ? (
        <Tooltip title="Cancel">
          <RightButton onClick={onCancel} size="large">
            <CloseIcon />
          </RightButton>
        </Tooltip>
      ) : (
        <Tooltip title="Done">
          <RightButton size="large">
            <SuccessIcon style={{ color: "#00C9EA" }} />
          </RightButton>
        </Tooltip>
      )}
    </RootContainer>
  );
};
